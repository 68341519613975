import React, { useContext, useEffect, useState } from "react";

import { BoardContext } from '../../../contexts/BoardContext';
import { ColumnContext } from '../../../contexts/ColumnContext';
import { UserContext } from '../../../contexts/UserContext';

import { Chart } from "react-google-charts";

import Style from './style.css';

function ChartValueColumn() {
    const { listaFilteredCards } = useContext(BoardContext);
    const { listaColumns, listaAllProcessos } = useContext(ColumnContext);
    const { listaProcessos } = useContext(UserContext);

    const [data, setData] = useState([]);

    useEffect(() => {

        const processIds = listaProcessos.map(column => column.id.toString());

        // Filtra os cards que têm id_column correspondente aos processos em listaAllProcessos
        const filteredCards = listaFilteredCards.filter(card => processIds.includes(card.id_column));

        const data = [
            ["Coluna", "Valor"],
            ...listaProcessos.map((column) => {
                const columnCards = filteredCards?.filter((card) => parseInt(card.id_column) === column.id);
                const columnValue = columnCards?.reduce((total, card) => total + parseFloat(card.valor), 0);

                return [
                    column.title,
                    columnValue,
                ];
            }),
        ];

        setData(data)
    }, [listaFilteredCards, listaAllProcessos])

    const options = {
        title: "VALOR POR PROCESSO",
        backgroundColor: "transparent", // Define a cor de fundo do gráfico
        chartArea: { width: "90%", height: "90%" }, // Define o raio de borda do gráfico

        is3D: false,
        legend: { position: "bottom" },
        fontSize: 11,

        pieSliceText: "value-and-percentage",
        pieSliceTextStyle: {
            color: "white",

        },
        tooltip: { text: "value-and-percentage" },
        colors: [
            "#FF4069",
            "#FFC233",
            "#069BFF",
            "#C749EF",
            "#01BA02",
            "#F7A24D",
            "#F66DB0",
            "#8FB1CE",
            "#FF9696", // Vermelho claro
            "#FFB396", // Laranja claro
            "#FFD996", // Amarelo claro
            "#FFFF96", // Amarelo-esverdeado claro
            "#C0FF96", // Verde claro
            "#96FFAE", // Verde azulado claro
            "#96FFD4", // Ciano claro
            "#96FFFF", // Azul claro
            "#96B6FF", // Azul arroxeado claro
            "#B996FF", // Lilás claro
            "#D696FF", // Magenta claro
            "#FF96F9", // Rosa claro
            "#FF96D2", // Rosa arroxeado claro
            "#FF96AE", // Rosa alaranjado claro
            "#FF9696", // Vermelho claro (repetido)
            "#FFB396", // Laranja claro (repetido)
            "#FFD996", // Amarelo claro (repetido)
            "#FFFF96", // Amarelo-esverdeado claro (repetido)
            "#C0FF96", // Verde claro (repetido)
            "#96FFAE", // Verde azulado claro (repetido)
        ], // Define cores personalizadas
        pieHole: 0.0, // Define o tamanho do buraco no centro do gráfico (0.4 representa 40% do tamanho total)

    };

    return (
        <div>
            <div className="grafico-container-a" style={{
            }}>
                <Chart
                    className="grafico-aa"
                    chartType="PieChart"
                    data={data}
                    options={options}
                />

            </div>
        </div>
    );
}

export default ChartValueColumn;
