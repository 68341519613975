import React, { useContext, useEffect, useState } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { UserContext } from '../../contexts/UserContext';

import apiUrl from '../../config/apiConfig';
import axios from 'axios';

function Produtos() {

    const { idEmpresa } = useContext(UserContext);

    const [produto, setProduto] = useState('');
    const [idProduto, setIdProduto] = useState('');
    const [codigo, setCodigo] = useState('');
    const [codigoExt, setCodigoExt] = useState('');
    const [cor, setCor] = useState('');
    const [valor, setValor] = useState(null);
    const [ipi, setIPI] = useState(null);
    const [conversao, setConversao] = useState(null);
    const [unidadeMedida, setUnidadeMedida] = useState('');
    const [peso, setPeso] = useState(null);
    const [tamanho, setTamanho] = useState('');
    const [seguimento, setSeguimento] = useState('');

    const [modalNovoProduto, setModalNovoProduto] = useState(false);
    const [modalUpdateProduto, setModalUpdateProduto] = useState(false);

    const [variante, setVariante] = useState('');

    const [listaProdutos, setListaProdutos] = useState([]);

    useEffect(() => {
        getProducts();
    }, []);

    function selectedProduct(data) {
        setIdProduto(data.id);
        setProduto(data.name);
        setCodigo(data.codigo);
        setCor(data.cor);
        setValor(data.valor);
        setIPI(data.ipi);
        setUnidadeMedida(data.unidade_medida);
        setConversao(data.converter);
        setPeso(data.peso);
        setTamanho(data.tamanho);
        setSeguimento(data.seguimento);
        setVariante(data.variante);
        setCodigoExt(data.codigo_ext);

        setModalUpdateProduto(true);
    }

    async function deletProduct() {
        const confirmDelete = window.confirm('Você tem certeza que deseja excluir este documento?');

        if (confirmDelete) {
            try {

                await axios.delete(`${apiUrl}/produtos/${idProduto}`);

                removerProduct(idProduto);

            } catch (error) {
                console.error('Erro ao excluir card:', error);
            }
        }
    }

    const removerProduct = (id) => {
        const novaLista = listaProdutos.filter(item => item.id !== id);
        setListaProdutos(novaLista);
        setModalUpdateProduto(false);
    };

    const updateProduct = async () => {

        try {

            const updatedProduct = {
                name: produto,
                codigo: codigo,
                cor: cor,
                valor: valor,
                unidade_medida: unidadeMedida,
                ipi: ipi,
                converter: conversao,
                peso: peso,
                tamanho: tamanho,
                seguimento: seguimento,
                variante: variante,
                codigo_ext: codigoExt,
            };

            await axios.put(`${apiUrl}/produtos/` + idProduto, updatedProduct);

            console.log('Card atualizado com sucesso no PostgreSQL');

            const productData = {
                id: idProduto,
                name: produto,
                codigo: codigo,
                cor: cor,
                valor: valor,
                unidade_medida: unidadeMedida,
                ipi: ipi,
                converter: conversao,
                peso: peso,
                tamanho: tamanho,
                seguimento: seguimento,
                variante: variante,
                codigo_ext: codigoExt,
            };

            updateProductsOffline(idProduto, productData);

        } catch (error) {
            console.error(error);
        }
    };

    const updateProductsOffline = (id, novosValores) => {

        setListaProdutos(prevLista => {
            return prevLista.map(column => {
                if (column.id === id) {
                    return { ...column, ...novosValores }; // Atualize todos os valores do item
                }
                return column;
            });
        });

        setModalUpdateProduto(false);
    };

    const getProducts = async () => {

        try {

            const url = `${apiUrl}/produtos/getProducts`;

            const params = {
                empresa: idEmpresa
            };

            const response = await axios.get(url, { params });
            const products = response.data;


            if (products && products.length > 0) {
                setListaProdutos(products);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const createProduct = async () => {

        try {

            const newProduct = {
                empresa: idEmpresa,
                name: produto,
                codigo: codigo,
                cor: cor,
                valor: valor,
                unidade_medida: unidadeMedida,
                ipi: ipi,
                converter: conversao,
                peso: peso,
                tamanho: tamanho,
                seguimento: seguimento,
            };

            const response = await axios.post(`${apiUrl}/produtos`, newProduct);
            const idProduct = response.data.id;

            console.log(idProduct);

            createProductOffline(idProduct);

        } catch (error) {
            console.log('Erro ao cadastrar produto');
        }
    };

    function createProductOffline(idProduct) {
        console.log('listaProdutos');

        const newProduct = {
            id: idProduct,
            empresa: idEmpresa,
            name: produto,
            codigo: codigo,
            cor: cor,
            valor: valor,
            unidade_medida: unidadeMedida,
            ipi: ipi,
            converter: conversao,
            peso: peso,
            tamanho: tamanho,
            seguimento: seguimento,
        };

        const listaAtualProdutos = [...listaProdutos, newProduct];
        setListaProdutos(listaAtualProdutos);

        console.log(listaAtualProdutos);
        setModalNovoProduto(false);

    };


    const cores = [
        { id: '0', cor: 'BRANCO' },
        { id: '1', cor: 'GOLDEN OAK' },
        { id: '2', cor: 'NOGUEIRA' },
        { id: '3', cor: 'PRETO LISO' },
        { id: '4', cor: 'PRETO TEXTURADO' },
        { id: '5', cor: 'BRONZE' },
        { id: '6', cor: 'GRAFITE' },
        { id: '7', cor: 'PIRITA' },
        { id: '8', cor: 'EXTERNO GOLDEN OAK' },
        { id: '9', cor: 'EXTERNO NOGUEIRA' },
        { id: '10', cor: 'EXTERNO PRETO LISO' },
        { id: '11', cor: 'EXTERNO PRETO TEXTURADO' },
        { id: '12', cor: 'EXTERNO BRONZE' },
        { id: '13', cor: 'EXTERNO GRAFITE' },
        { id: '14', cor: 'EXTERNO PIRITA' },
        { id: '15', cor: 'PRETO' },
        { id: '16', cor: 'NATURAL' },
    ];

    const und = [
        { id: '0', und: 'pc', descricao: 'PEÇA' },
        { id: '1', und: 'm', descricao: 'METRO' },
        { id: '2', und: 'br', descricao: 'BARRA' },
        { id: '3', und: 'p', descricao: 'PACOTE' },
    ];


    function selectCor(name) {
        setCor(name);
        openCloseSelectCor();
    }

    const [selectedCor, setSelectedCor] = useState(false);
    function openCloseSelectCor() {
        setSelectedCor(!selectedCor);
    }


    function selectUnidadeMedida(name) {
        setUnidadeMedida(name);
        openCloseSelectUnidadeMedida();
    }

    const [selectedUnidadeMedida, setSelectedUnidadeMedida] = useState(false);
    function openCloseSelectUnidadeMedida() {
        setSelectedUnidadeMedida(!selectedUnidadeMedida);
    }

    function newProduct() {
        setModalNovoProduto(true)

        setProduto('');

        setCodigo('');
        setCor('');
        setValor(null);
        setIPI(null);
        setConversao(null);
        setUnidadeMedida('');
        setPeso(null);
        setTamanho(null);
        setSeguimento('');
    }

    const [searchValue, setSearchValue] = useState('');

    //console.log(listaProdutos)

    return (
        <div className='produtos-modal'>

            <div className='produtos-container'>

                <div className='produtos-header'>
                    <label className='title'>Produtos</label>
                </div>

                <div className='produtos-header-buscar'>

                    <label className='title-filter-produto-table'>Filtrar:</label>

                    <input
                        style={{ backgroundColor: searchValue != '' ? 'deeppink' : '' }}
                        className='input-search-orcamento'
                        type="text"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="Buscar código, descrição..."
                    />
                </div>

                <div className='produtos-body'>

                    <li className='item-produto'>
                        <label className='item-lista-produtos-adm-codigo'>CODIGO</label>
                        <label className='item-lista-produtos-adm'>NOME</label>
                        <label className='item-lista-produtos-cor'>COR</label>
                        <label className='item-lista-produtos-cor'>VALOR</label>
                        <label className='item-lista-produtos-cor'>IPI</label>
                        <label className='item-lista-produtos-cor'>VARIANTE</label>
                        <label className='item-lista-produtos-cor'>PESO</label>
                        <label className='item-lista-produtos-cor'>CODIGO EXTERNO</label>
                    </li>

                    {
                        listaProdutos
                            .filter((item) =>

                                item.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                item.codigo?.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((item) => (
                                <li key={item.id} className='item-produto' onClick={() => selectedProduct(item)}>
                                    <label className='item-lista-produtos-adm-codigo'>{item.codigo}</label>
                                    <label className='item-lista-produtos-adm'>{item.name}</label>
                                    <label className='item-lista-produtos-cor'>{item.cor}</label>
                                    <label className='item-lista-produtos-cor'>{parseFloat(item.valor ? item.valor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>   
                                    <label className='item-lista-produtos-cor'>{item.ipi}</label>
                                    <label className='item-lista-produtos-cor'>{item.variante}</label>
                                    <label className='item-lista-produtos-cor'>{item.peso}</label>
                                    <label className='item-lista-produtos-cor'>{item.codigo_ext}</label>
                                </li>
                            ))
                    }

                </div>

                <div className='produtos-footer'>

                </div>

            </div>

            <button className='btn-create-produto' onClick={() => newProduct()}>+</button>

            {
                modalNovoProduto ?
                    <div className='modal-novo-produto-container'>
                        <div className='modal-novo-produto'>

                            <div className='novo-produto-header'>
                                <label className='title'>Criar novo Produto</label>
                                <button className='btn-close-novo-produto' onClick={() => setModalNovoProduto(false)}>x</button>
                            </div>

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' type='text' onChange={(e) => setCodigo(e.target.value)} placeholder={'Digite o código...'}></input>
                            </div>

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' type='text' onChange={(e) => setProduto(e.target.value)} placeholder={'Digite o nome do produto...'}></input>
                            </div>

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' type='text' value={cor ?? ''} readOnly onClick={openCloseSelectCor} placeholder={'Selecione a cor...'}></input>
                            </div>

                            {selectedCor ? (
                                <div className='produto-select'>
                                    {
                                        cores.map((item) => <li key={item.id} className='item-list-select-produto' onClick={() => selectCor(item.cor)}>{item.cor}</li>)
                                    }
                                </div>
                            ) : <></>}

                            <div className='novo-produto-body'>
                                <input
                                    className='input-novo-roduto'
                                    type='number'
                                    step='0.01'
                                    value={valor}
                                    onChange={(e) => setValor(parseFloat(e.target.value))}
                                    placeholder={'Valor de Venda...'}>
                                </input>
                            </div>

                            <div className='novo-produto-body'>
                                <input
                                    className='input-novo-roduto'
                                    type='number'
                                    step='0.01'
                                    value={ipi}
                                    onChange={(e) => setIPI(parseFloat(e.target.value))}
                                    placeholder={'IPI...'}>
                                </input>
                            </div>

                            <div className='novo-produto-body'>
                                <input
                                    className='input-novo-roduto'
                                    type='number'
                                    step='0.01'
                                    value={peso}
                                    onChange={(e) => setPeso(parseFloat(e.target.value))}
                                    placeholder={'Peso em gramas'}>
                                </input>
                            </div>

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' type='text' value={unidadeMedida ?? ''} readOnly onClick={openCloseSelectUnidadeMedida} placeholder={'Selecione a unidade de medida...'}></input>
                            </div>

                            {selectedUnidadeMedida ? (
                                <div className='produto-select'>
                                    {
                                        und.map((item) => <li key={item.id} className='item-list-select-produto' onClick={() => selectUnidadeMedida(item.descricao)}>{item.descricao} - {item.und}</li>)
                                    }
                                </div>
                            ) : <></>}

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' value={tamanho} type='text' onChange={(e) => setTamanho(e.target.value)} placeholder={'Medidas do produto...'}></input>
                            </div>


                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' type='text' onChange={(e) => setSeguimento(e.target.value)} placeholder={'Seguimento..'}></input>
                            </div>

                            <div className='novo-produto-body'>
                                <input
                                    className='input-novo-roduto'
                                    type='number'
                                    step='0.01'
                                    value={conversao}
                                    onChange={(e) => setConversao(parseFloat(e.target.value))}
                                    placeholder={'Conversão'}>
                                </input>
                            </div>

                            <div className='novo-produto-footer'>
                                <button className='btn-excluir-novo-produto' ></button>

                                <button className='btn-save-novo-produto' onClick={() => createProduct()}>Salvar</button>
                            </div>
                        </div>
                    </div>
                    : <></>
            }

            {
                modalUpdateProduto ?
                    <div className='modal-novo-produto-container'>
                        <div className='modal-novo-produto'>

                            <div className='novo-produto-header'>
                                <label className='title'>Atualizar Produto</label>
                                <button className='btn-close-novo-produto' onClick={() => setModalUpdateProduto(false)}>x</button>
                            </div>



                            <div className='novo-produto-body'>
                                <input disabled={true} style={{color: 'red'}} className='input-novo-roduto' value={codigo} type='text' onChange={(e) => setCodigo(e.target.value)} placeholder={'Digite o código...'}></input>
                            </div>

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' value={produto} type='text' onChange={(e) => setProduto(e.target.value)} placeholder={'Digite o nome do produto...'}></input>
                            </div>


                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' type='text' value={cor ?? ''} readOnly onClick={openCloseSelectCor} placeholder={'Selecione a cor...'}></input>
                            </div>

                            {selectedCor ? (
                                <div className='produto-select'>
                                    {
                                        cores.map((item) => <li key={item.id} className='item-list-select-produto' onClick={() => selectCor(item.cor)}>{item.cor}</li>)
                                    }
                                </div>
                            ) : <></>}

                            <div className='novo-produto-body'>
                                <input
                                    className='input-novo-roduto'
                                    type='number'
                                    step='0.01'
                                    value={valor}
                                    onChange={(e) => setValor(parseFloat(e.target.value))}
                                    placeholder={'Valor de Venda...'}>
                                </input>
                            </div>

                            <div className='novo-produto-body'>
                                <input
                                    className='input-novo-roduto'
                                    type='number'
                                    step='0.01'
                                    value={ipi}
                                    onChange={(e) => setIPI(parseFloat(e.target.value))}
                                    placeholder={'IPI...'}>
                                </input>
                            </div>

                            <div className='novo-produto-body'>
                                <input
                                    className='input-novo-roduto'
                                    type='number'
                                    step='0.01'
                                    value={peso}
                                    onChange={(e) => setPeso(parseFloat(e.target.value))}
                                    placeholder={'Peso em gramas'}>
                                </input>
                            </div>

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' value={tamanho} type='text' onChange={(e) => setTamanho(e.target.value)} placeholder={'Medidas do produto...'}></input>
                            </div>


                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' type='text' value={unidadeMedida ?? ''} readOnly onClick={openCloseSelectUnidadeMedida} placeholder={'Selecione a unidade de medida...'}></input>
                            </div>

                            {selectedUnidadeMedida ? (
                                <div className='produto-select'>
                                    {
                                        und.map((item) => <li key={item.id} className='item-list-select-produto' onClick={() => selectUnidadeMedida(item.descricao)}>{item.descricao} - {item.und}</li>)
                                    }
                                </div>
                            ) : <></>}

                            <div className='novo-produto-body'>
                                <input value={seguimento} className='input-novo-roduto' type='text' onChange={(e) => setSeguimento(e.target.value)} placeholder={'Seguimento..'}></input>
                            </div>


                            <div className='novo-produto-body'>
                                <input
                                    className='input-novo-roduto'
                                    type='number'
                                    step='0.01'
                                    value={conversao}
                                    onChange={(e) => setConversao(parseFloat(e.target.value))}
                                    placeholder={'Conversão'}>
                                </input>
                            </div>

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' value={codigoExt} type='text' onChange={(e) => setCodigoExt(e.target.value)} placeholder={'Digite o código externo...'}></input>
                            </div>

                            <div className='novo-produto-body'>
                                <input className='input-novo-roduto' value={variante} type='text' onChange={(e) => setVariante(e.target.value)} placeholder={'Digite a variante...'}></input>
                            </div>


                            <div className='novo-produto-footer'>
                                <button className='btn-excluir-novo-produto' onClick={() => deletProduct()}>Excluir</button>
                                <button className='btn-save-novo-produto' onClick={() => updateProduct()}>Salvar</button>
                            </div>
                        </div>
                    </div>
                    : <></>
            }

        </div>

    );
}
export default Produtos;