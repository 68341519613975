import React, { useContext, useState } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

// FIREBASE
import fb from '../../config/firebase';
import { getFirestore, addDoc, collection } from 'firebase/firestore';

function CreateParitipante() {

    //--- FIREBASE
    const db = getFirestore(fb);

    const { openCloseCreateParticipanteModal } = useContext(BoardContext);
    const { listaParticipantes,
        setListaParticipantes, idUser, setUsers } = useContext(UserContext);

    const [nameParticipante, setNameParticipante] = useState('');
    const [identidade, setIdentidade] = useState('');
    const [emailParticipante, setEmailParticipante] = useState('');
    const [adressParticipante, setAdressParticipante] = useState('');
    const [cityParticipante, setCityParticipante] = useState('');
    const [estadoParticipante, setEstadoParticipante] = useState('');
    const [cepParticipante, setCepParticipante] = useState('');
    const [foneParticipante, setFoneParticipante] = useState('');
    const [tipoParticipante, setTipoParticipante] = useState('Empresa');

    const [info, setInfo] = useState('');

    function validationForm() {

        if (nameParticipante != '' && emailParticipante != '' && estadoParticipante != '' && cityParticipante != '' && foneParticipante != '') {
            //addParticipanteFirestore();
            createParticipante();
        } else {
            setInfo('Algum campo esta vazio!')
        }

    }

    const [listaEstadosIBGE, setListaEstadosIBGE] = useState([]);
    const [selectedEstadoIBGE, setSelectedEstadoIBGE] = useState(false);

    async function getEstados() {
        try {
            const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const estados = response.data;
            setListaEstadosIBGE(estados);
            return estados;
        } catch (error) {
            console.error('Erro ao obter a lista de estados:', error);
            return [];
        }
    }

    function getEstadosCidades() {
        getEstados();
        openCloseSelectEstadoIBGE();
    }

    function openCloseSelectEstadoIBGE() {
        setSelectedEstadoIBGE(!selectedEstadoIBGE);
    }

    function selectEstado(id, sigla) {
        setEstadoParticipante(sigla);
        openCloseSelectEstadoIBGE();
        setSelectedCidadeIBGE(true);
        getCidades(id);
    }

    const [selectedCidadeIBGE, setSelectedCidadeIBGE] = useState(false);
    const [listaCidadesIBGE, setListaCidadesIBGE] = useState([]);

    async function getCidades(estadoId) {
        try {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`);
            const cidades = response.data;
            setListaCidadesIBGE(cidades);
            return cidades;
        } catch (error) {
            console.error('Erro ao obter a lista de cidades:', error);
            return [];
        }
    }

    function selectCidade(id, nome) {
        setCityParticipante(nome);
        setSelectedCidadeIBGE(false);
    }

    async function addParticipanteFirestore() {

        try {
            const cardsCollectionRef = collection(db, 'participante');
            const docRef = await addDoc(cardsCollectionRef, {
                id_user: idUser,
                name: nameParticipante,
                identidade: identidade,
                email: emailParticipante,
                adress: adressParticipante,
                city: cityParticipante,
                estado: estadoParticipante,
                cep: cepParticipante,
                fone: foneParticipante,
                tipo_participante: tipoParticipante,
            });

            const id = docRef.id;

            const novoItem = {
                id: id,
                id_user: idUser,
                name: nameParticipante,
                identidade: identidade,
                email: emailParticipante,
                adress: adressParticipante,
                city: cityParticipante,
                estado: estadoParticipante,
                cep: cepParticipante,
                fone: foneParticipante,
                tipo_participante: tipoParticipante,
            };

            const listaCardsAtualizada = [...listaParticipantes, novoItem];
            setListaParticipantes(listaCardsAtualizada);

            openCloseCreateParticipanteModal();

        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }


    const createParticipante = async () => {

        try {

            const data = {
                id_user: idUser,
                name: nameParticipante,
                identidade: identidade,
                email: emailParticipante,
                adress: adressParticipante,
                city: cityParticipante,
                estado: estadoParticipante,
                cep: cepParticipante,
                fone: foneParticipante,
                tipo_participante: tipoParticipante,
            };

            const response = await axios.post(`${apiUrl}/participantes`, data);

            const idNewParticipante = response.data.id;

            const newParticipante = {
                id: idNewParticipante,
                id_user: idUser,
                name: nameParticipante,
                identidade: identidade,
                email: emailParticipante,
                adress: adressParticipante,
                city: cityParticipante,
                estado: estadoParticipante,
                cep: cepParticipante,
                fone: foneParticipante,
                tipo_participante: tipoParticipante,
            };

            const listaParticipantesAtualizada = [...listaParticipantes, newParticipante];

            setListaParticipantes(listaParticipantesAtualizada);
            openCloseCreateParticipanteModal();

        } catch (error) {

            console.error('Erro ao criar Participante!');
        }
    };

    return (
        <div className='modal-create-participante-container'>

            <div className='create-participante-container'>

                <div className='create-participante-header'>
                    <div className='title'>Criar Participante</div>
                    <div className='btn-close-container'>
                        <button className='btn-close' onClick={openCloseCreateParticipanteModal}>X</button>
                    </div>
                </div>

                <div className='create-participante-body'>

                    <label className='title'>Nome</label>
                    <input className='input-create-participante' type='text' value={nameParticipante} onChange={(e) => setNameParticipante(e.target.value)} placeholder={'Digite o nome...'}></input>

                    <label className='title'>CPF/CNPJ</label>
                    <input className='input-create-participante' type='text' value={identidade} onChange={(e) => setIdentidade(e.target.value)} placeholder={'Digite o CPF ou CNPJ...'}></input>

                    <label className='title'>Email de contato</label>
                    <input className='input-create-participante' type='email' value={emailParticipante} onChange={(e) => setEmailParticipante(e.target.value)} placeholder={'Digite o email de contato...'}></input>

                    <label className='title'>Endereço</label>
                    <input className='input-create-participante' type='text' value={adressParticipante} onChange={(e) => setAdressParticipante(e.target.value)} placeholder={'Digite o endereço...'}></input>

                    <label className='title'>Estado</label>
                    <input className='input-create-participante' type='text' value={estadoParticipante || ''} readOnly onClick={getEstadosCidades} placeholder={'Selecione o estado...'}></input>
                    {selectedEstadoIBGE ? (
                        <div className='card-select'>
                            {
                                listaEstadosIBGE.map((estado) => <li key={estado.id} className='item-list-select' onClick={() => selectEstado(estado.id, estado.sigla)}>{estado.sigla}</li>)
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Cidade</label>
                    <input className='input-create-participante' type='text' value={cityParticipante || ''} readOnly onClick={() => selectCidade(true)} placeholder={'Selecione a cidade...'}></input>
                    {selectedCidadeIBGE ? (
                        <div className='card-select'>
                            {
                                listaCidadesIBGE.map((cidade) => <li key={cidade.id} className='item-list-select' onClick={() => selectCidade(cidade.id, cidade.nome)}>{cidade.nome}</li>)
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>CEP</label>
                    <input className='input-create-participante' type='text' value={cepParticipante} onChange={(e) => setCepParticipante(e.target.value)} placeholder={'Digite o CEP...'}></input>

                    <label className='title'>Fone</label>
                    <input className='input-create-participante' type='tel' value={foneParticipante} onChange={(e) => setFoneParticipante(e.target.value)} placeholder={'Digite o fone...'}></input>

                </div>

                <div className='create-participante-footer'>
                    <div className='info-create-participante'>{info}</div>
                    <button className='btn-cancel' onClick={openCloseCreateParticipanteModal}>Cancelar</button>
                    <button className='btn-save' onClick={validationForm}>Salvar</button>
                </div>

            </div>
        </div>
    );
}

export default CreateParitipante;

