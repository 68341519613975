import React, { useContext, useState } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';
import { CardContext } from '../../contexts/CardContext';
import Users from '../Users';
import Produtos from '../Produtos';
import Processos from '../Processos';

// EXCEL
import ImportExcel from '../ImportExcel';
import Atividades from '../Atividades';

import { utils, writeFile } from 'xlsx';

function Admin() {

    const { openCloseModalImportExcel, modalImprtExcel, editProcessModal, setEditProcessModal } = useContext(BoardContext);
    const { tipoParticipante, listaUsers } = useContext(UserContext);

    const { listaCards } = useContext(CardContext);

    const [usersModal, setUsersModal] = useState(false);
    const [produtosModal, setProdutosModal] = useState(false);
    const [atividadesModal, setAtividadesModal] = useState(false);

    if (tipoParticipante != "Administrador") {
        window.location.href = "/";
    }

    function selectModal(menu) {
        if (menu == 'users') {
            setUsersModal(true)
            setProdutosModal(false)
            setAtividadesModal(false)
            setEditProcessModal(false)
        }

        if (menu == 'produtos') {
            setUsersModal(false)
            setAtividadesModal(false)
            setProdutosModal(true)
            setEditProcessModal(false)
        }

        if (menu == 'atividades') {
            setUsersModal(false)
            setProdutosModal(false)
            setAtividadesModal(true)
            setEditProcessModal(false)
        }

        if (menu == 'processos') {
            setUsersModal(false)
            setProdutosModal(false)
            setAtividadesModal(false)
            setEditProcessModal(true)
        }

    }

function ExportBackupExcel() {

    //console.log(listaCards)
    //console.log(listaUsers)

    // Crie um array com os cabeçalhos das colunas
    const headers = [
        'ID',
        'City',
        'Conclusao Producao',
        'Cor',
        'Create By',
        'Date',
        'Document Card',
        'Edit Date',
        'Email',
        'Empresa',
        'Entrega Vidro',
        'Estado',
        'Etapa Producao',
        'Etiqueta',
        'Fone',
        'Horas Producao',
        'ID Column',
        'ID Create By',
        'Meio Contato',
        'Metros Quadrados',
        'Modification Date',
        'Motivo Perda',
        'Name',
        'Name Contato',
        'Name Obra',
        'Name Obra Cliente',
        'Name User',
        'Nivel',
        'Numero Pedido',
        'Obs',
        'Prazo Entrega',
        'Previsao',
        'Previsao Assistencia',
        'Previsao Entrega',
        'Previsao Instalacao',
        'Previsao Producao',
        'Prioridade',
        'Produto',
        'Quadros',
        'Quantidade Esquadrias',
        'Recebimento Medidas',
        'Status',
        'Status Vidro',
        'Valor',
        'Valor Venda',
        'Lista Anexos',
        'Lista Compartilhar',
        'Lista Historico',
        'Lista Tarefas',
    ];

    // Crie um array para armazenar os dados dos cards
    const data = listaCards.map(card => ([
        card.id,
        card.city || '',
        card.conclusao_producao || '',
        card.cor || '',
        card.create_by || '',
        card.date || '',
        card.document_card || '',
        card.edit_date || '',
        card.email || '',
        card.empresa || '',
        card.entrega_vidro || '',
        card.estado || '',
        card.etapa_producao || '',
        card.etiqueta || '',
        card.fone || '',
        card.horas_producao || '',
        card.id_column || '',
        card.id_create_by || '',
        card.meio_contato || '',
        card.metros_quadrados || '',
        card.modification_date || '',
        card.motivo_perca || '',
        card.name || '',
        card.name_contato || '',
        card.name_obra || '',
        card.name_obra_cliente || '',
        card.name_user || '',
        card.nivel || '',
        card.numero_pedido || '',
        card.obs || '',
        card.prazo_entrega || '',
        card.previsao || '',
        card.previsao_assistencia || '',
        card.previsao_entrega || '',
        card.previsao_instalacao || '',
        card.previsao_producao || '',
        card.prioridade || '',
        card.produto || '',
        card.quadros || '',
        card.quantidade_esquadrias || '',
        card.recebimento_medidas || '',
        card.status || '',
        card.status_vidro || '',
        card.valor || '',
        card.valor_venda || '',
        JSON.stringify(card.lista_anexos || []),
        JSON.stringify(card.lista_compartilhar || []),
        JSON.stringify(card.lista_historico || []),
        JSON.stringify(card.lista_tarefas || []),
    ]));

    // Crie uma nova planilha
    const ws = utils.aoa_to_sheet([headers, ...data]);

    // Crie um novo livro de trabalho e adicione a planilha
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Backup");

    // Salve o arquivo Excel
    writeFile(wb, "backup.xlsx");


    ExportUserInformation(listaUsers)

}  


function ExportUserInformation(listaUsers) {
    // Crie um array com os cabeçalhos das colunas
    const headers = [
        'ID',
        'Name',
        'Login',
        'Email',
        'City',
        'Estado',
        'Adress',
        'Cep',
        'Fone',
        'Empresa',
        'Entidade Preference',
        'Tipo Participante',
        'Atividade',
        'Avatar',
        'Meta',
        'Meta Grupo',
        'Modulos',
        'Lista Afilhados',
        'Lista Processos'
    ];

    // Crie um array para armazenar os dados de todos os usuários
    const data = listaUsers.map(user => ([
        user.id,
        user.name || '',
        user.login || '',
        user.email || '',
        user.city || '',
        user.estado || '',
        user.adress || '',
        user.cep || '',
        user.fone || '',
        user.empresa || '',
        user.entidade_preference || '',
        user.tipo_participante || '',
        user.atividade || '',
        user.avatar || '',
        user.meta || '',
        user.meta_grupo || '',
        user.modulos || '',
        JSON.stringify(user.lista_afilhados || []),
        JSON.stringify(user.lista_processos || [])
    ]));

    // Crie uma nova planilha
    const ws = utils.aoa_to_sheet([headers, ...data]);

    // Crie um novo livro de trabalho e adicione a planilha
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "UserInformation");

    // Salve o arquivo Excel
    writeFile(wb, "user_information.xlsx");
}



    return (

        <div className='adm-container'>

            <div className='adm-header'>

                <label className='title'>Admin</label>

            </div>

            <div className='menu-superior-adm'>

                <button className='btns-adm' onClick={openCloseModalImportExcel}>Importar</button>
                {
                    tipoParticipante != 'free' ? (
                        <button className='btns-adm' onClick={() => selectModal('users')}>Usuários</button>
                    ) : <></>}
                <button className='btns-adm' onClick={() => selectModal('produtos')}>Produtos</button>
                <button className='btns-adm' onClick={() => selectModal('atividades')}>Interação</button>
                <button className='btns-adm' onClick={() => selectModal('processos')}>Processos</button>
                <button className='btns-adm' onClick={() => ExportBackupExcel()}>Backup</button>

            </div>

            <div className='adm-body'>

                {modalImprtExcel ? (<ImportExcel />) : <></>}
                {usersModal && tipoParticipante != 'free' ? (<Users />) : <></>}
                {produtosModal ? (<Produtos />) : <></>}
                {atividadesModal ? (<Atividades />) : <></>}
                {editProcessModal ? (<Processos />) : <></>}

            </div>

        </div>

    );
}
export default Admin;