import React, { useContext, useState } from 'react'
import './style.css'

// CONTEXT API
import { CardContext } from '../../contexts/CardContext';
import { UserContext } from '../../contexts/UserContext';

// IDs ÚNICOS
import { v4 as uuidv4 } from 'uuid';

// DATE
import { format } from 'date-fns';

import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

function Historico() {

    const { listaHistoricoCard, setListaHistoricoCard, setEditDateCard, statusCard } = useContext(CardContext);
    const { nameUser } = useContext(UserContext);

    const [titleHistorico, setTitleHistorico] = useState('');

    function addHistorico() {

        if (statusCard != 'Vendido' && statusCard != 'Instalacao' && statusCard != 'Entregue' && statusCard != 'InstalacaoExt' && statusCard != 'Perdido' && statusCard != 'Arquivado') {
            setEditDateCard(new Date());
        }
        setEditDateCard(new Date());

        const novoHistorico = { id: uuidv4(), title: `${titleHistorico} ( ${nameUser} )`, date: format(new Date(), 'dd/MM/yyyy'), edit: true };

        if (listaHistoricoCard && titleHistorico && titleHistorico.length > 0) {

            setListaHistoricoCard([...listaHistoricoCard, novoHistorico]);
        }

        if (!listaHistoricoCard && titleHistorico && titleHistorico.length > 0) {

            setListaHistoricoCard([novoHistorico]);
        }
    }

    function excluirItem(id) {

        const confirmDelete = window.confirm("Você tem certeza que deseja excluir este documento?");

        if (confirmDelete) {

            const novaLista = listaHistoricoCard.filter(item => item.id !== id);
            setListaHistoricoCard(novaLista);

        } else {
            return;
        }

    };

    const [openCloseHistórico, setOpenCloseHistórico] = useState(false);

    return (
        <div className='historico-container'>

            <div className='header-historico'>
                <label className='title-historico'>Histórico</label>
                {!openCloseHistórico && <MdArrowDropDown onClick={() => setOpenCloseHistórico(!openCloseHistórico)} className='icon-open-close'/>}
                {openCloseHistórico && <MdArrowDropUp onClick={() => setOpenCloseHistórico(!openCloseHistórico)} className='icon-open-close'/>}
            </div>

            {openCloseHistórico && <div className='body-historico'>

                <div className='input-historico-container'>


                    <textarea
                        className='input-edit-date-historico'
                        value={titleHistorico}
                        onChange={(e) => setTitleHistorico(e.target.value)}
                        placeholder={'Descrição...'}
                        // Para permitir que o textarea cresça automaticamente conforme você digita mais linhas
                        style={{ minHeight: '100px', borderRadius: '5px', border: '1px solid #B3B3B3', padding: '10px', marginBottom: '10px' }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault(); // Evita que o evento padrão de "Enter" seja executado
                                setTitleHistorico(prevValue => prevValue + '\n'); // Adiciona uma quebra de linha ao valor atual
                            }
                        }}
                        // Para que o textarea cresça automaticamente conforme você digita mais linhas
                        rows={1}
                        // Para que o textarea cresça automaticamente conforme você digita mais linhas
                        wrap="soft"
                    />


                </div>

                <div className='btn-add-historico-container'>
                    <button className='btn-add-historico' onClick={addHistorico}>Adicionar</button>
                </div>

                <div className='lista-historico-container'>
                    {
                        false && listaHistoricoCard && listaHistoricoCard.length > 0 ? (
                            listaHistoricoCard
                                .sort((a, b) => a.id - b.id) // Ordena a lista pelo ID
                                .map((item) =>
                                    <li className='item-historico' key={item.id}>
                                        <label className='descricao-historico'>{item.title}</label>
                                        <div className='container-rigth-historico'>
                                            <label className='date-historico'>{item.date}</label>
                                            <button disabled={!item.edit} style={{ backgroundColor: item.edit ? '#d1d1d1' : '#d1d1d1' }} className='btn-del-historico' /*onClick={() => excluirItem(item.id)} */ >X</button>
                                        </div>
                                    </li>
                                )
                        ) : <></>
                    }

                </div>

                <div className='lista-historico-container'>
                    {listaHistoricoCard && listaHistoricoCard.length > 0 ? (
                        listaHistoricoCard
                            .sort((a, b) => a.id - b.id)
                            .map((item) => (
                                <li className='item-historico' key={item.id}>
                                    {/* Substitui '\n' por '<br>' para exibir quebras de linha */}
                                    <label
                                        className='descricao-historico'
                                        dangerouslySetInnerHTML={{ __html: item.title.replace(/\n/g, '<br>') }}
                                    />
                                    <div className='container-rigth-historico'>
                                        <label className='date-historico'>{item.date}</label>
                                        <button
                                            disabled={!item.edit}
                                            style={{ backgroundColor: item.edit ? '#d1d1d1' : '#d1d1d1' }}
                                            className='btn-del-historico'
                                        /*onClick={() => excluirItem(item.id)} */
                                        >
                                            X
                                        </button>
                                    </div>
                                </li>
                            ))
                    ) : (
                        <></>
                    )}
                </div>


            </div>
            }

            <div className='footer-tarefas'>

            </div>
        </div>
    );
}

export default Historico;