import React, { useContext, useEffect, useState } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { UserContext } from '../../contexts/UserContext';

import apiUrl from '../../config/apiConfig';
import axios from 'axios';

function Processos() {

    const { idEmpresa } = useContext(UserContext);

    const [processo, setProcesso] = useState('');
    const [setor, setSetor] = useState('');
    const [idProcesso, setIdProcesso] = useState('');

    const [modalNovoProduto, setModalNovoProduto] = useState(false);
    const [modalUpdateProduto, setModalUpdateProduto] = useState(false);

    const [listaProcessos, setListaProcessos] = useState([]);

    const [orderNumber, setOrderNumber] = useState(0);

    useEffect(() => {
        getProcessos();
    }, []);

    function selectedProcesso(id, processo, setor, ordem) {
        setIdProcesso(id);
        setProcesso(processo);
        setSetor(setor);
        setOrderNumber(ordem); // Limpa o número de ordem
        setModalUpdateProduto(true);
    }

    async function deleteProcesso() {
        const confirmDelete = window.confirm('Você tem certeza que deseja excluir este documento?');

        if (confirmDelete) {
            try {

                await axios.delete(`${apiUrl}/processo/${idProcesso}`);

                removerProduct(idProcesso);

            } catch (error) {
                console.error('Erro ao excluir card:', error);
            }
        }
    }

    const removerProduct = (id) => {
        const novaLista = listaProcessos.filter(item => item.id !== id);
        setListaProcessos(novaLista);
        setModalUpdateProduto(false);
    };

    const updateProcesso = async () => {

        try {

            const updatedProcesso = {
                title: processo,
                setor: setor,
                ordem: orderNumber, // Adicione orderNumber aqui
            };

            await axios.put(`${apiUrl}/processo/` + idProcesso, updatedProcesso);

            console.log('Card atualizado com sucesso no PostgreSQL');

            const productData = {
                id: idProcesso,
                title: processo,
                setor: setor,
                ordem: orderNumber, // Adicione orderNumber aqui
            };

            updateProductsOffline(idProcesso, productData);

        } catch (error) {
            console.error(error);
        }
    };

    const updateProductsOffline = (id, novosValores) => {

        setListaProcessos(prevLista => {
            return prevLista.map(column => {
                if (column.id === id) {
                    return { ...column, ...novosValores }; // Atualize todos os valores do item
                }
                return column;
            });
        });

        setModalUpdateProduto(false);
    };

    const getProcessos = async () => {

        try {

            const url = `${apiUrl}/processo/getProcesso`;

            const params = {
                empresa: idEmpresa
            };

            const response = await axios.get(url, { params });
            const processos = response.data;


            if (processos && processos.length > 0) {
                setListaProcessos(processos);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const createProcesso = async () => {

        try {

            const newProcesso = {
                empresa: idEmpresa,
                title: processo,
                setor: setor,
                ordem: orderNumber
            };

            const response = await axios.post(`${apiUrl}/processo`, newProcesso);
            const idProduct = response.data.id;

            console.log(idProduct);

            createProcessoOffline(idProduct);

        } catch (error) {
            console.log('Erro ao cadastrar produto');
        }
    };

    function createProcessoOffline(idProduct) {
        console.log('listaProdutos');

        const newProduct = {
            id: idProduct,
            empresa: idEmpresa,
            title: processo,
            setor: setor,
            ordem: orderNumber
        };

        const listaAtualProdutos = [...listaProcessos, newProduct];
        setListaProcessos(listaAtualProdutos);

        console.log(listaAtualProdutos);
        setModalNovoProduto(false);

    };


    return (
        <div className='processo-modal'>

            <div className='processo-container'>

                <div className='processo-header'>
                    <label className='title'>Processos</label>
                </div>

                <div className='processo-body'>

                    {
                        listaProcessos
                            .slice() // Crie uma cópia da lista para não modificar a original
                            .sort((a, b) => a.ordem - b.ordem) // Ordene os elementos com base na ordem crescente de 'ordem'
                            .map((item) => (
                                <li key={item.id} className='item-processo' onClick={() => selectedProcesso(item.id, item.title, item.setor, item.ordem)}>{item.title}</li>
                            ))
                    }


                </div>

                <div className='processo-footer'>

                </div>

            </div>

            <button className='btn-create-processo' onClick={() => setModalNovoProduto(true)}>+</button>

            {
                modalNovoProduto ?
                    <div className='modal-novo-processo-container'>
                        <div className='modal-novo-processo'>
                            <div className='novo-processo-header'>
                                <label className='title'>Criar novo Processo</label>
                                <button className='btn-close-novo-processo' onClick={() => setModalNovoProduto(false)}>x</button>
                            </div>
                            <div className='novo-processo-body'>
                                <input className='input-novo-processo' type='text' onChange={(e) => setProcesso(e.target.value)} placeholder={'Digite o nome do PROCESSO...'}></input>
                            </div>

                            <div className='novo-processo-body'>
                                <input className='input-novo-processo' type='text' onChange={(e) => setSetor(e.target.value)} placeholder={'Digite o nome do SETOR...'}></input>
                            </div>

                            <div className="novo-processo-body">
                                <input
                                    className="input-novo-processo"
                                    type="text"
                                    onChange={(e) => setOrderNumber(e.target.value)}
                                    placeholder="Número de ordem"
                                />
                            </div>

                            <div className='novo-processo-footer'>
                                <button className='btn-excluir-novo-processo' ></button>

                                <button className='btn-save-novo-processo' onClick={() => createProcesso()}>Salvar</button>
                            </div>

                        </div>
                    </div>
                    : <></>
            }

            {
                modalUpdateProduto ?
                    <div className='modal-novo-processo-container'>
                        <div className='modal-novo-processo'>
                            <div className='novo-processo-header'>
                                <label className='title'>Atualizar Processo</label>
                                <button className='btn-close-novo-processo' onClick={() => setModalUpdateProduto(false)}>x</button>
                            </div>
                            <div className='novo-processo-body'>
                                <input className='input-novo-processo' value={processo} type='text' onChange={(e) => processo !== 'Finalizados' && processo !== 'Import' && setProcesso(e.target.value)} placeholder={'Digite o nome do PROCESSO...'}></input>
                            </div>

                            <div className='novo-processo-body'>
                                <input className='input-novo-processo' value={setor} type='text' onChange={(e) => setSetor(e.target.value)} placeholder={'Digite o nome do SETOR...'}></input>
                            </div>

                            <div className="novo-processo-body">
                                <input
                                    className="input-novo-processo"
                                    value={orderNumber}
                                    type="text"
                                    onChange={(e) => setOrderNumber(e.target.value)}
                                    placeholder="Número de ordem"
                                />
                            </div>

                            <div className='novo-processo-footer'>
                                <button style={{display: 'none'}} className='btn-excluir-novo-processo' onClick={() =>  processo !== 'Finalizados' && processo !== 'Import' && deleteProcesso()}>Excluir</button>
                                <button className='btn-save-novo-processo' onClick={() => updateProcesso()}>Salvar</button>
                            </div>

                        </div>
                    </div>
                    : <></>
            }

        </div>

    );
}
export default Processos;