import React, { useContext, useEffect, useState } from "react";
import { BoardContext } from "../../../contexts/BoardContext";
import { ColumnContext } from "../../../contexts/ColumnContext";
import { UserContext } from '../../../contexts/UserContext';

import { Chart } from "react-google-charts";

import "./style.css";

function ChartValueRepresentante() {

  // --- CONTEXT API
  const { listaProcessos } = useContext(UserContext);
  const { listaFilteredCards } = useContext(BoardContext);
  const { listaColumns } = useContext(ColumnContext);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {

    const processIds = listaProcessos.map(processo => processo.id.toString());

    console.log(processIds)

    console.log(listaFilteredCards)

    const filteredCards = listaFilteredCards.filter(card => processIds.includes(card.id_column));

    console.log(filteredCards)

    const uniqueUsers = [...new Set(filteredCards.map((card) => card.name_user))];

    // Lista de status que devem ser considerados como "Vendido" ou equivalentes
    const statusVendido = ["Vendido", "Entregue", "Assistencia", "Instalacao", "AssistenciaExt", "InstalacaoExt"];

    // ...

    const data = uniqueUsers.map((user) => {
      const userData = [user];

      // Crie um objeto para acompanhar os valores de 'Vendido' e 'Perdido' separadamente
      const userValues = {
        Vendido: 0,
        Perdido: 0,
      };

      for (const card of filteredCards) {
        if (card.name_user === user) {
          if (statusVendido.includes(card.status)) {
            // Adicione o valor ao status "Vendido"
            userValues.Vendido += parseFloat(card.valor);
          } else if (card.status === "Perdido") {
            // Adicione o valor ao status "Perdido"
            userValues.Perdido += parseFloat(card.valor);
          }
        }
      }

      userData.push(userValues.Vendido);
      userData.push(userValues.Perdido);

      return userData;
    }).filter(userData => userData.slice(1).some(value => value > 0));


    data.sort((a, b) => b[1] - a[1]);

    const columnHeaders = ["Name User", "Vendido", "Perdido"];

    const chartDataWithHeader = [columnHeaders, ...data];
    setChartData(chartDataWithHeader);
  }, [listaFilteredCards, listaProcessos]);


  const chartOptions = {
    title: "VALOR POR PARCEIRO COMERCIAL",
    backgroundColor: "transparent",
    chartArea: { width: "60%", height: "90%" },
    legend: { position: "bottom" },
    fontSize: 10,
    hAxis: {
      title: "",
      minValue: 0,
    },
    vAxis: {
      title: "",
    },
    tooltip: { isHtml: true },
    // colors: [
    //   "#FF4069",
    //   "#FFC233",
    //   "#069BFF",
    //   "#C749EF",
    //   "#01BA02",
    //   "#F7A24D",
    //   "#F66DB0",
    //   "#8FB1CE",
    //   "#FF9696", // Vermelho claro
    //   "#FFB396", // Laranja claro
    //   "#FFD996", // Amarelo claro
    //   "#FFFF96", // Amarelo-esverdeado claro
    //   "#C0FF96", // Verde claro
    //   "#96FFAE", // Verde azulado claro
    //   "#96FFD4", // Ciano claro
    //   "#96FFFF", // Azul claro
    //   "#96B6FF", // Azul arroxeado claro
    //   "#B996FF", // Lilás claro
    //   "#D696FF", // Magenta claro
    //   "#FF96F9", // Rosa claro
    //   "#FF96D2", // Rosa arroxeado claro
    //   "#FF96AE", // Rosa alaranjado claro
    //   "#FF9696", // Vermelho claro (repetido)
    //   "#FFB396", // Laranja claro (repetido)
    //   "#FFD996", // Amarelo claro (repetido)
    //   "#FFFF96", // Amarelo-esverdeado claro (repetido)
    //   "#C0FF96", // Verde claro (repetido)
    //   "#96FFAE", // Verde azulado claro (repetido)
    // ],
    colors: ["#1FA6FF", "#FF4069"],
    annotations: {
      textStyle: {
        fontSize: 10,
      },
    },
  };

  const formatTooltip = (chartData, rowIndex) => {
    const nameUser = chartData.getValue(rowIndex, 0);
    let tooltipContent = `<div><strong>Name User:</strong> ${nameUser}</div>`;

    for (let i = 1; i < chartData.getNumberOfColumns(); i++) {
      const columnName = chartData.getColumnLabel(i);
      const value = chartData.getValue(rowIndex, i);
      tooltipContent += `<div><strong>${columnName}:</strong> ${value}</div>`;
    }

    return tooltipContent;
  };

  return (
    <div>
      <div className="grafico-container-e">
        <Chart
          className="grafico-ee"
          chartType="BarChart"
          data={chartData}
          options={chartOptions}
          chartEvents={[
            {
              eventName: "onmouseover",
              callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                const tooltip = chart.getTooltip();
                const selection = chart.getSelection();

                if (selection.length === 1 && tooltip.triggerEvent.row !== selection[0].row) {
                  const tooltipContent = formatTooltip(chartData, selection[0].row);
                  tooltip.show(selection[0], tooltipContent);
                }
              },
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ChartValueRepresentante;
