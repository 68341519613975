import React, { useEffect, useState, useContext } from 'react'

// COMPONENTS
import Header from '../../component/Header'
import Board from '../../component/Board';

import { UserContext } from '../../contexts/UserContext';
import Orcamento from '../../component/Orcamento';

function OrcamentosPage() {

    const { empresaData } = useContext(UserContext);

    const [modulosEmpresa, setModulosEmpresa] = useState('');

    //console.log('borad')
    //console.log(modulosEmpresa)

    useEffect(() => {

        empresaData.forEach((doc) => {
            setModulosEmpresa(doc.modulos);
        })

    }, [empresaData]);

    return (
        <header className='home-container'>
            <Header />
            {modulosEmpresa === 'aaa' ? (
                <Orcamento />
            ):<Orcamento />}

        </header>
    );
}
export default OrcamentosPage;