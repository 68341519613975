import React, { createContext, useState, useContext, useEffect } from "react";

// CONTEXT API
import { UserContext } from './UserContext';
import { BoardContext } from './BoardContext';

import axios from 'axios';

import { startOfMonth, endOfMonth, subMonths } from 'date-fns';

import apiUrl from '../config/apiConfig';

export const CardContext = createContext();

export const CardProvider = ({ children }) => {

  // CONTEXT API
  const { listaAfilhados, tipoParticipante, idUser, nameUser, idEmpresa, listaUsers } = useContext(UserContext);

  const {
    setSearchValue,
    searchValue,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    statusCardFilter, setStatusCardFilter,
    dateCardFilter, setDateCardFilter,
    setListaFilteredCards,
    grupoParticipantes, setGrupoParticipantes,

  } = useContext(BoardContext);

  const [idCard, setIdCard] = useState('');
  const [idCreateBy, setIdCreateBy] = useState('');
  const [createBy, setCreateBy] = useState('');
  const [idColumnCard, setIdColumnCard] = useState('');
  const [documentCard, setDocumentCard] = useState('');
  const [nameCard, setNameCard] = useState('');
  const [nameObraCard, setNameObraCard] = useState('');
  const [nameObra, setNameObra] = useState('');
  const [nameContato, setNameContato] = useState('');
  const [valorCard, setValorCard] = useState(0);
  const [horasProducaoCard, setHorasProducaoCard] = useState(0);
  const [valorVendaCard, setValorVendaCard] = useState(0);
  const [cityCard, setCityCard] = useState('');
  const [estadoCard, setEstadoCard] = useState('');
  const [foneCard, setFoneCard] = useState('');
  const [emailCard, setEmailCard] = useState('');
  const [previsaoCard, setPrevisaoCard] = useState('');
  const [previsaoProducaoCard, setPrevisaoProducaoCard] = useState('');
  const [previsaoEntregaCard, setPrevisaoEntregaCard] = useState('');
  const [previsaoInstalacaoCard, setPrevisaoInstalacaoCard] = useState('');
  const [previsaoAssistenciaCard, setPrevisaoAssistenciaCard] = useState('');
  const [conclusaoProducaoCard, setConclusaoProducaoCard] = useState('');
  const [meioContatoCard, setMeioContatoCard] = useState('');
  const [date, setDate] = useState('');
  const [nivel, setNivel] = useState(1);
  const [etiquetaCard, setEtiquetaCard] = useState('');
  const [modificationDateCard, setModificationDateCard] = useState('');
  const [editDateCard, setEditDateCard] = useState('');
  const [listaCards, setListaCards] = useState([]);
  const [motivoVendaPerdida, setMotivoVendaPerdida] = useState('');
  const [statusCard, setStatusCard] = useState('');
  const [produtoCard, setProdutoCard] = useState('Esquadria');
  const [corCard, setCorCard] = useState('');
  const [listaTarefasCard, setListaTarefasCard] = useState([]);
  const [listaHistoricoCard, setListaHistoricoCard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [etapaProducaoCard, setEtapaProducaoCard] = useState(0);
  const [listaCampartilhar, setListaCompartilhar] = useState([]);
  const [prioridade, setPrioridade] = useState('Normal');

  const [recebimentoMedidasCard, setRecebimentoMedidasCard] = useState('');
  const [prazoEntregaCard, setPrazoEntregaCard] = useState('');
  const [numeroPedidoCard, setNumeroPedidoCard] = useState('');
  const [quadrosCard, setQuadrosCard] = useState(0);
  const [metrosQuadradosCard, setMetrosQuadradosCard] = useState(0);
  const [quantidadeEsquadriasCard, setQuantidadeEsquadriasCard] = useState(0);

  const [entregaVidroCard, setEntregaVidroCard] = useState('');
  const [statusVidroCard, setStatusVidroCard] = useState('');

  const [obsCard, setObsCard] = useState('');

  const [listaEtiquetas, setListaEtiquetas] = useState([

    { id: '0', name: 'Solicitação de orçamento', id_column: 0 },
    { id: '1', name: 'Interesse em Informações', id_column: 0 },
    { id: '2', name: 'Reunião agendada', id_column: 0 },
    { id: '3', name: 'Aguardando contato', id_column: 0 },

    { id: '4', name: 'Proposta Enviada', id_column: 1 },
    { id: '5', name: 'Aguardando Resposta', id_column: 1 },
    { id: '6', name: 'Em revisão', id_column: 1 },

    { id: '7', name: 'Aguardando Assinatura', id_column: 2 },
    { id: '8', name: ' Aguardando Confirmação', id_column: 2 },

    { id: '9', name: 'Medição em obra', id_column: 3 },
    { id: '10', name: 'Negociação Finalizada', id_column: 3 },

  ]);
  const [fileLinksCard, setFileLinksCard] = useState([]);
  //console.log(fileLinksCard)

  useEffect(() => {
    const currentDate = new Date();
    let start, end;

    // start = startOfMonth(currentDate);
    // end = endOfMonth(currentDate);

    start = subMonths(startOfMonth(currentDate), 11);
    end = endOfMonth(currentDate);

    setStartDate(start);
    setEndDate(end);
    setStatusCardFilter('');
    setSearchValue('');
    setDateCardFilter('create')
    setStatusCardFilter('');
    setGrupoParticipantes([...listaUsers.map((item) => item.id), idUser]);
  }, [nameUser, listaUsers])

  useEffect(() => {
    getFilteredCards();
    const count = 1;
    //console.log(count + 1)
  }, [idUser, tipoParticipante, listaAfilhados, idEmpresa, startDate]);

  const getFilteredCards = async () => {
    setIsLoading(true);

    try {

      const url = `${apiUrl}/cards/filtered`;

      const params = {
        tipoParticipante: tipoParticipante,
        idUser: idUser,
        empresa: idEmpresa,
        startDate: startDate,
        listaAfilhados: listaAfilhados || []
      };

      const response = await axios.get(url, { params });
      
      const cards = response.data;
      //console.log(cards)

      if (cards && cards.length > 0) {
        setListaCards(cards);
        //console.log('cards')
      }

      setIsLoading(false);

    } catch (error) {

      setIsLoading(false);
    }
  };

  function converterDatePostegreToDate(dateConverter) {
    const dateString = dateConverter;
    const date = new Date(dateString);
    return date;
  }

  // useEffect(() => {
  //   const filteredCards = listaCards

  //     .filter((item) => (
  //       (dateCardFilter === 'create' && converterDatePostegreToDate(item.modification_date) >= startDate && converterDatePostegreToDate(item.modification_date) <= endDate)
  //       ||
  //       (dateCardFilter === 'finished' && converterDatePostegreToDate(item.modification_date) >= startDate && converterDatePostegreToDate(item.modification_date) <= endDate)
  //     ))

  //     .filter((item) =>
  //       item.valor.toString().includes(searchValue) ||
  //       item.fone.toString().includes(searchValue) ||
  //       item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
  //       item.city.toLowerCase().includes(searchValue.toLowerCase()) ||
  //       item.document_card.toLowerCase().includes(searchValue.toLowerCase()) ||
  //       (item.name_obra_cliente !== null && item.name_obra_cliente.toLowerCase().includes(searchValue.toLowerCase())))

  //     .filter((item) =>
  //       (item.name_obra !== null && searchValue.startsWith('%') && item.name_obra.toLowerCase().includes(searchValue.substring(1).toLowerCase()) ) ||
  //       (item.name_obra === null && !searchValue.startsWith('%')) ||
  //       statusCardFilter === "" || item.status === statusCardFilter
  //     )

  //     //.filter((item) => statusCardFilter === "" || item.status === statusCardFilter)

  //     .filter((item) =>
  //       grupoParticipantes.includes((item.id_create_by ? item.id_create_by.toString() : '')) ||
  //       (item.lista_compartilhar && item.lista_compartilhar.some((obj) => obj.id === idUser))
  //     )

  //     .sort((a, b) => {
  //       const dateA = converterDatePostegreToDate(a.date);
  //       const dateB = converterDatePostegreToDate(b.date);
  //       return dateA - dateB;
  //     });

  //   setListaFilteredCards(filteredCards);
  // }, [listaCards, startDate, endDate, statusCardFilter, dateCardFilter, searchValue, grupoParticipantes]);



  useEffect(() => {
    const filteredCards = listaCards
      .filter((item) => (
        (dateCardFilter === 'create' && converterDatePostegreToDate(item.modification_date) >= startDate && converterDatePostegreToDate(item.modification_date) <= endDate) ||
        (dateCardFilter === 'finished' && converterDatePostegreToDate(item.modification_date) >= startDate && converterDatePostegreToDate(item.modification_date) <= endDate)
      ))

      // .filter((item) => {
      //   if (!searchValue.includes('%')) { // --------- BUSCAR PARCEIRO INDICADOR ---------- 
      //     return (
      //       item.valor.toString().includes(searchValue) ||
      //       item.fone.toString().includes(searchValue) ||
      //       item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      //       item.city.toLowerCase().includes(searchValue.toLowerCase()) ||
      //       item.document_card.toLowerCase().includes(searchValue.toLowerCase()) ||
      //       (item.name_obra_cliente !== null && item.name_obra_cliente.toLowerCase().includes(searchValue.toLowerCase()))
      //     );
      //   } else {
      //     const searchTerm = searchValue.replace('%', '').toLowerCase();
      //     return item.name_obra !== null && item.name_obra.toLowerCase().includes(searchTerm);
      //   }
      // })


      .filter((item) => {     // ------------- % BUSCA POR PARCEIRO INDICADOR ----------------- # BUSCA POR ORIGEM
        if (searchValue.includes('%')) {
          const searchTerm = searchValue.replace('%', '').toLowerCase();
          return item.name_obra !== null && item.name_obra.toLowerCase().includes(searchTerm);
        } else if (searchValue.includes('#')) {
          const searchTerm = searchValue.replace('#', '').toLowerCase();
          return item.meio_contato !== null && item.meio_contato.toLowerCase().includes(searchTerm);
        } else if (searchValue.includes('@')) {
          const searchTerm = searchValue.replace('@', '').toLowerCase();
          return item.id_column !== null && item.id_column.toString().toLowerCase().includes(searchTerm);
        } else {
          return (
            item.valor.toString().includes(searchValue) ||
            item.fone.toString().includes(searchValue) ||
            item.name && item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.city && item.city.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.document_card && item.document_card.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.numero_pedido && item.numero_pedido.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.produto && item.produto.toLowerCase().includes(searchValue.toLowerCase()) ||

            (item.name_obra_cliente && item.name_obra_cliente.toLowerCase().includes(searchValue.toLowerCase()))

          );
        }
      })
      
      



      .filter((item) =>
        statusCardFilter === "" || item.status === statusCardFilter
      )
      .filter((item) =>
        grupoParticipantes.includes((item.id_create_by ? item.id_create_by.toString() : '')) ||
        (item.lista_compartilhar && item.lista_compartilhar.some((obj) => obj.id === idUser))
      )
      .sort((a, b) => {
        const dateA = converterDatePostegreToDate(a.date);
        const dateB = converterDatePostegreToDate(b.date);
        return dateA - dateB;
      });
  
    setListaFilteredCards(filteredCards);
  }, [listaCards, startDate, endDate, statusCardFilter, dateCardFilter, searchValue, grupoParticipantes]);
  






  return (
    <CardContext.Provider value={{
      listaHistoricoCard, setListaHistoricoCard,
      listaTarefasCard, setListaTarefasCard,
      isLoading, setIsLoading,
      modificationDateCard, setModificationDateCard,
      motivoVendaPerdida, setMotivoVendaPerdida,
      listaEtiquetas, setListaEtiquetas,
      etiquetaCard, setEtiquetaCard,
      idCard, setIdCard,
      idColumnCard, setIdColumnCard,
      listaCards, setListaCards,
      documentCard, setDocumentCard,
      nameCard, setNameCard,
      nameObraCard, setNameObraCard,
      valorCard, setValorCard,
      valorVendaCard, setValorVendaCard,
      cityCard, setCityCard,
      estadoCard, setEstadoCard,
      foneCard, setFoneCard,
      emailCard, setEmailCard,
      previsaoCard, setPrevisaoCard,
      meioContatoCard, setMeioContatoCard,
      date, setDate,
      nivel, setNivel,
      produtoCard, setProdutoCard,
      statusCard, setStatusCard,
      idCreateBy, setIdCreateBy,
      createBy, setCreateBy,
      nameObra, setNameObra,
      nameContato, setNameContato,
      fileLinksCard, setFileLinksCard,
      previsaoEntregaCard, setPrevisaoEntregaCard,
      horasProducaoCard, setHorasProducaoCard,
      previsaoInstalacaoCard, setPrevisaoInstalacaoCard,
      previsaoAssistenciaCard, setPrevisaoAssistenciaCard,
      etapaProducaoCard, setEtapaProducaoCard,
      listaCampartilhar, setListaCompartilhar,
      corCard, setCorCard,
      editDateCard, setEditDateCard,
      prioridade, setPrioridade,
      previsaoProducaoCard, setPrevisaoProducaoCard,
      recebimentoMedidasCard, setRecebimentoMedidasCard,
      prazoEntregaCard, setPrazoEntregaCard,
      numeroPedidoCard, setNumeroPedidoCard,
      quadrosCard, setQuadrosCard,
      metrosQuadradosCard, setMetrosQuadradosCard,
      quantidadeEsquadriasCard, setQuantidadeEsquadriasCard,
      entregaVidroCard, setEntregaVidroCard,
      statusVidroCard, setStatusVidroCard,
      obsCard, setObsCard,
      conclusaoProducaoCard, setConclusaoProducaoCard
    }}>
      {children}
    </CardContext.Provider>
  );

};
