import React, { useContext, useState, useRef } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

// FIREBASE
import fb from '../../config/firebase';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, addDoc } from 'firebase/firestore';

function CreateUser() {

    //--- FIREBASE
    const db = getFirestore(fb);

    const { openCloseCreateUserModal } = useContext(BoardContext);
    const { idEmpresa, users, setUsers } = useContext(UserContext);

    const [nameUser, setNameUser] = useState('');
    const [loginUser, setloginUser] = useState('');
    const [emailUser, setEmailUser] = useState('');
    const [adressUser, setAdressUser] = useState('');
    const [cityUser, setCityUser] = useState('');
    const [estadoUser, setEstadoUser] = useState('');
    const [cepUser, setCepUser] = useState('');
    const [foneUser, setFoneUser] = useState('');
    const [passwordUser, setPasswordUsera] = useState('');
    const [confirmePasswordUser, setConfirmePasswordUsera] = useState('');
    const [tipoParticipante, setTipoParticipante] = useState('');
    const [entidadePreference, setEntidadePreference] = useState('');
    const [listaAfilhados, setListaAfilhados] = useState([]);
    const [metaUser, setMetaUser] = useState(1000);

    const [info, setInfo] = useState('');

    function validationForm() {

        if (tipoParticipante != '') {
            if (loginUser != "" && loginUser.length >= 10) {

                if (passwordUser == confirmePasswordUser && passwordUser != '' && passwordUser.length >= 6) {

                    if (nameUser != '' && emailUser != '' && estadoUser != '' && cityUser != '' && foneUser != '') {
                        handleSignUp();
                    } else {
                        setInfo('Algum campo esta vazio!')
                    }
                } else {
                    setInfo('Senha invalida! As senhas precisam ser iguais e ao menos 8 caracteres')
                }

            } else {
                setInfo('Login invalido!')
            }
        } else {
            setInfo('Tipo de participante vazio!')
        }

    }

    const handleSignUp = async () => {

        const auth = getAuth();
        createUserWithEmailAndPassword(auth, loginUser, passwordUser)
            .then((userCredential) => {

                const user = userCredential.user;
                //addUserFirestore();
                createUser();

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }

    const createUser = async () => {

        try {

            const userData = {
                login: loginUser,
                name: nameUser,
                email: emailUser,
                adress: adressUser,
                city: cityUser,
                estado: estadoUser,
                cep: cepUser,
                fone: foneUser,
                empresa: idEmpresa ? idEmpresa : "",
                tipo_participante: tipoParticipante,
                lista_afilhados: listaAfilhados,
                entidade_preference: entidadePreference,
                meta: metaUser,
                lista_processos: [],
                modulos: '100',
                atividade: 0
            };

            const response = await axios.post(`${apiUrl}/users`, userData);

            const idNewUser = response.data.id;

            const newUser = {
                id: idNewUser,
                login: loginUser,
                name: nameUser,
                email: emailUser,
                adress: adressUser,
                city: cityUser,
                estado: estadoUser,
                cep: cepUser,
                fone: foneUser,
                empresa: idEmpresa ? idEmpresa : "",
                tipo_participante: tipoParticipante,
                lista_afilhados: listaAfilhados,
                entidade_preference: entidadePreference,
                meta: metaUser,
                lista_processos: [],
                modulos: '100',
                atividade: 0
            };

            const listaUsuariosAtualizada = [...users, newUser];

            setUsers(listaUsuariosAtualizada);
            openCloseCreateUserModal();

        } catch (error) {
            console.error('Erro ao criar Usuário!', error);
            setInfo('Erro ao criar usuário. Verifique os dados e o número de usuários contratados.');
        }
    };

    const listaTipoParticipante = [
        { id: '0', tipo: 'Administrador' },
        { id: '1', tipo: 'Parceiro' },
        { id: '2', tipo: 'Escritorio' },
        { id: '3', tipo: 'Producao' },
        { id: '4', tipo: 'Externo' },
    ]

    const [modalTipoParticipante, setModalTipoParticipante] = useState(false);
    function selectTipoParticipante(tipo) {
        setTipoParticipante(tipo);
        setModalTipoParticipante(!modalTipoParticipante);
    }



    // IBGE
    async function getEstados() {
        try {
            const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const estados = response.data;
            setListaEstadosIBGE(estados);
            return estados;
        } catch (error) {
            console.error('Erro ao obter a lista de estados:', error);
            return [];
        }
    }

    const [listaEstadosIBGE, setListaEstadosIBGE] = useState([]);

    const [selectedEstadoIBGE, setSelectedEstadoIBGE] = useState(false);
    function openCloseSelectEstadoIBGE() {
        getEstados();
        setSelectedEstadoIBGE(!selectedEstadoIBGE);
    }

    function selectEstado(id, sigla) {
        setEstadoUser(sigla);
        openCloseSelectEstadoIBGE();
        setSelectedCidadeIBGE(true);
        getCidades(id);
        setEstadoFiltro(sigla);
        irPraInputCidade();
    }

    const [selectedCidadeIBGE, setSelectedCidadeIBGE] = useState(false);

    const [listaCidadesIBGE, setListaCidadesIBGE] = useState([]);

    // Função para obter a lista de cidades de um estado
    async function getCidades(estadoId) {
        try {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`);
            const cidades = response.data;
            setListaCidadesIBGE(cidades);
            return cidades;
        } catch (error) {
            console.error('Erro ao obter a lista de cidades:', error);
            return [];
        }
    }

    function selectCidade(id, nome) {
        setCityUser(nome);
        setSelectedCidadeIBGE(false);
        setCidadeFiltro(nome);
    }

    // const [listaEstadosIBGE, setListaEstadosIBGE] = useState([]);
    // const [selectedEstadoIBGE, setSelectedEstadoIBGE] = useState(false);

    // async function getEstados() {
    //     try {
    //         const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
    //         const estados = response.data;
    //         setListaEstadosIBGE(estados);
    //         return estados;
    //     } catch (error) {
    //         console.error('Erro ao obter a lista de estados:', error);
    //         return [];
    //     }
    // }

    // function getEstadosCidades() {
    //     getEstados();
    //     openCloseSelectEstadoIBGE();
    // }

    // function openCloseSelectEstadoIBGE() {
    //     setSelectedEstadoIBGE(!selectedEstadoIBGE);
    // }

    // function selectEstado(id, sigla) {
    //     setEstadoUser(sigla);
    //     openCloseSelectEstadoIBGE();
    //     setSelectedCidadeIBGE(true);
    //     getCidades(id);
    //     setEstadoFiltro(sigla);
    //     irPraInputCidade();
    // }

    // const [selectedCidadeIBGE, setSelectedCidadeIBGE] = useState(false);
    // const [listaCidadesIBGE, setListaCidadesIBGE] = useState([]);

    // async function getCidades(estadoId) {
    //     try {
    //         const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`);
    //         const cidades = response.data;
    //         setListaCidadesIBGE(cidades);
    //         return cidades;
    //     } catch (error) {
    //         console.error('Erro ao obter a lista de cidades:', error);
    //         return [];
    //     }
    // }

    // function selectCidade(id, nome) {
    //     setCityUser(nome);
    //     setSelectedCidadeIBGE(false);
    //     setCidadeFiltro(nome);
    // }


    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [cidadeFiltro, setCidadeFiltro] = useState('');
    const input2Ref = useRef(null);

    const irPraInputCidade = () => {
        // Define o foco no segundo campo de entrada ao clicar no botão
        input2Ref.current.focus();
    };

    return (

        <div className='modal-create-user-container'>

            <div className='create-user-container'>

                <div className='create-user-header'>
                    <div className='title'>Criar Usuário</div>
                    <div className='btn-close-container'>
                        <button className='btn-close' onClick={openCloseCreateUserModal}>X</button>
                    </div>
                </div>

                <div className='create-user-body'>

                    <label className='title'>Tipo de Participante</label>
                    <input className='input-create-user' type='text' value={tipoParticipante || ''} onClick={() => setModalTipoParticipante(!modalTipoParticipante)} placeholder={'Selecione o tipo de participante...'}></input>
                    {modalTipoParticipante ? (
                        <div className='user-select'>
                            {
                                listaTipoParticipante.map((item) => <li key={item.id} className='item-list-select' onClick={() => selectTipoParticipante(item.tipo)}>{item.tipo}</li>)
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Login</label>
                    <input className='input-create-user' type='email' value={loginUser} onChange={(e) => setloginUser(e.target.value)} placeholder={'Digite o email de login...'}></input>

                    <label className='title'>Senha</label>
                    <input className='input-create-user' type='password' value={passwordUser} onChange={(e) => setPasswordUsera(e.target.value)} placeholder={'Digite a senha...'}></input>

                    <label className='title'>Confirmar senha</label>
                    <input className='input-create-user' type='password' value={confirmePasswordUser} onChange={(e) => setConfirmePasswordUsera(e.target.value)} placeholder={'Digite a senha novamente...'}></input>

                    <label className='title'>Nome</label>
                    <input className='input-create-user' type='text' value={nameUser} onChange={(e) => setNameUser(e.target.value)} placeholder={'Digite o nome...'}></input>

                    <label className='title'>Email de contato</label>
                    <input className='input-create-user' type='email' value={emailUser} onChange={(e) => setEmailUser(e.target.value)} placeholder={'Digite o email de contato...'}></input>

                    <label className='title'>Endereço</label>
                    <input className='input-create-user' type='text' value={adressUser} onChange={(e) => setAdressUser(e.target.value)} placeholder={'Digite o endereço...'}></input>



                    <label className='title'>Estado</label>
                    {/*<input className='input-edit-card' type='text' value={estadoCard ?? ''} readOnly onClick={openCloseSelectEstadoIBGE} placeholder={'Selecione o estado...'}></input>*/}
                    <input className='input-create-user' type='text' value={estadoFiltro ?? estadoUser} onChange={(e) => setEstadoFiltro(e.target.value)} onClick={openCloseSelectEstadoIBGE} placeholder={estadoUser || 'Selecione o estado...'}></input>

                    {selectedEstadoIBGE ? (
                        <div className='card-select'>
                            {
                                listaEstadosIBGE
                                    .filter((estado) => estadoFiltro ? estado.sigla.toLowerCase().includes(estadoFiltro.toLowerCase()) : true)
                                    .sort((a, b) => a.sigla.localeCompare(b.sigla)) // Ordena em ordem alfabética por sigla
                                    .map((estado) => <li key={estado.id} className='item-list-select' onClick={() => selectEstado(estado.id, estado.sigla)}>{estado.sigla}</li>)

                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Cidade</label>
                    <input ref={input2Ref} className='input-create-user' type='text' value={cidadeFiltro ?? cityUser} onChange={(e) => setCidadeFiltro(e.target.value)} placeholder={cityUser || 'Selecione a cidade...'}></input>

                    {selectedCidadeIBGE ? (
                        <div className='card-select'>
                            {
                                listaCidadesIBGE
                                    .filter((cidade) => cidadeFiltro ? cidade.nome.toLowerCase().includes(cidadeFiltro.toLowerCase()) : true)
                                    .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena em ordem alfabética por nome
                                    .map((cidade) => <li key={cidade.id} className='item-list-select' onClick={() => selectCidade(cidade.id, cidade.nome)}>{cidade.nome}</li>)
                            }
                        </div>
                    ) : <></>}

                    {/* <label className='title'>Estado</label>
                    <input className='input-create-user' type='text' value={estadoUser || ''} readOnly onClick={getEstadosCidades} placeholder={'Selecione o estado...'}></input>
                    {selectedEstadoIBGE ? (
                        <div className='card-select'>
                            {
                                listaEstadosIBGE.map((estado) => <li key={estado.id} className='item-list-select' onClick={() => selectEstado(estado.id, estado.sigla)}>{estado.sigla}</li>)
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Cidade</label>
                    <input className='input-create-user' type='text' value={cityUser || ''} readOnly onClick={() => selectCidade(true)} placeholder={'Selecione a cidade...'}></input>
                    {selectedCidadeIBGE ? (
                        <div className='card-select'>
                            {
                                listaCidadesIBGE.map((cidade) => <li key={cidade.id} className='item-list-select' onClick={() => selectCidade(cidade.id, cidade.nome)}>{cidade.nome}</li>)
                            }
                        </div>
                    ) : <></>} */}

                    <label className='title'>CEP</label>
                    <input className='input-create-user' type='text' value={cepUser} onChange={(e) => setCepUser(e.target.value)} placeholder={'Digite o CEP...'}></input>

                    <label className='title'>Fone</label>
                    <input className='input-create-user' type='tel' value={foneUser} onChange={(e) => setFoneUser(e.target.value)} placeholder={'Digite o fone...'}></input>

                    <label className='title'>Meta a atingir no mês</label>
                    <input className='input-create-user' type='tel' value={metaUser} onChange={(e) => setMetaUser(e.target.value)} placeholder={'Digite o valor...'}></input>

                    <label className='title'>Entidade Preference</label>
                    <input className='input-create-user' type='text' value={entidadePreference} onChange={(e) => setEntidadePreference(e.target.value)} placeholder={'Digite a referência da Entidade Preference...'}></input>

                </div>

                <div className='create-user-footer'>
                    <div className='info-create-user'>{info}</div>
                    <button className='btn-cancel' onClick={openCloseCreateUserModal}>Cancelar</button>
                    <button className='btn-save' onClick={validationForm}>Salvar</button>
                </div>

            </div>
        </div>
    );
}

export default CreateUser;

