import React, { useState } from 'react';

// STYLE
import './style.css';

// IMAGE
import logo from '../../assets/logo.png';

// FIREBASE
import fb from '../../config/firebase';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';

function NewLoginPage() {

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [senha, setSenha] = useState();
    const [confirmePassword, setConfirmePassword] = useState();
    const [mensagem, setMensagem] = useState();

    //--- FIREBASE
    const db = getFirestore(fb);

    function validationForm() {

        if (email != "" && email.length >= 10) {

            if (senha == confirmePassword && senha != '' && confirmePassword.length >= 6) {

                handleSignUp();

            } else {
                setMensagem('Senha invalida! As senhas precisam ser iguais e ao menos 8 caracteres')
            }

        } else {
            setMensagem('Login invalido!')
        }
    }


    const handleSignUp = async () => {

        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, senha)
            .then((userCredential) => {

                const user = userCredential.user;
                addUserFirestore();

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
    }

    async function addUserFirestore() {

        try {
            const usersCollectionRef = collection(db, 'users');
            const docRef = await addDoc(usersCollectionRef, {
                login: email,
                name: name,
                empresa: "free$" + email,
                tipo_participante: 'free',
                avatar: 'gs://suiteflow-1c751.appspot.com/logo-suite-flow.ico',
                lista_afilhados: [],
            });

            setMensagem('Login criado com sucesso!')
            window.location.href = "/";

        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // Impede o comportamento padrão do formulário
        }
    };

    return (
        <div className='logout-container-logout'
            style={{
                //backgroundImage: `url(${MinhaImagemVendas})`,
                maxHeight: 'calc(100vh - 0px)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                zIndex: '0',

            }}
        >

            <header className="header-container">

                <div className="menu" >

                </div>

                <div className="title">
                    <img src={logo} alt="SuiteFlow" />
                    <h3>SuiteFlow</h3>
                </div>


                <div className="user" >
                    <Link to='/'>
                        <button className='btn-login-in-logout'>Login</button>
                    </Link>
                </div>

            </header>

            <div className="logout-container">

                <div className="logout-form-container">

                    <div className="logout-logo-container">
                        <img src={logo} alt="Logo" />
                        <h3 className='logout-title'>SuiteFlow</h3>
                    </div>

                    <div className="logout-form-group">
                        <input onChange={(e) => setName(e.target.value)} type="text" id="email" name="Email" placeholder="Nome" />
                    </div>

                    <div className="logout-form-group">
                        <input onChange={(e) => setEmail(e.target.value)} type="email" id="email" name="Email" placeholder="Email" />
                    </div>

                    <div className="logout-form-group">
                        <input onKeyDown={handleKeyDown} onChange={(e) => setSenha(e.target.value)} type="password" id="password" name="password" placeholder="Password" />
                    </div>

                    <div className="logout-form-group">
                        <input onKeyDown={handleKeyDown} onChange={(e) => setConfirmePassword(e.target.value)} type="password" id="password" name="password" placeholder="Confirm Password" />
                    </div>

                    <div className="logout-form-msg">
                        <span>{mensagem}</span>
                    </div>

                    <div className="logout-container-login-form-btn">
                        <button type="submit" onClick={() => validationForm()} className="login-form-btn">Criar Login</button>
                    </div>

                    <div className="logout-form-msg"></div>

                </div>
            </div>
        </div>
    );
}
export default NewLoginPage;