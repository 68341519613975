import React, { useEffect, useState, useContext } from 'react';
import Header from '../../component/Header';
import Board from '../../component/Board';
import { UserContext } from '../../contexts/UserContext';

function HomePage() {
    const { empresaData, modulosUser } = useContext(UserContext);
    const [modulosEmpresa, setModulosEmpresa] = useState('');

    useEffect(() => {
        // Se empresaData for um array, você pode usar map para acessar o primeiro elemento
        if (empresaData && empresaData.length > 0) {
            setModulosEmpresa(empresaData[0].modulos);
        }
    }, [empresaData]);



    return (
        <header className='home-container'>
            <Header />
            <Board />
        </header>
    );
}

export default HomePage;
