import React, { useContext, useEffect, useState, useRef } from 'react'
import './style.css'

// DATE
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';
import { ColumnContext } from '../../contexts/ColumnContext';
import { CardContext } from '../../contexts/CardContext';

import moment from 'moment';
import 'moment/locale/pt-br';

// IDs ÚNICOS
import { v4 as uuidv4 } from 'uuid';

import { MdWindow, MdBuild, MdLocalShipping, MdThumbDown, MdRestoreFromTrash, MdThumbUp, MdGrade, MdWhatsapp, MdOutlineEmail, MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

import Tarefas from '../Tarefas';
import Historico from '../Historico';

import apiUrl from '../../config/apiConfig';
import axios from 'axios';

import fb from '../../config/firebase'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";



function EditCard() {

    // DATE PICKER
    registerLocale('pt-BR', ptBR);

    // --- CONTEXT API
    const { nameUser, idUser, tipoParticipante, idEmpresa, listaUsers, atividadeUser, setAtividadeUser, listaProcessos, listaAfilhados } = useContext(UserContext);
    const { setShowConfetti, openCloseEditCardModal, setListaCardsVendas } = useContext(BoardContext);
    const { vendaPerdidaModal, listaColumns, listaAllProcessos } = useContext(ColumnContext);

    const {
        idCard,
        idColumnCard, setIdColumnCard,
        listaCards, setListaCards,
        documentCard, setDocumentCard,
        nameCard, setNameCard,
        nameObraCard, setNameObraCard,
        valorCard, setValorCard,
        horasProducaoCard, setHorasProducaoCard,
        valorVendaCard, setValorVendaCard,
        cityCard, setCityCard,
        estadoCard, setEstadoCard,
        foneCard, setFoneCard,
        emailCard, setEmailCard,
        previsaoCard, setPrevisaoCard,
        meioContatoCard, setMeioContatoCard,
        setDate,
        etiquetaCard,
        nivel, setNivel,
        motivoVendaPerdida, setMotivoVendaPerdida,
        modificationDateCard, setModificationDateCard,
        listaTarefasCard,
        listaHistoricoCard, setListaHistoricoCard,
        produtoCard, setProdutoCard,
        statusCard, setStatusCard,
        idCreateBy, setIdCreateBy,
        createBy, setCreateBy,
        nameObra, setNameObra,
        nameContato, setNameContato,
        fileLinksCard, setFileLinksCard,
        previsaoEntregaCard, setPrevisaoEntregaCard,
        previsaoInstalacaoCard, setPrevisaoInstalacaoCard,
        previsaoAssistenciaCard, setPrevisaoAssistenciaCard,
        etapaProducaoCard,
        listaCampartilhar, setListaCompartilhar,
        corCard, setCorCard,
        editDateCard, setEditDateCard,
        prioridade, setPrioridade,
        previsaoProducaoCard, setPrevisaoProducaoCard,

        recebimentoMedidasCard, setRecebimentoMedidasCard,
        prazoEntregaCard, setPrazoEntregaCard,
        numeroPedidoCard, setNumeroPedidoCard,
        quadrosCard, setQuadrosCard,
        metrosQuadradosCard, setMetrosQuadradosCard,
        quantidadeEsquadriasCard, setQuantidadeEsquadriasCard,
        entregaVidroCard, setEntregaVidroCard,
        statusVidroCard, setStatusVidroCard,
        obsCard, setObsCard,
        conclusaoProducaoCard, setConclusaoProducaoCard
    } = useContext(CardContext);

    const [info, setInfo] = useState('');

    const [linkPatrocinado, setLinkPatrocinado] = useState(false);



    const [count, setCount] = useState(0)
    useEffect(() => {

        setCount(count + 1)
        if (count > 1) {
            setEditDateCard(new Date());
        }

    }, [
        idColumnCard,
        listaCards,
        documentCard,
        nameCard,
        nameObraCard,
        valorCard,
        horasProducaoCard,
        valorVendaCard,
        cityCard,
        estadoCard,
        foneCard,
        emailCard,
        previsaoCard,
        meioContatoCard,
        setDate,
        etiquetaCard,
        nivel,
        motivoVendaPerdida,
        modificationDateCard,
        listaTarefasCard,
        listaHistoricoCard,
        produtoCard,
        statusCard,
        idCreateBy,
        createBy,
        nameObra,
        nameContato,
        fileLinksCard,
        previsaoEntregaCard,
        previsaoInstalacaoCard,
        previsaoAssistenciaCard,
        etapaProducaoCard,
        listaCampartilhar,
        corCard,
        editDateCard,
        prioridade,
        previsaoProducaoCard,

        recebimentoMedidasCard,
        prazoEntregaCard,
        numeroPedidoCard,
        quadrosCard,
        metrosQuadradosCard,
        quantidadeEsquadriasCard,
        entregaVidroCard,
        statusVidroCard,
        obsCard,
        conclusaoProducaoCard,
    ]); // Dependências vazias para executar apenas na montagem






    // Use `handleSave` em um botão ou em uma função que precisa dessa verificação



    // --------------------------- mensagens -------------------

    useEffect(() => {

        if (prioridade == 'Potencial Cliente') {
            setLinkPatrocinado(true)
            console.log(prioridade)
        } else {
            setLinkPatrocinado(false)
        }

    }, [prioridade, idCreateBy])

    useEffect(() => {

        setLinkPatrocinado(false)

    }, [])

    const sendMessage = async (numero, contato, mensagem) => {
        try {

            // Envie a mensagem
            await axios.post(`${apiUrl}/mensagens/enviar`, { numero, mensagem, contato });
            console.log('Mensagem enviada com sucesso!');
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error.message);
        }
    };

    // --------------------------- fim mensagens -------------------

    // PEGAR FONE CORRESPONDENTE PREFWEB / SUITEFLOW
    function buscarFoneReferencia(entidade) {

        const listaFiltrada = listaUsers.filter((item) => item.id.toString() === entidade.toString());

        const fone = listaFiltrada.map((item) => item.fone);

        return fone.toString();
    }

    const [isEdit, setIsEdit] = useState(false);

    function validationEditCard() {
        // Verifica se pelo menos um item em listaProcessos tem o id igual a idColumnCard e edit igual a true
        const isEditTrue = listaProcessos.filter((item) => {
            return (item.id.toString() === idColumnCard || item.id === idColumnCard) && item.edit === true;
        });

        const isEdit = isEditTrue.length > 0; // Verifique o tamanho do array 

        setIsEdit(isEdit)
    }

    useEffect(() => {
        validationEditCard();
    }, []);



    function validationForm() {
        if ((nameCard.length >= 2 && nameCard != '') && (estadoCard !== undefined && estadoCard != '') && (cityCard != '' && cityCard !== undefined && cityCard)) {

            // Verifica se pelo menos um item em listaProcessos tem o id igual a idColumnCard e edit igual a true
            const isEditTrue = listaProcessos.filter((item) => {
                return (item.id.toString() === idColumnCard || item.id === idColumnCard) && item.edit === true;
            });

            const isEdit = isEditTrue.length > 0; // Verifique o tamanho do array   

            //if (isEdit) {

            updateCardInPostgreSQL();

            registrarAtividadeUser(1);

            if (linkPatrocinado) {

                //console.log(createBy)
                //console.log(buscarFoneReferencia(createBy))
                const valorFormatado = valorCard.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                sendMessage(buscarFoneReferencia(idCreateBy), '', `${createBy}\n \nCard com Link Patrocinado criado com sucesso no CRM! \n \nCliente: ${nameCard} \nNúmero do orçamento: ${documentCard} \nValor: ${valorFormatado}\n\n Verifique as informações fornecidas!\n\nBazze PVC`);
            }


            // } else {
            //     setInfo('Você não tem permissão para salvar o card.');
            // }
        } else {
            setInfo('Nome, Telefone, Estado e Cidade são dados obrigatórios!');
        }
    }

    const registrarAtividadeUser = async (pontos) => {

        try {

            const data = {
                atividade: pontos
            };

            await axios.put(`${apiUrl}/users/${idUser}/atividade`, data);

            //console.log(pontos)

        } catch (error) {
            console.error(error);
        }
    };

    const [idColumnCardTemporario, setIdColumnCardTemporario] = useState(0)

    useEffect(() => {
        getCurrentDate();
        setSelectedDateModification((modificationDateCard));
        setIdColumnCardTemporario(idColumnCard)
        setModificationDateTemporaria(modificationDateCard);
        setStatusTemporario(statusCard);
    }, [])

    function getCurrentDate() {
        const date = new Date();
        const diaSemana = date.getDate();
        const mes = date.getMonth() + 1;
        const ano = date.getFullYear();
        const data = diaSemana + '/' + mes + '/' + ano;
        setDate(data);
    }

    function selectMeioContato(name) {
        setMeioContatoCard(name);
        openCloseSelectMeioContato();
    }

    const [selectedMeioContato, setSelectedMeioContato] = useState(false);
    function openCloseSelectMeioContato() {
        setSelectedMeioContato(!selectedMeioContato);
    }

    const [selectedPrioridade, setSelectedPrioridade] = useState(false);
    function openCloseSelectPrioridade() {
        setSelectedPrioridade(!selectedPrioridade);
    }

    function selectPrioridade(tipo) {
        setPrioridade(tipo);
        openCloseSelectPrioridade();
    }

    const listaPrioridades = [
        { id: '0', tipo: 'Normal' },
        { id: '1', tipo: 'Baixa' },
        { id: '2', tipo: 'Média' },
        { id: '3', tipo: 'Alta' },
        { id: '4', tipo: 'Alteração de Pedido' },
        { id: '5', tipo: 'Potencial Cliente' },
        { id: '6', tipo: 'Aguardando Informação' },
        { id: '7', tipo: 'Recusado' },
        { id: '8', tipo: 'Contrato Assinado' },
    ];

    const listaStatusVidros = [
        { id: '0', name: 'Aguardando a Compra' },
        { id: '1', name: 'Solicitado' },
        { id: '2', name: 'Entrega Parcial' },
        { id: '3', name: 'Entregue' },
    ];

    const [selectedVidro, setSelectedVidro] = useState(false);
    function openCloseSelectVidro() {
        setSelectedVidro(!selectedVidro);
    }

    function selectVidroCard(name) {
        setStatusVidroCard(name);
        openCloseSelectVidro();
    }

    const [selectedProduto, setSelectedProduto] = useState(false);
    function openCloseSelectProduto() {
        if (selectedProduto == false) {
            getProducts();
        }
        setSelectedProduto(!selectedProduto);
    }

    const meioContato = [
        { id: '0', meio: 'Patrocinado FACEBOOK' },
        { id: '1', meio: 'Patrocinado INSTAGRAM' },
        { id: '2', meio: 'Patrocinado GOOGLE' },
        { id: '3', meio: 'Parceiro' },
        { id: '4', meio: 'Site' },
        { id: '5', meio: 'Facebook' },
        { id: '6', meio: 'Instagram' },
        { id: '7', meio: 'WhatsApp' },
        { id: '8', meio: 'LinkedIn' },
        { id: '9', meio: 'Google' },
        { id: '10', meio: 'Email' },
        { id: '11', meio: 'Telefone' },
        { id: '12', meio: 'Recomendação' },
        { id: '13', meio: 'Prospecção Direta' },
        { id: '14', meio: 'Cliente Recorrente' },
        { id: '15', meio: 'Expo Revestir' },
        { id: '16', meio: 'Construsul' },
    ];


    function selectCor(name) {
        setCorCard(name);
        openCloseSelectCor();
    }

    const [selectedCor, setSelectedCor] = useState(false);
    function openCloseSelectCor() {
        setSelectedCor(!selectedCor);
    }


    const cores = [
        { id: '0', cor: 'BRANCO' },
        { id: '1', cor: 'AMADEIRADO GOLDEN OAK' },
        { id: '2', cor: 'NOGUEIRA' },
        { id: '3', cor: 'PRETO LISO' },
        { id: '4', cor: 'PRETO TEXTURADO' },
        { id: '5', cor: 'BRONZE' },
        { id: '6', cor: 'GRAFITE' },
        { id: '7', cor: 'PIRITA' },
        { id: '8', cor: 'INTERNO BRANCO E EXTERNO AMADEIRADO GOLDEN OAK' },
        { id: '9', cor: 'INTERNO BRANCO E EXTERNO AMADEIRADO NOGUEIRA' },
        { id: '10', cor: 'INTERNO BRANCO E EXTERNO PRETO LISO' },
        { id: '11', cor: 'INTERNO BRANCO E EXTERNO PRETO TEXTURADO' },
        { id: '12', cor: 'INTERNO BRANCO E EXTERNO BRONZE' },
        { id: '13', cor: 'INTERNO BRANCO E EXTERNO GRAFITE' },
        { id: '14', cor: 'INTERNO BRANCO E EXTERNO PIRITA' },
        { id: '15', cor: 'ESPECIAL' },
    ];

    // ----- date ----
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setPrevisaoCard(format(date, 'dd/MM/yyyy'));
    };

    // ----- date ----

    const [selectedDateVidro, setSelectedDateVidro] = useState(null);

    const handleDateChangeVidro = (date) => {
        setSelectedDateVidro(date);
        setEntregaVidroCard(format(date, 'dd/MM/yyyy'));
    };

    const [selectedDatePrazo, setSelectedDatePrazo] = useState(null);

    const handleDateChangePrazo = (date) => {
        setSelectedDatePrazo(date);
        setPrazoEntregaCard(format(date, 'dd/MM/yyyy'));
    };


    const [selectedDateMedidas, setSelectedDateMedidas] = useState(null);

    const handleDateChangeMedidas = (date) => {
        setSelectedDateMedidas(date);
        setRecebimentoMedidasCard(format(date, 'dd/MM/yyyy'));
    };

    const [selectedDateConclusaoProducao, setSelectedDateConclusaoProducao] = useState(null);

    const handleDateChangeConclusaoProducao = (date) => {
        setSelectedDateConclusaoProducao(date);
        setConclusaoProducaoCard(format(date, 'dd/MM/yyyy'));
    };


    const [selectedDateProducao, setSelectedDateProducao] = useState(null);

    const handleDateChangeProducao = (date) => {
        setSelectedDateProducao(date);
        setPrevisaoProducaoCard(format(date, 'dd/MM/yyyy'));
    };

    const [selectedDateEntrega, setSelectedDateEntrega] = useState(null);

    const handleDateChangeEntrega = (date) => {
        setSelectedDateEntrega(date);
        setPrevisaoEntregaCard(format(date, 'dd/MM/yyyy'));
    };

    const [selectedDateInstalacao, setSelectedDateInstalacao] = useState(null);

    const handleDateChangeInstalacao = (date) => {
        setSelectedDateInstalacao(date);
        setPrevisaoInstalacaoCard(format(date, 'dd/MM/yyyy'));
    };

    const [selectedDateAssistencia, setSelectedDateAssistencia] = useState(null);

    const handleDateChangeAssistencia = (date) => {
        setSelectedDateAssistencia(date);
        setPrevisaoAssistenciaCard(format(date, 'dd/MM/yyyy'));
    };

    // DEFINIÇÃO DE VENDA
    function defineProcess(id, title) {

        setEditDateCard(new Date());

        //---------- mensagem -----------
        //const valorFormatado = valorCard.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        //sendMessage(buscarFoneReferencia(idCreateBy), '', `${createBy}\n \nSeu Card mudou de posição no CRM para ${title}! \n \nCliente: ${nameCard} \nNúmero do orçamento: ${documentCard} \nValor: ${valorFormatado}\n\n Verifique as informações fornecidas!\n\nBazze PVC`);
        //---------- fim mensagem -----------

        if (title != 'Finalizados') {
            //openCloseModalFinalizados();
            setIdColumnCard(id);
            if (statusCard == 'Perdido') {
                setStatusCard('');
            }
            if (statusCard == 'Arquivado') {
                setStatusCard('');
            }
        }

        // Adicionar no Histórico o nome do processo
        const colunaEncontrada = listaAllProcessos.find(coluna => coluna.id === id);
        if (colunaEncontrada) {
            if (title != 'Finalizados')
                addHistorico(`${colunaEncontrada.title} ( ${nameUser} )`);
        }
    }

    //---------------------- SELECIONAR ESTADO E CIDADE -----------------

    async function getEstados() {
        try {
            const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const estados = response.data;
            setListaEstadosIBGE(estados);
            return estados;
        } catch (error) {
            console.error('Erro ao obter a lista de estados:', error);
            return [];
        }
    }

    const [listaEstadosIBGE, setListaEstadosIBGE] = useState([]);

    const [selectedEstadoIBGE, setSelectedEstadoIBGE] = useState(false);
    function openCloseSelectEstadoIBGE() {
        getEstados();
        setSelectedEstadoIBGE(!selectedEstadoIBGE);
    }

    function selectEstado(id, sigla) {
        setEstadoCard(sigla);
        openCloseSelectEstadoIBGE();
        setSelectedCidadeIBGE(true);
        getCidades(id);
        setEstadoFiltro(sigla);
        irPraInputCidade();
    }

    const [selectedCidadeIBGE, setSelectedCidadeIBGE] = useState(false);

    const [listaCidadesIBGE, setListaCidadesIBGE] = useState([]);

    async function getCidades(estadoId) {
        try {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`);
            const cidades = response.data;
            setListaCidadesIBGE(cidades);
            return cidades;
        } catch (error) {
            console.error('Erro ao obter a lista de cidades:', error);
            return [];
        }
    }

    function selectCidade(id, nome) {
        setCityCard(nome);
        setSelectedCidadeIBGE(false);
        setCidadeFiltro(nome);
    }

    const [isLoading, setIsLoading] = useState('Salvar');

    function selectProdutoCard(name) {
        setProdutoCard(name);
        openCloseSelectProduto();
    }

    const arquivarCardInPostgreSQL = async () => {
        const confirmDelete = window.confirm("ARQUIVAR a oportunidade?");

        if (confirmDelete) {

            //addHistorico(`Opostunidade Arquiva por: ${nameUser}`);

            setIsLoading("Salvando...");

            registrarAtividadeUser(1);

            const novoHistorico = { id: uuidv4(), title: `Arquivado ( ${nameUser} )`, date: format(new Date(), 'dd/MM/yyyy'), edit: false };

            try {

                const columnFinalizados = listaAllProcessos.find(item => item.title === 'Finalizados'); // Use "find" em vez de "filter"
                const idColumnFinalizados = columnFinalizados.id;
                //console.log(idColumnFinalizados)

                const listaAtualizada = {
                    id_column: idColumnFinalizados,
                    nivel: nivel,
                    status: 'Arquivado',
                    lista_historico: listaHistoricoCard ? [...listaHistoricoCard, novoHistorico] : [novoHistorico],
                };

                await axios.put(`${apiUrl}/cards/${idCard}/arquivar`, listaAtualizada);
                //onsole.log('Card atualizado com sucesso!');

                setListaCards(prevLista => {
                    return prevLista.map(column => {
                        if (column.id === idCard) {
                            return {
                                ...column,
                                id_column: idColumnFinalizados,
                                nivel: nivel,
                                status: 'Arquivado',
                                lista_historico: listaHistoricoCard ? [...listaHistoricoCard, novoHistorico] : [novoHistorico],
                            };
                        }
                        return column;
                    });
                });

                openCloseEditCardModal();
            } catch (error) {
                console.error(error);
            }
        }
    };


    function selectEmpresaCard(name) {
        setNameObraCard(name);
        openCloseSelectEmpresa();
    }

    const [selectedEmpresa, setSelectedEmpresa] = useState(false);
    function openCloseSelectEmpresa() {
        setSelectedEmpresa(!selectedEmpresa);
        getParticipantes();
    }

    const [listaAtualParticipantes, setListaAtualParticipantes] = useState([]);

    const getParticipantes = async () => {

        try {

            const url = `${apiUrl}/participantes/getParticipantes`;

            const params = {
                id_user: idUser,
            };

            const response = await axios.get(url, { params });
            const data = response.data;

            setListaAtualParticipantes(data);

        } catch (error) {
            console.error(error);
        }
    };

    const [modificationDateTemporaria, setModificationDateTemporaria] = useState('');

    const [modalFinalizados, setModalFinalizados] = useState(false);
    function openCloseModalFinalizados() {

        if (isEdit) {

            setModificationDateTemporaria(modificationDateCard);

            if (statusCard != 'Perdido' && (nivelUser == 2 || nivelUser == 3 || nivelUser == 1))
                setModalFinalizados(!modalFinalizados);

        }

    }

    function defineFinalizados(status) {
        const columnFinalizados = listaAllProcessos.find(item => item.title === 'Finalizados'); // Use "find" em vez de "filter"
        const idColumnFinalizados = columnFinalizados.id;
        //console.log(idColumnFinalizados)
        //setIdColumnCard(idColumnFinalizados);
        if (status === 'Vendido' && statusTemporario != 'Vendido') {
            setIdColumnCard(idColumnCardTemporario);
            addHistorico(`Vendido ( ${nameUser} )`);
            openCloseModalFinalizados();
            setStatusCard('Vendido');
            setModificationDateCard(new Date())
            //setNivel(5);
        }

        if (status === 'Perdido' && statusTemporario != 'Perdido') {
            setIdColumnCard(idColumnFinalizados);
            addHistorico(`Perdido ( ${nameUser} )`);
            openCloseModalFinalizados();
            vendaPerdidaModal();
            setStatusCard('Perdido');
            setModificationDateCard(new Date())
        }

        if (status === 'Entregue' && statusTemporario != 'Entregue') {
            setIdColumnCard(idColumnCardTemporario);
            addHistorico(`Entregue ( ${nameUser} )`);
            openCloseModalFinalizados();
            setStatusCard('Entregue');
        }


        if (status === 'Instalacao' && statusTemporario != 'Instalacao') {
            setIdColumnCard(idColumnCardTemporario);
            addHistorico(`Instalação ( ${nameUser} )`);
            openCloseModalFinalizados();
            setStatusCard('Instalacao');
        }


        if (status === 'Assistencia' && statusTemporario != 'Assistencia') {
            setIdColumnCard(idColumnCardTemporario);
            addHistorico(`Assistência ( ${nameUser} )`);
            openCloseModalFinalizados();
            setStatusCard('Assistencia');
        }


        if (status === 'InstalacaoExt' && statusTemporario != 'InstalacaoExt') {
            setIdColumnCard(idColumnCardTemporario);
            addHistorico(`Instalação Externa ( ${nameUser} )`);
            openCloseModalFinalizados();
            setStatusCard('InstalacaoExt');
        }


        if (status === 'AssistenciaExt' && statusTemporario != 'AssistenciaExt') {
            setIdColumnCard(idColumnCardTemporario);
            addHistorico(`Assistência Externa ( ${nameUser} )`);
            openCloseModalFinalizados();
            setStatusCard('AssistenciaExt');
        }

    }

    const MAX_RETRIES = 5; // Número máximo de tentativas


    const isDataValid = (data) => {
        // Verifique se 'listaTarefasCard' e 'listaHistoricoCard' são do tipo array
        if (!Array.isArray(data.listaTarefasCard) || !Array.isArray(data.listaHistoricoCard)) {
            return false;
        }

        // Se ambos forem do tipo array, os dados são válidos
        return true;
    };


    const updateCardInPostgreSQL = async () => {
        setIsLoading("Salvando...");

        let retryCount = 0;

        while (retryCount < MAX_RETRIES) {
            try {


                // Verifique se os dados são válidos antes de continuar
                if (!isDataValid({ listaTarefasCard, listaHistoricoCard })) {
                    console.error("Dados inválidos.");
                    break; // Saia do loop se os dados não forem válidos
                }


                await axios.put(`${apiUrl}/cards/` + idCard, updatedCardData);

                //console.log('Card atualizado com sucesso no PostgreSQL');

                const listaAtualizada = {
                    id: idCard,
                    document_card: documentCard,
                    name: nameCard,
                    valor: valorCard >= 0 ? valorCard : 0,
                    valor_venda: valorVendaCard >= 0 ? valorVendaCard : 0,
                    email: emailCard ? emailCard : '',
                    name_obra: nameObraCard,
                    fone: foneCard,
                    city: cityCard,
                    estado: estadoCard,
                    previsao: previsaoCard,
                    meio_contato: meioContatoCard ? meioContatoCard : '',
                    id_column: idColumnCard.toString(),
                    nivel: nivel,
                    etiqueta: etiquetaCard ? etiquetaCard : '',
                    motivo_perca: motivoVendaPerdida,
                    //modification_date: statusCard != 'Vendido' && statusCard != 'Perdido' && statusCard != 'Arquivado' ? new Date() : modificationDateCard,
                    modification_date: modificationDateCard,
                    lista_tarefas: listaTarefasCard ? listaTarefasCard : [],
                    lista_historico: listaHistoricoCard ? listaHistoricoCard : [],
                    produto: produtoCard,
                    status: statusCard ? statusCard : '',
                    id_create_by: idCreateBy,
                    create_by: createBy,
                    name_user: createBy,
                    name_obra_cliente: nameObra ? nameObra : '',
                    name_contato: nameContato ? nameContato : '',
                    lista_anexos: fileLinksCard ? fileLinksCard : [],
                    previsao_entrega: previsaoEntregaCard,
                    horas_producao: horasProducaoCard >= 0 ? horasProducaoCard : 0,
                    previsao_instalacao: previsaoInstalacaoCard,
                    previsao_assistencia: previsaoAssistenciaCard,
                    lista_compartilhar: listaCampartilhar ? listaCampartilhar : [],
                    cor: corCard ? corCard : 'Branco',
                    //edit_date: editDateCard ? editDateCard : new Date(),
                    edit_date: editDateCard ? editDateCard : new Date(),
                    prioridade: prioridade ? prioridade : '',
                    previsao_producao: previsaoProducaoCard,
                    recebimento_medidas: recebimentoMedidasCard ? recebimentoMedidasCard : '',
                    prazo_entrega: prazoEntregaCard ? prazoEntregaCard : '',
                    numero_pedido: numeroPedidoCard ? numeroPedidoCard : '',
                    quadros: quadrosCard ? quadrosCard : 0,
                    metros_quadrados: metrosQuadradosCard ? metrosQuadradosCard : 0,
                    quantidade_esquadrias: quantidadeEsquadriasCard ? quantidadeEsquadriasCard : 0,
                    entrega_vidro: entregaVidroCard ? entregaVidroCard : '',
                    status_vidro: statusVidroCard ? statusVidroCard : '',
                    obs: obsCard ? obsCard : '',
                    conclusao_producao: conclusaoProducaoCard ? conclusaoProducaoCard : '',
                };

                atualizarListaCardsOfflinePostgre(idCard, listaAtualizada);

                // Saia do loop se a atualização for bem-sucedida
                break;

            } catch (error) {

                console.error(`Tentativa ${retryCount + 1} de atualização falhou:`, error);

                // Incrementa o contador de tentativas
                retryCount++;

                console.error(error);

                // Aguarde um curto período antes de tentar novamente (opcional)
                await new Promise(resolve => setTimeout(resolve, 1000));
                setIsLoading(`Salvando... Tentativa ${retryCount}`);

                if (retryCount == 5) {
                    setIsLoading(`Sem conexão!`);
                }
            }
        }
    };

    const [statusTemporario, setStatusTemporario] = useState('');

    const atualizarListaCardsOfflinePostgre = (id, novosValores) => {

        setListaCards(prevLista => {
            return prevLista.map(column => {
                if (column.id === id) {
                    return { ...column, ...novosValores }; // Atualize todos os valores do item
                }
                return column;
            });
        });


        if (statusCard == "Vendido" && statusTemporario != 'Vendido') {
            setShowConfetti(true);
            setTimeout(() => {
                setShowConfetti(false);
            }, 8000);
        }

        openCloseEditCardModal();
    };

    const updatedCardData = {
        document_card: documentCard,
        name: nameCard,
        valor: valorCard >= 0 ? valorCard : 0,
        valor_venda: valorVendaCard >= 0 ? valorVendaCard : 0,
        email: emailCard ? emailCard : '',
        name_obra: nameObraCard ? nameObraCard : '',
        fone: foneCard,
        city: cityCard,
        estado: estadoCard,
        previsao: previsaoCard,
        meio_contato: meioContatoCard ? meioContatoCard : '',
        id_column: idColumnCard,
        nivel: nivel,
        etiqueta: etiquetaCard ? etiquetaCard : '',
        motivo_perca: motivoVendaPerdida,
        modification_date: modificationDateCard,
        lista_tarefas: listaTarefasCard ? listaTarefasCard : [],
        lista_historico: listaHistoricoCard ? listaHistoricoCard : [],
        produto: produtoCard,
        status: statusCard ? statusCard : '',
        id_create_by: idCreateBy,
        create_by: createBy,
        name_user: createBy,
        name_obra_cliente: nameObra ? nameObra : '',
        name_contato: nameContato ? nameContato : '',
        lista_anexos: fileLinksCard ? fileLinksCard : [],
        previsao_entrega: previsaoEntregaCard,
        horas_producao: horasProducaoCard >= 0 ? horasProducaoCard : 0,
        previsao_instalacao: previsaoInstalacaoCard,
        previsao_assistencia: previsaoAssistenciaCard,
        lista_compartilhar: listaCampartilhar ? listaCampartilhar : [],
        cor: corCard ? corCard : '',
        edit_date: editDateCard ? editDateCard : new Date(),
        prioridade: prioridade ? prioridade : '',
        previsao_producao: previsaoProducaoCard,
        recebimento_medidas: recebimentoMedidasCard ? recebimentoMedidasCard : '',
        prazo_entrega: prazoEntregaCard ? prazoEntregaCard : '',
        numero_pedido: numeroPedidoCard ? numeroPedidoCard : '',
        quadros: quadrosCard ? quadrosCard : 0,
        metros_quadrados: metrosQuadradosCard ? metrosQuadradosCard : 0,
        quantidade_esquadrias: quantidadeEsquadriasCard ? quantidadeEsquadriasCard : 0,
        entrega_vidro: entregaVidroCard ? entregaVidroCard : '',
        status_vidro: statusVidroCard ? statusVidroCard : '',
        obs: obsCard ? obsCard : '',
        conclusao_producao: conclusaoProducaoCard ? conclusaoProducaoCard : '',
    };

    function formatarData(data) {
        const date = moment(data);
        return date.format('DD/MM/YYYY');
    }


    function abrirEmail(destinatario) {
        const emailUri = `mailto:${destinatario}`;
        window.location.href = emailUri;
    }

    const [listaProdutos, setListaProdutos] = useState([]);

    const getProducts = async () => {

        try {

            const url = `${apiUrl}/produtos/getProducts`;

            const params = {
                empresa: idEmpresa
            };

            const response = await axios.get(url, { params });
            const products = response.data;


            if (products && products.length > 0) {
                setListaProdutos(products);
            }

        } catch (error) {
            console.error(error);
        }
    };


    const [selectedNewEntidadeResponsavel, setSelectedNewEntidadeResponsavel] = useState(false);

    function entidadeResponsavelSelecionada(name, id) {

        addHistorico(`${nameUser} alterou o responsável para ${name}`);

        setCreateBy(name);
        setIdCreateBy(id);
        setSelectedNewEntidadeResponsavel(false);
    }


    // ----- ALTERAR DATA DE MODIFICAÇÃO MANUALMENTE ----
    const [selectedDateModification, setSelectedDateModification] = useState(null);

    const handleDateChangeModification = (date) => {
        setSelectedDateModification(format(date, 'dd/MM/yyyy'));
        setModificationDateCard(date);
    };

    function formatarData(data) {
        const date = moment(data);
        return date.format('DD/MM/YYYY');
    }


    function addHistorico(mensagem) {


        if (statusCard != 'Vendido' && statusCard != 'Instalacao' && statusCard != 'Entregue' && statusCard != 'InstalacaoExt' && statusCard != 'Perdido' && statusCard != 'Arquivado') {
            setEditDateCard(new Date());
        }
        //setEditDateCard(new Date());

        registrarAtividadeUser(5);

        const novoHistorico = { id: uuidv4(), title: mensagem, date: format(new Date(), 'dd/MM/yyyy'), edit: false };

        if (listaHistoricoCard) {

            setListaHistoricoCard([...listaHistoricoCard, novoHistorico]);
        }

        if (!listaHistoricoCard) {

            setListaHistoricoCard([novoHistorico]);
        }
    }

    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [cidadeFiltro, setCidadeFiltro] = useState('');
    const input2Ref = useRef(null);

    const irPraInputCidade = () => {
        // Define o foco no segundo campo de entrada ao clicar no botão
        input2Ref.current.focus();
    };

    // ANEXO DE ARQUIVOS -----------------------------------------------

    // Create a root reference
    const storage = getStorage(fb);

    const MAX_FILE_SIZE_BYTES = 1 * 1024 * 1024;
    const [infoUpload, setInfoUpload] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const [exigirSalvar, setExigirSalvar] = useState(false);

    const handleFileUpload = async (e) => {

        setIsLoading('Salvando...')

        const files = e.target.files;

        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                if (file.size <= MAX_FILE_SIZE_BYTES) {
                    setInfoUpload("Salvando arquivo...");
                    setIsSaving(true);
                    setExigirSalvar(true);

                    // Gerar um nome único para o arquivo, por exemplo, usando um timestamp
                    const timestamp = Date.now();
                    const uniqueFileName = `${file.name}_${timestamp}`;

                    const storageRef = ref(storage, `uploads/${uniqueFileName}`);

                    try {

                        setModalMensagemAviso(true)
                        setMensagemAviso('Anexando Arquivo...')

                        await uploadBytes(storageRef, file);

                        const link = await getDownloadURL(storageRef);

                        setFileLinksCard((prevLinks) => {
                            if (Array.isArray(prevLinks)) {
                                return [...prevLinks, { name: uniqueFileName, link: link }];
                            } else {
                                return [{ name: uniqueFileName, link: link }]; // Se prevLinks não for um array, crie um novo array com link
                            }
                        });
                        console.log(fileLinksCard)

                        setInfoUpload("Arquivo salvo com sucesso!");
                        setIsSaving(false);
                        setIsLoading('Salvar')
                        setModalMensagemAviso(false)
                    } catch (error) {
                        console.error("Erro ao enviar o arquivo:", error);
                        setInfoUpload("Erro ao salvar! Tente novamente...");
                        setIsSaving(false);
                        setModalMensagemAviso(false)
                    }
                } else {
                    setInfoUpload("Tamanho do arquivo excede o limite permitido.");
                    setIsSaving(false);
                    setModalMensagemAviso(false)
                }
            }
        }
    };


    const deleteAllAnexos = async (id) => {
        setExigirSalvar(true);

        const confirmDelete = window.confirm('Você tem certeza que deseja excluir este documento?');

        if (confirmDelete) {

            if (fileLinksCard && fileLinksCard.length > 0) {

                for (let i = 0; i < fileLinksCard.length; i++) {
                    const fileLink = fileLinksCard[i];

                    try {

                        const storageRef = ref(storage, `uploads/${fileLink.name}`);
                        await deleteObject(storageRef);
                        console.log("Anexo excluído: " + fileLink.name);

                        deletCard(id);
                    } catch (error) {
                        console.error("Erro ao excluir anexo: " + fileLink, error);
                    }
                }
            } else {
                console.log('Nenhum anexo')
                deletCard(id);
            }

        }
    };


    async function deletCard(id) {

        try {

            await axios.delete(`${apiUrl}/cards/${id}`);

            removerCard(id);

        } catch (error) {
            console.error('Erro ao excluir card:', error);
        }

    }

    const removerCard = (id) => {
        const novaLista = listaCards.filter(item => item.id !== id);
        setListaCards(novaLista);
        openCloseEditCardModal();
    };


    const handleDeleteFile = async (fileName) => {

        const confirmDelete = window.confirm('Excluir arquivo?');

        if (confirmDelete) {

            setExigirSalvar(true);
            // Remova o arquivo do Storage
            const storageRef = ref(storage, `uploads/${fileName}`);
            try {
                await deleteObject(storageRef);
            } catch (error) {
                console.error("Erro ao excluir o arquivo do Storage:", error);
            }

            // Crie uma nova lista excluindo o arquivo com o mesmo nome
            const updatedFileLinks = fileLinksCard.filter(item => item.name !== fileName);

            // Atualize o estado com a nova lista
            setFileLinksCard(updatedFileLinks);
        }

    };

    const [modalConfirm, setModalConfirm] = useState(false);
    const [confirmMensagem, setConfirmMensagem] = useState(false);
    const [mensagem, setMensagem] = useState(false);

    const [modalMensagemAviso, setModalMensagemAviso] = useState(false);
    const [mensagemAviso, setMensagemAviso] = useState('');

    function validarConfirmacao(confirm) {
        if (confirm) {
            openCloseEditCardModal();
        } else {
            setModalConfirm(false);
        }
    }


    function validarCancelEdit(mensagem) {

        setMensagem(mensagem);

        setModalConfirm(true)

    }

    function abrirWhatsApp(fone) {

        const confirmDelete = window.confirm('Iniciar conversa pelo WhatsApp?');

        if (confirmDelete) {

            registrarAtividadeUser(1);

            addHistorico(`WhatsApp ( ${nameUser} )`);

            const numeroLimpo = fone.replace(/[^\d]/g, ''); // Remove caracteres não numéricos

            const codigoPais = '+55'; // Código do país (Brasil)

            const numeroTelefone = codigoPais + numeroLimpo;

            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );

            if (isMobile) {
                window.location.href = `https://api.whatsapp.com/send?phone=${numeroTelefone}`;
            } else {
                window.open(`https://web.whatsapp.com/send?phone=${numeroTelefone}`, '_blank');
            }
        }
    }



    //console.log("listaProcessosUser")
    //console.log(listaProcessos)

    const [desableButton, setDesableButton] = useState(false);

    useEffect(() => {
        // Verifica se pelo menos um item em listaProcessos tem o id igual a idColumnCard e edit igual a true
        const isEditTrue = listaProcessos.some((item) => {
            return (item.id.toString() === idColumnCardTemporario || item.id === idColumnCardTemporario) && item.edit === true;
        });

        //console.log(idColumnCard)
        //console.log(listaProcessos)
        //console.log(isEditTrue)
        setDesableButton(!isEditTrue); // Define desableButton como verdadeiro se a condição não for atendida
    }, [listaProcessos, idColumnCard, idColumnCardTemporario]);





    const criarEtapaProducao = async () => {

        const confirmDelete = window.confirm('Você tem certeza que deseja criar uma Etapa de produção e duplicar este card?');

        if (confirmDelete) {

            setIsLoading("Salvando...");

            try {

                const cardData = {
                    document_card: documentCard,
                    name: nameCard,
                    name_obra_cliente: nameObra ? nameObra : '',
                    valor: 0,
                    email: emailCard ? emailCard : '',
                    fone: foneCard,
                    city: cityCard,
                    estado: estadoCard,
                    previsao: previsaoCard ? previsaoCard : '',
                    meio_contato: meioContatoCard ? meioContatoCard : '',
                    create_by: createBy,
                    id_create_by: idCreateBy,
                    name_user: createBy != '' ? createBy : '',
                    id_column: idColumnCard,
                    date: new Date(),
                    nivel: nivel,
                    etiqueta: etiquetaCard ? etiquetaCard : '',
                    empresa: idEmpresa,
                    motivo_perca: '',
                    modification_date: new Date(),
                    produto: produtoCard ? produtoCard : '',
                    status: statusCard,
                    lista_tarefas: [],
                    lista_historico: [{ id: uuidv4(), title: `Etapa de Produção ( ${nameUser} )`, date: format(new Date(), 'dd/MM/yyyy'), edit: false }],
                    tipo_participante: tipoParticipante,
                    etapa_producao: 1,
                    cor: corCard ? corCard : '',
                    edit_date: new Date(),
                    name_contato: nameContato ? nameContato : '',
                    valor_venda: 0,
                };

                const response = await axios.post(`${apiUrl}/cards`, cardData);
                console.log(response.data);

                const idCard = response.data.id;

                const listaAtualizada = {
                    id: idCard,
                    document_card: documentCard,
                    name: nameCard,
                    name_obra_cliente: nameObra ? nameObra : '',
                    valor: 0,
                    email: emailCard ? emailCard : '',
                    fone: foneCard,
                    city: cityCard,
                    estado: estadoCard,
                    previsao: previsaoCard ? previsaoCard : '',
                    meio_contato: meioContatoCard ? meioContatoCard : '',
                    create_by: createBy,
                    id_create_by: idCreateBy,
                    name_user: createBy != '' ? createBy : '',
                    id_column: idColumnCard,
                    date: new Date(),
                    nivel: nivel,
                    etiqueta: etiquetaCard ? etiquetaCard : '',
                    empresa: idEmpresa,
                    motivo_perca: '',
                    modification_date: new Date(),
                    produto: produtoCard ? produtoCard : '',
                    status: statusCard,
                    lista_tarefas: [],
                    lista_historico: [{ id: uuidv4(), title: `Etapa de Produção ( ${nameUser} )`, date: format(new Date(), 'dd/MM/yyyy'), edit: false }],
                    tipo_participante: tipoParticipante,
                    etapa_producao: 1,
                    cor: corCard ? corCard : '',
                    edit_date: new Date(),
                    name_contato: nameContato ? nameContato : '',
                    valor_venda: 0,
                };

                const listaCardsAtualizada = [...listaCards, listaAtualizada];

                setListaCards(listaCardsAtualizada);

                openCloseEditCardModal()


            } catch (error) {

                console.error(`Falha ao duplicar Card:`, error);

            }

        }

    }

    const [nivelUser, setNivelUser] = useState(0);

    useEffect(() => {
        if (tipoParticipante === 'Administrador') {
            setNivelUser(1);
        }

        if (tipoParticipante === 'Parceiro') {
            setNivelUser(2);
        }

        if (tipoParticipante === 'Escritorio') {
            setNivelUser(3);
        }

        if (tipoParticipante === 'Producao') {
            setNivelUser(4);
        }

        if (tipoParticipante === 'Externo') {
            setNivelUser(5);
        }
    }, [tipoParticipante]);



    // -----------COMPARTILHAR CARD -------

    const [selectedUser, setSelectedUser] = useState(false);
    function openCloseSelectUser() {
        setSelectedUser(!selectedUser);
    }

    function selectUser(id, name) {
        const novoParticipanteFilho = { id: id, name: name };

        if (listaCampartilhar && listaCampartilhar.length > 0) {
            const itemExistente = listaCampartilhar.find((item) => item.id === id);

            if (!itemExistente) {
                setListaCompartilhar([...listaCampartilhar, novoParticipanteFilho]);
            } else {
                const novaLista = listaCampartilhar.filter((item) => item.id !== id);
                setListaCompartilhar(novaLista);
            }
        } else {
            setListaCompartilhar([novoParticipanteFilho]);
        }
    }




    // -------------------- problemas no scroll em input number


    // -------------------- fim problemas no scroll em input number

    const [openCloseAnexos, setOpenCloseAnexos] = useState(false);

    return (
        <div className='modal-edit-card-container'>

            <div className='edit-card-container' style={{ border: etapaProducaoCard > 0 ? '2px solid deeppink' : 'none' }}>

                <div className='edit-card-header'>
                    <div className='title-header-edit-card'>Oportunidade</div>

                    {etapaProducaoCard > 0 ? (
                        <div className='title-header-edit-card' style={{ color: 'deeppink' }} >Etapa de Produção</div>
                    ) : <></>}

                    <div className='btn-close-container'>
                        {/*<button className='btn-close' disabled={exigirSalvar} onClick={() => validarConfirmacao(false)}>X</button>*/}
                    </div>

                </div>
                <div className='edit-card-body'>

                    <label className='title'>Processo</label>

                    <div className='define-process'>
                        {prioridade != 'Potencial Cliente' &&
                            listaAllProcessos
                                .slice() // Crie uma cópia da lista para não modificar a original
                                .sort((a, b) => a.ordem - b.ordem) // Ordene os elementos com base na ordem crescente de 'ordem'
                                .map((item) => {

                                    const isAllowed = listaProcessos.some((processo) => processo.id === item.id && processo.edit === true);

                                    if (!isAllowed) {
                                        return null;
                                    };

                                    return (
                                        <button
                                            disabled={!isEdit}
                                            key={item.id}
                                            style={{
                                                background: idColumnCard.toString() === item.id.toString() ? 'deeppink' : '',
                                                color: idColumnCard.toString() === item.id.toString() ? 'white' : ''
                                            }}
                                            onClick={() => defineProcess(item.id, item.title)}
                                            className='process'
                                        >
                                            {item.title}
                                        </button>
                                    );
                                })
                        }
                    </div>

                    <div className='modal-cards-finalizados-edit'>

                        {nivelUser < 6 && prioridade != 'Potencial Cliente' &&

                            <div className='modal-finalizados-container-edit'>

                                <div className='finalizados-container-icons-edit'>
                                    {true ? (
                                        <div className='finalizados-container-item-edit' style={{ boxShadow: statusCard == 'Vendido' ? '0px 0px 5px rgba(255, 47, 134, 0.7)' : 'none', }} onClick={() => openCloseModalFinalizados()}>
                                            <MdThumbUp className='icon-finalizados-vendido-edit' />
                                        </div>
                                    ) : <></>}
                                    {true ? (
                                        <div className='finalizados-container-item-edit' style={{ boxShadow: statusCard == 'Perdido' ? '0px 0px 5px rgba(255, 47, 134, 0.7)' : 'none', }} onClick={() => openCloseModalFinalizados()}>
                                            <MdThumbDown className='icon-finalizados-perdido-edit' />
                                        </div>
                                    ) : <></>}

                                    {true ? (
                                        <div className='finalizados-container-item-edit' style={{ boxShadow: statusCard == 'Instalacao' ? '0px 0px 5px rgba(255, 47, 134, 0.7)' : 'none', }} onClick={() => openCloseModalFinalizados()}>
                                            <MdWindow className='icon-finalizados-entregue-edit' />
                                        </div>
                                    ) : <></>}

                                    {true ? (
                                        <div className='finalizados-container-item-edit' style={{ boxShadow: statusCard == 'InstalacaoExt' ? '0px 0px 5px rgba(255, 47, 134, 0.7)' : 'none', }} onClick={() => openCloseModalFinalizados()}>
                                            <MdWindow className='icon-finalizados-ext-edit' />
                                        </div>
                                    ) : <></>}

                                    {true ? (
                                        <div className='finalizados-container-item-edit' style={{ boxShadow: statusCard == 'Assistencia' ? '0px 0px 5px rgba(255, 47, 134, 0.7)' : 'none', }} onClick={() => openCloseModalFinalizados()}>
                                            <MdBuild className='icon-finalizados-entregue-edit' />
                                        </div>
                                    ) : <></>}

                                    {true ? (
                                        <div className='finalizados-container-item-edit' style={{ boxShadow: statusCard == 'AssistenciaExt' ? '0px 0px 5px rgba(255, 47, 134, 0.7)' : 'none', }} onClick={() => openCloseModalFinalizados()}>
                                            <MdBuild className='icon-finalizados-ext-edit' />
                                        </div>
                                    ) : <></>}

                                    {true ? (
                                        <div className='finalizados-container-item-edit' style={{ boxShadow: statusCard == 'Entregue' ? '0px 0px 5px rgba(255, 47, 134, 0.7)' : 'none', }} onClick={() => openCloseModalFinalizados()}>
                                            <MdLocalShipping className='icon-finalizados-entregue-edit' />
                                        </div>
                                    ) : <></>}


                                </div>
                            </div>
                        }

                    </div>


                    <div className='modal-cards-finalizados-edit'>

                        <div className='pontencial-venda-container'>

                            {nivelUser < 3 ? (
                                <label className='card-stars-container'>
                                    <MdGrade
                                        className='icon-star-edit-card'
                                        onClick={() => setNivel(1)}
                                        style={{ color: nivel >= 1 ? '#9961FF' : 'rgba(172, 172, 172, 0.6)' }}
                                    />
                                    <MdGrade
                                        className='icon-star-edit-card'
                                        onClick={() => setNivel(2)}
                                        style={{ color: nivel >= 2 ? '#777CFF' : 'rgba(172, 172, 172, 0.6)' }}
                                    />
                                    <MdGrade
                                        className='icon-star-edit-card'
                                        onClick={() => setNivel(3)}
                                        style={{ color: nivel >= 3 ? '#5F8EFF' : 'rgba(172, 172, 172, 0.6)' }}
                                    />
                                    <MdGrade
                                        className='icon-star-edit-card'
                                        onClick={() => setNivel(4)}
                                        style={{ color: nivel >= 4 ? '#39A9FF' : 'rgba(172, 172, 172, 0.6)' }}
                                    />
                                    <MdGrade
                                        className='icon-star-edit-card'
                                        onClick={() => setNivel(5)}
                                        style={{ color: nivel >= 5 ? '#09CEFF' : 'rgba(172, 172, 172, 0.6)' }}
                                    />
                                </label>
                            ) : <></>}

                            {nivelUser < 3 ? (
                                <label className='title'>Potencial de venda</label>
                            ) : <></>}

                        </div>

                    </div>

                    {nivelUser < 6 && (prioridade != 'Potencial Cliente' || tipoParticipante == 'Administrador') ? (
                        <>
                            <label className='title'>Etiqueta</label>
                            <input disabled={nivelUser > 300} className='edit-card-select' type='text' value={prioridade ?? ''} readOnly onClick={openCloseSelectPrioridade} placeholder={'Selecione a prioridade...'}></input>
                        </>
                    ) : <></>}

                    {
                        selectedPrioridade ? (
                            <div className='card-select'>
                                {
                                    listaPrioridades
                                        .filter((item) => item.id != '5' || nivelUser != 200)
                                        .map((item) => <li key={item.id} className='item-list-select' onClick={() => selectPrioridade(item.tipo)}>{item.tipo}</li>)
                                }
                            </div>
                        ) : <></>
                    }

                    <label className='title'>Nome do cliente - Obrigatório</label>
                    <input disabled={nivelUser > 3 || !isEdit} className='input-edit-card' type='text' value={nameCard ?? ''} onChange={(e) => setNameCard(e.target.value)} placeholder={'Digite o nome do cliente...'}></input>

                    <label className='title'>Nome da Obra</label>
                    <input disabled={nivelUser > 3 || !isEdit} className='input-edit-card' type='text' value={nameObra ?? ''} onChange={(e) => setNameObra(e.target.value)} placeholder={'Digite o nome da obra...'}></input>

                    {nivelUser < 4 ? (
                        <>
                            <label className='title'>Contato Responsável</label>
                            <input disabled={nivelUser > 3 || !isEdit} className='input-edit-card' type='text' value={nameContato ?? ''} onChange={(e) => setNameContato(e.target.value)} placeholder={'Digite o nome do contato responsável...'}></input>
                        </>
                    ) : <></>}

                    {nivelUser < 3 ? (
                        <>
                            <label className='title'>Parceiro Indicador</label>
                            <input className='edit-card-select' type='text' value={nameObraCard ?? ''} readOnly onClick={openCloseSelectEmpresa} placeholder={'Selecione a empresa...'}></input>
                        </>
                    ) : <></>}

                    {selectedEmpresa ? (
                        <div className='card-select'>
                            {
                                listaAtualParticipantes.map((item) => <li key={item.id} className='item-list-select' onClick={() => selectEmpresaCard(item.name)}>{item.name}</li>)
                            }
                        </div>
                    ) : <></>}

                    {nivelUser < 6 ? (
                        <label className='title'>Número da proposta</label>
                    ) : <></>}

                    {nivelUser < 6 ? (
                        <input disabled={nivelUser > 3 || !isEdit} className='input-edit-card' type='text' value={documentCard ?? ''} onChange={(e) => setDocumentCard(e.target.value)} placeholder={'Digite o número da proposta...'}></input>
                    ) : <></>}


                    {nivelUser < 6 ? (
                        <label className='title'>Número do Pedido</label>
                    ) : <></>}

                    {nivelUser < 6 ? (
                        <input disabled={nivelUser > 3 || !isEdit} className='input-edit-card' type='text' value={numeroPedidoCard ?? ''} onChange={(e) => setNumeroPedidoCard(e.target.value)} placeholder={'Digite o número do Pedido...'}></input>
                    ) : <></>}

                    {nivelUser < 4 ? (
                        <label className='title'>Valor de Custo - Obrigatório</label>
                    ) : <></>}

                    {nivelUser < 4 ? (
                        <input
                            disabled={nivelUser > 3 || !isEdit}
                            className='input-edit-card'
                            type='number'
                            step='0.01' // Define a precisão do valor decimal
                            value={valorCard}
                            onChange={(e) => setValorCard(parseFloat(e.target.value))}
                            placeholder={'Valor de Custo...'}>
                        </input>
                    ) : <></>}

                    {nivelUser < 4 ? (
                        <label className='title'>Valor de Venda</label>
                    ) : <></>}

                    {nivelUser < 4 ? (
                        <input
                            disabled={nivelUser > 3}
                            className='input-edit-card'
                            type='number'
                            step='0.01'
                            value={valorVendaCard}
                            onChange={(e) => setValorVendaCard(parseFloat(e.target.value))}
                            placeholder={'Valor de Venda...'}>
                        </input>
                    ) : <></>}

                    {nivelUser < 3 ? (
                        <>
                            <label className='title'>Previsão de Fechamento</label>
                            <form>
                                <DatePicker
                                    disabled={nivelUser > 2}
                                    type='number'
                                    locale="pt-BR"
                                    className='input-data-picker-edit-card'
                                    calendarClassName='custom-calendar-edit-card'
                                    id="data"
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={previsaoCard ? previsaoCard : 'Selecione a data...'}
                                />
                            </form>
                        </>
                    ) : <></>}


                    {nivelUser < 4 ? (
                        <>
                            <label className='title'>Email</label>
                            <div className='fone-container'>
                                <input disabled={nivelUser > 3 || !isEdit} className='input-edit-card' type='email' value={emailCard ?? ''} onChange={(e) => setEmailCard(e.target.value)} placeholder={'Digite o Email...'}></input>
                                <MdOutlineEmail className='icons-email' onClick={() => abrirEmail(emailCard)} />
                            </div>
                        </>
                    ) : <></>}

                    {nivelUser < 4 ? (
                        <>
                            <label className='title'>Fone - Obrigatório</label>
                            <div className='fone-container'>
                                <input disabled={nivelUser > 3 || !isEdit} className='input-edit-card' type='tel' value={foneCard ?? ''} onChange={(e) => setFoneCard(e.target.value)} placeholder={'Digite o telefone...'}></input>
                                <MdWhatsapp className='icons-whatsapp' onClick={() => abrirWhatsApp(foneCard)} />
                            </div>
                        </>
                    ) : <></>}

                    <label className='title'>Estado - Obrigatório</label>
                    <input disabled={nivelUser > 3 || !isEdit} className='input-edit-card' type='text' value={estadoFiltro ?? estadoCard} onChange={(e) => setEstadoFiltro(e.target.value)} onClick={openCloseSelectEstadoIBGE} placeholder={estadoCard || 'Selecione o estado...'}></input>

                    {selectedEstadoIBGE ? (
                        <div className='card-select'>
                            {
                                listaEstadosIBGE
                                    .filter((estado) => estadoFiltro ? estado.sigla.toLowerCase().includes(estadoFiltro.toLowerCase()) : true)
                                    .sort((a, b) => a.sigla.localeCompare(b.sigla)) // Ordena em ordem alfabética por sigla
                                    .map((estado) => <li key={estado.id} className='item-list-select' onClick={() => selectEstado(estado.id, estado.sigla)}>{estado.sigla}</li>)

                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Cidade - Obrigatório</label>
                    <input disabled={nivelUser > 3 || !isEdit} ref={input2Ref} className='input-edit-card' type='text' value={cidadeFiltro ?? cityCard} onChange={(e) => setCidadeFiltro(e.target.value)} placeholder={cityCard || 'Selecione a cidade...'}></input>

                    {selectedCidadeIBGE ? (
                        <div className='card-select'>
                            {
                                listaCidadesIBGE
                                    .filter((cidade) => cidadeFiltro ? cidade.nome.toLowerCase().includes(cidadeFiltro.toLowerCase()) : true)
                                    .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena em ordem alfabética por nome
                                    .map((cidade) => <li key={cidade.id} className='item-list-select' onClick={() => selectCidade(cidade.id, cidade.nome)}>{cidade.nome}</li>)
                            }
                        </div>
                    ) : <></>}

                    {nivelUser < 4 ? (
                        <>
                            <label className='title'>Origem</label>
                            <input disabled={nivelUser > 3 || !isEdit} className='edit-card-select' type='text' value={meioContatoCard ?? ''} readOnly onClick={openCloseSelectMeioContato} placeholder={'Selecione o meio de contato...'}></input>
                        </>
                    ) : <></>}

                    {selectedMeioContato ? (
                        <div className='card-select'>
                            {
                                meioContato.map((item) => <li key={item.id} className='item-list-select' onClick={() => selectMeioContato(item.meio)}>{item.meio}</li>)
                            }
                        </div>
                    ) : <></>}

                    {nivelUser < 6 ? (
                        <>
                            <label className='title'>Produto</label>
                            <input disabled={nivelUser > 3 || !isEdit} className='edit-card-select' type='text' value={produtoCard ?? ''} readOnly onClick={openCloseSelectProduto} placeholder={'Selecione um produto...'}></input>
                        </>
                    ) : <></>}

                    {
                        selectedProduto ? (
                            <div className='card-select'>
                                {
                                    listaProdutos.map((item) => <li key={item.id} className='item-list-select' onClick={() => selectProdutoCard(item.name)}>{item.name}</li>)
                                }
                            </div>
                        ) : <></>
                    }

                    {nivelUser < 4 ? (
                        <>
                            <label className='title'>Cor</label>
                            <input disabled={nivelUser > 3 || !isEdit} className='edit-card-select' type='text' value={corCard ?? ''} readOnly onClick={openCloseSelectCor} placeholder={'Selecione a cor...'}></input>
                        </>
                    ) : <></>}

                    {selectedCor ? (
                        <div className='card-select'>
                            {
                                cores.map((item) => <li key={item.id} className='item-list-select' onClick={() => selectCor(item.cor)}>{item.cor}</li>)
                            }
                        </div>
                    ) : <></>}


                    {statusCard != 'Perdido' && nivelUser < 5 ? (
                        <div className='date-container'>
                            <label className='title'>Recebimento de medidas</label>
                            <div className='container-producao'>
                                <form >
                                    <DatePicker
                                        disabled={(nivelUser != 1 && nivelUser != 2 && nivelUser != 3) || !isEdit}
                                        type='number'
                                        locale="pt-BR"
                                        className='input-data-picker-edit-card'
                                        calendarClassName='custom-calendar-edit-card'
                                        id="data"
                                        selected={selectedDateMedidas}
                                        onChange={handleDateChangeMedidas}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={recebimentoMedidasCard ? recebimentoMedidasCard : 'Selecione a data...'}
                                    />
                                </form>

                                <button disabled={!isEdit} className='btn-clear-date-prod' onClick={() => setRecebimentoMedidasCard(null)}>X</button>
                            </div>
                        </div>
                    ) : <></>}

                    <div className='producao-container'>

                        {statusCard != 'Perdido' && nivelUser != 2 ? (
                            <div className='date-container'>
                                <label className='title'>Previsão de Produção</label>
                                <form >
                                    <DatePicker
                                        disabled={(nivelUser != 1 && nivelUser != 3) || !isEdit}
                                        type='number'
                                        locale="pt-BR"
                                        className='input-data-picker-edit-card'
                                        calendarClassName='custom-calendar-edit-card'
                                        id="data"
                                        selected={selectedDateProducao}
                                        onChange={handleDateChangeProducao}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={previsaoProducaoCard ? previsaoProducaoCard : 'Selecione a data...'}
                                    />
                                </form>
                            </div>
                        ) : <></>}

                        {statusCard != 'Perdido' && nivelUser != 2 ? (
                            <div className='date-container'>
                                <div className='container-producao'>
                                    <div className='container-conclusao-producao'>
                                        <label className='title'>Conclusão de Produção</label>
                                        <form >
                                            <DatePicker
                                                disabled={(nivelUser != 1 && nivelUser != 3) || !isEdit}
                                                type='number'
                                                locale="pt-BR"
                                                className='input-data-picker-edit-card'
                                                calendarClassName='custom-calendar-edit-card'
                                                id="data"
                                                selected={selectedDateConclusaoProducao}
                                                onChange={handleDateChangeConclusaoProducao}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText={conclusaoProducaoCard ? conclusaoProducaoCard : 'Selecione a data...'}
                                            />
                                        </form>
                                    </div>
                                    <button className='btn-clear-date-prod' onClick={() => setConclusaoProducaoCard(null)}>X</button>
                                </div>
                            </div>
                        ) : <></>}

                    </div>

                    {(statusCard == 'Vendido' || statusCard == 'Instalacao' || statusCard == 'InstalacaoExt' || nivelUser == 1) && (nivelUser < 6) ? (
                        <label className='title'>Horas necessárias para Produção</label>
                    ) : <></>}

                    {(statusCard == 'Vendido' || statusCard == 'Instalacao' || statusCard == 'InstalacaoExt' || nivelUser == 1) && (nivelUser < 6) ? (
                        <input
                            disabled={(nivelUser != 1 && nivelUser != 3) || !isEdit}
                            className='input-edit-card'
                            type='number'
                            step='1' // Define a precisão do valor decimal
                            value={horasProducaoCard}
                            onChange={(e) => setHorasProducaoCard(parseInt(e.target.value))}
                            placeholder={'Horas necessárias para a produção...'}>
                        </input>
                    ) : <></>}

                    {statusCard != 'Perdido' && nivelUser < 6 ? (
                        <div>
                            <label className='title'>Previsão de Instalação</label>
                            <form >
                                <DatePicker
                                    disabled={(nivelUser != 1 && nivelUser != 2 && nivelUser != 3) || !isEdit}
                                    type='number'
                                    locale="pt-BR"
                                    className='input-data-picker-edit-card'
                                    calendarClassName='custom-calendar-edit-card'
                                    id="data"
                                    selected={selectedDateInstalacao}
                                    onChange={handleDateChangeInstalacao}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={previsaoInstalacaoCard ? previsaoInstalacaoCard : 'Selecione a data...'}
                                />
                            </form>
                        </div>
                    ) : <></>}

                    {statusCard != 'Perdido' && nivelUser < 6 ? (
                        <div>
                            <label className='title'>Previsão de Assistência Técnica</label>
                            <form >
                                <DatePicker
                                    disabled={(nivelUser != 1 && nivelUser != 2 && nivelUser != 3) || !isEdit}
                                    type='number'
                                    locale="pt-BR"
                                    className='input-data-picker-edit-card'
                                    calendarClassName='custom-calendar-edit-card'
                                    id="data"
                                    selected={selectedDateAssistencia}
                                    onChange={handleDateChangeAssistencia}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={previsaoAssistenciaCard ? previsaoAssistenciaCard : 'Selecione a data...'}
                                />
                            </form>
                        </div>
                    ) : <></>}

                    <div className='producao-container'>

                        {statusCard != 'Perdido' && nivelUser != 2 ? (
                            <div className='date-container'>
                                <label className='title'>Previsão Entrega</label>
                                <form >
                                    <DatePicker
                                        disabled={(nivelUser != 1 && nivelUser != 2 && nivelUser != 3) || !isEdit}
                                        type='number'
                                        locale="pt-BR"
                                        className='input-data-picker-edit-card'
                                        calendarClassName='custom-calendar-edit-card'
                                        id="data"
                                        selected={selectedDateEntrega}
                                        onChange={handleDateChangeEntrega}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={previsaoEntregaCard ? previsaoEntregaCard : 'Selecione a data...'}
                                    />
                                </form>
                            </div>
                        ) : <></>}

                        {statusCard != 'Perdido' && nivelUser < 6 ? (
                            <div className='date-container'>
                                <label className='title'>Prazo máximo de Entrega</label>
                                <form >
                                    <DatePicker
                                        disabled={(nivelUser != 1 && nivelUser != 2 && nivelUser != 3) || !isEdit}
                                        type='number'
                                        locale="pt-BR"
                                        className='input-data-picker-edit-card'
                                        calendarClassName='custom-calendar-edit-card'
                                        id="data"
                                        selected={selectedDatePrazo}
                                        onChange={handleDateChangePrazo}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={prazoEntregaCard ? prazoEntregaCard : 'Selecione a data...'}
                                    />
                                </form>
                            </div>
                        ) : <></>}

                    </div>

                    {nivelUser != 2 ? (
                        <label className='title'>Quantidade de Esquadrias</label>
                    ) : <></>}

                    {nivelUser != 2 ? (
                        <input
                            disabled={(nivelUser != 1 && nivelUser != 2 && nivelUser != 3) || !isEdit}
                            className='input-edit-card'
                            type='number'
                            step='1'
                            value={quantidadeEsquadriasCard}
                            onChange={(e) => setQuantidadeEsquadriasCard(parseInt(e.target.value))}
                            placeholder={'Quantidade de Esquadrias...'}>
                        </input>
                    ) : <></>}

                    {nivelUser != 2 ? (
                        <label className='title'>Quantidade de Quadros</label>
                    ) : <></>}

                    {nivelUser != 2 ? (
                        <input
                            disabled={(nivelUser != 1 && nivelUser != 3) || !isEdit}
                            className='input-edit-card'
                            type='number'
                            step='1'
                            value={quadrosCard}
                            onChange={(e) => setQuadrosCard(parseInt(e.target.value))}
                            placeholder={'Quantidade de Quadros...'}>
                        </input>
                    ) : <></>}


                    {nivelUser != 2 ? (
                        <label className='title'>Metros Quadrados</label>
                    ) : <></>}

                    {nivelUser != 2 ? (
                        <input
                            disabled={(nivelUser != 1 && nivelUser != 3) || !isEdit}
                            className='input-edit-card'
                            type='number'
                            step='1'
                            value={metrosQuadradosCard}
                            onChange={(e) => setMetrosQuadradosCard(parseInt(e.target.value))}
                            placeholder={'Metros Quadrados...'}>
                        </input>
                    ) : <></>}

                    {statusCard != 'Perdido' && nivelUser < 6 ? (
                        <div>
                            <label className='title'>Prazo de Entrega de Vidro</label>
                            <form >
                                <DatePicker
                                    disabled={(nivelUser != 1 && nivelUser != 2 && nivelUser != 3) || !isEdit}
                                    type='number'
                                    locale="pt-BR"
                                    className='input-data-picker-edit-card'
                                    calendarClassName='custom-calendar-edit-card'
                                    id="data"
                                    selected={selectedDateVidro}
                                    onChange={handleDateChangeVidro}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={entregaVidroCard ? entregaVidroCard : 'Selecione a data...'}
                                />
                            </form>
                        </div>
                    ) : <></>}


                    {nivelUser < 6 ? (
                        <>
                            <label className='title'>Status Entrega de Vidro</label>
                            <input disabled={(nivelUser != 1 && nivelUser != 2 && nivelUser != 3) || !isEdit} className='edit-card-select' type='text' value={statusVidroCard ?? ''} readOnly onClick={openCloseSelectVidro} placeholder={'Selecione um produto...'}></input>
                        </>
                    ) : <></>}

                    {
                        selectedVidro ? (
                            <div className='card-select'>
                                {
                                    listaStatusVidros.map((item) => <li key={item.id} className='item-list-select' onClick={() => selectVidroCard(item.name)}>{item.name}</li>)
                                }
                            </div>
                        ) : <></>
                    }

                    {nivelUser < 6 && nivelUser != 2 ? (
                        <>
                            <label className='title'>Fornecedor de Vidros e Obs.</label>
                            <div className='fone-container'>
                                <input disabled={(nivelUser > 3) || !isEdit} className='input-edit-card' type='email' value={obsCard ?? ''} onChange={(e) => setObsCard(e.target.value)} placeholder={'Digite as observações...'}></input>
                            </div>
                        </>
                    ) : <></>}


                    {
                        nivelUser != 200 ? (
                            <Tarefas criarHistorico={(title) => addHistorico(title)} />
                        ) : <></>
                    }

                    {
                        nivelUser < 6 ? (
                            <Historico />
                        ) : <></>
                    }

                    <div className='anexos-header-container'>

                        <div className='anexos-header'>
                            <label className="title-anexos">Anexos - <label className='limite-tamanho-arquivo'>Máximo 1 MB por arquivo</label></label>
                            {!openCloseAnexos && <MdArrowDropDown onClick={() => setOpenCloseAnexos(!openCloseAnexos)} className='icon-open-close' />}
                            {openCloseAnexos && <MdArrowDropUp onClick={() => setOpenCloseAnexos(!openCloseAnexos)} className='icon-open-close' />}
                        </div>

                        {nivelUser < 4 && openCloseAnexos && (
                            <div className='anexos-container'>

                                <label className='info-upload-arquivo'>{infoUpload}</label>
                                <input type="file" disabled={isSaving || desableButton} className="input-anexos" onChange={handleFileUpload} />
                                <ul className='arquivos-storage-container'>
                                    {fileLinksCard && fileLinksCard.length > 0 && (
                                        fileLinksCard.map((file, index) => {
                                            // Use uma expressão regular para remover os números após o último sublinhado no nome do arquivo.
                                            const displayName = file.name.replace(/_\d+$/, '');

                                            return (
                                                <li className='item-arquivo' key={index}>
                                                    <a className='link-anexo' href={file.link} target="_blank" rel="noopener noreferrer" download>
                                                        {index + 1} - {displayName}
                                                    </a>
                                                    <button className='btn-delete-anexo' onClick={() => handleDeleteFile(file.name)}>X</button>
                                                </li>
                                            );
                                        })
                                    )}
                                </ul>
                            </div>
                        )}

                    </div>


                    {
                        statusCard == "Perdido" && (
                            <label className='label-motivo-perca'>Motivo de perca: {motivoVendaPerdida}</label>
                        )
                    }

                    {
                        nivelUser < 3 && (
                            <>
                                <label className='title'>Mudar Entidade Responsável</label>
                                <input disabled={!isEdit} className='edit-card-select' type='text' value={createBy ?? ''} readOnly onClick={() => setSelectedNewEntidadeResponsavel(!selectedNewEntidadeResponsavel)} placeholder={'Selecione a Entidade...'}></input>

                                {selectedNewEntidadeResponsavel ? (
                                    <div className='card-select'>

                                        <div className='item-list-select' onClick={() => entidadeResponsavelSelecionada(nameUser, idUser)} >{nameUser}</div>

                                        {
                                            listaUsers
                                                .slice()
                                                .sort((a, b) => a.name.localeCompare(b.name))
                                                .map((item) => <li key={item.id} className='item-list-select' onClick={() => entidadeResponsavelSelecionada(item.name, item.id)}>{item.name}</li>)
                                        }
                                    </div>
                                ) : <></>}
                            </>
                        )
                    }


                    {
                        nivelUser < 2 && (
                            <div>
                                <label className='title'>Alterar data de modificação Manualmente</label>
                                <form >
                                    <DatePicker
                                        type='number'
                                        locale="pt-BR"
                                        className='input-data-picker-edit-card'
                                        calendarClassName='custom-calendar-edit-card'
                                        id="data"
                                        selected={selectedDate}
                                        onChange={handleDateChangeModification}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText={modificationDateCard ? formatarData(modificationDateCard) : 'Selecione a data...'}
                                    />
                                </form>
                            </div>
                        )
                    }


                    {(nivelUser == 1 || nivelUser == 3) &&
                        <>
                            <label className='title'>Compartilhar Card</label>
                            <input className='input-edit-card' type='text' onClick={openCloseSelectUser} placeholder={'Selecione os parceiros...'}></input>
                        </>
                    }
                    {selectedUser ? (
                        <div className='user-select'>
                            {
                                listaUsers
                                    .map((item) =>
                                        <li
                                            style={{
                                                backgroundColor: listaCampartilhar && listaCampartilhar.find((filho) => filho.id === item.id) ? 'rgb(153, 241, 131)' : ''
                                            }}
                                            key={item.id}
                                            className='item-list-select'
                                            onClick={() => selectUser(item.id, item.name)}
                                        >
                                            {item.name}
                                        </li>

                                    )
                            }
                        </div>
                    ) : <></>}


                    <div className='infos-finais-container'>
                        <label className='label-data-modification'>{'Data de Modificação: ' + formatarData(modificationDateCard)}</label>
                        <label className='label-data-modification'>{'ID: ' + idCard}</label>
                    </div>
                    {(nivelUser == 1 || nivelUser == 3) &&
                        <div className='btn-duplicar-card-container'>
                            <button onClick={() => criarEtapaProducao()}>Criar etapa de produção</button>
                        </div>
                    }

                </div>

                <div className='edit-card-footer'>

                    <div className='info-container'>
                        <div className='info-edit-user'>{info}</div>
                    </div>

                    <div className='edit-card-footer-footer'>
                        {(nivelUser < 2 || tipoParticipante == 'free') ? (

                            <div>
                                <button className='btn-excluir' onClick={() => deleteAllAnexos(idCard)}>Excluir</button>
                            </div>

                        ) : <></>}

                        {nivelUser < 4 ? (

                            <div>
                                <button className='btn-excluir' disabled={desableButton} onClick={() => arquivarCardInPostgreSQL()}> Arquivar </button>
                            </div>

                        ) : <></>}

                        <div className='footer-rigth'>
                            <button className='btn-cancel' disabled={exigirSalvar} onClick={() => validarCancelEdit("Deseja sair sem salvar a edição?")}>Cancelar</button>
                            {/*<button className='btn-save' onClick={validationForm} disabled={isLoading === 'Salvando...' || desableButton} >{isLoading}</button>*/}
                            <button className='btn-save' onClick={validationForm} disabled={isLoading === 'Salvando...'} >{isLoading}</button>

                        </div>
                    </div>

                </div>
            </div>

            {
                modalFinalizados ? (
                    <div className='modal-cards-finalizados'>
                        <div className='modal-finalizados-container'>
                            {statusCard != 'Instalacao' && statusTemporario != 'Instalacao' && statusCard != 'Assistencia' && statusCard != 'Entregue' && statusTemporario != 'InstalacaoExt' && statusCard != 'AssistenciaExt' ? (
                                <div className='finalizados-container-item' onClick={() => defineFinalizados('Vendido')}>
                                    <MdThumbUp className='icon-finalizados-vendido' />
                                    <label className='title'>Vendido</label>
                                </div>
                            ) : <></>}
                            {statusCard == 'Vendido' && statusTemporario == 'Vendido' && (statusCard != 'Instalacao' && statusTemporario != 'Instalacao') || (statusCard == '') ? (
                                <div className='finalizados-container-item' onClick={() => defineFinalizados('Perdido')}>
                                    <MdThumbDown className='icon-finalizados-perdido' />
                                    <label className='title'>Perdido</label>
                                </div>
                            ) : <></>}

                            {statusCard == 'Vendido' && statusTemporario == 'Vendido' ? (
                                <div className='finalizados-container-item' onClick={() => defineFinalizados('Instalacao')}>
                                    <MdWindow className='icon-finalizados-entregue' />
                                    <label className='title'>Instalação</label>
                                </div>
                            ) : <></>}

                            {statusCard == 'Vendido' && statusTemporario == 'Vendido' || (statusCard == 'Instalacao') || (statusCard == 'Entregue') || (statusCard == 'InstalacaoExt') ? (
                                <div className='finalizados-container-item' onClick={() => defineFinalizados('Assistencia')}>
                                    <MdBuild className='icon-finalizados-entregue' />
                                    <label className='title'>Assistência</label>
                                </div>
                            ) : <></>}

                            {statusCard == 'Vendido' && statusTemporario == 'Vendido' || (statusCard == 'Instalacao') || (statusCard == 'Assistencia') || (statusCard == 'InstalacaoExt') || (statusCard == 'AssistenciaExt') ? (
                                <div className='finalizados-container-item' onClick={() => defineFinalizados('Entregue')}>
                                    <MdLocalShipping className='icon-finalizados-entregue' />
                                    <label className='title'>Entregue</label>
                                </div>
                            ) : <></>}

                        </div>

                        {statusCard == 'Vendido' && statusTemporario == 'Vendido' || (statusCard == 'Entregue') || (statusCard == 'InstalacaoExt') ? (
                            <div className='modal-finalizados-container'>

                                {statusCard == 'Vendido' && statusTemporario == 'Vendido' ? (
                                    <div className='finalizados-container-item' onClick={() => defineFinalizados('InstalacaoExt')}>
                                        <MdWindow className='icon-finalizados-ext' />
                                        <label className='title'>Instalação Externa</label>
                                    </div>
                                ) : <></>}


                                {statusCard == 'Vendido' && statusTemporario == 'Vendido' || (statusCard == 'Entregue') || (statusCard == 'InstalacaoExt') ? (
                                    <div className='finalizados-container-item' onClick={() => defineFinalizados('AssistenciaExt')}>
                                        <MdBuild className='icon-finalizados-ext' />
                                        <label className='title'>Assistência Externa</label>
                                    </div>
                                ) : <></>}

                            </div>
                        ) : <></>}


                        {true ? (
                            <div className='btn-close-finalizados-container'>
                                <button className='btn-close-finalizados' onClick={() => openCloseModalFinalizados()}>X</button>
                            </div>
                        ) : <></>}

                    </div>
                ) : <></>
            }



            {
                modalConfirm ? (
                    <div className='modal-confirm-container'>

                        <div className='modal-confirm'>

                            <label className='label-mensagem-confirm'>{mensagem}</label>
                            <div className='btns-modal-confirm-container'>
                                <button className='btn-cancel-confirm' onClick={() => validarConfirmacao(false)}>Cancelar</button>
                                <button className='btn-save-confirm' onClick={() => validarConfirmacao(true)}>Confirmar</button>
                            </div>

                        </div>

                    </div>
                ) : <></>
            }

            {
                modalMensagemAviso ? (
                    <div className='modal-mensagem-aviso-container'>

                        <div className='modal-mensagem-aviso'>

                            <label className='label-mensagem-aviso'>{mensagemAviso}</label>

                        </div>

                    </div>
                ) : <></>
            }


        </div>
    );
}

export default EditCard;