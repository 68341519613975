import React, { useContext, useEffect, useState } from 'react'

import Confetti from "react-confetti";

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

// STYLE
import './style.css'

// COMPONENTS
import Column from '../../component/Column';
import CreateCard from '../../component/CreateCard';
import EditCard from '../../component/EditCard';
import VendaPerdida from '../../component/VendaPerdida';

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { ColumnContext } from '../../contexts/ColumnContext';
import { UserContext } from '../../contexts/UserContext';
import { CardContext } from '../../contexts/CardContext';

import { MdSegment, MdInfo, MdRemoveShoppingCart, MdShoppingCart, MdDoneOutline, MdWindow, MdBuild, MdLocalShipping, MdThumbUp, MdThumbDown, MdRestoreFromTrash, MdOutlineSearch, MdPictureAsPdf, MdViewColumn } from "react-icons/md";

import { RiFileExcel2Fill } from "react-icons/ri";

import { utils, writeFile } from 'xlsx';
import jsPDF from 'jspdf';


import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import moment from 'moment';

import { Tooltip } from 'react-tooltip'; // Mostra mensages ao colocar o mouse sobre os botoes com title


function Board() {

    const { openCloseEditCardModal, openCloseMenuFilter, showConfetti, createCardModal, editCardModal, orderBy, setOrderBy, orderByCrescenteDecrescente, setOrderByCrescenteDecrescente, listaFilteredCards } = useContext(BoardContext);
    const { openCloseVendaPerdida, listaAllProcessos } = useContext(ColumnContext);
    const { tipoParticipante, listaProcessos, foneUser, nameUser, listaUsers } = useContext(UserContext);
    const {

        listaHistoricoCard, setListaHistoricoCard,
        listaTarefasCard, setListaTarefasCard,
        isLoading, setIsLoading,
        modificationDateCard, setModificationDateCard,
        motivoVendaPerdida, setMotivoVendaPerdida,
        listaEtiquetas, setListaEtiquetas,
        etiquetaCard, setEtiquetaCard,
        idCard, setIdCard,
        idColumnCard, setIdColumnCard,
        listaCards, setListaCards,
        documentCard, setDocumentCard,
        nameCard, setNameCard,
        nameObraCard, setNameObraCard,
        valorCard, setValorCard,
        valorVendaCard, setValorVendaCard,
        cityCard, setCityCard,
        estadoCard, setEstadoCard,
        foneCard, setFoneCard,
        emailCard, setEmailCard,
        previsaoCard, setPrevisaoCard,
        meioContatoCard, setMeioContatoCard,
        date, setDate,
        nivel, setNivel,
        produtoCard, setProdutoCard,
        statusCard, setStatusCard,
        idCreateBy, setIdCreateBy,
        createBy, setCreateBy,
        nameObra, setNameObra,
        nameContato, setNameContato,
        fileLinksCard, setFileLinksCard,
        previsaoEntregaCard, setPrevisaoEntregaCard,
        horasProducaoCard, setHorasProducaoCard,
        previsaoInstalacaoCard, setPrevisaoInstalacaoCard,
        previsaoAssistenciaCard, setPrevisaoAssistenciaCard,
        etapaProducaoCard, setEtapaProducaoCard,
        listaCampartilhar, setListaCompartilhar,
        corCard, setCorCard,
        editDateCard, setEditDateCard,
        prioridade, setPrioridade,
        previsaoProducaoCard, setPrevisaoProducaoCard,
        recebimentoMedidasCard, setRecebimentoMedidasCard,
        prazoEntregaCard, setPrazoEntregaCard,
        numeroPedidoCard, setNumeroPedidoCard,
        quadrosCard, setQuadrosCard,
        metrosQuadradosCard, setMetrosQuadradosCard,
        quantidadeEsquadriasCard, setQuantidadeEsquadriasCard,
        entregaVidroCard, setEntregaVidroCard,
        statusVidroCard, setStatusVidroCard,
        obsCard, setObsCard,
        conclusaoProducaoCard, setConclusaoProducaoCard
    } = useContext(CardContext);






    //----------- TESTE MANDAR MENSAGEM WHATSAPP USANDO BOTMENSAGEM

    const [numero, setNumero] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [contato, setContato] = useState('');

    useEffect(() => {
        console.log('listaUsers', listaUsers)
    }, [listaUsers]);


    useEffect(() => {
        setNumero(foneUser);
        setMensagem(nameUser + " Sejá bem vindo!");
        setContato(nameUser);
    }, [foneUser]);

    const sendMessage = async () => {
        try {
            await axios.post(`${apiUrl}/mensagens/enviar`, { numero, mensagem, contato });
            console.log('Mensagem enviada com sucesso!');
        } catch (error) {
            console.error('Erro ao enviar mensagem:', error.message);
        }
    };




    function orderByCards(order) {
        setOrderBy(order);
        setOrderByCrescenteDecrescente(!orderByCrescenteDecrescente);
    }


    
    // const handleExportToExcel = () => {
    //     const confirmDelete = window.confirm('Gerar Excel?');

    //     if (confirmDelete) {
    //         if (listaCards.length > 0) {

    //             // Formata o valor como real
    //             const formatarComoReal = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    //             // Adiciona todas as colunas disponíveis ao objeto de cada cartão
    //             const listaCardsComSoma = listaFilteredCards.map((card) => ({
    //                 Documento: card.document_card,
    //                 Versão: '',
    //                 Nome: card.name,
    //                 Data: card.date,
    //                 Valor: formatarComoReal(parseFloat(card.valor || 0)),
    //                 Entidade: card.create_by,
    //                 Origem: card.meio_contato,
    //                 // Adicione todas as outras colunas conforme necessário
    //                 IdCard: card.id,
    //                 IdColumnCard: card.id_column,
    //                 DocumentCard: card.document_card,
    //                 NameCard: card.name,
    //                 NameObraCard: card.name_obra_cliente,
    //                 ValorCard: formatarComoReal(parseFloat(card.valor || 0)),
    //                 ValorVendaCard: formatarComoReal(parseFloat(card.valor_venda || 0)),
    //                 CityCard: card.city,
    //                 EstadoCard: card.estado,
    //                 FoneCard: card.fone,
    //                 EmailCard: card.email,
    //                 PrevisaoCard: card.previsao,
    //                 MeioContatoCard: card.meio_contato,
    //                 Date: card.date,
    //                 Nivel: card.nivel,
    //                 EtiquetaCard: card.etiqueta,
    //                 ModificationDateCard: card.modification_date,
    //                 ListaTarefasCard: JSON.stringify(card.lista_tarefas || []),
    //                 ListaHistoricoCard: JSON.stringify(card.lista_historico || []),
    //                 ProdutoCard: card.produto,
    //                 StatusCard: card.status,
    //                 IdCreateBy: card.id_create_by,
    //                 CreateBy: card.create_by,
    //                 NameObra: card.name_obra,
    //                 NameContato: card.name_contato,
    //                 MotivoVendaPerdida: card.motivo_perca,
    //                 FileLinksCard: JSON.stringify(card.lista_anexos || []),
    //                 PrevisaoEntregaCard: card.previsao_entrega,
    //                 HorasProducaoCard: card.horas_producao,
    //                 previsaoInstalacaoCard: card.previsao_instalaca,
    //                 previsaoAssistenciaCard: card.previsao_assistencia,
    //                 EtapaProducaoCard: card.etapa_producao,
    //                 ListaCompartilhar: JSON.stringify(card.lista_compartilhar || []),
    //                 CorCard: card.cor,
    //                 EditDateCard: card.edit_date,
    //                 Prioridade: card.prioridade,
    //                 PrevisaoProducaoCard: card.previsao_producao,
    //                 RecebimentoMedidasCard: card.recebimento_medidas,
    //                 PrazoEntregaCard: card.prazo_entrega,
    //                 NumeroPedidoCard: card.numero_pedido,
    //                 QuadrosCard: card.quadros,
    //                 MetrosQuadradosCard: card.metros_quadrados,
    //                 QuantidadeEsquadriasCard: card.quantidade_esquadrias,
    //                 EntregaVidroCard: card.entrega_vidro,
    //                 StatusVidroCard: card.status_vidro,
    //                 ObsCard: card.obs,
    //                 ConclusaoProducaoCard: card.conclusao_producao
    //             }));

    //             // Calcula a soma dos valores
    //             const totalValor = listaFilteredCards.reduce((total, card) => total + parseFloat(card.valor || 0), 0);

    //             // Adiciona a soma à última linha da lista
    //             listaCardsComSoma.push({
    //                 Documento: 'Soma:',
    //                 Versão: '',
    //                 Nome: '',
    //                 Data: '',
    //                 Valor: formatarComoReal(totalValor),
    //                 Entidade: '',
    //                 // Adicione outras colunas vazias, se necessário
    //             });

    //             // Cria a planilha Excel
    //             const wb = utils.book_new();
    //             const ws = utils.json_to_sheet(listaCardsComSoma);
    //             utils.book_append_sheet(wb, ws, 'Planilha1');

    //             // Exporta para o Excel
    //             writeFile(wb, 'SuiteFlowCards.xlsx');
    //         } else {
    //             console.error('Nenhum dado para exportar.');
    //         }
    //     }
    // };

    const handleExportToExcel = () => {
        const confirmDelete = window.confirm('Gerar Excel?');
    
        if (confirmDelete) {
            if (listaCards.length > 0) {
    
                // Formata o valor como real
                const formatarComoReal = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    
                // Encontra o nome do usuário com base no id_create_by
                const findUserNameById = (id) => {
                    const user = listaUsers.find(user => user.id.toString() === id.toString());
                    return user ? user.entidade_preference : '';
                };
    
                // Adiciona todas as colunas disponíveis ao objeto de cada cartão
                const listaCardsComSoma = listaFilteredCards.map((card) => ({
                    Documento: card.document_card,
                    Versão: '',
                    Nome: card.name,
                    CreateDate: card.date,
                    Valor: formatarComoReal(parseFloat(card.valor || 0)),
                    Entidade: findUserNameById(card.id_create_by),
                    Origem: card.meio_contato,
                    // Adicione todas as outras colunas conforme necessário
                    IdCard: card.id,
                    IdColumnCard: card.id_column,
                    DocumentCard: card.document_card,
                    NameCard: card.name,
                    NameObraCard: card.name_obra_cliente,
                    ValorCard: formatarComoReal(parseFloat(card.valor || 0)),
                    ValorVendaCard: formatarComoReal(parseFloat(card.valor_venda || 0)),
                    CityCard: card.city,
                    EstadoCard: card.estado,
                    FoneCard: card.fone,
                    EmailCard: card.email,
                    PrevisaoCard: card.previsao,
                    MeioContatoCard: card.meio_contato,
                    UpdateDate: card.modification_date,
                    Nivel: card.nivel,
                    EtiquetaCard: card.etiqueta,
                    ModificationDateCard: card.modification_date,
                    ListaTarefasCard: JSON.stringify(card.lista_tarefas || []),
                    ListaHistoricoCard: JSON.stringify(card.lista_historico || []),
                    ProdutoCard: card.produto,
                    StatusCard: card.status,
                    IdCreateBy: card.id_create_by,
                    CreateBy: card.create_by,
                    NameObra: card.name_obra,
                    NameContato: card.name_contato,
                    MotivoVendaPerdida: card.motivo_perca,
                    FileLinksCard: JSON.stringify(card.lista_anexos || []),
                    PrevisaoEntregaCard: card.previsao_entrega,
                    HorasProducaoCard: card.horas_producao,
                    previsaoInstalacaoCard: card.previsao_instalaca,
                    previsaoAssistenciaCard: card.previsao_assistencia,
                    EtapaProducaoCard: card.etapa_producao,
                    ListaCompartilhar: JSON.stringify(card.lista_compartilhar || []),
                    CorCard: card.cor,
                    StatusDate: card.edit_date,
                    Prioridade: card.prioridade,
                    PrevisaoProducaoCard: card.previsao_producao,
                    RecebimentoMedidasCard: card.recebimento_medidas,
                    PrazoEntregaCard: card.prazo_entrega,
                    NumeroPedidoCard: card.numero_pedido,
                    QuadrosCard: card.quadros,
                    MetrosQuadradosCard: card.metros_quadrados,
                    QuantidadeEsquadriasCard: card.quantidade_esquadrias,
                    EntregaVidroCard: card.entrega_vidro,
                    StatusVidroCard: card.status_vidro,
                    ObsCard: card.obs,
                    ConclusaoProducaoCard: card.conclusao_producao
                }));
    
                // Calcula a soma dos valores
                const totalValor = listaFilteredCards.reduce((total, card) => total + parseFloat(card.valor || 0), 0);
    
                // Adiciona a soma à última linha da lista
                listaCardsComSoma.push({
                    Documento: 'Soma:',
                    Versão: '',
                    Nome: '',
                    Data: '',
                    Valor: formatarComoReal(totalValor),
                    Entidade: '',
                    // Adicione outras colunas vazias, se necessário
                });
    
                // Cria a planilha Excel
                const wb = utils.book_new();
                const ws = utils.json_to_sheet(listaCardsComSoma);
                utils.book_append_sheet(wb, ws, 'Planilha1');
    
                // Exporta para o Excel
                writeFile(wb, 'SuiteFlowCards.xlsx');
            } else {
                console.error('Nenhum dado para exportar.');
            }
        }
    };
    

    // const handleExportToExcel = () => {
    //     const confirmDelete = window.confirm('Gerar Excel?');
    
    //     if (confirmDelete) {
    //         if (listaCards.length > 0) {
    
    //             // Formata o valor como real
    //             const formatarComoReal = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    
    //             // Função para gerar o Excel a partir de uma lista de cartões
    //             const gerarExcel = (lista, blocoNumero) => {
    //                 // Adiciona todas as colunas disponíveis ao objeto de cada cartão
    //                 const listaCardsComSoma = lista.map((card) => ({
    //                     Documento: card.document_card,
    //                     Versão: '',
    //                     Nome: card.name,
    //                     Data: card.date,
    //                     Valor: formatarComoReal(parseFloat(card.valor || 0)),
    //                     Entidade: card.create_by,
    //                     Origem: card.meio_contato,
    //                     // Adicione todas as outras colunas conforme necessário
    //                     IdCard: card.id,
    //                     IdColumnCard: card.id_column,
    //                     DocumentCard: card.document_card,
    //                     NameCard: card.name,
    //                     NameObraCard: card.name_obra_cliente,
    //                     ValorCard: formatarComoReal(parseFloat(card.valor || 0)),
    //                     ValorVendaCard: formatarComoReal(parseFloat(card.valor_venda || 0)),
    //                     CityCard: card.city,
    //                     EstadoCard: card.estado,
    //                     FoneCard: card.fone,
    //                     EmailCard: card.email,
    //                     PrevisaoCard: card.previsao,
    //                     MeioContatoCard: card.meio_contato,
    //                     Date: card.date,
    //                     Nivel: card.nivel,
    //                     EtiquetaCard: card.etiqueta,
    //                     ModificationDateCard: card.modification_date,
    //                     ListaTarefasCard: JSON.stringify(card.lista_tarefas || []),
    //                     ListaHistoricoCard: JSON.stringify(card.lista_historico || []),
    //                     ProdutoCard: card.produto,
    //                     StatusCard: card.status,
    //                     IdCreateBy: card.id_create_by,
    //                     CreateBy: card.create_by,
    //                     NameObra: card.name_obra,
    //                     NameContato: card.name_contato,
    //                     MotivoVendaPerdida: card.motivo_perca,
    //                     FileLinksCard: JSON.stringify(card.lista_anexos || []),
    //                     PrevisaoEntregaCard: card.previsao_entrega,
    //                     HorasProducaoCard: card.horas_producao,
    //                     previsaoInstalacaoCard: card.previsao_instalaca,
    //                     previsaoAssistenciaCard: card.previsao_assistencia,
    //                     EtapaProducaoCard: card.etapa_producao,
    //                     ListaCompartilhar: JSON.stringify(card.lista_compartilhar || []),
    //                     CorCard: card.cor,
    //                     EditDateCard: card.edit_date,
    //                     Prioridade: card.prioridade,
    //                     PrevisaoProducaoCard: card.previsao_producao,
    //                     RecebimentoMedidasCard: card.recebimento_medidas,
    //                     PrazoEntregaCard: card.prazo_entrega,
    //                     NumeroPedidoCard: card.numero_pedido,
    //                     QuadrosCard: card.quadros,
    //                     MetrosQuadradosCard: card.metros_quadrados,
    //                     QuantidadeEsquadriasCard: card.quantidade_esquadrias,
    //                     EntregaVidroCard: card.entrega_vidro,
    //                     StatusVidroCard: card.status_vidro,
    //                     ObsCard: card.obs,
    //                     ConclusaoProducaoCard: card.conclusao_producao
    //                 }));
    
    //                 // Calcula a soma dos valores
    //                 const totalValor = lista.reduce((total, card) => total + parseFloat(card.valor || 0), 0);
    
    //                 // Adiciona a soma à última linha da lista
    //                 listaCardsComSoma.push({
    //                     Documento: 'Soma:',
    //                     Versão: '',
    //                     Nome: '',
    //                     Data: '',
    //                     Valor: formatarComoReal(totalValor),
    //                     Entidade: '',
    //                     // Adicione outras colunas vazias, se necessário
    //                 });
    
    //                 // Cria a planilha Excel
    //                 const wb = utils.book_new();
    //                 const ws = utils.json_to_sheet(listaCardsComSoma);
    //                 utils.book_append_sheet(wb, ws, `Planilha${blocoNumero}`);
    
    //                 // Exporta para o Excel
    //                 writeFile(wb, `SuiteFlowCards_Bloco${blocoNumero}.xlsx`);
    //             };
    
    //             // Divide a lista de cartões em blocos de 5000 linhas
    //             const tamanhoBloco = 5000;
    //             let blocoNumero = 1;
    //             for (let i = 0; i < listaCards.length; i += tamanhoBloco) {
    //                 const bloco = listaCards.slice(i, i + tamanhoBloco);
    //                 gerarExcel(bloco, blocoNumero);
    //                 blocoNumero++;
    //             }
    //         } else {
    //             console.error('Nenhum dado para exportar.');
    //         }
    //     }
    // };
    


    // const handleExportToExcel = () => {
    //     const confirmDelete = window.confirm('Gerar Excel?');

    //     if (confirmDelete) {
    //         if (listaCards.length > 0) {

    //             // Calcula a soma dos valores
    //             const totalValor = listaFilteredCards.reduce((total, card) => total + parseFloat(card.valor || 0), 0);

    //             // Formata o valor como real
    //             const formatarComoReal = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

    //             // Adiciona a soma à última linha da lista
    //             const listaCardsComSoma = [
    //                 ...listaFilteredCards.map((card) => ({
    //                     Documento: card.document_card,
    //                     Versão: '',
    //                     Nome: card.name,
    //                     Data: card.date,
    //                     Valor: formatarComoReal(parseFloat(card.valor || 0)),
    //                     Entidade: card.create_by,
    //                     Origem: card.meio_contato,
    //                 })),
    //                 {
    //                     Documento: 'Soma:',
    //                     Versão: '',
    //                     Nome: '',
    //                     Data: '',
    //                     Valor: formatarComoReal(totalValor),
    //                     Entidade: '',
    //                 }
    //             ];

    //             // Cria a planilha Excel
    //             const wb = utils.book_new();
    //             const ws = utils.json_to_sheet(listaCardsComSoma);
    //             utils.book_append_sheet(wb, ws, 'Planilha1');

    //             // Exporta para o Excel
    //             writeFile(wb, 'SuiteFlowCards.xlsx');
    //         } else {
    //             console.error('Nenhum dado para exportar.');
    //         }
    //     }
    // };


    const handleExportToPDF = () => {

        const confirmDelete = window.confirm('Gerar PDF?');

        if (confirmDelete) {

            if (listaFilteredCards.length > 0) {
                const doc = new jsPDF();

                // Define o tamanho da fonte para o cabeçalho
                doc.setFontSize(12); // Tamanho da fonte para o cabeçalho

                doc.text('SuiteFlow - Cards', 8, 10);

                // Mapeamento de nomes de colunas desejados para nomes de colunas reais
                const nomeColunaMap = {
                    'Linhas': { nomeReal: 'linhas', largura: 15 },
                    'Documento': { nomeReal: 'document_card', largura: 28 },
                    //'Versão': { nomeReal: 'Versão', largura: 18 },
                    'Nome': { nomeReal: 'name', largura: 50 },
                    'Data': { nomeReal: 'date', largura: 35 },
                    'Valor': { nomeReal: 'valor', largura: 30 },
                    'Entidade': { nomeReal: 'create_by', largura: 35 },
                };

                const margemEsquerda = 8;
                const margemSuperior = 0;
                const linhaInicial = 20;
                const alturaLinha = 5;
                const espacamentoLinha = 1;
                const espacamentoTexto = 3; // Espaçamento abaixo dos nomes das colunas
                let linhaAtual = linhaInicial; // Posição vertical (Y) dos nomes das colunas

                // Define o tamanho da fonte para os dados
                doc.setFontSize(8); // Tamanho da fonte para os dados

                const linhasPorPagina = 45; // Defina quantas linhas cabem em uma página
                let paginaAtual = 1; // Página atual

                for (let startIndex = 0; startIndex < listaFilteredCards.length; startIndex += linhasPorPagina) {
                    if (paginaAtual > 1) {
                        doc.addPage(); // Adicione uma nova página para as próximas linhas
                        linhaAtual = margemSuperior + 10; // Redefina a posição vertical (Y) com uma margem adicional
                    }

                    // Adicione o cabeçalho com as colunas desejadas
                    let colunaAtualEsquerda = margemEsquerda;
                    Object.keys(nomeColunaMap).forEach((nomeColuna) => {
                        const configColuna = nomeColunaMap[nomeColuna];
                        // Salvar o tamanho da fonte atual
                        const tamanhoFonteOriginal = doc.getFontSize();

                        // Definir o tamanho da fonte para o nome da coluna
                        doc.setFontSize(12); // Tamanho da fonte para o nome da coluna

                        const nomeColunaTexto = nomeColuna; // Nome da coluna sem espaço em branco
                        doc.text(nomeColunaTexto, colunaAtualEsquerda, linhaAtual);

                        // Restaurar o tamanho da fonte original
                        doc.setFontSize(tamanhoFonteOriginal);

                        colunaAtualEsquerda += configColuna.largura; // Ajuste a posição para a próxima coluna
                    });

                    linhaAtual += espacamentoTexto; // Aumente a posição vertical para a próxima linha após os nomes das colunas

                    // Adicione os dados das colunas desejadas para esta página
                    for (let i = startIndex; i < Math.min(startIndex + linhasPorPagina, listaFilteredCards.length); i++) {
                        colunaAtualEsquerda = margemEsquerda;
                        Object.keys(nomeColunaMap).forEach((nomeColuna) => {
                            const configColuna = nomeColunaMap[nomeColuna];
                            const colunaReal = configColuna.nomeReal;
                            let valor = listaFilteredCards[i][colunaReal] || ''; // Certifique-se de que o valor não seja nulo

                            // Inclua a contagem de linhas na coluna "Linhas"
                            if (nomeColuna === 'Linhas') {
                                valor = i + 1; // Comece com 1 em vez de 0
                            }

                            // Verifique se o tamanho do texto é maior do que a largura da coluna
                            if (valor.toString().length * 2.5 > configColuna.largura) {
                                // Salva o tamanho da fonte atual
                                const tamanhoFonteOriginal = doc.getFontSize();

                                // Reduza o tamanho da fonte temporariamente
                                doc.setFontSize(8);
                                // Reduza o tamanho do texto
                                valor = valor.toString().substr(0, 20) + ''; // Exibe os primeiros 20 caracteres e adiciona '...'

                                // Restaure o tamanho da fonte original
                                doc.setFontSize(tamanhoFonteOriginal);
                            }

                            doc.text(valor.toString(), colunaAtualEsquerda, linhaAtual + alturaLinha);
                            colunaAtualEsquerda += configColuna.largura; // Ajuste a posição para a próxima coluna
                        });

                        linhaAtual += alturaLinha + espacamentoLinha; // Aumente a posição vertical para a próxima linha de dados
                    }

                    paginaAtual++; // Aumente o número da página
                }
                // Salve o PDF com um nome de arquivo
                doc.save('SuiteFlowCards.pdf');
            } else {
                console.error('Nenhum dado para exportar.');
            }
        }
    };
    // EXPORTAR CARDS ----------------------------------------------  FIM

    function formatarData(data) {
        const date = moment(data);
        return date.format('DD/MM/YYYY');
    }

    const [openClosePCP, setOpenClosePCP] = useState(true);

    function getColumnName(id) {
        const processo = listaProcessos.find(processo => processo.id.toString() === id);
        return processo ? processo.title : '';
    }

    const processColumnIds = listaProcessos ? listaProcessos.map(processo => processo.id.toString()) : [];

    function getIdCard(item) {
        setIdCard(item.id);
        setIdColumnCard(item.id_column);
        setDocumentCard(item.document_card);
        setNameCard(item.name);
        setNameObraCard(item.name_obra);
        setValorCard(item.valor);
        setValorVendaCard(item.valor_venda);
        setCityCard(item.city);
        setEstadoCard(item.estado);
        setFoneCard(item.fone);
        setEmailCard(item.email);
        setPrevisaoCard(item.previsao);
        setMeioContatoCard(item.meio_contato);
        setDate(item.date);
        setNivel(item.nivel);
        setEtiquetaCard(item.etiqueta);
        setModificationDateCard(item.modification_date);
        setListaTarefasCard(item.lista_tarefas);
        setProdutoCard(item.produto);
        setListaHistoricoCard(item.lista_historico);
        setStatusCard(item.status);
        setIdCreateBy(item.id_create_by);
        setCreateBy(item.create_by);
        setNameObra(item.name_obra_cliente);
        setNameContato(item.name_contato);
        setMotivoVendaPerdida(item.motivo_perca);
        setFileLinksCard(item.lista_anexos);
        setPrevisaoEntregaCard(item.previsao_entrega);
        setHorasProducaoCard(item.horas_producao);
        setPrevisaoInstalacaoCard(item.previsao_instalacao);
        setPrevisaoAssistenciaCard(item.previsao_assistencia);
        setEtapaProducaoCard(item.etapa_producao);
        setListaCompartilhar(item.lista_compartilhar);
        setCorCard(item.cor);
        setEditDateCard(item.edit_date);
        setPrioridade(item.prioridade);
        setPrevisaoProducaoCard(item.previsao_producao);
        setRecebimentoMedidasCard(item.recebimento_medidas);
        setPrazoEntregaCard(item.prazo_entrega);
        setNumeroPedidoCard(item.numero_pedido);
        setQuadrosCard(item.quadros);
        setMetrosQuadradosCard(item.metros_quadrados);
        setQuantidadeEsquadriasCard(item.quantidade_esquadrias);
        setEntregaVidroCard(item.entrega_vidro);
        setStatusVidroCard(item.status_vidro);
        setObsCard(item.obs);
        setConclusaoProducaoCard(item.conclusao_producao);
        openCloseEditCardModal();
    }

    function getColorProduct(color) {

        let cor = '';

        if (color == 'Branco') {
            cor = 'white';
        }

        if (color == 'BRANCO') {
            cor = 'white';
        }
        if (color == 'AMADEIRADO GOLDEN OAK') {
            cor = '#916B4C';
        }
        if (color == 'NOGUEIRA') {
            cor = 'rgb(138, 94, 1)';
        }
        if (color == 'PRETO LISO') {
            cor = 'rgb(0, 0, 0)';
        }
        if (color == 'PRETO TEXTURADO') {
            cor = 'rgb(0, 0, 0)';
        }
        if (color == 'BRONZE') {
            cor = '#C2B5A5';
        }

        if (color == 'GRAFITE') {
            cor = '#727378';
        }
        if (color == 'PIRITA') {
            cor = '#C7BAA2';
        }
        if (color == 'INTERNO BRANCO E EXTERNO AMADEIRADO GOLDEN OAK') {
            cor = '#916B4C';
        }
        if (color == 'INTERNO BRANCO E EXTERNO AMADEIRADO NOGUEIRA') {
            cor = 'rgb(138, 94, 1)';
        }
        if (color == 'INTERNO BRANCO E EXTERNO PRETO LISO') {
            cor = 'black';
        }
        if (color == 'INTERNO BRANCO E EXTERNO PRETO TEXTURADO') {
            cor = 'black';
        }
        if (color == 'INTERNO BRANCO E EXTERNO BRONZE') {
            cor = '#C2B5A5';
        }
        if (color == 'INTERNO BRANCO E EXTERNO GRAFITE') {
            cor = '#727378';
        }
        if (color == 'INTERNO BRANCO E EXTERNO PIRITA') {
            cor = '#C7BAA2';
        }

        if (color == 'ESPECIAL') {
            cor = 'red';
        }

        return cor;
    }

    function handleClickTableRow(item) {
        getIdCard(item);
        console.log(item);
    }

    function calculateDaysUntilDelivery(deliveryDateStr) {
        // Converter a data para o formato "MM/DD/YYYY"
        const parts = deliveryDateStr.split("/");
        if (parts.length === 3) {
            const formattedDateStr = `${parts[1]}/${parts[0]}/${parts[2]}`;
            const deliveryDate = new Date(formattedDateStr);

            if (isNaN(deliveryDate)) {
                console.error("Data de entrega inválida:", deliveryDateStr);
                return "Data inválida";
            }

            const today = new Date();
            const timeDifference = deliveryDate - today;
            const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            return daysDifference;
        } else {
            console.error("Formato de data inválido:", deliveryDateStr);
            return "Formato de data inválido";
        }
    }

    function converterData(dataString) {
        if (dataString && typeof dataString === 'string') {
            const [dia, mes, ano] = dataString.split('/').map(Number);
            return new Date(ano, mes - 1, dia);
        }
        // Se o valor de dataString for nulo, retorne a data atual
        return new Date();
    }

    const [controlePlanejaento, setControlePlanejamento] = useState('producao');

    function selectPlanejamentoControle(controle) {
        setControlePlanejamento(controle);
    }

    const filteredCards = listaFilteredCards
        .filter((card) => processColumnIds.includes(card.id_column))
        .filter(
            (card) =>
                (card.status == 'Vendido' && controlePlanejaento === 'producao')
                || (((card.status == 'AssistenciaExt' || card.status == 'Entregue' || card.status == 'Vendido' || card.status == 'Instalacao' || card.status == 'Assistencia' || card.status == 'InstalacaoExt') && controlePlanejaento == 'conclusaoProducao'))
                || (card.status == 'Instalacao' && controlePlanejaento === 'producao')
                || (card.status == 'Instalacao' && controlePlanejaento === 'instalacao')
                //|| (card.status == 'Vendido' && controlePlanejaento === 'instalacao')
                //|| (card.status == 'Vendido' && controlePlanejaento === 'instalacaoext')
                || (card.status == 'Assistencia' && controlePlanejaento === 'assistencia')
                || (card.status == 'InstalacaoExt' && controlePlanejaento === 'instalacaoext')
                || (card.status == 'AssistenciaExt' && controlePlanejaento === 'assistenciaext')
        )
        //.filter((card) => card.status == 'Vendido'  && controlePlanejaento === 'producao')
        .sort((a, b) => {
            const dateA = converterData(a.previsao_producao);
            const dateB = converterData(b.previsao_producao);
            //console.log("Data A:", dateA); // Adicione essa linha
            //console.log("Data B:", dateB); // Adicione essa linha
            return dateA - dateB;
        });



    function groupDataByWeek(data) {
        const groupedData = {};

        data.forEach((item) => {
            const previsaoEntrega = item.previsao_producao;
            const previsaoInstalacao = item.previsao_instalacao;
            const conclusaoProducao = item.conclusao_producao;
            const previsaoAssistencia = item.previsao_assistencia;

            if (item.status == 'Entregue') {
                //return;
            }

            if (previsaoEntrega && controlePlanejaento == 'producao' && !conclusaoProducao) {
                const date = converterData(previsaoEntrega);
                const dayOfWeek = date.getDay(); // 0 para domingo, 1 para segunda-feira, etc.

                // Subtrair o número de dias desde a segunda-feira até a data para obter o início da semana
                const startOfWeek = new Date(date);
                startOfWeek.setDate(date.getDate() - (dayOfWeek - 1));

                // Agora, você pode calcular o número da semana com base no início da semana (segunda-feira)
                const timeDifference = startOfWeek - new Date(0); // Diferença em milissegundos desde 01/01/1970
                const weekNumber = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000)); // Diferença em semanas

                if (!groupedData[weekNumber]) {
                    groupedData[weekNumber] = [];
                }

                groupedData[weekNumber].push(item);
            }

            if (conclusaoProducao && controlePlanejaento == 'conclusaoProducao') {
                const date = converterData(conclusaoProducao);
                const dayOfWeek = date.getDay(); // 0 para domingo, 1 para segunda-feira, etc.

                // Subtrair o número de dias desde a segunda-feira até a data para obter o início da semana
                const startOfWeek = new Date(date);
                startOfWeek.setDate(date.getDate() - (dayOfWeek - 1));

                // Agora, você pode calcular o número da semana com base no início da semana (segunda-feira)
                const timeDifference = startOfWeek - new Date(0); // Diferença em milissegundos desde 01/01/1970
                const weekNumber = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000)); // Diferença em semanas

                if (!groupedData[weekNumber]) {
                    groupedData[weekNumber] = [];
                }

                groupedData[weekNumber].push(item);
            }

            if (previsaoInstalacao && controlePlanejaento == 'instalacao') {
                const date = converterData(previsaoInstalacao);
                const dayOfWeek = date.getDay(); // 0 para domingo, 1 para segunda-feira, etc.

                // Subtrair o número de dias desde a segunda-feira até a data para obter o início da semana
                const startOfWeek = new Date(date);
                startOfWeek.setDate(date.getDate() - (dayOfWeek - 1));

                // Agora, você pode calcular o número da semana com base no início da semana (segunda-feira)
                const timeDifference = startOfWeek - new Date(0); // Diferença em milissegundos desde 01/01/1970
                const weekNumber = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000)); // Diferença em semanas

                if (!groupedData[weekNumber]) {
                    groupedData[weekNumber] = [];
                }

                groupedData[weekNumber].push(item);
            }


            if (previsaoAssistencia && controlePlanejaento == 'assistencia') {
                const date = converterData(previsaoAssistencia);
                const dayOfWeek = date.getDay(); // 0 para domingo, 1 para segunda-feira, etc.

                // Subtrair o número de dias desde a segunda-feira até a data para obter o início da semana
                const startOfWeek = new Date(date);
                startOfWeek.setDate(date.getDate() - (dayOfWeek - 1));

                // Agora, você pode calcular o número da semana com base no início da semana (segunda-feira)
                const timeDifference = startOfWeek - new Date(0); // Diferença em milissegundos desde 01/01/1970
                const weekNumber = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000)); // Diferença em semanas

                if (!groupedData[weekNumber]) {
                    groupedData[weekNumber] = [];
                }

                groupedData[weekNumber].push(item);
            }

            if (previsaoAssistencia && controlePlanejaento == 'assistenciaext') {
                const date = converterData(previsaoAssistencia);
                const dayOfWeek = date.getDay(); // 0 para domingo, 1 para segunda-feira, etc.

                // Subtrair o número de dias desde a segunda-feira até a data para obter o início da semana
                const startOfWeek = new Date(date);
                startOfWeek.setDate(date.getDate() - (dayOfWeek - 1));

                // Agora, você pode calcular o número da semana com base no início da semana (segunda-feira)
                const timeDifference = startOfWeek - new Date(0); // Diferença em milissegundos desde 01/01/1970
                const weekNumber = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000)); // Diferença em semanas

                if (!groupedData[weekNumber]) {
                    groupedData[weekNumber] = [];
                }

                groupedData[weekNumber].push(item);
            }

            if (previsaoInstalacao && controlePlanejaento == 'instalacaoext') {
                const date = converterData(previsaoInstalacao);
                const dayOfWeek = date.getDay(); // 0 para domingo, 1 para segunda-feira, etc.

                // Subtrair o número de dias desde a segunda-feira até a data para obter o início da semana
                const startOfWeek = new Date(date);
                startOfWeek.setDate(date.getDate() - (dayOfWeek - 1));

                // Agora, você pode calcular o número da semana com base no início da semana (segunda-feira)
                const timeDifference = startOfWeek - new Date(0); // Diferença em milissegundos desde 01/01/1970
                const weekNumber = Math.floor(timeDifference / (7 * 24 * 60 * 60 * 1000)); // Diferença em semanas

                if (!groupedData[weekNumber]) {
                    groupedData[weekNumber] = [];
                }

                groupedData[weekNumber].push(item);
            }

        });


        return groupedData;
    }


    //---------- UMA SEMANA ANTES ---------
    function getDateOneWeekBefore(dateString) {
        if (dateString && typeof dateString === 'string') {
            const [day, month, year] = dateString.split('/').map(Number);
            const date = new Date(year, month - 1, day);
            date.setDate(date.getDate() - 7); // Subtrai 7 dias
            const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            return formattedDate;
        }
        return "";
    }

    const [tableKey, setTableKey] = useState(0);

    useEffect(() => {
        setTableKey((prevKey) => prevKey + 1);
    }, [controlePlanejaento]);

    function calculateWeekNumber(dateString) {
        if (!dateString) {
            return { startDate: '', endDate: '' };
        }

        const [day, month, year] = dateString.split('/').map(Number);

        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return { startDate: '', endDate: '' };
        }

        const startDate = new Date(year, month - 1, day);

        // Encontre o início da semana (segunda-feira)
        while (startDate.getDay() !== 1) {
            startDate.setDate(startDate.getDate() - 1);
        }

        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        const formattedStartDate = moment(startDate).format('DD/MM/YYYY');
        const formattedEndDate = moment(endDate).format('DD/MM/YYYY');

        return { startDate: formattedStartDate, endDate: formattedEndDate };
    }


    function renderTableRows(data) {
        const rows = [];

        for (const weekNumber in data) {
            if (data.hasOwnProperty(weekNumber)) {
                // Inicialize a soma de horas para a semana como 0
                let totalHorasProducao = 0;
                let totalValue = 0;
                let totalEsquadrias = 0;
                let totalQuadros = 0;
                let totalMetrosQuadrados = 0;

                let weekInfo = null;
                let headerAdded = false;

                if (controlePlanejaento === 'producao') {
                    weekInfo = calculateWeekNumber(data[weekNumber][0].previsao_producao);
                } else if (controlePlanejaento === 'conclusaoProducao') {
                    weekInfo = calculateWeekNumber(data[weekNumber][0].conclusao_producao);
                } else if (controlePlanejaento === 'instalacao') {
                    weekInfo = calculateWeekNumber(data[weekNumber][0].previsao_instalacao);
                } else if (controlePlanejaento === 'assistencia') {
                    weekInfo = calculateWeekNumber(data[weekNumber][0].previsao_assistencia);
                } else if (controlePlanejaento === 'instalacaoext') {
                    weekInfo = calculateWeekNumber(data[weekNumber][0].previsao_instalacao);
                } else if (controlePlanejaento === 'assistenciaext') {
                    weekInfo = calculateWeekNumber(data[weekNumber][0].previsao_assistencia);
                }

                if (weekInfo == null)
                    return

                if (weekInfo.startDate && weekInfo.endDate) {

                    rows.push(
                        <tr key={`week-header-${weekNumber}`}>
                            <td className='semana-container' colSpan="21">
                                <label className='semana-label'>{`( ${weekInfo.startDate} - ${weekInfo.endDate} )`}</label>
                            </td>
                        </tr>
                    );

                    data[weekNumber].forEach((item) => {
                        let previsaoData = null;

                        if (controlePlanejaento === 'producao') {
                            previsaoData = item.previsao_producao;
                        } else if (controlePlanejaento === 'conclusaoProducao') {
                            previsaoData = item.conclusao_producao;
                        } else if (controlePlanejaento === 'instalacao') {
                            previsaoData = item.previsao_instalacao;
                        } else if (controlePlanejaento === 'assistencia') {
                            previsaoData = item.previsao_assistencia;
                        } else if (controlePlanejaento === 'instalacaoext') {
                            previsaoData = item.previsao_instalacao;
                        } else if (controlePlanejaento === 'assistenciaext') {
                            previsaoData = item.previsao_assistencia;
                        }

                        if (previsaoData) {
                            totalHorasProducao += item.horas_producao ? parseFloat(item.horas_producao) : 0;
                            totalValue += item.valor ? parseFloat(item.valor) : 0;
                            totalEsquadrias += item.quantidade_esquadrias ? parseFloat(item.quantidade_esquadrias) : 0;
                            totalQuadros += item.quadros ? parseFloat(item.quadros) : 0;
                            totalMetrosQuadrados += item.metros_quadrados ? parseFloat(item.metros_quadrados) : 0;

                            if (!headerAdded) {

                                rows.push(
                                    <tr className='header-semana-title'>
                                        <th >Status</th>
                                        <th>Cliente</th>
                                        <th>Processo</th>

                                        <th>Pedido</th>
                                        {true ? (
                                            <th>Produção</th>
                                        ) : <></>}
                                        {true ? (
                                            <th>Entrega</th>
                                        ) : <></>}
                                        {true ? (
                                            <th>Instalação</th>
                                        ) : <></>}
                                        {true ? (
                                            <th>Assistência</th>
                                        ) : <></>}

                                        <th>Representante</th>
                                        <th>Cidade/Estado</th>
                                        <th>Medidas</th>

                                        {true ? (
                                            <th>Valor</th>
                                        ) : <></>}

                                        <th>Horas</th>
                                        <th>Esquadrias</th>
                                        <th>Quadros</th>
                                        <th>m2</th>
                                        <th>Vidro</th>
                                        <th>Observações</th>
                                        <th>Prazo</th>
                                        <th>Compartilhamento</th>
                                        <th>Cor</th>
                                    </tr>
                                );
                                headerAdded = true;
                            }

                            rows.push(
                                <tr key={item.id} onClick={() => handleClickTableRow(item)}>

                                    <td className='icon-status-controle'>{/*item.status*/}

                                        {item.status == 'Vendido' ? (
                                            <MdThumbUp className='icon-venda-efetuada-pcp' />
                                        ) : <></>}

                                        {item.status == 'Perdido' ? (
                                            <MdThumbDown className='icon-venda-verdida-pcp' />
                                        ) : <></>}

                                        {item.status == 'Arquivado' ? (
                                            <MdRestoreFromTrash className='icon-card-arquivado-pcp' />
                                        ) : <></>}

                                        {item.status == 'Entregue' ? (
                                            <MdLocalShipping className='icon-card-entregue-pcp' />
                                        ) : <></>}

                                        {item.status == 'Instalacao' ? (
                                            <MdWindow className='icon-card-entregue-pcp' />
                                        ) : <></>}

                                        {item.status == 'Assistencia' ? (
                                            <MdBuild className='icon-card-entregue-pcp' />
                                        ) : <></>}

                                        {item.status == 'InstalacaoExt' ? (
                                            <MdWindow className='icon-card-ext-pcp' />
                                        ) : <></>}

                                        {item.status == 'AssistenciaExt' ? (
                                            <MdBuild className='icon-card-ext-pcp' />
                                        ) : <></>}

                                    </td>
                                    <td style={{ fontSize: '12px' }} >{item.name}</td>
                                    <td style={{ fontSize: '12px' }}>{getColumnName(item.id_column)}</td>
                                    <td style={{ fontSize: '12px' }}>{item.numero_pedido}</td>

                                    {true ? (
                                        <td style={{ fontSize: '12px', color: controlePlanejaento === 'producao' ? 'red' : 'none' }}>{item.previsao_producao}</td>
                                    ) : <></>}
                                    {true ? (
                                        <td style={{ fontSize: '12px' }}>{item.previsao_entrega}</td>
                                    ) : <></>}
                                    {true ? (
                                        <td style={{ fontSize: '12px', color: controlePlanejaento === 'instalacao' ? 'red' : 'none' }}>{item.previsao_instalacao}</td>
                                    ) : <></>}
                                    {true ? (
                                        <td style={{ fontSize: '12px', color: controlePlanejaento === 'assistencia' ? 'red' : 'none' }}>{item.previsao_assistencia}</td>
                                    ) : <></>}

                                    <td style={{ fontSize: '12px' }}>{item.create_by}</td>
                                    <td>{`${item.city} / ${item.estado}`}</td>
                                    <td style={{ fontSize: '12px' }}>{item.recebimento_medidas}</td>

                                    {true ? (
                                        <td style={{ fontSize: '12px' }}>{parseFloat(item.valor ? item.valor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                    ) : <></>}

                                    <td style={{ fontSize: '12px' }}>{item.horas_producao ? item.horas_producao : 0}</td>
                                    <td style={{ fontSize: '12px' }}>{item.quantidade_esquadrias}</td>
                                    <td style={{ fontSize: '12px' }}>{item.quadros}</td>
                                    <td style={{ fontSize: '12px' }}>{item.metros_quadrados}</td>

                                    <td style={{ fontSize: '12px' }}>
                                        <div className='vidro-container'>
                                            <div className='status-vidro'>
                                                {item.status_vidro === 'Entrega Parcial' ? (
                                                    <MdInfo className='icon-status-vidro-parcial' />
                                                ) : <></>}
                                                {item.status_vidro === 'Aguardando a Compra' || item.status_vidro === '' || !item.status_vidro ? (
                                                    <MdRemoveShoppingCart className='icon-status-vidro-comprar' />
                                                ) : <></>}
                                                {item.status_vidro === 'Solicitado' ? (
                                                    <MdShoppingCart className='icon-status-vidro-comprado' />
                                                ) : <></>}
                                                {item.status_vidro === 'Entregue' ? (
                                                    <MdDoneOutline className='icon-status-vidro-entergue' />
                                                ) : <></>}

                                            </div>
                                            {item.entrega_vidro}
                                        </div>

                                    </td>
                                    <td style={{ fontSize: '12px' }}>{item.obs}</td>

                                    <td>{item.prazo_entrega ? calculateDaysUntilDelivery(item.prazo_entrega) : 0} dias</td>

                                    <td style={{ fontSize: '12px' }}>
                                        {item.lista_compartilhar.map((sharedItem) => (
                                            <span key={sharedItem.id}>( {sharedItem.name} ) </span>

                                        ))}
                                    </td>


                                    <td style={{ fontSize: '11px', color: `black` }}><div className='color-container-ref'><div style={{ fontSize: '11px', color: item.cor == 'BRANCO' ? `black` : `white`, background: `${getColorProduct(item.cor)}` }} className='color-ref'></div>{item.cor} </div></td>


                                </tr>
                            );
                        }
                    });

                    rows.push(
                        <tr key={`week-row-total-${weekNumber}`}>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: '#9862FF', color: 'white' }}><label className='horas-totais-label'>{parseFloat(totalValue ? totalValue : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label></td>
                            <td style={{ backgroundColor: '#9820FF', color: 'white' }} ><label className='horas-totais-label'>{totalHorasProducao.toFixed(0)}</label></td>
                            <td style={{ backgroundColor: '#9862FF', color: 'white' }}><label className='horas-totais-label'>{totalEsquadrias.toFixed(0)}</label></td>
                            <td style={{ backgroundColor: '#9820FF', color: 'white' }} ><label className='horas-totais-label'>{totalQuadros.toFixed(0)}</label></td>
                            <td style={{ backgroundColor: '#9862FF', color: 'white' }}><label className='horas-totais-label'>{totalMetrosQuadrados.toFixed(0)}</label></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                            <td style={{ backgroundColor: 'white', color: 'white' }} ></td>
                        </tr>
                    );

                    rows.push(
                        <div className='linha-vazia-programacao' >

                        </div>
                    );
                }
            }
        }
        return rows;
    }



    // const exportToExcel = () => {
    //     const table = document.getElementById('table-to-export'); // ID da tabela que deseja exportar
    //     const wb = utils.table_to_book(table);
    //     writeFile(wb, 'planilha_de_dados.xlsx');
    // }


    const ExcelJS = require('exceljs');

    const exportToExcel = () => {
        const table = document.getElementById('table-to-export');
        const wb = new ExcelJS.Workbook();
        const ws = wb.addWorksheet('Planilha de Dados');
        const rows = table.querySelectorAll('tr');

        // Copiar dados da tabela HTML para a planilha Excel
        let isWeekRow = false;

        rows.forEach((row, rowIndex) => {
            const cells = row.querySelectorAll('td, th');
            const rowValues = [];
            cells.forEach((cell) => {
                rowValues.push(cell.textContent);
            });
            const rowOptions = {};

            if (!isWeekRow) {
                // Estilo para a linha da semana (cor de fundo azul)
                isWeekRow = true;
            } else if (rowValues.length > 0) {
                // Estilo para as linhas dos itens (alternando cores branco e cinza)
                rowOptions.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: rowIndex % 2 === 0 ? 'FFFFFF' : 'DDDDDD' } };
            } else {
                // Estilo para as linhas de totais (cor de fundo vermelha)
                rowOptions.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF0000' } };
                rowOptions.font = { color: { argb: 'FFFFFF' }, bold: true };
                isWeekRow = false; // Não reinicie a flag para a próxima semana
            }
            const newRow = ws.addRow(rowValues);
            newRow.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.border = {
                    right: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    top: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                };
                cell.fill = rowOptions.fill;
                cell.font = rowOptions.font || { bold: true, size: 11 };
            });
            // Adicione uma borda ao redor de toda a semana
            if (!isWeekRow) {
                newRow.eachCell((cell) => {
                    cell.border = {
                        right: { style: 'thin', color: { argb: '000000' } },
                        left: { style: 'thin', color: { argb: '000000' } },
                        top: { style: 'thin', color: { argb: '000000' } },
                        bottom: { style: 'thin', color: { argb: '000000' } },
                    };
                });
            }
        });

        // Ajustar colunas ao conteúdo na horizontal com um tamanho mínimo
        const minimumColumnWidth = 5; // Tamanho mínimo em caracteres
        ws.columns.forEach((column) => {
            let maxLength = 0;
            column.eachCell((cell) => {
                const text = cell.text || '';
                maxLength = Math.max(maxLength, text.length);
            });
            column.width = Math.max(maxLength + 8, minimumColumnWidth);
        });

        // Salvar o arquivo Excel
        wb.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'planilha_de_dados.xlsx');
        });
    };





    const [boardName, setBoardName] = useState('Todos');

    const [selectBoardContainer, setSelectBoardContainer] = useState(false);

    function openCloseSelectBoard() {
        setSelectBoardContainer(!selectBoardContainer);
    }

    function selectBoard(id, setor) {
        setBoardName(setor)
        openCloseSelectBoard()
    }

    const setoresUnicos = [];

    const listaSetoresUnicos = listaProcessos.filter((item) => {
        if (!setoresUnicos.includes(item.setor) && listaAllProcessos.setor != '') {
            setoresUnicos.push(item.setor);
            return true;
        }
        return false;
    });


    function AbrirGoogleDrive() {
        window.open('https://drive.google.com/drive/folders/15982jl1M6jwUK2eDIJaaScqOx0uBO6-k?usp=drive_link', '_blank');
    }

    return (
        <div className='board-container'>

            <Tooltip />

            {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={50} />}

            <div className='board-header'>

                <button title="Filtrar por setor" className='btn-select-board' onClick={() => openCloseSelectBoard()}>{boardName ? boardName : ''} <MdSegment className='icon-select-board' /></button>
                {selectBoardContainer &&
                    <div className='select-board-container'>
                        <li className='lista-boards-item' style={{ background: boardName === 'Todos' ? 'deeppink' : '' }} onClick={() => selectBoard(100000, 'Todos')}>Todos</li>
                        {

                            listaSetoresUnicos.map((item) =>

                                <li key={item.id} style={{ background: boardName === item.setor ? 'deeppink' : '' }} className='lista-boards-item' onClick={() => selectBoard(item.id, item.setor)}>{item.setor}</li>

                            )
                        }
                    </div>
                }

                {/*<label className='title-order-by'>Ordenar por: </label>*/}
                <button title="Ordenar por nome" style={{ background: orderBy === 'nome' ? 'deeppink' : '' }} className='btn-order-by' onClick={() => orderByCards('nome')}>Nome</button>
                <button title="Ordenar por data" style={{ background: orderBy === 'data' ? 'deeppink' : '' }} className='btn-order-by' onClick={() => orderByCards('data')}>Data</button>
                <button title="Ordenar por data Update" style={{ background: orderBy === 'dataUpdate' ? 'deeppink' : '' }} className='btn-order-by-date-update' onClick={() => orderByCards('dataUpdate')}>DataUpdate</button>
                <button title="Ordenar por valor" style={{ background: orderBy === 'valor' ? 'deeppink' : '' }} className='btn-order-by' onClick={() => orderByCards('valor')}>Valor</button>
                <button title="" style={{ display: 'none', background: orderBy === 'valor' ? 'deeppink' : '' }} className='btn-order-by' onClick={() => sendMessage()}>Enviar Mensagem</button>

                <button title="Exportar Cards em arquivo Excel" className='icon-export-excel' onClick={handleExportToExcel}><RiFileExcel2Fill /></button>
                <button title="Exportar Cards em arquivo PDF" className='icon-export-pdf' onClick={handleExportToPDF}><MdPictureAsPdf /></button>

                <button title="Videos de treinamento disponíveis" className='btn-tutoriais' onClick={() => AbrirGoogleDrive()} >Tutoriais</button>


            </div>

            <div className='board-body'>

                {!openClosePCP &&
                    <div className="pcp-container">

                        <div className='pcp-header-container'>
                            <div className='container-buttons-header-controle'>
                                {/*<label className='label-header-controller'>Planejamento e Controle</label>*/}

                                <div className='buttons-header-controle'>
                                    <button className='icon-export-excel' onClick={exportToExcel}><RiFileExcel2Fill /></button>
                                    <div className='buttons-controle'>
                                        <button style={{ backgroundColor: controlePlanejaento == 'producao' ? 'deeppink' : 'dodgerblue' }} className='btn-programacao-producao' onClick={() => selectPlanejamentoControle('producao')}>Produção</button>
                                        <button style={{ backgroundColor: controlePlanejaento == 'conclusaoProducao' ? 'deeppink' : 'dodgerblue' }} className='btn-programacao-producao' onClick={() => selectPlanejamentoControle('conclusaoProducao')}>Produzidos</button>
                                        <button style={{ backgroundColor: controlePlanejaento == 'instalacao' ? 'deeppink' : 'dodgerblue' }} className='btn-programacao-producao' onClick={() => selectPlanejamentoControle('instalacao')}>Instalação</button>
                                        <button style={{ backgroundColor: controlePlanejaento == 'assistencia' ? 'deeppink' : 'dodgerblue' }} className='btn-programacao-producao' onClick={() => selectPlanejamentoControle('assistencia')}>Assistência</button>
                                        <button style={{ backgroundColor: controlePlanejaento == 'instalacaoext' ? 'deeppink' : 'dodgerblue' }} className='btn-programacao-producao' onClick={() => selectPlanejamentoControle('instalacaoext')}>Instalação Externa</button>
                                        <button style={{ backgroundColor: controlePlanejaento == 'assistenciaext' ? 'deeppink' : 'dodgerblue' }} className='btn-programacao-producao' onClick={() => selectPlanejamentoControle('assistenciaext')}>Assistência Externa</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table className="pcp-table" id="table-to-export">
                            <tbody key={tableKey}>
                                {renderTableRows(groupDataByWeek(filteredCards))}
                            </tbody>
                        </table>
                    </div>
                }

                {openClosePCP &&
                    listaAllProcessos
                        .filter((item) => item.setor == boardName || boardName == 'Todos')
                        .slice() // Crie uma cópia da lista para não modificar a original
                        .sort((a, b) => a.ordem - b.ordem) // Ordene os elementos com base na ordem crescente de 'ordem'
                        .map((item) => {
                            // Verifique se o ID do item está na listaProcessos
                            const isAllowed = listaProcessos?.some((processo) => processo.id === item.id);

                            // Verifique outras condições, como o tipoParticipante
                            if (!isAllowed || (item.id === '5' && tipoParticipante !== 'Administrador')) {
                                return null;
                            }

                            return <Column key={item.id} columnData={item} />;
                        })
                }

                <button title="Filtros" className='btn-filter-float' onClick={openCloseMenuFilter}><MdOutlineSearch /></button>

            </div>

            <label className='versao-system'>Versão 2.0</label>

            {createCardModal ? (<CreateCard />) : <></>}
            {editCardModal ? (<EditCard />) : <></>}
            {openCloseVendaPerdida ? (<VendaPerdida />) : <></>}

            {false &&
                <div className='modal-notification-manutencao'>
                    <div className='notification-manutencao-container'>
                        <label>Em Atualização ....</label>
                    </div>
                </div>
            }

            <button title="Planilhas de PCP" className='icon-grid-pcp' onClick={() => setOpenClosePCP(!openClosePCP)}><MdViewColumn /></button>
        </div >
    );
}
export default Board;