import React, { useContext, useEffect, useState } from 'react'

// STYLE
import './style.css'

// COMPONENTS
import CreateParitipante from '../CreateParticipante';
import ChartValueParticipante from '../charts/ChartValueParticipante';

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';

import EditParticipante from '../EditParticipante';

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

function Participantes() {


    const {
        createParticipanteModal,
        openCloseCreateParticipanteModal,
        editParticipanteModal,
        openCloseEditParticipanteModal,
    } = useContext(BoardContext);

    const { idUser, setListaParticipantes, listaParticipantes, tipoParticipante } = useContext(UserContext);

    useEffect(() => {
        getParticipantes();
    }, []);

    const getParticipantes = async () => {

        try {

            const url = `${apiUrl}/participantes/getParticipantes`;

            const params = {
                id_user: idUser,
                tipo_participante: tipoParticipante,
            };

            const response = await axios.get(url, { params });
            const data = response.data;

            setListaParticipantes(data);

        } catch (error) {
            console.error(error);
        }
    };

    const [dadosParticitanteSelected, setDadosParticipanteSelected] = useState();

    function dadosParticipante(dados) {
        setDadosParticipanteSelected(dados);
        openCloseEditParticipanteModal();
    }

    return (
        <div className='participante-container'>
            <div className='participante-header'>
                {<label className='title'>Participante</label>}
                <button className='btn-create-empresa' onClick={openCloseCreateParticipanteModal}>+</button>
            </div>
            <div className='participantes-body'>
                <div className='lista-participantes-container'>

                    <div className='lista-participantes'>
                        {
                            listaParticipantes && listaParticipantes.map(item => (
                                <li key={item.id} className='item-participante' onClick={() => dadosParticipante(item)}>
                                    {item.name}
                                </li>
                            ))
                        }

                    </div>

                </div>

                <ChartValueParticipante />

                {createParticipanteModal ? (<CreateParitipante />) : <></>}
                {editParticipanteModal ? (<EditParticipante dadosParticipante={dadosParticitanteSelected} />) : <></>}

            </div>

        </div>
    );
}
export default Participantes;