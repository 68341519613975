import React from 'react'

// COMPONENTS
import Header from '../../component/Header'
import Participantes from '../../component/Participantes';

function UsersPage() {

    return (
        <header className='user-container'>
            <Header />
            <Participantes />
        </header>
    );
}
export default UsersPage;