import React, { useContext, useEffect, useState, useRef } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';
import { ColumnContext } from '../../contexts/ColumnContext';

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

function EditUser(props) {

    const { openCloseEditUserModal } = useContext(BoardContext);
    const { idEmpresa, setUsers } = useContext(UserContext);
    const { listaColumns } = useContext(ColumnContext);

    const [idUser, setIdUser] = useState('');
    const [loginUser, setloginUser] = useState('');
    const [nameUser, setNameUser] = useState('');
    const [emailUser, setEmailUser] = useState('');
    const [adressUser, setAdressUser] = useState('');
    const [cityUser, setCityUser] = useState('');
    const [estadoUser, setEstadoUser] = useState('');
    const [cepUser, setCepUser] = useState('');
    const [foneUser, setFoneUser] = useState('');
    const [tipoParticipante, setTipoParticipante] = useState('');
    const [listaAfilhados, setListaAfilhados] = useState([]);
    const [listaProcessos, setListaProcessos] = useState([]);
    const [entidadePreference, setEntidadePreference] = useState('');
    const [urlAvatar, setUrlAvatar] = useState('');
    const [metaUser, setMetaUser] = useState(0);
    const [metaGrupo, setMetaGrupo] = useState(0);

    const [info, setInfo] = useState('');

    useEffect(() => {
        setIdUser(props.dadosParticipante.id);
        setloginUser(props.dadosParticipante.login);
        setNameUser(props.dadosParticipante.name);
        setEmailUser(props.dadosParticipante.email);
        setAdressUser(props.dadosParticipante.adress);
        setCityUser(props.dadosParticipante.city);
        setEstadoUser(props.dadosParticipante.estado);
        setCepUser(props.dadosParticipante.cep);
        setFoneUser(props.dadosParticipante.fone);
        setTipoParticipante(props.dadosParticipante.tipo_participante);
        setListaAfilhados(props.dadosParticipante.lista_afilhados);
        setEntidadePreference(props.dadosParticipante.entidade_preference);
        setUrlAvatar(props.dadosParticipante.avatar);
        setMetaUser(props.dadosParticipante.meta ? props.dadosParticipante.meta : 1000);
        setMetaGrupo(props.dadosParticipante.meta_grupo ? props.dadosParticipante.meta_grupo : 0);
        setListaProcessos(props.dadosParticipante.lista_processos);
    }, [])

    function validationForm() {

        if (tipoParticipante != '') {
            if (loginUser != "" && loginUser.length >= 10) {

                if (nameUser != '' && emailUser != '' && estadoUser != '' && cityUser != '' && foneUser != '') {
                    //updateUser();
                    updateUserBd();
                } else {
                    setInfo('Algum campo esta vazio!')
                }
            } else {
                setInfo('Senha invalida! As senhas precisam ser iguais e ao menos 8 caracteres')
            }

        } else {
            setInfo('Tipo de participante vazio!')
        }
    }

    useEffect(() => {
        getUsersEmpresa();
    }, []);

    const getUsersEmpresa = async () => {
        try {
            const url = `${apiUrl}/users/getUsers`;
            const params = {
                empresa: idEmpresa,
            };
            const response = await axios.get(url, { params });
            const usersData = response.data;
            const allUsers = [];

            const options = usersData
                .filter((user) => user.tipo_participante === 'Parceiro')
                .map((user) => {
                    return {
                        id: user.id,
                        name: user.name,
                    };
                });

            setListaUsers(options);
        } catch (error) {
            console.error(error);
        }
    };

    const updateUserBd = async () => {

        try {

            const userData = {
                name: nameUser,
                email: emailUser,
                adress: adressUser,
                city: cityUser,
                estado: estadoUser,
                cep: cepUser,
                fone: foneUser,
                empresa: idEmpresa ? idEmpresa : "",
                tipo_participante: tipoParticipante,
                lista_afilhados: listaAfilhados ? listaAfilhados : [],
                entidade_preference: entidadePreference,
                avatar: urlAvatar,
                meta: metaUser,
                meta_grupo: metaGrupo,
                lista_processos: listaProcessos ? listaProcessos : [],
            };

            await axios.put(`${apiUrl}/users/` + idUser, userData);

            console.log('User atualizado com sucesso!');

            const listaAtualizada = {
                id: idUser,
                name: nameUser,
                email: emailUser,
                adress: adressUser,
                city: cityUser,
                estado: estadoUser,
                cep: cepUser,
                fone: foneUser,
                empresa: idEmpresa ? idEmpresa : "",
                tipo_participante: tipoParticipante,
                lista_afilhados: listaAfilhados ? listaAfilhados : [],
                entidade_preference: entidadePreference,
                avatar: urlAvatar,
                meta: metaUser,
                meta_grupo: metaGrupo,
                lista_processos: listaProcessos ? listaProcessos : [],
            };

            atualizarListaUsersOffline(idUser, listaAtualizada);

        } catch (error) {
            console.error(error);
        }
    };

    const atualizarListaUsersOffline = (id, novosValores) => {

        setUsers(prevLista => {
            return prevLista.map(column => {
                if (column.id === id) {
                    return { ...column, ...novosValores };
                }
                return column;
            });
        });
        openCloseEditUserModal();
    };

    const [listaUsers, setListaUsers] = useState([]);

    function selectUser(id, name) {
        const novoParticipanteFilho = { id: id, name: name };

        if (listaAfilhados && listaAfilhados.length > 0) {
            const itemExistente = listaAfilhados.find((item) => item.id === id);

            if (!itemExistente) {
                setListaAfilhados([...listaAfilhados, novoParticipanteFilho]);
            } else {
                const novaLista = listaAfilhados.filter((item) => item.id !== id);
                setListaAfilhados(novaLista);
            }
        } else {
            setListaAfilhados([novoParticipanteFilho]);
        }
    }

    const [selectedUser, setSelectedUser] = useState(false);
    function openCloseSelectUser() {
        setSelectedUser(!selectedUser);
    }

    const listaTipoParticipante = [
        { id: '0', tipo: 'Administrador' },
        { id: '1', tipo: 'Parceiro' },
        { id: '2', tipo: 'Escritorio' },
        { id: '3', tipo: 'Producao' },
        { id: '4', tipo: 'Externo' },
    ]

    const [modalTipoParticipante, setModalTipoParticipante] = useState(false);
    function selectTipoParticipante(tipo) {
        setTipoParticipante(tipo);
        setModalTipoParticipante(!modalTipoParticipante);
    }

    // IBGE
    async function getEstados() {
        try {
            const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const estados = response.data;
            setListaEstadosIBGE(estados);
            return estados;
        } catch (error) {
            console.error('Erro ao obter a lista de estados:', error);
            return [];
        }
    }

    const [listaEstadosIBGE, setListaEstadosIBGE] = useState([]);

    const [selectedEstadoIBGE, setSelectedEstadoIBGE] = useState(false);
    function openCloseSelectEstadoIBGE() {
        getEstados();
        setSelectedEstadoIBGE(!selectedEstadoIBGE);
    }

    function selectEstado(id, sigla) {
        setEstadoUser(sigla);
        openCloseSelectEstadoIBGE();
        setSelectedCidadeIBGE(true);
        getCidades(id);
        setEstadoFiltro(sigla);
        irPraInputCidade();
    }

    const [selectedCidadeIBGE, setSelectedCidadeIBGE] = useState(false);

    const [listaCidadesIBGE, setListaCidadesIBGE] = useState([]);

    // Função para obter a lista de cidades de um estado
    async function getCidades(estadoId) {
        try {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`);
            const cidades = response.data;
            setListaCidadesIBGE(cidades);
            return cidades;
        } catch (error) {
            console.error('Erro ao obter a lista de cidades:', error);
            return [];
        }
    }

    function selectCidade(id, nome) {
        setCityUser(nome);
        setSelectedCidadeIBGE(false);
        setCidadeFiltro(nome);
    }

    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [cidadeFiltro, setCidadeFiltro] = useState('');
    const input2Ref = useRef(null);

    const irPraInputCidade = () => {
        // Define o foco no segundo campo de entrada ao clicar no botão
        input2Ref.current.focus();
    };

    const [listaAllProcessos, setListaAllProcessos] = useState([]);

    // function selectProcesso(id, name, setor) {

    //     const novoProcesso = { id: id, title: name, setor: setor, edite: canEdit };

    //     if (listaProcessos && listaProcessos.length > 0) {
    //         const itemExistente = listaProcessos.find((item) => item.id === id);

    //         if (!itemExistente) {
    //             setListaProcessos([...listaProcessos, novoProcesso]);
    //         } else {
    //             const novaLista = listaProcessos.filter((item) => item.id !== id);
    //             setListaProcessos(novaLista);
    //         }
    //     } else {
    //         setListaProcessos([novoProcesso]);
    //     }
    // }


    const getProcessos = async () => {

        try {

            const url = `${apiUrl}/processo/getProcesso`;

            const params = {
                empresa: idEmpresa
            };

            const response = await axios.get(url, { params });
            const processos = response.data;


            if (processos && processos.length > 0) {
                // Atualize a lista de processos com a propriedade "canEdit"
                const processosComCanEdit = processos.map((processo) => ({
                    ...processo,
                    edit: false, // Inicialmente, nenhum processo pode ser editado
                }));
                setListaAllProcessos(processosComCanEdit);
            }


            // if (processos && processos.length > 0) {
            //     setListaAllProcessos(processos);
            // }

            setSelectedProcesso(!selectedProcesso);

        } catch (error) {
            console.error(error);
        }
    };

    const [selectedProcesso, setSelectedProcesso] = useState(false);
    const [selectedProcessoEdit, setSelectedProcessoEdit] = useState(false);

    function selectProcesso(id, name, setor, edit) {

        const novoProcesso = { id: id, title: name, setor: setor, edit: edit };


        if (listaProcessos && listaProcessos.length > 0) {
            const itemExistente = listaProcessos.find((item) => item.id === id);

            if (!itemExistente) {
                setListaProcessos([...listaProcessos, novoProcesso]);
            } else {
                const novaLista = listaProcessos.filter((item) => item.id !== id);
                setListaProcessos(novaLista);
            }
        } else {
            setListaProcessos([novoProcesso]);
        }
    }

    function toggleProcessoEdit(id, isChecked) {

        console.log(isChecked)

        setListaProcessos((prevLista) =>
            prevLista.map((item) =>
                item.id === id ? { ...item, edit: isChecked } : item
            )
        );
    }

    const [check, setCheck] = useState(false);

    useEffect(() => {
        console.log(listaProcessos)

    }, [listaProcessos]);



    return (
        <div className='modal-edit-user-container'>
            <div className='edit-user-container'>
                <div className='edit-user-header'>
                    <div className='title'>Editar Usuário</div>
                    <div className='btn-close-container'>
                        <button className='btn-close' onClick={openCloseEditUserModal}>X</button>
                    </div>
                </div>

                <div className='edit-user-body'>

                    <label className='title'>Tipo de Participante</label>
                    <input className='input-edit-user' type='text' value={tipoParticipante || ''} onClick={() => setModalTipoParticipante(!modalTipoParticipante)} placeholder={'Selecione o tipo de participante...'}></input>
                    {modalTipoParticipante ? (
                        <div className='user-select'>
                            {
                                listaTipoParticipante.map((item) => <li key={item.id} className='item-list-select' onClick={() => selectTipoParticipante(item.tipo)}>{item.tipo}</li>)
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Login</label>
                    <input style={{ color: 'red' }} className='input-edit-user' type='email' value={loginUser} onChange={(e) => setEmailUser(e.target.value)} disabled={true} placeholder={'Digite o email de login...'}></input>

                    <label className='title'>Nome</label>
                    <input className='input-edit-user' type='text' value={nameUser} onChange={(e) => setNameUser(e.target.value)} placeholder={'Digite o nome...'}></input>

                    <label className='title'>Email de contato</label>
                    <input className='input-edit-user' type='email' value={emailUser} onChange={(e) => setEmailUser(e.target.value)} placeholder={'Digite o email de contato...'}></input>

                    <label className='title'>Endereço</label>
                    <input className='input-edit-user' type='text' value={adressUser} onChange={(e) => setAdressUser(e.target.value)} placeholder={'Digite o endereço...'}></input>

                    <label className='title'>Estado</label>
                    {/*<input className='input-edit-card' type='text' value={estadoCard ?? ''} readOnly onClick={openCloseSelectEstadoIBGE} placeholder={'Selecione o estado...'}></input>*/}
                    <input className='input-edit-user' type='text' value={estadoFiltro ?? estadoUser} onChange={(e) => setEstadoFiltro(e.target.value)} onClick={openCloseSelectEstadoIBGE} placeholder={estadoUser || 'Selecione o estado...'}></input>

                    {selectedEstadoIBGE ? (
                        <div className='card-select'>
                            {
                                listaEstadosIBGE
                                    .filter((estado) => estadoFiltro ? estado.sigla.toLowerCase().includes(estadoFiltro.toLowerCase()) : true)
                                    .sort((a, b) => a.sigla.localeCompare(b.sigla)) // Ordena em ordem alfabética por sigla
                                    .map((estado) => <li key={estado.id} className='item-list-select' onClick={() => selectEstado(estado.id, estado.sigla)}>{estado.sigla}</li>)

                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Cidade</label>
                    <input ref={input2Ref} className='input-edit-user' type='text' value={cidadeFiltro ?? cityUser} onChange={(e) => setCidadeFiltro(e.target.value)} placeholder={cityUser || 'Selecione a cidade...'}></input>

                    {selectedCidadeIBGE ? (
                        <div className='card-select'>
                            {
                                listaCidadesIBGE
                                    .filter((cidade) => cidadeFiltro ? cidade.nome.toLowerCase().includes(cidadeFiltro.toLowerCase()) : true)
                                    .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena em ordem alfabética por nome
                                    .map((cidade) => <li key={cidade.id} className='item-list-select' onClick={() => selectCidade(cidade.id, cidade.nome)}>{cidade.nome}</li>)
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>CEP</label>
                    <input className='input-edit-user' type='text' value={cepUser} onChange={(e) => setCepUser(e.target.value)} placeholder={'Digite o CEP...'}></input>

                    <label className='title'>Fone</label>
                    <input className='input-edit-user' type='tel' value={foneUser} onChange={(e) => setFoneUser(e.target.value)} placeholder={'Digite o fone...'}></input>

                    <label className='title'>Meta individual a atingir no mês</label>
                    <input className='input-edit-user' type='tel' value={metaUser} onChange={(e) => setMetaUser(e.target.value)} placeholder={'Digite o valor...'}></input>

                    <label className='title'>Meta do grupo a atingir no mês</label>
                    <input className='input-edit-user' type='tel' value={metaGrupo} onChange={(e) => setMetaGrupo(e.target.value)} placeholder={'Digite o valor...'}></input>

                    <label className='title'>Afilhados</label>
                    <input className='input-edit-user' type='text' onClick={openCloseSelectUser} placeholder={'Selecione os parceiros...'}></input>
                    {selectedUser ? (
                        <div className='user-select'>
                            {
                                listaUsers
                                    .map((item) =>
                                        <li
                                            style={{
                                                backgroundColor: listaAfilhados && listaAfilhados.find((filho) => filho.id === item.id) ? 'rgb(153, 241, 131)' : ''
                                            }}
                                            key={item.id}
                                            className='item-list-select'
                                            onClick={() => selectUser(item.id, item.name)}
                                        >
                                            {item.name}
                                        </li>

                                    )
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Processos</label>
                    <input className='input-edit-user' type='text' onClick={getProcessos} placeholder={'Selecione os processos...'}></input>
                    {selectedProcesso ? (
                        <div className='user-select'>
                            {
                                listaColumns
                                    .map((item) =>
                                        <li
                                            style={{
                                                backgroundColor: listaProcessos && listaProcessos.find((filho) => filho.id === item.id) ? 'rgb(153, 241, 131)' : ''
                                            }}
                                            key={item.id}
                                            className='item-list-select'
                                            onClick={() => selectProcesso(item.id, item.title, item.setor, true)}
                                        >
                                            {item.title}
                                        </li>

                                    )
                            }

                            {
                                listaAllProcessos
                                    .slice() // Crie uma cópia da lista para não modificar a original
                                    .sort((a, b) => a.ordem - b.ordem) // Ordene os elementos com base na ordem crescente de 'ordem'
                                    .map((item) =>
                                        <li
                                            style={{
                                                backgroundColor: listaProcessos && listaProcessos.find((filho) => filho.id === item.id) ? 'rgb(153, 241, 131)' : ''
                                            }}
                                            key={item.id}
                                            className='item-list-select'
                                            onClick={() => selectProcesso(item.id, item.title, item.setor, true)}
                                        >
                                            <label > {item.title}</label>

                                        </li>

                                    )
                            }


                        </div>
                    ) : <></>}


                    <label className='title'>Edição de Processos</label>
                    <input className='input-edit-user' type='text' onClick={() => setSelectedProcessoEdit(!selectedProcessoEdit)} placeholder={'Selecione os processos...'}></input>
                    {selectedProcessoEdit ? (
                        <div className='user-select'>

                            {
                                listaColumns
                                    .map((item) =>
                                        <li
                                            style={{
                                                //backgroundColor: listaProcessos && listaProcessos.find((filho) => filho.id === item.id) ? 'rgb(153, 241, 131)' : ''
                                            }}
                                            key={item.id}
                                            className='item-list-select-edit'

                                        >

                                            <label >{item.title}</label>
                                            <label className='checkbox-edit-container'>
                                                Permitir Edição:
                                                <input
                                                    type="checkbox"
                                                    checked={item.edit}
                                                    onChange={(e) => toggleProcessoEdit(item.id, e.target.checked)}
                                                    className='input-checkbox-edit'
                                                />
                                            </label>


                                        </li>

                                    )
                            }

                            {
                                listaProcessos
                                    .slice() // Crie uma cópia da lista para não modificar a original
                                    .sort((a, b) => a.ordem - b.ordem) // Ordene os elementos com base na ordem crescente de 'ordem'
                                    .map((item) =>
                                        <li
                                            style={{
                                                //backgroundColor: listaProcessos && listaProcessos.find((filho) => filho.id === item.id) ? 'rgb(153, 241, 131)' : ''
                                            }}
                                            key={item.id}
                                            className='item-list-select-edit'

                                        >

                                            <label > {item.title}</label>
                                            <label className='checkbox-edit-container'>
                                                Permitir Edição:
                                                <input
                                                    type="checkbox"
                                                    checked={item.edit}
                                                    onChange={(e) => toggleProcessoEdit(item.id, e.target.checked)}
                                                    className='input-checkbox-edit'
                                                />
                                            </label>


                                        </li>

                                    )
                            }


                        </div>
                    ) : <></>}

                    <label className='title'>Entidade</label>
                    <input className='input-edit-user' type='text' value={entidadePreference} onChange={(e) => setEntidadePreference(e.target.value)} placeholder={'Digite a referência da Entidade...'}></input>

                    <label className='title'>Avatar</label>
                    <input className='input-edit-user' type='text' value={urlAvatar} onChange={(e) => setUrlAvatar(e.target.value)} placeholder={'Digite a URL do avatar...'}></input>

                </div>
                <div className='edit-user-footer'>

                    <div className='info-edit-user'>{info}</div>
                    <button className='btn-cancel' onClick={openCloseEditUserModal}>Cancelar</button>
                    <button className='btn-save' onClick={validationForm}>Salvar</button>

                </div>
            </div>
        </div>
    );
}

export default EditUser;