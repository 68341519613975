import React, { useContext, useState, useEffect } from 'react';

// STYLE
import './style.css';

// DATE
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';

import { MdWindow, MdBuild, MdLocalShipping, MdDeleteForever, MdSave, MdFilter1, MdFilter2, MdFilter3, MdClose, MdThumbDown, MdRestoreFromTrash, MdThumbUp, MdOutlineFilterAltOff } from "react-icons/md";

import { startOfMonth, subMonths, endOfMonth, set } from 'date-fns';

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

function Filter() {

  // DATE PICKER
  registerLocale('pt-BR', ptBR);

  const { nameUser, listaAfilhados, tipoParticipante, listaUsers, idUser, listaFiltersUser } = useContext(UserContext);

  const {
    setSearchValue,
    searchValue,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    openCloseMenuFilter,
    statusCardFilter,
    setStatusCardFilter,
    setDateCardFilter,
    grupoParticipantes,
    setGrupoParticipantes,
    definePeriodo, setDefinePeriodo,
    searchParticipante, setSearchParticipante
  } = useContext(BoardContext);

  function filterPeriodo(periodo) {
    setDefinePeriodo(periodo);

    const currentDate = new Date();
    let start, end;

    if (periodo === 'mes') {
      start = startOfMonth(currentDate);
      end = endOfMonth(currentDate);
    } if (periodo === 'trimestre') {
      start = subMonths(startOfMonth(currentDate), 2);
      end = endOfMonth(currentDate);
    } if (periodo === 'semestre') {
      start = subMonths(startOfMonth(currentDate), 5);
      end = endOfMonth(currentDate);
    } if (periodo === 'ano') {
      start = subMonths(startOfMonth(currentDate), 11);
      end = endOfMonth(currentDate);
    }

    setStartDate(start);
    setEndDate(end);
  }

  function filterStatusVenda(status) {
    setStatusCardFilter(status);
    if (status == 'Vendido' || status == 'Perdido' || status == 'Arquivado') {
      setDateCardFilter('finished')
    } else {
      setDateCardFilter('create')
    }
  }

  function resetFilter() {
    const currentDate = new Date();
    let start, end;

    //start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    //end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    start = subMonths(startOfMonth(currentDate), 5);
    end = endOfMonth(currentDate);

    setStartDate(start);
    setEndDate(end);
    setStatusCardFilter('');
    setSearchValue(nameUser);
    filterStatusVenda('')
    filterPeriodo('ano');
    setSearchValue('');
    setGrupoParticipantes([...listaUsers.map((item) => item.id), idUser]);

    setFilterUserNumber('');
  }

  function setParticipanteFilter(idCreateBy) {

    if (grupoParticipantes.includes(idCreateBy)) {
      const novoGrupo = grupoParticipantes.filter((participante) => participante !== idCreateBy);
      setGrupoParticipantes(novoGrupo);
    } else {
      setGrupoParticipantes([...grupoParticipantes, idCreateBy]);
    }

    if (idCreateBy == "todos" && (tipoParticipante == "Administrador" || tipoParticipante == "Escritorio" || tipoParticipante == "Producao")) {

      if (grupoParticipantes.length != (listaUsers.length + 1)) {
        setGrupoParticipantes([...listaUsers.map((item) => item.id), idUser]);
      } else {
        setGrupoParticipantes([idUser]);
      }
    }

    if (idCreateBy == "todos" && tipoParticipante != "Administrador" && tipoParticipante != "Escritorio" && tipoParticipante != "Producao") {
      if (grupoParticipantes.length != (listaAfilhados.length + 1)) {
        setGrupoParticipantes([...listaAfilhados.map((item) => item.id), idUser]);
      } else {
        setGrupoParticipantes([idUser]);
      }

    }

  }

  const [filterUserNumber, setFilterUserNumber] = useState('');

  function saveFilterUser() {

    const confirmDelete = window.confirm("Salvar filtro?");

    if (confirmDelete) {

      if (filterUserNumber == 'f1' || filterUserNumber == 'f2' || filterUserNumber == 'f3') {
        createFilterUser();
      }

    }

  }

  const createFilterUser = async () => {

    try {

      const data = {
        id_user: idUser,
        number: filterUserNumber,
        status: statusCardFilter,
        periodo: definePeriodo,
        grupo: grupoParticipantes,
      };

      const response = await axios.post(`${apiUrl}/filters`, data);

    } catch (error) {

    }
  };


  useEffect(() => {
    getFilters();
  }, [filterUserNumber]);


  const getFilters = async () => {

    try {

      const url = `${apiUrl}/filters/getFilters`;

      const params = {
        id_user: idUser,
      };

      const response = await axios.get(url, { params });
      const data = response.data;

      if (data.length > 0) {

        // Classifique a lista de filtros em ordem alfabética considerando o segundo dígito do número.
        data.sort((a, b) => {
          const numeroA = parseInt(a.number.charAt(1)); // Pega o segundo dígito de 'fX'.
          const numeroB = parseInt(b.number.charAt(1));

          // Compare os números e ordene com base nessa comparação.
          return numeroA - numeroB;
        });

        const filter1 = data[0]; // Assuming the API returns an array of filters
        const filter2 = data[1]; // Assuming the API returns an array of filters
        const filter3 = data[2]; // Assuming the API returns an array of filters

        if (filterUserNumber == 'f1' && filter1) {

          setFilterUserNumber('f1')
          filterStatusVenda(filter1.status)
          filterPeriodo(filter1.periodo);
          setGrupoParticipantes(filter1.grupo);

        }

        if (filterUserNumber == 'f2' && filter2) {

          setFilterUserNumber('f2')
          filterStatusVenda(filter2.status)
          filterPeriodo(filter2.periodo);
          setGrupoParticipantes(filter2.grupo);

        }

        if (filterUserNumber == 'f3' && filter3) {

          setFilterUserNumber('f3')
          filterStatusVenda(filter3.status)
          filterPeriodo(filter3.periodo);
          setGrupoParticipantes(filter3.grupo);

        }

      }

    } catch (error) {
      console.error(error);
    }
  };


  async function deleteFilterUser() {
    const confirmDelete = window.confirm("Excluir filtro?");

    if (confirmDelete) {

      if (filterUserNumber == 'f1' || filterUserNumber == 'f2' || filterUserNumber == 'f3') {

        try {
          await axios.delete(`${apiUrl}/filters/${idUser}/${filterUserNumber}`);
        } catch (error) {
          console.error('Erro ao excluir filtro:', error);
        }

      }

    }
  }


  return (
    <div className='filter-container' >
      <div className='close-container' onClick={openCloseMenuFilter}></div>

      <div className='menu-filtro'>

        <div className='filter-header-container'>

          <button style={{ display: 'none' }} className='btn-close-filter'></button>
          <label className='title'>Filtros</label>
          <button className='btn-close-filter' onClick={openCloseMenuFilter}><MdClose /></button>

        </div>

        <div className='body-filter-container'>

          <div className='search-container'>

            <input
              style={{ backgroundColor: searchValue.length > 0 ? 'rgb(245, 59, 130)' : '', color: searchValue.length > 0 ? 'rgb(255, 255, 255)' : '' }}
              className='input-search-card'
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Buscar cliente, obra, orçamento, pedido, fone, cidade, valor, participante, #origem @IdColuna, %Parceiro..."
            />

          </div>

          <div className='btns-set-periodo'>
            <label className='title-item-filter'>Filtro do usuário</label>
            <div className='btns-status-container'>
              <button className='btn-funcoes-save' onClick={() => saveFilterUser()}><MdSave className='icon-status' /></button>
              <button style={{ background: filterUserNumber == 'f1' ? 'dodgerblue' : '' }} className='btn-funcoes' onClick={() => setFilterUserNumber('f1')}><MdFilter1 className='icon-status' /></button>
              <button style={{ background: filterUserNumber == 'f2' ? 'dodgerblue' : '' }} className='btn-funcoes' onClick={() => setFilterUserNumber('f2')}><MdFilter2 className='icon-status' /></button>
              <button style={{ background: filterUserNumber == 'f3' ? 'dodgerblue' : '' }} className='btn-funcoes' onClick={() => setFilterUserNumber('f3')}><MdFilter3 className='icon-status' /></button>
              <button className='btn-funcoes' onClick={() => deleteFilterUser()}><MdDeleteForever className='icon-status' /></button>
            </div>
          </div>

          <div className='btns-set-periodo'>
            <label className='title-item-filter'>Status</label>
            <div className='btns-status-container'>
              <button title="Ver apenas os Vendidos" style={{ border: statusCardFilter == 'Vendido' ? '2px solid dodgerblue' : 'white', color: 'deepskyblue' }} className='btn-status-vendido' onClick={() => filterStatusVenda('Vendido')}><MdThumbUp className='icon-status' /></button>
              <button title="Ver apenas os Perdidos" style={{ border: statusCardFilter == 'Perdido' ? '2px solid dodgerblue' : 'white', color: 'deeppink' }} className='btn-status-perdido' onClick={() => filterStatusVenda('Perdido')}><MdThumbDown className='icon-status' /></button>
              <button title="Ver apenas os Arquivados" style={{ border: statusCardFilter == 'Arquivado' ? '2px solid dodgerblue' : 'white', color: 'deeppink' }} className='btn-status-arquivado' onClick={() => filterStatusVenda('Arquivado')}><MdRestoreFromTrash className='icon-status' /></button>
              <button title="Ver apenas em Instalação" style={{ border: statusCardFilter == 'Instalacao' ? '2px solid dodgerblue' : 'white', color: '#9862FF' }} className='btn-status-arquivado' onClick={() => filterStatusVenda('Instalacao')}><MdWindow className='icon-status' /></button>
              <button title="Ver apenas em Instalação Externa" style={{ border: statusCardFilter == 'InstalacaoExt' ? '2px solid dodgerblue' : 'white', color: '#ffc862' }} className='btn-status-arquivado' onClick={() => filterStatusVenda('InstalacaoExt')}><MdWindow className='icon-status' /></button>
              <button title="Ver apenas em Assistência" style={{ border: statusCardFilter == 'Assistencia' ? '2px solid dodgerblue' : 'white', color: '#9862FF' }} className='btn-status-arquivado' onClick={() => filterStatusVenda('Assistencia')}><MdBuild className='icon-status' /></button>
              <button title="Ver apenas em assistência Externa" style={{ border: statusCardFilter == 'AssistenciaExt' ? '2px solid dodgerblue' : 'white', color: '#ffc862' }} className='btn-status-arquivado' onClick={() => filterStatusVenda('AssistenciaExt')}><MdBuild className='icon-status' /></button>
              <button title="Ver apenas os Entregues" style={{ border: statusCardFilter == 'Entregue' ? '2px solid dodgerblue' : 'white', color: '#9862FF' }} className='btn-status-arquivado' onClick={() => filterStatusVenda('Entregue')}><MdLocalShipping className='icon-status' /></button>

              <button title="Ver todos os Status" style={{ border: statusCardFilter == '' ? '2px solid dodgerblue' : 'white', color: '#3f3f3f' }} className='btn-status-arquivado' onClick={() => filterStatusVenda('')}>All</button>
              <button title="Limpar filtros" style={{ border: statusCardFilter == '' ? 'white' : 'white', color: '#3f3f3f' }} className='btn-status-vendido' onClick={() => resetFilter()}><MdOutlineFilterAltOff className='icon-status' /></button>
            </div>
          </div>

          <div className='btns-set-periodo'>
            <label className='title-item-filter'>Selecione o período:</label>
            <button style={{ background: definePeriodo == 'mes' ? 'dodgerblue' : '' }} className='btn-periodo' onClick={() => filterPeriodo('mes')}>Mês</button>
            <button style={{ background: definePeriodo == 'trimestre' ? 'dodgerblue' : '' }} className='btn-periodo' onClick={() => filterPeriodo('trimestre')}>Trimestre</button>
            <button style={{ background: definePeriodo == 'semestre' ? 'dodgerblue' : '' }} className='btn-periodo' onClick={() => filterPeriodo('semestre')}>Semestre</button>
            <button style={{ background: definePeriodo == 'ano' ? 'dodgerblue' : '' }} className='btn-periodo' onClick={() => filterPeriodo('ano')}>Ano</button>
          </div>

          <div className='select-periodo-filter-conteiner'>

            <div className='select-periodo-start'>
              <label className='title-item-filter'>Data inicial</label>
              <DatePicker
                locale="pt-BR"
                className='input-date-filter'
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                calendarClassName='custom-calendar-edit-card'
              />
            </div>

            <div className='select-periodo-end'>
              <label className='title-item-filter'>Data final</label>
              <DatePicker
                locale="pt-BR"
                className='input-date-filter'
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
                calendarClassName='custom-calendar-edit-card'
              />
            </div>

          </div>

          <label className='title-item-filter'>Busca por participante</label>
          <div className='btns-set-periodo'>

            <div className='filter-participantes-container'>

              <button className='btn-periodo' onClick={() => setParticipanteFilter('todos')}>Marcar / Desmarcar Todos</button>

              <button style={{ background: grupoParticipantes.includes(idUser) ? 'dodgerblue' : '' }} className='btn-periodo' onClick={() => setParticipanteFilter(idUser)}>{nameUser}</button>

              {
                tipoParticipante == 'Administrador' || tipoParticipante == 'Producao' || tipoParticipante == 'Escritorio' ? (
                  listaUsers
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) =>
                      <button key={item.id} style={{ background: grupoParticipantes.includes(item.id) ? 'dodgerblue' : '' }} className='btn-periodo' onClick={() => setParticipanteFilter(item.id)}>{item.name}</button>
                    )
                ) :
                  listaAfilhados
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) =>
                      <button key={item.id} style={{ background: grupoParticipantes.includes(item.id) ? 'dodgerblue' : '' }} className='btn-periodo' onClick={() => setParticipanteFilter(item.id)}>{item.name}</button>
                    )
              }

            </div>

          </div>

        </div>

      </div>

    </div>

  );
};
export default Filter;
