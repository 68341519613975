import React, { useContext, useEffect, useState } from 'react'
import './style.css'

import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';


// CONTEXT API

import { UserContext } from '../../contexts/UserContext';
import { BoardContext } from '../../contexts/BoardContext';

import moment from 'moment';
import 'moment/locale/pt-br';

import axios from 'axios';
import apiUrl from '../../config/apiConfig';
import EditOrcamento from '../EditOrcamento';

function Orcamento() {

    const { idUser, idEmpresa, tipoParticipante } = useContext(UserContext);
    const { openOrcamento, setOpenOrcamento } = useContext(BoardContext);


    // DATE PICKER
    registerLocale('pt-BR', ptBR);

    const [isLoading, setIsLoading] = useState('Salvar');

    const [currentOrcamento, setCurrentOrcamento] = useState([])


    useEffect(() => {
        getOrcamentos();
    }, [idUser])

    useEffect(() => {
        getOrcamentos();
    }, [openOrcamento])

    const getOrcamentos = async () => {

        try {

            const url = `${apiUrl}/orcamentos/getOrcamentos`;

            const params = {
                tipoParticipante: tipoParticipante,
                id_user: idUser,
                empresa: idEmpresa,
            };

            const response = await axios.get(url, { params });
            const data = response.data;

            setListaOrcamentos(data);

        } catch (error) {
            console.error(error);
        }
    };

    const [listaOrcamentos, setListaOrcamentos] = useState([]);

    function orcamento(data) {
        setCurrentOrcamento(data);
        setOpenOrcamento(true)
    }

    const createNewOrcamento = async () => {
        try {

            const response = await axios.get(`${apiUrl}/orcamentos/ultimo-number`);
            const lastOrcamentoNumber = response.data.lastNumber;

            const nextOrcamentoNumber = parseInt(lastOrcamentoNumber) + 1;

            createOrcamento(nextOrcamentoNumber);

        } catch (error) {
            console.error('Erro ao buscar o último número de orçamento:', error);
            return null;
        }

    }

    const [create, setCreate] = useState(false);

    const createOrcamento = async (numberOrcamento) => {

        setIsLoading("Salvando...");

        try {

            const cardData = {
                empresa: idEmpresa,
                document: numberOrcamento,
                name: '',
                email: '',
                fone: '',
                city: '',
                estado: '',
                id_create_by: idUser,
                date_create: new Date(),
                status: '',
            };

            const response = await axios.post(`${apiUrl}/orcamentos`, cardData);
            console.log(response.data);

            const idCard = response.data.id;

            const data = {
                id: idCard,
                empresa: idEmpresa,
                document: numberOrcamento,
                name: '',
                email: '',
                fone: '',
                city: '',
                estado: '',
                id_create_by: idUser,
                date_create: new Date(),
                status: '',
            };

            setCurrentOrcamento(data);
            setCreate(true)

        } catch (error) {
            console.error('Erro ao criar o card:', error);
        }

    };

    useEffect(() => {
        setCreate(false)
        setCurrentOrcamento(false)
    }, [])

    useEffect(() => {
        if (currentOrcamento !== null && create) {
            setOpenOrcamento(true);
            setCreate(false)
        }
    }, [currentOrcamento, create]);


    function formatarData(data) {
        const date = moment(data);
        return date.format('DD/MM/YYYY');
    }

    const [searchValue, setSearchValue] = useState('');
    const [filterDate, setFilterDate] = useState('Mes');
    const [filterStatus, setFilterStatus] = useState('Todos');

    function filterOrcamentosForDate(time) {
        setFilterDate(time);
    }


    function filterOrcamentosForStatus(status) {
        setFilterStatus(status);
    }

    return (

        <div className='container-orcamentos'>

            <div className='ferramentas-lista-orcamentos-tools'>

                <label className='title-filter-orcamentos-table'>Filtrar:</label>
                <input
                    style={{ backgroundColor: searchValue != '' ? 'deeppink' : '' }}
                    className='input-search-orcamento'
                    type="text"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Buscar cliente, obra ou documento..."
                />

                <label className='title-filter-orcamentos-table'>Período:</label>
                <button style={{ backgroundColor: filterDate == 'Mes' ? 'deeppink' : '' }} onClick={() => filterOrcamentosForDate('Mes')} className='btns-filter-orcamentos'>Mês</button>
                <button style={{ backgroundColor: filterDate == 'Semestre' ? 'deeppink' : '' }} onClick={() => filterOrcamentosForDate('Semestre')} className='btns-filter-orcamentos'>Semestre</button>
                <button style={{ backgroundColor: filterDate == 'Ano' ? 'deeppink' : '' }} onClick={() => filterOrcamentosForDate('Ano')} className='btns-filter-orcamentos'>Ano</button>
                <button style={{ backgroundColor: filterDate == 'Tudo' ? 'deeppink' : '' }} onClick={() => filterOrcamentosForDate('Tudo')} className='btns-filter-orcamentos'>Tudo</button>

                <label className='title-filter-orcamentos-table'>Status:</label>
                <button style={{ backgroundColor: filterStatus == '' ? 'rgb(20, 255, 71)' : '' }} onClick={() => filterOrcamentosForStatus('')} className='btns-filter-orcamentos'>Orçamentos</button>
                <button style={{ backgroundColor: filterStatus == 'Solicitado' ? 'rgb(20, 255, 71)' : '' }} onClick={() => filterOrcamentosForStatus('Solicitado')} className='btns-filter-orcamentos'>Solicitados</button>
                <button style={{ backgroundColor: filterStatus == 'Aceito' ? 'rgb(20, 255, 71)' : '' }} onClick={() => filterOrcamentosForStatus('Aceito')} className='btns-filter-orcamentos'>Aceitos</button>
                <button style={{ backgroundColor: filterStatus == 'Todos' ? 'rgb(20, 255, 71)' : '' }} onClick={() => filterOrcamentosForStatus('Todos')} className='btns-filter-orcamentos'>Todos</button>


            </div>


            <div className='lista-orcamentos-container'>

                <table className='table-orcamentos' style={{ borderSpacing: '0' }}  >
                    <thead className='tabela-lista-orcamentos-header'>
                        <tr className='item-header-table-orcamentos'>
                            <th className='item-table-orcamento-item-header'>Documento</th>
                            <th className='item-table-orcamento-item-header'>Nome</th>
                            <th className='item-table-orcamento-item-header'>Obra</th>
                            <th className='item-table-orcamento-item-header'>Telefone</th>
                            <th className='item-table-orcamento-item-header'>Email</th>
                            <th className='item-table-orcamento-item-header'>Cidade / Estado</th>
                            <th className='item-table-orcamento-item-header'>Valor Total</th>
                            <th className='item-table-orcamento-item-header'>Data</th>
                            <th className='item-table-orcamento-item-header'>Status</th>
                        </tr>
                    </thead>

                    <tbody className='table-body'>
                        {listaOrcamentos
                            .filter((item) =>
                                (item.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.obra?.toLowerCase().includes(searchValue.toLowerCase()) ||
                                    item.document?.toLowerCase().includes(searchValue.toLowerCase()))
                                &&
                                (item.status == filterStatus || filterStatus === 'Todos')
                            )
                            .map((item, index) => {

                                return (
                                    <tr key={item.id} className={index % 2 === 0 ? 'linha-cinza-claro' : 'linha-branca'} onClick={() => orcamento(item)}>
                                        <td className='item-table-orcamento-item'>{item.document}</td>
                                        <td className='item-table-orcamento-item'>{item.name}</td>
                                        <td className='item-table-orcamento-item'>{item.obra ? item.obra : ''}</td>
                                        <td className='item-table-orcamento-item'>{item.fone}</td>
                                        <td className='item-table-orcamento-item'>{item.email}</td>
                                        <td className='item-table-orcamento-item'>{`${item.city} / ${item.estado}`}</td>
                                        <td className='item-table-orcamento-item'>{parseFloat(item.valor_total ? item.valor_total : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td className='item-table-orcamento-item'>{item.date_create ? formatarData(item.date_create) : ''}</td>
                                        <td className='item-table-orcamento-item'>{item.status !== '' ? item.status : 'Orçamento'}</td>
                                    </tr>
                                );
                            })}
                    </tbody>


                </table>
            </div>

            {openOrcamento &&
                <EditOrcamento orcamentoData={currentOrcamento} />
            }


            <button className='btn-create-empresa' onClick={() => createNewOrcamento()}>+</button>
        </div>


    );

}

export default Orcamento;