import React, { useState, useContext, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// REACT ICONS
import { FaRankingStar } from "react-icons/fa6";
import { FaBell, FaBars } from 'react-icons/fa';
import { MdInfo, MdCalendarMonth, MdUpdate, MdAccountBalance, MdAddTask, MdShoppingCart, MdOutlineDateRange, MdAccountBox, MdFace, MdClose, MdOutlineFullscreen, MdOutlineExitToApp } from 'react-icons/md';

// LINK
import { Link, useLocation } from 'react-router-dom';

// ASSETS
import Logo from '../../assets/logo.png';
import LogoParceiro from '../../assets/logo_parceiro.png';

// STYLE
import './style.css';

// FIREBASE
import { getAuth, signOut } from 'firebase/auth';

import fb from '../../config/firebase'

// CONTEXT API
import { UserContext } from '../../contexts/UserContext';
import { CardContext } from '../../contexts/CardContext';
import { BoardContext } from '../../contexts/BoardContext';
import Filter from '../Filter';

// FULL SCREEM
import screenfull from 'screenfull';


import axios from 'axios';
import apiUrl from '../../config/apiConfig';

import sinoSound from '../../assets/bell.mp3';

import { format, parse } from 'date-fns';


// CALENDARIO
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
//import '@fullcalendar/core/main.css'; // Importe o estilo principal do FullCalendar

// Se você estiver usando outras visualizações, importe os plugins correspondentes, por exemplo:
// import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';

import { Tooltip } from 'react-tooltip'; // Mostra mensages ao colocar o mouse sobre os botoes com title


function Header() {

  const location = useLocation();

  const {
    setIdCard,
    setIdColumnCard,
    listaCards,
    setDocumentCard,
    setNameCard,
    setNameObraCard,
    setValorCard,
    setCityCard,
    setEstadoCard,
    setFoneCard,
    setEmailCard,
    setPrevisaoCard,
    setMeioContatoCard,
    setDate,
    setNivel,
    setEtiquetaCard,
    setModificationDateCard,
    setListaTarefasCard,
    setListaHistoricoCard,
    setProdutoCard,
    setStatusCard,
    setNameObra,
    setNameContato,
    setMotivoVendaPerdida,
    setFileLinksCard,
    setValorVendaCard,
    setCreateBy,
    setIdCreateBy,
    setPrevisaoEntregaCard,
    setHorasProducaoCard,
    setPrevisaoInstalacaoCard,
    setPrevisaoAssistenciaCard,
    setListaCompartilhar,
    setCorCard,
    setEditDateCard,
    setPrioridade,
    setPrevisaoProducaoCard,

    recebimentoMedidasCard, setRecebimentoMedidasCard,
    prazoEntregaCard, setPrazoEntregaCard,
    numeroPedidoCard, setNumeroPedidoCard,
    quadrosCard, setQuadrosCard,
    metrosQuadradosCard, setMetrosQuadradosCard,
    quantidadeEsquadriasCard, setQuantidadeEsquadriasCard,
    setEntregaVidroCard,
    setStatusVidroCard,
    setObsCard,
    setConclusaoProducaoCard
  } = useContext(CardContext);


  function getIdCard(cardData) {
    setIdCard(cardData.id);
    setIdColumnCard(cardData.id_column);
    setDocumentCard(cardData.document_card);
    setNameCard(cardData.name);
    setNameObraCard(cardData.name_obra);
    setValorCard(cardData.valor);
    setValorVendaCard(cardData.valor_venda);
    setCityCard(cardData.city);
    setEstadoCard(cardData.estado);
    setFoneCard(cardData.fone);
    setEmailCard(cardData.email);
    setPrevisaoCard(cardData.previsao);
    setMeioContatoCard(cardData.meio_contato);
    setDate(cardData.date);
    setNivel(cardData.nivel);
    setEtiquetaCard(cardData.etiqueta);
    setModificationDateCard(cardData.modification_date);
    setListaTarefasCard(cardData.lista_tarefas);
    setProdutoCard(cardData.produto);
    setListaHistoricoCard(cardData.lista_historico);
    setStatusCard(cardData.status);
    setNameObra(cardData.name_obra_cliente);
    setNameContato(cardData.name_contato);
    setMotivoVendaPerdida(cardData.motivo_perca);
    setFileLinksCard(cardData.lista_anexos);
    setCreateBy(cardData.create_by);
    setIdCreateBy(cardData.id_create_by);
    setPrevisaoEntregaCard(cardData.previsao_entrega);
    setHorasProducaoCard(cardData.horas_producao);
    setPrevisaoInstalacaoCard(cardData.previsao_instalacao);
    setPrevisaoAssistenciaCard(cardData.previsao_assistencia);
    setListaCompartilhar(cardData.lista_compartilhar);
    setCorCard(cardData.cor);
    setEditDateCard(cardData.edit_date);
    setPrioridade(cardData.prioridade);
    setPrevisaoProducaoCard(cardData.previsao_producao);

    setRecebimentoMedidasCard(cardData.recebimento_medidas);
    setPrazoEntregaCard(cardData.prazo_entrega);
    setNumeroPedidoCard(cardData.numero_pedido);
    setQuadrosCard(cardData.quadros);
    setMetrosQuadradosCard(cardData.metros_quadrados);
    setQuantidadeEsquadriasCard(cardData.quantidade_esquadrias);
    setEntregaVidroCard(cardData.entrega_vidro);
    setStatusVidroCard(cardData.status_vidro);
    setObsCard(cardData.obs);
    setConclusaoProducaoCard(cardData.conclusao_producao);

    openCloseEditCardModal();
  }

  // CONTEXT API
  const { tipoParticipante, user, nameUser, idUser, imageUrl, modulosUser, metaUser, metaGrupo } = useContext(UserContext);
  const { menuFilter, openCloseEditCardModal } = useContext(BoardContext);

  // FIREBASE
  const auth = getAuth(fb);



  const [listaTarefasFiltrada, setListaTarefasFiltrada] = useState([]);
  const [listaTarefasFiltradaUser, setListaTarefasFiltradaUser] = useState([]);
  const [listaPrevisao, setListaPrevisao] = useState([]);


  const [calendario, setCalendario] = useState(false);

  const [containerGanhadores, setContainerGanhadores] = useState(false);

  const [openCloseContainerLGPD, setOpenCloseContainerLGPD] = useState(false);

  if (user.email == null) {
    window.location.href = "/";
  }

  const navigate = useNavigate();  // Alterado de useHistory para useNavigate

  useEffect(() => {

    if (window.location.href.endsWith('/home')) {
      // Verificar o valor de modulosUser apenas se a tela atual for "/home"
      if (modulosUser === '100') {
        navigate('/home');
      } else if (modulosUser === '110') {
        navigate('/home');
      } else if (modulosUser === '010') {
        navigate('/orcamentos');
      } else if (modulosUser === '000') {
        navigate('/');
      } else {
        // Outras condições, se necessário
      }
    }

  }, [modulosUser, navigate]);

  // LOGOUT
  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.href = "/login";
    } catch (error) {

    }
  };

  const [menuUser, setMenuUser] = useState(false);
  const [menuNotification, setMenuNotification] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const showMenuUser = () => {
    setMenuUser(!menuUser);
  };

  const showMenuNotification = () => {
    setMenuNotification(!menuNotification);
  };

  const showNavbar = () => {
    setNavbar(!navbar);
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  function fullScreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    } else {

    }

    setIsFullscreen(screenfull.isFullscreen);
  }

  function convertDate(date) {

    const dateString = date;
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const dateObject = new Date(year, month, day);

    return dateObject;
  }

  useEffect(() => {

    const listaPrevisaoFechamento = listaCards
      .filter((item) => item.id_create_by == idUser)
      .filter((item) => item.status === '' && item.status != 'Vendido' && item.status != 'Entregue' && item.status != 'Instalacao' && item.status != 'Assistencia' && item.status != 'InstalacaoExt' && item.status != 'AssistenciaExt')
      .filter((item) => {
        const itemDate = convertDate(item.previsao);
        const currentDate = new Date();
        itemDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        return itemDate.getTime() <= currentDate.getTime();
      });

    setListaPrevisao(listaPrevisaoFechamento);

    getTarefas();
    getTarefasUser();

  }, [listaCards])

  const [numberNotification, setNumberNotification] = useState(0);

  function setFilterByPrevision(cardData) {
    getIdCard(cardData);
    //showMenuNotification();
  }

  function getTarefasUser() {
    const listaFiltradaTarefas = listaCards
      .filter((item) => item.lista_tarefas)
      .flatMap((item) => {
        const tarefasFiltradas = item.lista_tarefas.filter((tarefa) => {
          return tarefa.id_user === idUser &&
            convertDate(tarefa.date) <= new Date() &&
            tarefa.status === false;
        });

        return tarefasFiltradas.map((tarefa) => ({ card: item, tarefa: tarefa }));
      })

    setListaTarefasFiltradaUser(listaFiltradaTarefas);
  }


  // function getTarefas() {

  //   const listaFiltradaTarefas = listaCards
  //     .filter((item) => item.lista_tarefas)
  //     //.filter((item) => item.id_create_by == idUser || listaAfilhados.some(afilhado => afilhado.name == item.create_by))
  //     .filter((item) => item.id_create_by == idUser)
  //     .filter((item) => item.lista_tarefas.some((tarefa) => tarefa => convertDate(tarefa.date) <= new Date() && tarefa.status === false))
  //     .flatMap((item) => {
  //       return item.lista_tarefas

  //         .filter(tarefa => convertDate(tarefa.date) <= new Date() && tarefa.status === false)

  //         .map(tarefa => ({ card: item, tarefa: tarefa }));
  //     });

  //   setListaTarefasFiltrada(listaFiltradaTarefas);
  // }

  useEffect(() => {
    getNumberNotification();
  }, [listaTarefasFiltrada, listaPrevisao, listaTarefasFiltradaUser]);

  function getNumberNotification() {
    const hasTarefas = listaTarefasFiltrada.length > 0;
    const hasTarefasUser = listaTarefasFiltradaUser.length > 0;
    const hasPrevisao = listaPrevisao.length > 0;

    if (hasTarefas || hasPrevisao || hasTarefasUser) {
      setNumberNotification(listaTarefasFiltrada.length + listaPrevisao.length + listaTarefasFiltradaUser.length);
    } else {
      setNumberNotification(0);
    }
  }

  const [valorCards, setValorCards] = useState(0);

  useEffect(() => {
    obterValorVendas();
    obterValorVendasEquipe();
  }, [listaCards, listaTarefasFiltrada]);

  function converterDatePostegreToDate(dateConverter) {
    const dateString = dateConverter;
    const date = new Date(dateString);
    return date;
  }

  const obterValorVendas = () => {

    // MÊS ATUAL -------
    const currentDate = new Date();
    let start, end;
    start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    // -----------------

    const filteredCards = listaCards
      //.filter((item) => item.id_column === '2AjZ4OGF')
      .filter((item) => (
        (converterDatePostegreToDate(item.modification_date) >= start && converterDatePostegreToDate(item.modification_date) <= end)
      ))
      .filter((item) => item.status === 'Vendido' || item.status === 'Entregue' || item.status === 'Instalacao' || item.status === 'Assistencia' || item.status === 'InstalacaoExt' || item.status === 'AssistenciaExt')
      .filter((item) => item.id_create_by == idUser)

    const somaValores = filteredCards.reduce((acumulador, item) => acumulador + parseFloat(item.valor), 0);

    setValorCards(somaValores);
  };

  const [valorCardsEquipe, setValorCardsEquipe] = useState(0);

  const obterValorVendasEquipe = () => {

    // MÊS ATUAL -------
    const currentDate = new Date();
    let start, end;
    start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    // -----------------

    const filteredCards = listaCards
      //.filter((item) => item.id_column === '2AjZ4OGF')
      .filter((item) => (
        (converterDatePostegreToDate(item.modification_date) >= start && converterDatePostegreToDate(item.modification_date) <= end)
      ))
      .filter((item) => item.status === 'Vendido' || item.status === 'Entregue' || item.status === 'Instalacao' || item.status === 'Assistencia' || item.status === 'InstalacaoExt' || item.status === 'AssistenciaExt')

    const somaValores = filteredCards.reduce((acumulador, item) => acumulador + parseFloat(item.valor), 0);

    setValorCardsEquipe(somaValores);
  };

  const percentualProgresso = (valorCards / metaUser) * 100;
  const percentualProgressoEquipe = (valorCardsEquipe / metaGrupo) * 100;

  // ----------------------------------------

  useEffect(() => {
    if (tipoParticipante == 'Administrador') {
      getVendasDoDia();
    }
  }, [tipoParticipante]);

  const [vendasAnteriores, setVendasAnteriores] = useState([]);
  const [novosVendidos, setNovosVendidos] = useState([]);
  const [vendaUltimoMinuto, setVendaUltimoMinuto] = useState([]);
  const [exibirSino, setExibirSino] = useState(false);

  // Buscar todos os cards com status == "Vendido" && data de modificação == dia de hoje colocar em uma lista
  const getVendasDoDia = async () => {
    try {
      const url = `${apiUrl}/cards/sold-last-minute`;
      const response = await axios.get(url);
      const vendasExistentes = response.data;

      setVendasAnteriores(vendasExistentes);

    } catch (error) {
      console.error('Erro ao buscar vendas existentes:', error);
    }
  };

  useEffect(() => {

    if (tipoParticipante == 'Administrador') {
      const primeiraChamada = setTimeout(() => {

        const intervalId = setInterval(getNovasVendas, 30000);

        return () => clearInterval(intervalId);
      }, 30000);

      return () => clearTimeout(primeiraChamada);
    }

  }, [tipoParticipante]);

  const getNovasVendas = async () => {

    if (tipoParticipante == 'Administrador') {

      try {
        const url = `${apiUrl}/cards/sold-last-minute`;
        const response = await axios.get(url);
        const vendas = response.data;

        setNovosVendidos(vendas);

      } catch (error) {
        console.error('Erro ao buscar novos vendidos:', error);
      }
    }

  };

  useEffect(() => {

    if (novosVendidos.length > vendasAnteriores.length) {
      console.log("Existem novas vendas registradas")

      const vendasNovasCalculadas = novosVendidos.filter((venda) => !vendasAnteriores.some((vendaAnterior) => venda.id === vendaAnterior.id));

      setVendaUltimoMinuto(vendasNovasCalculadas);
      setExibirSino(true);
      setPlaySino(!playSino);

      setTimeout(() => {
        setExibirSino(false);
        setVendasAnteriores(novosVendidos);
        setVendaUltimoMinuto('');
      }, 8000);

    }

  }, [novosVendidos]);

  const [playSino, setPlaySino] = useState(false);

  useEffect(() => {
    if (playSino) {
      const audio = new Audio(sinoSound);
      audio.play();
      audio.onended = () => setPlaySino(false);
    }
  }, [playSino]);


  function updatePage() {
    window.location.reload();
  }


  function getTarefas() {

    const listaFiltradaTarefas = listaCards
      .filter((item) => item.lista_tarefas)
      //.filter((item) => item.id_create_by == idUser || listaAfilhados.some(afilhado => afilhado.name == item.create_by))
      .filter((item) => item.id_create_by == idUser)
      .filter((item) => item.lista_tarefas.some((tarefa) => tarefa => convertDate(tarefa.date) <= new Date() && tarefa.status === false))
      .flatMap((item) => {
        return item.lista_tarefas

          .filter(tarefa => !tarefa.id_user && convertDate(tarefa.date) <= new Date() && tarefa.status === false)

          .map(tarefa => ({ card: item, tarefa: tarefa }));
      })

    setListaTarefasFiltrada(listaFiltradaTarefas);
  }


  // CALENDARIO ------------------------

  const [agenda, setAgenda] = useState([]);

  function getAgenda() {
    const listaAgenda = listaCards
      .filter((item) => item.lista_tarefas)
      .filter((item) => item.id_create_by == idUser)
      .filter((item) => item.lista_tarefas.some((tarefa) => !tarefa.id_user && convertDate(tarefa.date) <= new Date() && tarefa.status === false))
      .flatMap((item) => {
        return item.lista_tarefas
          .filter(tarefa => !tarefa.id_user && convertDate(tarefa.date) <= new Date() && tarefa.status === false)
          .map(tarefa => ({
            title: tarefa.title,
            start: parse(tarefa.date, 'dd/MM/yyyy', new Date()), // Inclua a propriedade 'start' com a data da tarefa
            extendedProps: {
              cardData: item, // Inclua todo o objeto de card nas propriedades estendidas do evento
            },
          }));
      });

    setAgenda(listaAgenda);
  }


  // function getAgenda() {
  //   const listaAgenda = listaCards
  //     .filter((item) => item.lista_tarefas)
  //     .filter((item) => item.id_create_by == idUser)
  //     .filter((item) => item.lista_tarefas.some((tarefa) => tarefa => convertDate(tarefa.date) <= new Date() && tarefa.status === false))
  //     .flatMap((item) => {
  //       return item.lista_tarefas.map((tarefa) => ({
  //         title: tarefa.title,
  //         start: parse(tarefa.date, 'dd/MM/yyyy', new Date()), // Inclua a propriedade 'start' com a data da tarefa
  //         extendedProps: {
  //           cardData: item, // Inclua todo o objeto de card nas propriedades estendidas do evento
  //         },
  //       }));
  //     });

  //   setAgenda(listaAgenda);
  // }

  // function getAgenda() {
  //   const listaAgenda = listaCards
  //     .filter((item) => item.lista_tarefas)
  //     .filter((item) => item.id_create_by == idUser)
  //     .flatMap((item) => {
  //       return item.lista_tarefas.map((tarefa) => ({
  //         title: tarefa.title,
  //         start: parse(tarefa.date, 'dd/MM/yyyy', new Date()), // Inclua a propriedade 'start' com a data da tarefa
  //         extendedProps: {
  //           cardData: item, // Inclua todo o objeto de card nas propriedades estendidas do evento
  //         },
  //       }));
  //     });

  //   setAgenda(listaAgenda);
  // }

  useEffect(() => {
    getAgenda();
  }, [listaCards]);

  useEffect(() => {
    //console.log("agenda", agenda);
  }, [agenda]);

  function handleEventClick(eventInfo) {
    // Acesse as informações do evento clicado
    const { event } = eventInfo;

    const cardData = event.extendedProps.cardData; // Supondo que você armazene o objeto de card em "cardData" nas propriedades estendidas do evento

    setFilterByPrevision(cardData);
  }


  const [currentDate] = useState(new Date());


  const [nivelUser, setNivelUser] = useState(0);

  useEffect(() => {
    if (tipoParticipante === 'Administrador') {
      setNivelUser(1);
    }

    if (tipoParticipante === 'Parceiro') {
      setNivelUser(2);
    }

    if (tipoParticipante === 'Escritorio') {
      setNivelUser(3);
    }

    if (tipoParticipante === 'Producao') {
      setNivelUser(4);
    }

    if (tipoParticipante === 'Externo') {
      setNivelUser(5);
    }
  }, [tipoParticipante]);

  return (

    <header className="header-container">

      <div className="menu" onClick={showNavbar}>
        <FaBars title="Menu" className="fa-bars" />
      </div>

      <Link title="Ir para Home" to='/home' className="title">
        <img src={Logo} alt="SuiteFlow" />
        <h3>SuiteFlow</h3>
      </Link>

      <div className="user" >
        {location.pathname === '/home' && (
          <>

            <MdOutlineFullscreen title="Mostrar em tela cheia" className="icon-full-screen" onClick={(fullScreen)} />
            <MdCalendarMonth title="Calendário" className="icon-calendar" onClick={() => setCalendario(!calendario)} />
            <FaBell title="Ver notificações" className="icon-notification-header" onClick={showMenuNotification} />
            {listaPrevisao.length > 0 || listaTarefasFiltrada.length > 0 || listaTarefasFiltradaUser.length > 0 ? (
              <div className='number-notification' onClick={showMenuNotification}>{numberNotification}</div>
            ) : <></>}

          </>
        )}

        {
          imageUrl != '' && (
            <div title="Informações de Usuário" className='avatar-container' onClick={showMenuUser}>
              {imageUrl && <img src={imageUrl} alt="Parceiro" className='avatar-storage' />}
            </div>
          )
        }

        {
          imageUrl == '' && (
            <div title="Informações de Usuário" className='avatar-container' onClick={showMenuUser}>
              <img src={LogoParceiro} alt="Parceiro" className='avatar-storage' />
            </div>
          )
        }

      </div>

      {calendario && (
        <div className="calendar-container">
          <button className='close-calendario' onClick={() => setCalendario(!calendario)}>x</button>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            height="99%"
            headerToolbar={{
              left: '',
              center: 'title',
              right: 'prev,next today',
            }}
            events={agenda}
            eventClassNames="custom-event-style" // Nome da classe CSS personalizada
            eventContent={(eventInfo) => {
              const isPastDate = eventInfo.event.start < currentDate;
              const eventStyle = {
                backgroundColor: isPastDate ? 'rgb(255, 0, 119)' : 'deepskyblue', // Define a cor de fundo com base na condição
                color: 'white', // Cor do texto
                fontWeight: isPastDate ? 'bold' : 'bold',
                fontSize: '12px', // Tamanho da fonte
                borderRadius: '8px', // Borda arredondada
                paddingLeft: '5px', // Espaçamento à esquerda
                height: '30px',
                display: 'flex', // Correção na propriedade display
                alignItems: 'center', // Correção na propriedade align-items
                width: '100%',
              };

              return (
                <div className="custom-event-style" style={eventStyle}>
                  {eventInfo.event.title}
                </div>
              );
            }}



            eventClick={handleEventClick}

          />
        </div>
      )
      }


      {/* ---- MENU NOTIFICATION ---- */}
      {
        menuNotification && (

          <div className="modal-notification-container" onClick={showMenuNotification}>
            <div className="modal-notification">
              <div className="notification-info">
                <div className='top-header-notification'>

                  <label className='notification-container-header'>
                    Notificações
                    {listaPrevisao.length > 0 || listaTarefasFiltrada.length > 0 || listaTarefasFiltradaUser.length > 0 ? (
                      <div style={{ display: "none" }} className='number-notification-container' onClick={showMenuNotification}>{numberNotification}</div>
                    ) : <></>}
                  </label>

                  <button className='btn-close-menu-notification' onClick={showMenuNotification}><MdClose className='icon-close-notification' /></button>

                </div>

                <div className='notification-header'>

                  {
                    listaPrevisao.length > 0 ? (
                      <div className='lista-prev-fechamento-container'>

                        {
                          listaPrevisao
                            .filter((item) => item.status === '')
                            .map((item) => (
                              <li key={item.id} className='notification-item-previsao' onClick={() => setFilterByPrevision(item)}>
                                <label className='notification-item-name-header'>PREVISÃO DE FECHAMENTO</label>
                                <label className='previsao-item-name'>< MdFace className='icon-tarefas-item' /> {item.name.toUpperCase().substring(0, 33)}</label>
                                <label className='previsao-item-name'>< MdAccountBalance className='icon-tarefas-item' /> {item.name_obra_cliente ? item.name_obra_cliente.toUpperCase().substring(0, 33) : ''}</label>
                                <label className='previsao-item-name'>< MdAccountBox className='icon-tarefas-item' /> {item.create_by.toUpperCase().substring(0, 33)}</label>
                                <label className='previsao-item-name'><MdShoppingCart className='icon-tarefas-item' />{parseFloat(item.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                                <label className='previsao-item-name'><MdOutlineDateRange className='icon-tarefas-item' />{item.previsao}</label>
                              </li>
                            ))
                        }
                      </div>
                    ) : <></>
                  }

                  {
                    listaTarefasFiltrada.length > 0 ? (
                      <div className='lista-tarefas-container'>

                        {
                          listaTarefasFiltrada.map(item => (
                            <li key={item.tarefa.id} className='notification-item-tarefa' onClick={() => setFilterByPrevision(item.card)}>
                              <label className='notification-item-name-header'>TAREFA</label>
                              <label className='previsao-item-name'>< MdFace className='icon-tarefas-item' /> {item.card.name.toUpperCase().substring(0, 33)}</label>
                              <label className='previsao-item-name'>< MdAccountBox className='icon-tarefas-item' /> {item.card.create_by.toUpperCase().substring(0, 33)}</label>
                              {<label className='previsao-item-name'><MdAddTask className='icon-tarefas-item' /> {item.tarefa.title.substring(0, 50)}</label>}
                              <label className='previsao-item-name'><MdShoppingCart className='icon-tarefas-item' />{parseFloat(item.card.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                              <label className='previsao-item-name'><MdOutlineDateRange className='icon-tarefas-item' />{item.tarefa.date}</label>
                            </li>
                          ))
                        }
                      </div>
                    ) : <></>
                  }

                  {
                    listaTarefasFiltradaUser.length > 0 ? (
                      <div className='lista-tarefas-container'>

                        {
                          listaTarefasFiltradaUser.map(item => (
                            <li key={item.tarefa.id} className='notification-item-tarefa' onClick={() => setFilterByPrevision(item.card)}>
                              <label className='notification-item-name-header'>TAREFA USER</label>
                              <label className='previsao-item-name'>< MdFace className='icon-tarefas-item' /> {item.card.name.toUpperCase().substring(0, 33)}</label>
                              <label className='previsao-item-name'>< MdAccountBox className='icon-tarefas-item' /> {item.card.create_by.toUpperCase().substring(0, 33)}</label>
                              {<label className='previsao-item-name'><MdAddTask className='icon-tarefas-item' /> {item.tarefa.title.substring(0, 50)}</label>}
                              <label className='previsao-item-name'><MdShoppingCart className='icon-tarefas-item' />{parseFloat(item.card.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                              <label className='previsao-item-name'><MdOutlineDateRange className='icon-tarefas-item' />{item.tarefa.date}</label>
                            </li>
                          ))
                        }
                      </div>
                    ) : <></>
                  }

                  {
                    listaTarefasFiltrada.length == 0 && listaPrevisao.length == 0 && listaTarefasFiltradaUser.length == 0 ? (

                      <label className='mensagem-sem-notification'>Tudo certo por aqui! 😎👍</label>

                    ) : <></>
                  }

                </div>

              </div>

            </div>
          </div>
        )}

      {/* ---- MENU USER ---- */}
      {
        menuUser && (
          <div className="modal-user-container">
            <div className="modal-user">
              <div className="user-info">

                <div className='top-header-user'>
                  <button className='btn-close-menu-user' onClick={showMenuUser}><MdClose className='icon-close-user' /></button>
                </div>

                <div className='body-menu_user-header'>


                  {
                    imageUrl != '' && (
                      <div className='avatar-container-menu-user' onClick={showMenuUser}>
                        {imageUrl && <img src={imageUrl} alt="Parceiro" className='avatar-storage' />}
                      </div>
                    )
                  }

                  {imageUrl == '' &&
                    (
                      <div className='avatar-container-menu-user' onClick={showMenuUser}>
                        <img src={LogoParceiro} alt="Parceiro" className='avatar-storage' />
                      </div>
                    )
                  }

                  <label className="email-user">{nameUser}</label>
                  <label className="email-user">{'Vendas - INDIVIDUAL'}</label>
                  {
                    metaUser > 0 && (
                      <label className="valor-vendas-user">{parseFloat(valorCards).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                    )
                  }
                </div>

                {
                  metaUser > 0 && (
                    <>
                      <label className='title-meta'>Meta individual a alcançar no mês {parseFloat(metaUser).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                      <div className="progress-bar-container">

                        <div className="progress-label">
                          {`${percentualProgresso.toFixed(0)}%`}
                        </div>

                        <div className="progress-bar" style={{ width: `${percentualProgresso}%` }}></div>

                      </div>

                    </>
                  )
                }

                {
                  metaGrupo > 0 && (
                    <div className='valor-total-equipe'>
                      <label className="email-user">{'Vendas - GRUPO'}</label>

                      <label className="valor-vendas-user">{parseFloat(valorCardsEquipe).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>

                    </div>
                  )
                }

                {
                  metaGrupo > 0 && (
                    <>
                      <label className='title-meta'>Meta da equipe a alcançar no mês {parseFloat(metaGrupo).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                      <div className="progress-bar-container">
                        <div className="progress-label">
                          {`${percentualProgressoEquipe.toFixed(0)}%`}
                        </div>

                        <div className="progress-bar" style={{ width: `${percentualProgressoEquipe}%` }}></div>

                      </div>
                    </>
                  )
                }

                <div className='footer-menu-user-header'>
                  <button className='btn-update-page' onClick={updatePage}>
                    <MdUpdate />
                  </button>
                  <button className="btn-sair" onClick={handleLogout}>
                    <MdOutlineExitToApp className='icon-sair' />
                    <p className="title">Sair</p>
                  </button>
                </div>

              </div>

            </div>
          </div>
        )
      }

      {/* ---- NAVIBAR ---- */}
      {
        navbar && (
          <div className="navbar-modal" onClick={showNavbar}>
            <nav className="navbar">
              <div>
                <h3 className="navbar-title">Menu</h3>

                {(modulosUser == '110' || modulosUser == '100') && nivelUser < 3 &&
                  <Link to='/home' className='link'>
                    <button className="btn-Dashboard">Pipeline</button>
                  </Link>
                }

                {nivelUser < 3 &&
                  <>

                    {(modulosUser == '110' || modulosUser == '100') && nivelUser < 3 &&
                      <Link to='/users' className='link'>
                        <button className="btn-Dashboard">Participantes</button>
                      </Link>
                    }

                    {(modulosUser == '110' || modulosUser == '100') && nivelUser < 3 &&
                      <Link to='/dashboard' className='link'>
                        <button className="btn-Dashboard">Dashboard</button>
                      </Link>
                    }

                    {(modulosUser == '110' || modulosUser == '010') && nivelUser < 3 &&
                      <Link to='/orcamentos' className='link'>
                        <button className="btn-Dashboard">Orçamentos</button>
                      </Link>
                    }
                  </>
                }

              </div>

              {tipoParticipante == 'Administrador' &&
                <div className='area-adm'>
                  <Link to='/admin' className='link'>
                    <button className="btn-Dashboard">Administrador</button>
                  </Link>
                </div>
              }

            </nav>
          </div>
        )
      }
      {menuFilter ? (<Filter />) : <></>}

      {(exibirSino == true) && tipoParticipante == "Administrador" && (
        <div className='notification-admin'>
          <div className='header-notification-adm'>Nova Venda!</div>
          <FaBell className='notification-admin-icon-sino' />
          <div className='novas-vendas-container'>
            {
              vendaUltimoMinuto.map((venda) => (
                <div key={venda.id} className='nova-venda-adm'>
                  {/* Renderize informações da venda nova aqui */}
                  <label className='nova-venda-name'>{venda.create_by}</label>
                  <label className='nova-venda-valor'>{parseFloat(venda.valor ? venda.valor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                </div>
              ))
            }
          </div>
        </div>
      )}

      <button className='btn-lgpd' onClick={() => setOpenCloseContainerLGPD(!openCloseContainerLGPD)}><label className='label-info-lgpd'>LGPD</label>{/*<MdInfo className='icon-info-lgpd'/>*/}</button>

      {
        openCloseContainerLGPD && (
          <div className="container-lgpd-container">
            <div className="container-lgpd">
              <h2 className='container-lgpd-title'>Lei Geral de Proteção de Dados (LGPD)</h2>
              <p className='container-lgpd-li-h2-p'>A LGPD é uma legislação brasileira que protege a privacidade e os dados pessoais.</p>
              <p className='container-lgpd-li-h2-p'>Como usuário deste sistema CRM, é importante conhecer suas responsabilidades:</p>
              <ul className='container-lgpd-ul'>
                <li className='container-lgpd-li-h2-p'>Obtenção de Consentimento: Sempre peça permissão antes de coletar ou usar dados pessoais.</li>
                <li className='container-lgpd-li-h2-p'>Segurança de Dados: Proteja as informações pessoais contra acesso não autorizado.</li>
                <li className='container-lgpd-li-h2-p'>Acesso e Portabilidade de Dados: Os titulares têm direito de acessar e transferir seus dados.</li>
                <li className='container-lgpd-li-h2-p'>Exclusão de Dados: Atenda a pedidos de exclusão, a menos que haja uma base legal.</li>
              </ul>
              <p className='container-lgpd-li-h2-p'>Para mais informações sobre a LGPD e suas responsabilidades, consulte o site da <a href="https://www.gov.br/anpd/pt-br">Autoridade Nacional de Proteção de Dados (ANPD)</a> ou entre em contato com nossa equipe de suporte.</p>
              <p className='container-lgpd-agradecimento'>Obrigado por proteger a privacidade de nossos clientes.</p>
            </div>
          </div>
        )
      }


      <div className='trofeu-container' onClick={() => setContainerGanhadores(!containerGanhadores)}>
      <FaRankingStar onClick={() => setContainerGanhadores(!containerGanhadores)}/>
      </div>



      {containerGanhadores &&
        <div className='ganhadores-container' >

          <div className='close-trofeu-container' onClick={() => setContainerGanhadores(!containerGanhadores)}>
            <label onClick={() => setContainerGanhadores(!containerGanhadores)} >X</label>
          </div>

          <label className='title-ganhadores'>Vencedores do Ano!</label>



          <div className='container-ganhadores-ano'>

            <div className='column-ganhadores'>
              <img
                className='ganhador-trofeu-img'
                src={'https://firebasestorage.googleapis.com/v0/b/suiteflow-1c751.appspot.com/o/Ganhadores%2F2023%20-%20CRISTIANO.jpg?alt=media&token=bde12dba-173f-425c-8864-64454dfb8567'}
                alt="Ganhador Anual"
                onError={(e) => {
                  console.error('Erro ao carregar a imagem:', e);
                }}
              />
              <label className='ano-vencedor'>Vendedor do ano: CRISTIANO FAVERSANI</label>
            </div>

            <div className='column-ganhadores'>
              <img
                className='ganhador-trofeu-img'
                src={'https://firebasestorage.googleapis.com/v0/b/suiteflow-1c751.appspot.com/o/Ganhadores%2F2023%20-%20Maestria.jpeg?alt=media&token=c7d8edac-0e81-4e7c-a5df-e6b2b4a27efe'}
                alt="Ganhador Anual"
                onError={(e) => {
                  console.error('Erro ao carregar a imagem:', e);
                }}
              />
              <label className='ano-vencedor'>Parceiro que mais cresceu: MAESTRIA</label>
            </div>

            <div className='column-ganhadores'>
              <img
                className='ganhador-trofeu-img'
                src={'https://firebasestorage.googleapis.com/v0/b/suiteflow-1c751.appspot.com/o/Ganhadores%2F2023%20-%20Romeu.jpeg?alt=media&token=e1699075-804a-4129-b21b-fe49820f1669'}
                alt="Ganhador Anual"
                onError={(e) => {
                  console.error('Erro ao carregar a imagem:', e);
                }}
              />
              <label className='ano-vencedor'>Maior número de pedidos: ROMEU SCHNEIDER</label>
            </div>

            <div className='column-ganhadores'>
              <img
                className='ganhador-trofeu-img'
                src={'https://firebasestorage.googleapis.com/v0/b/suiteflow-1c751.appspot.com/o/Ganhadores%2F2023%20-%20Concetto.jpeg?alt=media&token=8f972914-e70f-4fc1-bd31-44778fb912c8'}
                alt="Ganhador Anual"
                onError={(e) => {
                  console.error('Erro ao carregar a imagem:', e);
                }}
              />
              <label className='ano-vencedor'>Engajamento 2023 - CONCETTO</label>
            </div>




          </div>

        </div>
      }





    </header >
  );
}

export default Header;
