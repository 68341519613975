import React, { useContext, useEffect, useState } from "react";
import { BoardContext } from "../../../contexts/BoardContext";
import { ColumnContext } from "../../../contexts/ColumnContext";
import { UserContext } from '../../../contexts/UserContext';
import { CardContext } from '../../../contexts/CardContext';

import { Chart } from "react-google-charts";

// DATE
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { startOfMonth, endOfMonth } from 'date-fns';

import { MdBarChart } from 'react-icons/md';

import "./style.css";

function ChartValueParticipante() {

  // DATE PICKER
  registerLocale('pt-BR', ptBR);

  const { listaCards } = useContext(CardContext);
  const { listaColumns } = useContext(ColumnContext);
  const { listaParticipantes } = useContext(UserContext);

  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(endOfMonth(new Date()));

  useEffect(() => {

    const filteredCards = listaCards.filter((card) => {
      const cardDate = new Date(card.modification_date);
      return (
        cardDate >= startDate &&
        cardDate <= endDate &&
        card.status === "Vendido" &&
        //card.id_column === '2AjZ4OGF' &&
        card.name_obra !== "" &&
        card.name_obra !== undefined &&
        listaParticipantes.some(participante => participante.name === card.name_obra)
      );
    });

    const uniqueUsers = [...new Set(filteredCards.map((card) => card.name_obra))];

    const data = uniqueUsers.map((user) => {
      const userValue = filteredCards
        .filter((card) => card.name_obra === user)
        .reduce((total, card) => total + parseFloat(card.valor), 0);

      return [user, userValue];
    });

    const columnHeaders = ["Name User", 'Total em Vendas no período'];

    const chartDataWithHeader = [columnHeaders, ...data];

    setChartData(chartDataWithHeader);
  }, [listaCards, listaColumns, startDate, endDate, listaParticipantes]);

  const uniqueUsers = [...new Set(chartData.slice(1).map((row) => row[0]))];
  const colors = [
    "#FF4069",
    "#FFC233",
    "#069BFF",
    "#C749EF",
    "#01BA02",
    "#F7A24D",
    "#F66DB0",
    "#8FB1CE",
    "#FF9696", // Vermelho claro
    "#FFB396", // Laranja claro
    "#FFD996", // Amarelo claro
    "#FFFF96", // Amarelo-esverdeado claro
    "#C0FF96", // Verde claro
    "#96FFAE", // Verde azulado claro
    "#96FFD4", // Ciano claro
    "#96FFFF", // Azul claro
    "#96B6FF", // Azul arroxeado claro
    "#B996FF", // Lilás claro
    "#D696FF", // Magenta claro
    "#FF96F9", // Rosa claro
    "#FF96D2", // Rosa arroxeado claro
    "#FF96AE", // Rosa alaranjado claro
  ];

  const series = uniqueUsers.map((user, index) => {
    return { color: colors[index % colors.length] };
  });

  const chartOptions = {
    title: "VALOR POR PARTICIPANTE",
    backgroundColor: "transparent",
    chartArea: { width: "80%", height: "90%" },
    legend: { position: "bottom" },
    fontSize: 11,
    hAxis: {
      title: "",
      minValue: 0,
    },
    vAxis: {
      title: "",
    },
    tooltip: { isHtml: true },
    series: Object.fromEntries(series.map((s, i) => [i, s])),
    annotations: {
      textStyle: {
        fontSize: 10,
      },
    },
  };

  const formatTooltip = (chartData, rowIndex) => {
    const nameParticipante = chartData.getValue(rowIndex, 0);
    let tooltipContent = `<div><strong>Name User:</strong> ${nameParticipante}</div>`;

    const columnName = chartData.getColumnLabel(1);
    const valor = chartData.getValue(rowIndex, 1);
    tooltipContent += `<div><strong>${columnName}:</strong> ${valor}</div>`;

    return tooltipContent;
  };

  return (
    <div>

      <div className="grafico-container-j">

        <div className="date-picker-container-participantes">
          <label className="title">Data inicial</label>
          <div className="picker">
            <DatePicker
              className="date-picker-participantes"
              locale="pt-BR"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Data inicial"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <label className="title">Data final</label>
          <div className="picker">
            <DatePicker
              className="date-picker-participantes"
              locale="pt-BR"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="Data final"
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>

        <div className="grafico-participantes">
          {chartData.length > 1 ? ( // Verifica se há dados no chartData
            <Chart
              className="grafico-jj"
              chartType="BarChart"
              data={chartData}
              options={chartOptions}
              chartEvents={[
                {
                  eventName: "onmouseover",
                  callback: ({ chartWrapper, google }) => {
                    const chart = chartWrapper.getChart();
                    const tooltip = chart.getTooltip();
                    const selection = chart.getSelection();

                    if (selection.length === 1 && tooltip.triggerEvent.row !== selection[0].row) {
                      const tooltipContent = formatTooltip(chartData, selection[0].row);
                      tooltip.show(selection[0], tooltipContent);
                    }
                  },
                },
              ]}
            />

          ) : (
            <p className="grafico-vazio-container"><MdBarChart className="vazio-message-icon" /> <label className="vazio-message"> Nada por aqui ...</label></p>
          )}
        </div>

      </div>
    </div>
  );
}

export default ChartValueParticipante;
