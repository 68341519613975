import React, { useContext, useState } from 'react'
import './style.css'

// CONTEXT API
import { CardContext } from '../../contexts/CardContext';

// IDs ÚNICOS
import { v4 as uuidv4 } from 'uuid';

// DATE
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

import { MdCheckCircle } from "react-icons/md";

import { UserContext } from '../../contexts/UserContext';

import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

function Tarefas(props) {

    // DATE PICKER
    registerLocale('pt-BR', ptBR);

    // ----- date ----
    const [selectedDate, setSelectedDate] = useState(null);
    const [titleTarefa, setTitleTarefa] = useState('');
    const [statusTarefa, setStatusTarefa] = useState(false);

    const { nameUser, idUser } = useContext(UserContext);
    const {
        listaTarefasCard,
        setListaTarefasCard,
        setEditDateCard
    } = useContext(CardContext);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const selectTarefas = [
        { id: '0', title: 'Telefonar para cliente' },
        { id: '1', title: 'Enviar Email' },
        { id: '2', title: 'Visitar cliente' },
        { id: '3', title: 'Almoçar com cliente' },
        { id: '4', title: 'Visitar a obra' },
        { id: '5', title: 'Medir a obra' },
    ]

    function addTarefaNova() {

        if (listaTarefasCard && titleTarefa.length > 0 && selectedDate && titleTarefa != '') {

            const novaTarefa = {
                id: uuidv4(),
                title: `${titleTarefa} ( ${nameUser} )`,
                date: format(selectedDate, 'dd/MM/yyyy'),
                status: statusTarefa,
                edit: true,
                id_user: idUser,
            };

            setListaTarefasCard([...listaTarefasCard, novaTarefa]);
            setEditDateCard(new Date());
            props.criarHistorico(`Tarefa Criada! ( ${nameUser} )`);
        }

        if (!listaTarefasCard && titleTarefa.length > 0 && selectedDate && titleTarefa != '') {

            const novaTarefa = {
                id: uuidv4(),
                title: `${titleTarefa} ( ${nameUser} )`,
                date: format(selectedDate, 'dd/MM/yyyy'),
                status: statusTarefa,
                edit: true,
                id_user: idUser,
            };

            setListaTarefasCard([novaTarefa]);
            props.criarHistorico(`Tarefa Criada! ( ${nameUser} )`);
            setEditDateCard(new Date());
        }
    }

    function excluirItem(id) {

        //const confirmDelete = window.confirm("Você tem certeza que deseja excluir este documento?");

        //if (confirmDelete) {

        const novaLista = listaTarefasCard.filter(item => item.id !== id);
        setListaTarefasCard(novaLista);

        //} else {
        //return;
        //}

    };

    function atualizarItem(id, novoStatus, title, status, date) {

        //if (status == false) {
        const confirmDelete = window.confirm('Você concluiu a tarefa?');

        if (confirmDelete) {

            props.criarHistorico(`${title} em ${date}. Concluído ( ${nameUser} )`);

            const novaLista = listaTarefasCard.map(item => {
                if (item.id === id) {
                    return { ...item, status: novoStatus };
                }
                return item;
            });
            setListaTarefasCard(novaLista);
            excluirItem(id);
            setEditDateCard(new Date());

        }
        //}
    }

    const [selectTarefaPadraoModal, setSelectTarefaPadraoModal] = useState(false);

    function selectItemPadraoTarefa(title) {
        setTitleTarefa(title);
        setSelectTarefaPadraoModal(!selectTarefaPadraoModal);
    }


    const [openCloseTarefas, setOpenCloseTarefas] = useState(false);

    return (
        <div className='tarefas-container'>

            <div className='header-tarefas'>
                <label className='title-tarefas'>Tarefas</label>
                {!openCloseTarefas && <MdArrowDropDown onClick={() => setOpenCloseTarefas(!openCloseTarefas)} className='icon-open-close' />}
                {openCloseTarefas && <MdArrowDropUp onClick={() => setOpenCloseTarefas(!openCloseTarefas)} className='icon-open-close' />}
            </div>

            {openCloseTarefas && <div className='body-tarefas'>

                <div className='input-tarefa-container'>
                    <input className='input-edit-date-tarefa' value={titleTarefa} onChange={(e) => setTitleTarefa(e.target.value)} onClick={() => setSelectTarefaPadraoModal(!selectTarefaPadraoModal)} placeholder={'Título da tarefa...'}></input>
                </div>

                {selectTarefaPadraoModal ? (
                    <div className='select-tarefa-container'>
                        {
                            selectTarefas.map((item) =>
                                <button key={item.id} className='btn-seletc-tarefa' onClick={() => selectItemPadraoTarefa(item.title)}>{item.title}</button>
                            )
                        }
                    </div>
                ) : <></>}

                <form className='date-taefas-container'>
                    <DatePicker
                        type='number'
                        locale="pt-BR"
                        className='input-edit-date-tarefa'
                        id="data"
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={'Selecione a data...'}
                        calendarClassName='custom-calendar-edit-card'
                    />
                </form>

                <div className='btn-add-tarefa-container'>
                    <button className='btn-add-tarefa' onClick={addTarefaNova}>Adicionar</button>
                </div>

                <div className='lista-tarefas-container'>
                    {
                        listaTarefasCard ? (
                            listaTarefasCard.map((item) =>
                                <li className='item-tarefa' key={item.id}>
                                    <label>{item.title}</label>
                                    <div className='container-rigth-tarefas'>
                                        <label>{item.date}</label>
                                        <MdCheckCircle style={{ color: item.status ? 'dodgerblue' : '' }} className='icon-tarefa-finalizada' onClick={() => atualizarItem(item.id, !item.status, item.title, item.status, item.date)} />
                                        {false && <button disabled={!item.edit} style={{ backgroundColor: item.edit ? '#d1d1d1' : '#d1d1d1' }} className='btn-del-tarefa' /*onClick={() => excluirItem(item.id)} */ >X</button>}
                                        <button style={{ backgroundColor: item.edit ? '#d1d1d1' : '#d1d1d1' }} className='btn-del-tarefa' /*onClick={() => excluirItem(item.id)} */ >X</button>
                                    </div>

                                </li>
                            )
                        ) : <></>

                    }
                </div>

            </div>
            }

            <div className='footer-tarefas'>

            </div>
        </div>
    );
}

export default Tarefas;