import React, { useContext, useEffect, useState } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { UserContext } from '../../contexts/UserContext';

function Atividades() {

    const { listaUsers } = useContext(UserContext);

    //const listaPontosUsersOrder = listaUsers.filter(item => item.tipo_participante != "Administrador").slice().sort((a, b) => b.atividade - a.atividade);
    const listaPontosUsersOrder = listaUsers.slice().sort((a, b) => b.atividade - a.atividade);

    return (
        <div className='atividades-modal'>

            <div className='atividades-container'>

                <div className='atividades-header'>
                    <label className='title'>Interação dos usuário com o sistema</label>
                </div>

                <div className='atividades-body'>

                    {
                        listaPontosUsersOrder.map((user, index) => (
                            <li key={index} className='item-atividades' >
                                <label>{user.name}</label>
                                <label className='atividades-pontos'> - Pontos: {user.atividade}</label>
                            </li>
                        ))
                    }


                </div>

                <div className='atividades-footer'>

                </div>

            </div>


        </div>

    );
}
export default Atividades;