import React, { useContext, useEffect, useState } from "react";
import { CardContext } from '../../../contexts/CardContext';
import { BoardContext } from '../../../contexts/BoardContext';
import { ColumnContext } from '../../../contexts/ColumnContext';
import { UserContext } from '../../../contexts/UserContext';
import _ from "lodash";

import Style from './style.css';

import { MdRocketLaunch, MdShoppingCart, MdTranscribe, MdTrendingDown } from "react-icons/md";

function InfosGerais() {
    const { listaCards } = useContext(CardContext);
    const { } = useContext(BoardContext);
    const { listaColumns } = useContext(ColumnContext);
    const { listaProcessos } = useContext(UserContext);

    // filtro de data
    const { startDate, endDate, statusCardFilter, dateCardFilter } = useContext(BoardContext);

    const [filteredCards, setFilteredCards] = useState([]);

    function converterDatePostegreToDate(dateConverter) {
        const dateString = dateConverter;
        const date = new Date(dateString);
        return date;
    }

    useEffect(() => {
        const filtered = listaCards.filter(card =>
            (card.status === "Vendido" ||
                card.status === "Entregue" ||
                card.status === "Instalacao" ||
                card.status === "InstalacaoExt" ||
                card.status === "AssistenciaExt" ||
                card.status === "Assistencia") &&
            converterDatePostegreToDate(card.modification_date) >= startDate &&
            converterDatePostegreToDate(card.modification_date) <= endDate
        );


        // Obtenha uma lista de `id_column` com base em `listaProcessos`
        const processColumnIds = listaProcessos.map(processo => processo.id.toString());

        // Filtrar os cards com `id_column` existente em `processColumnIds`
        const filteredCardsProcesso = filtered.filter(card => processColumnIds.includes(card.id_column));

        setFilteredCards(filteredCardsProcesso);
    }, [listaCards, endDate, startDate, statusCardFilter, dateCardFilter]);

    // Agrupar os cards por vendedor
    const cardsByUser = _.groupBy(filteredCards, "name_user");

    // Calcular o valor total por vendedor
    const salesByUser = Object.entries(cardsByUser).map(([name_user, cards]) => {
        const valorTotal = cards.reduce((total, card) => total + parseFloat(card.valor), 0);
        return { name_user, valorTotal };
    });

    // Ordenar as vendas por valor total decrescente
    const sortedSalesByUser = _.orderBy(salesByUser, ["valorTotal"], ["desc"]);

    // Obter os 3 maiores vendedores
    const topSellers = sortedSalesByUser.slice(0, 3);

    // Agrupar os cards por produto
    const cardsByProduct = _.groupBy(filteredCards, "produto");

    // Calcular o valor total vendido de cada produto
    const salesByProduct = Object.entries(cardsByProduct).map(([produto, cards]) => {
        const valorTotal = cards.reduce((total, card) => total + parseFloat(card.valor), 0);
        return { produto, valorTotal };
    });

    // Ordenar as vendas por valor total decrescente
    const sortedSalesByProduct = _.orderBy(salesByProduct, ["valorTotal"], ["desc"]);

    // Obter os 3 produtos mais vendidos
    const topProducts = sortedSalesByProduct.slice(0, 3);

    // Filtrar os cards perdidos
    const lostCards = listaCards.filter(card =>
        card.status === "Perdido"
    );

    // Agrupar os cards perdidos por motivo de perda de venda
    const lostCardsByLossReason = _.groupBy(lostCards, "motivo_perca");

    // Contar o número de perdas de venda por motivo
    const lossReasonCount = Object.entries(lostCardsByLossReason).map(([motivo_perca, cards]) => {
        const count = cards.length;
        return { motivo_perca, count };
    });

    // Ordenar os motivos de perda de venda por contagem decrescente
    const sortedLossReasonCount = _.orderBy(lossReasonCount, ["count"], ["desc"]);

    // Obter os 3 maiores motivos de perdas de venda
    const topLossReasons = sortedLossReasonCount.slice(0, 3);


    // Agrupar os cards perdidos por motivo de perda de venda
    const filteredMeioContqto = filteredCards.filter(card =>

        card.meio_contato != ""

    );

    const meioDeContato = _.groupBy(filteredMeioContqto, "meio_contato");

    // Contar o número de perdas de venda por motivo
    const meioDeContatoCount = Object.entries(meioDeContato).map(([meio_contato, cards]) => {
        const count = cards.length;
        return { meio_contato, count };
    });

    // Ordenar os motivos de perda de venda por contagem decrescente
    const sortedMeioDeContatoCount = _.orderBy(meioDeContatoCount, ["count"], ["desc"]);

    // Obter os 3 maiores motivos de perdas de venda
    const topMeioDeContato = sortedMeioDeContatoCount.slice(0, 3);

    return (
        <div className="infos-gerais-container">
            <div className="top-sellers">
                <div className="icon-infos-gerais-container">
                    <MdRocketLaunch className="icon-infos-gerais" />
                </div>
                <div className="info-column-container">
                    <h3 className="infos-gerais-header-header">Top 3 Vendedores</h3>
                    <ul className="infos-gerais-body">
                        {topSellers.map((seller, index) => (
                            <li className="infos-gerais-item" key={index}>
                                {`${seller.name_user}: ${seller.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="top-products">
                <div className="icon-infos-gerais-container">
                    <MdShoppingCart className="icon-infos-gerais" />
                </div>
                <div className="info-column-container">
                    <h3 className="infos-gerais-header-header">Top 3 Produtos Mais Vendidos</h3>
                    <ul className="infos-gerais-body">
                        {topProducts.map((product, index) => (
                            <li className="infos-gerais-item" key={index}>
                                {`${product.produto.toUpperCase()}: ${product.valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="top-loss-reasons">
                <div className="icon-infos-gerais-container">
                    <MdTrendingDown className="icon-infos-gerais" />
                </div>
                <div className="info-column-container">
                    <h3 className="infos-gerais-header-header">Top 3 Motivos Venda Perdida</h3>
                    <ul className="infos-gerais-body">
                        {topLossReasons.map((reason, index) => (
                            <li className="infos-gerais-item" key={index}>
                                {`${reason.motivo_perca.toUpperCase()}: ${reason.count} vezes`}
                            </li>
                        ))}
                    </ul>
                </div>

            </div>

            <div className="top-loss-reasonsxxx">
                <div className="icon-infos-gerais-container">
                    <MdTranscribe className="icon-infos-gerais" />
                </div>
                <div className="info-column-container">
                    <h3 className="infos-gerais-header-header">Top 3 Meio de Contato</h3>
                    <ul className="infos-gerais-body">
                        {topMeioDeContato.map((reason, index) => (
                            <li className="infos-gerais-item" key={index}>
                                {` ${reason.meio_contato.toUpperCase()}: ${reason.count} solicitações`}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default InfosGerais;
