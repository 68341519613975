import React, { useContext, useEffect, useState } from 'react'

// STYLE
import './style.css'

// COMPONENTS
import CreateUser from '../../component/CreateUser';

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';

import EditUser from '../EditUser';


import apiUrl from '../../config/apiConfig';
import axios from 'axios';

function Users() {

    const { editUserModal, openCloseEditUserModal, createUserModal, openCloseCreateUserModal } = useContext(BoardContext);

    const { users, setUsers, idEmpresa, empresaData } = useContext(UserContext);

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {

        try {

            const url = `${apiUrl}/users/getUsers`;

            const params = {
                empresa: idEmpresa,
            };

            const response = await axios.get(url, { params });
            const userData = response.data;

            setUsers(userData);

        } catch (error) {
            console.error(error);
        }
    };

    const [dadosParticitanteSelected, setDadosParticipanteSelected] = useState();

    function dadosParticipante(dados) {
        setDadosParticipanteSelected(dados);
        openCloseEditUserModal();
    }

    //console.log(empresaData)

    const [numberUsers, setNumberUsers] = useState(0);

    useEffect(() => {
        empresaData.forEach((doc) => {
            setNumberUsers(doc.users)
        })
      }, [empresaData]);

    return (
        <div className='users-container'>
            <div className='users-header'>

            </div>
            <div className='users-body'>
                <div className='lista-users-container'>

                    <label className='total-users-label-adm'> Usuários ativos: {users.length} / Licenças adquiridas: {numberUsers}</label>

                    <div className='lista-users'>
                        {
                            users
                                .slice()
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((item) =>
                                    <li key={item.id} className='item-user' onClick={() => dadosParticipante(item)}>
                                        {item.name}
                                    </li>,
                                )
                        }
                    </div>

                </div>

                {createUserModal ? (<CreateUser />) : <></>}
                {editUserModal ? (<EditUser dadosParticipante={dadosParticitanteSelected} />) : <></>}

            </div>

            <button className='btn-create-user' onClick={openCloseCreateUserModal}>+</button>

        </div>
    );
}
export default Users;