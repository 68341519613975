import React, { createContext, useState, useEffect } from "react";

// FIREBASE
import fb from '../config/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import axios from 'axios';
import apiUrl from '../config/apiConfig';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  //--- FIREBASE
  const auth = getAuth(fb);

  const [atividadeUser, setAtividadeUser] = useState(0);
  const [nameUser, setNameUser] = useState('');
  const [idUser, setIdUser] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [adressUser, setAdressUser] = useState('');
  const [cityUser, setCityUser] = useState('');
  const [estadoUser, setEstadoUser] = useState('');
  const [cepUser, setCepUser] = useState('');
  const [foneUser, setFoneUser] = useState('');
  const [idEmpresa, setIdEmpresa] = useState('');
  const [user, setUser] = useState({ id: '', email: '', });
  const [tipoParticipante, setTipoParticipante] = useState('');
  const [users, setUsers] = useState([]);
  const [listaAfilhados, setListaAfilhados] = useState([]);
  const [avatar, setAvatar] = useState('');
  const [metaUser, setMetaUser] = useState(0);
  const [metaGrupo, setMetaGrupo] = useState(0);
  const [listaProcessos, setListaProcessos] = useState([]);

  const [currentUser, setCurrentUser] = useState([]);
  const [listaParticipantes, setListaParticipantes] = useState([]);
  const [listaUsers, setListaUsers] = useState([]);
  const [modulosUser, setModulosUser] = useState('');


  function updateUser(id, email) {
    setUser({ id, email });
    getUsersLogin(email);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        updateUser(user.uid, user.email);
      } else {
        updateUser(null, null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    currentUser.forEach((doc) => {
      setIdUser(doc.id);
      setNameUser(doc.name);
      setEmailUser(doc.email);
      setAdressUser(doc.adress);
      setEstadoUser(doc.estado);
      setCityUser(doc.city);
      setCepUser(doc.cep);
      setFoneUser(doc.fone);
      setIdEmpresa(doc.empresa);
      setTipoParticipante(doc.tipo_participante);
      setListaAfilhados(doc.lista_afilhados);
      setAvatar(doc.avatar);
      setMetaUser(doc.meta);
      setMetaGrupo(doc.meta_grupo);
      setAtividadeUser(doc.atividade);
      setListaProcessos(doc.lista_processos);
      setModulosUser(doc.modulos);
    })
  }, [currentUser]);

  useEffect(() => {
    console.log('lista afilhados', listaAfilhados)
  }, [listaAfilhados]);

  const getUsersLogin = async (email, idEmpresa) => {
    try {
      const url = `${apiUrl}/users/getCurrentUser`;
      const params = {
        login: email,
      };
      const response = await axios.get(url, { params });
      const usersData = response.data;
      const allUsers = [];

      usersData.forEach((userData) => { // Não há necessidade de chamar doc.data()
        if (userData.login === email) {
          const todosUsers = {
            id: userData.id, // Se houver uma propriedade "id", use-a diretamente
            ...userData,
          };
          allUsers.push(todosUsers);
        }
      });

      setCurrentUser(allUsers);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUsersEmpresa(user.email, idEmpresa);
    getEmpresa(idEmpresa)
    
  }, [idEmpresa]);

  const getUsersEmpresa = async (email, idEmpresa) => {
    try {
      const url = `${apiUrl}/users/getUsers`;
      const params = {
        empresa: idEmpresa,
      };
      const response = await axios.get(url, { params });
      const usersData = response.data;
      const allUsers = [];

      usersData.forEach((userData) => { // Não há necessidade de chamar doc.data()
        if (userData.login !== email) {
          const todosUsers = {
            id: userData.id, // Se houver uma propriedade "id", use-a diretamente
            ...userData,
          };
          allUsers.push(todosUsers);
        }
      });

      setListaUsers(allUsers);

      //console.log(allUsers)

    } catch (error) {
      console.error(error);
    }
  };

  const [imageUrl, setImageUrl] = useState('');

  const storage = getStorage(fb);

  useEffect(() => {
    const fetchImageUrl = async () => {
      const storageRef = ref(storage, avatar);

      try {
        const url = await getDownloadURL(storageRef);
        setImageUrl(url);
      } catch (error) {
        //console.log('Erro ao obter a URL da imagem:', error);
      }
    };

    fetchImageUrl();
  }, [avatar]);

  const [empresaData, setEmpresaData] = useState([]);

  const getEmpresa = async (idEmpresa) => {
    try {
      const url = `${apiUrl}/empresas/getEmpresa`;
      const params = {
        empresa: idEmpresa,
      };
      const response = await axios.get(url, { params });
      const empresaData = response.data;
      
      setEmpresaData(empresaData);

      //console.log('empresaData');
      //console.log(empresaData);

    } catch (error) {
      console.error(error);
    }

  };



  return (
    <UserContext.Provider value={{
      listaAfilhados,
      setListaAfilhados,
      listaParticipantes,
      setListaParticipantes,
      tipoParticipante,
      setTipoParticipante,
      idUser,
      setIdUser,
      idEmpresa,
      users,
      setUsers,
      user,
      nameUser,
      emailUser,
      adressUser,
      cityUser,
      estadoUser,
      cepUser,
      foneUser,
      currentUser,
      setCurrentUser,
      imageUrl,
      setImageUrl,
      listaUsers, setListaUsers,
      metaUser,
      metaGrupo,
      atividadeUser, setAtividadeUser,
      listaProcessos, setListaProcessos,
      empresaData, setEmpresaData,
      modulosUser, setModulosUser
    
    }}>
      {children}
    </UserContext.Provider>
  );
};
