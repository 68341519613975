import React, { createContext, useState, useContext, useEffect } from "react";

import axios from 'axios';
import apiUrl from '../config/apiConfig';

// CONTEXT API
import { BoardContext } from '../contexts/BoardContext';
import { UserContext } from '../contexts/UserContext';

export const ColumnContext = createContext();

export const ColumnProvider = ({ children }) => {

  const { openCloseEditUserModal } = useContext(BoardContext);
  const { idEmpresa, setUsers } = useContext(UserContext);

  const [idColumn, setIdColumn] = useState('');
  const [nameColumn, setNameColumn] = useState('');

  const listaColumns = [
    // { id: 'LxpCulXe', title: 'Potenciais Clientes' },
    // { id: '2UVrpGg6', title: 'Em Orçamento' },
    // { id: '9gOxBRps', title: 'Em Negociação' },
    // { id: 'KcVGKMYf', title: 'Em Fechamento' },
    //{ id: '2AjZ4OGF', title: 'Finalizados' },
  ];

  const [listaAllProcessos, setListaAllProcessos] = useState([]);

  const [openCloseVendaPerdida, setOpenCloseVendaPerdida] = useState(false);
  function vendaPerdidaModal() {
    setOpenCloseVendaPerdida(!openCloseVendaPerdida);
  }


  useEffect(() => {
    getProcessos();
  }, [idEmpresa]);

  const getProcessos = async () => {

    try {

      const url = `${apiUrl}/processo/getProcesso`;

      const params = {
        empresa: idEmpresa
      };

      const response = await axios.get(url, { params });
      const processos = response.data;


      if (processos && processos.length > 0) {
        setListaAllProcessos(processos);
      }

      //console.log(processos)

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ColumnContext.Provider value={{
      openCloseVendaPerdida,
      vendaPerdidaModal,
      listaColumns,
      listaAllProcessos,
      idColumn,
      setIdColumn,
      nameColumn,
      setNameColumn
    }}>
      {children}
    </ColumnContext.Provider>
  );

};