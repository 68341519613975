import React, { useContext, useEffect, useState, useRef } from 'react'
import './style.css'

// DATE
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';
import { ColumnContext } from '../../contexts/ColumnContext';
import { CardContext } from '../../contexts/CardContext';

// IDs ÚNICOS
import { v4 as uuidv4 } from 'uuid';

import { MdGrade } from "react-icons/md";

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

function CreateCard() {

    // DATE PICKER
    registerLocale('pt-BR', ptBR);

    const { openCloseCreateCardModal } = useContext(BoardContext);

    const { idUser, idEmpresa, nameUser, emailUser, tipoParticipante } = useContext(UserContext);

    const { idColumn } = useContext(ColumnContext);

    const [documentCard, setDocumentCard] = useState('');
    const [nameCard, setNameCard] = useState('');
    const [nameObraCard, setNameObraCard] = useState('');
    const [valorCard, setValorCard] = useState(0);
    const [cityCard, setCityCard] = useState('');
    const [estadoCard, setEstadoCard] = useState('');
    const [foneCard, setFoneCard] = useState('');
    const [emailCard, setEmailCard] = useState('');
    const [previsaoCard, setPrevisaoCard] = useState('');
    const [meioContatoCard, setMeioContatoCard] = useState('');
    const [date, setDate] = useState('');
    const [nivel, setNivel] = useState(1);
    const [etiquetaCard, setEtiquetaCard] = useState('Sem Etiqueta');
    const [produtoCard, setProdutoCard] = useState('');
    const [listaHistoricoCard, setListaHistoricoCard] = useState([]);
    const [corCard, setCorCard] = useState('');

    const { idColumnCard, listaCards, setListaCards, listaEtiquetas } = useContext(CardContext);

    const [info, setInfo] = useState('');

    function validationForm() {

        if (nameCard != '' && estadoCard != '' && cityCard != '' && foneCard != '') {
            createCard();
        } else {
            setInfo('Nome, Telefone, Estado e Cidade são dados obrigatórios!');
        }

    }

    useEffect(() => {
        getCurrentDate();
    }, [])

    function getCurrentDate() {
        const date = new Date();
        const diaSemana = date.getDate().toString().padStart(2, '0');
        const mes = (date.getMonth() + 1).toString().padStart(2, '0');
        const ano = date.getFullYear();
        const data = diaSemana + '/' + mes + '/' + ano;
        setDate(date);
        return data;
    }

    const [isLoading, setIsLoading] = useState('Salvar');

    const MAX_CREATION_RETRIES = 5; // Número máximo de tentativas de criação

    const createCard = async () => {

        setIsLoading("Salvando...");

        let creationRetryCount = 0;

        const dataAtual = new Date();
        const primeiraData = new Date(dataAtual.getTime() + 1 * 24 * 60 * 60 * 1000);
        const segundaData = new Date(dataAtual.getTime() + 2 * 24 * 60 * 60 * 1000);
        const terceiraData = new Date(dataAtual.getTime() + 15 * 24 * 60 * 60 * 1000);

        while (creationRetryCount < MAX_CREATION_RETRIES) {
            try {

                const cardData = {
                    document_card: documentCard,
                    name: nameCard,
                    name_obra: nameObraCard ? nameObraCard : '',
                    valor: valorCard ? valorCard : 0,
                    email: emailCard ? emailCard : '',
                    fone: foneCard,
                    city: cityCard,
                    estado: estadoCard,
                    previsao: previsaoCard ? previsaoCard : '',
                    meio_contato: meioContatoCard ? meioContatoCard : '',
                    create_by: nameUser,
                    id_create_by: idUser,
                    name_user: nameUser != '' ? nameUser : '',
                    id_column: idColumn,
                    date: new Date(),
                    nivel: nivel,
                    etiqueta: etiquetaCard ? etiquetaCard : '',
                    empresa: idEmpresa,
                    motivo_perca: '',
                    modification_date: new Date(),
                    produto: produtoCard ? produtoCard : '',
                    status: '',
                    lista_tarefas: [
                        { id: uuidv4(), title: 'Enviar proposta ao cliente', date: format(primeiraData, 'dd/MM/yyyy'), status: false, edit: false },
                        { id: uuidv4(), title: 'Primeiro contato pós envio da proposta', date: format(segundaData, 'dd/MM/yyyy'), status: false, edit: false },
                        { id: uuidv4(), title: 'Segundo contato pós envio da proposta', date: format(terceiraData, 'dd/MM/yyyy'), status: false, edit: false }
                    ],
                    lista_historico: [{ id: uuidv4(), title: `Criado ( ${nameUser} )`, date: format(new Date(), 'dd/MM/yyyy'), edit: false }],
                    tipo_participante: tipoParticipante,
                    etapa_producao: 0,
                    cor: corCard ? corCard : '',
                    edit_date: new Date(),
                    name_contato: '',
                    valor_venda: 0,
                };

                const response = await axios.post(`${apiUrl}/cards`, cardData);
                console.log(response.data);

                const idCard = response.data.id;

                const listaAtualizada = {
                    id: idCard,
                    document_card: documentCard,
                    name: nameCard,
                    name_obra: nameObraCard ? nameObraCard : '',
                    valor: valorCard ? valorCard : 0,
                    email: emailCard ? emailCard : '',
                    fone: foneCard,
                    city: cityCard,
                    estado: estadoCard,
                    previsao: previsaoCard ? previsaoCard : '',
                    meio_contato: meioContatoCard ? meioContatoCard : '',
                    create_by: nameUser,
                    id_create_by: idUser,
                    name_user: nameUser != '' ? nameUser : '',
                    id_column: idColumn,
                    date: new Date(),
                    nivel: nivel,
                    etiqueta: etiquetaCard ? etiquetaCard : '',
                    empresa: idEmpresa,
                    motivo_perca: '',
                    modification_date: new Date(),
                    produto: produtoCard ? produtoCard : '',
                    status: '',
                    lista_tarefas: [
                        { id: uuidv4(), title: 'Enviar proposta ao cliente', date: format(primeiraData, 'dd/MM/yyyy'), status: false, edit: false },
                        { id: uuidv4(), title: 'Primeiro contato pós envio da proposta', date: format(segundaData, 'dd/MM/yyyy'), status: false, edit: false },
                        { id: uuidv4(), title: 'Segundo contato pós envio da proposta', date: format(terceiraData, 'dd/MM/yyyy'), status: false, edit: false }
                    ],
                    lista_historico: [{ id: uuidv4(), title: `Criado ( ${nameUser} )`, date: format(new Date(), 'dd/MM/yyyy'), edit: false }],

                    etapa_producao: 0,
                    cor: corCard ? corCard : '',
                    edit_date: new Date(),
                    name_contato: '',
                    valor_venda: 0,
                };
                

                const listaCardsAtualizada = [...listaCards, listaAtualizada];

                if (tipoParticipante == "free" && listaCards.length < 5) {
                    setListaCards(listaCardsAtualizada);
                }

                if (tipoParticipante == "free" && listaCards.length >= 5) {
                    alert('Limite de 5 cards atingido na versão gratuita!!!')
                }

                if (tipoParticipante != "free") {
                    setListaCards(listaCardsAtualizada);
                }

                openCloseCreateCardModal();

                // Saia do loop se a criação for bem-sucedida
                break;

            } catch (error) {

                console.error(`Tentativa ${creationRetryCount + 1} de criação falhou:`, error);
                // Incrementa o contador de tentativas
                creationRetryCount++;

                alert('Limite de 20 cards atingido na versão gratuita!!!')
                // Exibir a mensagem de erro apenas no console.log
                if (error.response && error.response.data && error.response.data.message) {
                    console.error(error.response.data.message);
                } else {
                    console.error('Erro ao criar o card:', error);
                }

                // Aguarde um curto período antes de tentar novamente (opcional)
                await new Promise(resolve => setTimeout(resolve, 1000));

                setIsLoading(`Salvando... Tentativa ${creationRetryCount}`);

                if(creationRetryCount == 5){
                    setIsLoading(`Sem conexão!`);
                }
            }
        }
    };

    // SELECT MEIO DE CONTATO
    function selectMeioContato(name) {
        setMeioContatoCard(name);
        openCloseSelectMeioContato();
    }
    const [selectedMeioContato, setSelectedMeioContato] = useState(false);
    function openCloseSelectMeioContato() {
        setSelectedMeioContato(!selectedMeioContato);
    }

    const meioContato = [
        { id: '0', meio: 'Patrocinado FACEBOOK' },
        { id: '1', meio: 'Patrocinado INSTAGRAM' },
        { id: '2', meio: 'Patrocinado GOOGLE' },
        { id: '3', meio: 'Parceiro' },
        { id: '4', meio: 'Site' },
        { id: '5', meio: 'Facebook' },
        { id: '6', meio: 'Instagram' },
        { id: '7', meio: 'WhatsApp' },
        { id: '8', meio: 'LinkedIn' },
        { id: '9', meio: 'Google' },
        { id: '10', meio: 'Email' },
        { id: '11', meio: 'Telefone' },
        { id: '12', meio: 'Recomendação' },
        { id: '13', meio: 'Prospecção Direta' },
        { id: '14', meio: 'Cliente Recorrente' },
    ];

    // ----- date ----
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setPrevisaoCard(format(date, 'dd/MM/yyyy'));
    };

    // IBGE
    async function getEstados() {
        try {
            const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const estados = response.data;
            setListaEstadosIBGE(estados);
            return estados;
        } catch (error) {
            console.error('Erro ao obter a lista de estados:', error);
            return [];
        }
    }

    const [listaEstadosIBGE, setListaEstadosIBGE] = useState([]);

    const [selectedEstadoIBGE, setSelectedEstadoIBGE] = useState(false);
    function openCloseSelectEstadoIBGE() {
        getEstados();
        setSelectedEstadoIBGE(!selectedEstadoIBGE);
    }

    function selectEstado(id, sigla) {
        setEstadoCard(sigla);
        openCloseSelectEstadoIBGE();
        setSelectedCidadeIBGE(true);
        getCidades(id);
        setEstadoFiltro(sigla);
        irPraInputCidade();
    }

    const [selectedCidadeIBGE, setSelectedCidadeIBGE] = useState(false);

    const [listaCidadesIBGE, setListaCidadesIBGE] = useState([]);

    // Função para obter a lista de cidades de um estado
    async function getCidades(estadoId) {
        try {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`);
            const cidades = response.data;
            setListaCidadesIBGE(cidades);
            return cidades;
        } catch (error) {
            console.error('Erro ao obter a lista de cidades:', error);
            return [];
        }
    }

    function selectCidade(id, nome) {
        setCityCard(nome);
        setSelectedCidadeIBGE(false);
        setCidadeFiltro(nome);
    }

    const [selectedProduto, setSelectedProduto] = useState(false);
    function openCloseSelectProduto() {
        if (selectedProduto == false) {
            getProducts();
        }
        setSelectedProduto(!selectedProduto);
    }

    function selectProdutoCard(name) {
        setProdutoCard(name);
        openCloseSelectProduto();
    }

    // useEffect(() => {
    //     addHistorico();
    // }, [nameUser])

    // // HISTORICO
    // function addHistorico() {

    //     const novoHistorico = { id: uuidv4(), title: 'Criado por ' + nameUser, date: format(new Date(), 'dd/MM/yyyy'), edit: false };

    //     setListaHistoricoCard([novoHistorico]);

    // }

    const [listaProdutos, setListaProdutos] = useState([]);

    const getProducts = async () => {

        try {

            const url = `${apiUrl}/produtos/getProducts`;

            const params = {
                empresa: idEmpresa
            };

            const response = await axios.get(url, { params });
            const products = response.data;


            if (products && products.length > 0) {
                setListaProdutos(products);
            }

        } catch (error) {
            console.error(error);
        }
    };


    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [cidadeFiltro, setCidadeFiltro] = useState('');
    const input2Ref = useRef(null);

    const irPraInputCidade = () => {
        // Define o foco no segundo campo de entrada ao clicar no botão
        input2Ref.current.focus();
    };

    return (
        <div className='modal-create-card-container'>
            <div className='create-card-container'>
                <div className='create-card-header'>
                    <div className='title'>Nova Oportunidade</div>
                    <div className='btn-close-container'>
                        <button className='btn-close' onClick={openCloseCreateCardModal}>X</button>
                    </div>
                </div>
                <div className='create-card-body'>

                    {idColumnCard != '2AjZ4OGF' ? (
                        <label className='title'>Potencial de venda</label>
                    ) : <></>}
                    {idColumnCard != '2AjZ4OGF' ? (
                        <label className='card-stars-container'>
                            <MdGrade
                                className='icon-star-create-card'
                                onClick={() => setNivel(1)}
                                style={{ color: nivel >= 1 ? '#9961FF' : 'rgba(172, 172, 172, 0.6)' }}
                            />
                            <MdGrade
                                className='icon-star-create-card'
                                onClick={() => setNivel(2)}
                                style={{ color: nivel >= 2 ? '#777CFF' : 'rgba(172, 172, 172, 0.6)' }}
                            />
                            <MdGrade
                                className='icon-star-create-card'
                                onClick={() => setNivel(3)}
                                style={{ color: nivel >= 3 ? '#5F8EFF' : 'rgba(172, 172, 172, 0.6)' }}
                            />
                            <MdGrade
                                className='icon-star-create-card'
                                onClick={() => setNivel(4)}
                                style={{ color: nivel >= 4 ? '#39A9FF' : 'rgba(172, 172, 172, 0.6)' }}
                            />
                            <MdGrade
                                className='icon-star-create-card'
                                onClick={() => setNivel(5)}
                                style={{ color: nivel >= 5 ? '#09CEFF' : 'rgba(172, 172, 172, 0.6)' }}
                            />
                        </label>
                    ) : <></>}

                    <label className='title'>Nome do cliente - Obrigatório</label>
                    <input className='input-create-card' type='text' onChange={(e) => setNameCard(e.target.value)} placeholder={'Digite o nome do cliente...'}></input>

                    {idColumn != 'LxpCulXe' ? (
                        <label className='title'>Número da proposta</label>
                    ) : <></>}

                    {idColumn != 'LxpCulXe' ? (
                        <input className='input-create-card' type='text' onChange={(e) => setDocumentCard(e.target.value)} placeholder={'Digite o número da proposta...'}></input>
                    ) : <></>}

                    {idColumn != 'LxpCulXe' ? (
                        <label className='title'>Valor de custo - Obrigatório</label>
                    ) : <></>}

                    {idColumn != 'LxpCulXe' ? (
                        <input className='input-create-card' type='number' onChange={(e) => setValorCard(parseInt(e.target.value))} placeholder={'Valor de negócio...'}></input>
                    ) : <></>}

                    {idColumn != 'LxpCulXe' ? (
                        <div>
                            <label className='title'>Previsão de Fechamento</label>
                            <form >
                                <DatePicker
                                    locale="pt-BR"
                                    type='number'
                                    className='input-create-card'
                                    id="data"
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText={previsaoCard ? previsaoCard : 'Selecione a data...'}
                                    calendarClassName='custom-calendar-edit-card'
                                />
                            </form>
                        </div>
                    ) : <></>}

                    <label className='title'>Email</label>
                    <input className='input-create-card' type='email' onChange={(e) => setEmailCard(e.target.value)} placeholder={'Digite o Email...'}></input>
                    <label className='title'>Fone - Obrigatório</label>
                    <input className='input-create-card' type='tel' onChange={(e) => setFoneCard(e.target.value)} placeholder={'Digite o telefone...'}></input>

                    <label className='title'>Estado - Obrigatório</label>
                    {/*<input className='input-edit-card' type='text' value={estadoCard ?? ''} readOnly onClick={openCloseSelectEstadoIBGE} placeholder={'Selecione o estado...'}></input>*/}
                    <input className='input-create-card' type='text' value={estadoFiltro ?? estadoCard} onChange={(e) => setEstadoFiltro(e.target.value)} onClick={openCloseSelectEstadoIBGE} placeholder={estadoCard || 'Selecione o estado...'}></input>

                    {selectedEstadoIBGE ? (
                        <div className='card-select'>
                            {
                                listaEstadosIBGE
                                    .filter((estado) => estadoFiltro ? estado.sigla.toLowerCase().includes(estadoFiltro.toLowerCase()) : true)
                                    .sort((a, b) => a.sigla.localeCompare(b.sigla)) // Ordena em ordem alfabética por sigla
                                    .map((estado) => <li key={estado.id} className='item-list-select' onClick={() => selectEstado(estado.id, estado.sigla)}>{estado.sigla}</li>)

                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Cidade - Obrigatório</label>
                    <input ref={input2Ref} className='input-create-card' type='text' value={cidadeFiltro ?? cityCard} onChange={(e) => setCidadeFiltro(e.target.value)} placeholder={cityCard || 'Selecione a cidade...'}></input>

                    {selectedCidadeIBGE ? (
                        <div className='card-select'>
                            {
                                listaCidadesIBGE
                                    .filter((cidade) => cidadeFiltro ? cidade.nome.toLowerCase().includes(cidadeFiltro.toLowerCase()) : true)
                                    .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena em ordem alfabética por nome
                                    .map((cidade) => <li key={cidade.id} className='item-list-select' onClick={() => selectCidade(cidade.id, cidade.nome)}>{cidade.nome}</li>)
                            }
                        </div>
                    ) : <></>}


                    <label className='title'>Produto</label>
                    <input className='edit-card-select' type='text' value={produtoCard || ''} readOnly onClick={openCloseSelectProduto} placeholder={'Selecione um produto...'}></input>

                    {selectedProduto ? (
                        <div className='card-select'>
                            {
                                listaProdutos.map((item) => <li key={item.id} className='item-list-select' onClick={() => selectProdutoCard(item.name)}>{item.name}</li>)
                            }
                        </div>
                    ) : <></>}

                </div>
                <div className='info-container'>
                    <div className='info-edit-user'>{info}</div>
                </div>
                <div className='create-card-footer'>
                    <button className='btn-cancel' onClick={openCloseCreateCardModal}>Cancelar</button>
                    <button className='btn-save' onClick={validationForm} disabled={isLoading === 'Salvando...'} >{isLoading}</button>
                </div>
            </div>
        </div>
    );
}

export default CreateCard;