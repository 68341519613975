import React, { useContext, useEffect, useState, useRef } from 'react'
import './style.css'

// DATE
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

// CONTEXT API
import { UserContext } from '../../contexts/UserContext';
import { BoardContext } from '../../contexts/BoardContext';
import { ColumnContext } from '../../contexts/ColumnContext';

import moment from 'moment';
import 'moment/locale/pt-br';

// ASSETS
import Logo from '../../assets/logo.png';
import LogoParceiro from '../../assets/logo_parceiro.png';

import { MdSave, MdAssignmentTurnedIn, MdAssignmentAdd, MdOutlineExitToApp, MdPerson, MdCurrencyExchange, MdEditSquare, MdAddCard, MdDeleteForever, MdThumbDown, MdRestoreFromTrash, MdOutlineSearch, MdPictureAsPdf, MdViewColumn } from "react-icons/md";

import { RiFileExcel2Fill } from "react-icons/ri";

import apiUrl from '../../config/apiConfig';
import axios from 'axios';

import html2pdf from 'html2pdf.js';

import * as XLSX from 'xlsx';

// IDs ÚNICOS
import { v4 as uuidv4 } from 'uuid';

function EditOrcamento(props) {


    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const { listaAllProcessos } = useContext(ColumnContext);
    const { openOrcamento, setOpenOrcamento } = useContext(BoardContext);
    const { foneUser, estadoUser, cityUser, emailUser, nameUser, idUser, tipoParticipante, idEmpresa, modulosUser, listaProcessos, imageUrl } = useContext(UserContext);

    const [pesoTotal, setPesoTotal] = useState(0);

    const [desconto, setDesconto] = useState(0);
    const [documentOrcamento, setDocumentOrcamento] = useState('');
    const [nameOrcamento, setNameOrcamento] = useState('');
    const [cityOrcamento, setCityOrcamento] = useState('');
    const [estadoOrcamento, setEstadoOrcamento] = useState('');
    const [foneOrcamento, setFoneOrcamento] = useState('');
    const [emailOrcamento, setEmailOrcamento] = useState('');
    const [statusPedido, setStatusPedido] = useState('');
    const [listaProdutosAdicionados, setListaProdutosAdicionados] = useState([]);
    const [valorTotal, setValorTotal] = useState(0);
    const [dateCreate, setDateCreate] = useState('');
    const [dateRecalculo, setDateRecalculo] = useState('');
    const [observacoes, setObservacoes] = useState('');
    const [obra, setObra] = useState('');
    const [frete, setFrete] = useState('');
    const [valorFrete, setValorFrete] = useState(0);
    const [icms, setICMS] = useState('');
    const [pagamento, setPagamento] = useState('');

    const [idOrcamento, setIdOrcamento] = useState(0);

    const [produto, setProduto] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [orcamento, setOrcamento] = useState([]);

    const [statusTemporario, setStatusTemporario] = useState('');

    const [verificationStatus, setVerificationStatus] = useState(true);

    const [viewPDF, setViewPDF] = useState(false);



    useEffect(() => {

        setStatusTemporario(statusPedido)
        setVerificationStatus(false)
    }, [verificationStatus]);





    const [descontoCurrent, setDescontoCurrent] = useState(0);
    const [nameOrcamentoCurrent, setNameOrcamentoCurrent] = useState('');
    const [cityOrcamentoCurrent, setCityOrcamentoCurrent] = useState('');
    const [estadoOrcamentoCurrent, setEstadoOrcamentoCurrent] = useState('');
    const [foneOrcamentoCurrent, setFoneOrcamentoCurrent] = useState('');
    const [emailOrcamentoCurrent, setEmailOrcamentoCurrent] = useState('');
    const [statusPedidoCurrent, setStatusPedidoCurrent] = useState('Solicitado');
    const [listaProdutosAdicionadosCurrent, setListaProdutosAdicionadosCurrent] = useState([]);
    const [observacoesCurrent, setObservacoesCurrent] = useState('');
    const [obraCurrent, setObraCurrent] = useState('');
    const [freteCurrent, setFreteCurrent] = useState('');
    const [icmsCurrent, setICMSCurrent] = useState('');
    const [pagamentoCurrent, setPagamentoCurrent] = useState('');


    useEffect(() => {
        setUnsavedChanges(true)
    }, [
        desconto,
        nameOrcamento,
        cityOrcamento,
        estadoOrcamento,
        foneOrcamento,
        emailOrcamento,
        statusPedido,
        listaProdutosAdicionados,
        observacoes,
        obra,
        frete,
        icms,
        pagamento,
        valorFrete
    ]);




    useEffect(() => {
        setIdOrcamento(props.orcamentoData.id)
        setOrcamento(props.orcamentoData)
        setNameOrcamento(props.orcamentoData.name)
        setCityOrcamento(props.orcamentoData.city)
        setEstadoOrcamento(props.orcamentoData.estado)
        setFoneOrcamento(props.orcamentoData.fone)
        setEmailOrcamento(props.orcamentoData.email)
        setStatusPedido(props.orcamentoData.status ? props.orcamentoData.status : '')
        setDocumentOrcamento(props.orcamentoData.document)
        setDesconto(props.orcamentoData.desconto ? props.orcamentoData.desconto : 0)
        setListaProdutosAdicionados(props.orcamentoData.lista_produtos ? props.orcamentoData.lista_produtos : [])
        //setValorTotal(props.orcamentoData.valor_total)
        setDateCreate(props.orcamentoData.date_create)
        setObservacoes(props.orcamentoData.observacoes)
        setObra(props.orcamentoData.obra)

        setPagamento(props.orcamentoData.pagamento)
        setFrete(props.orcamentoData.frete)
        setICMS(props.orcamentoData.icms)
        setValorFrete(props.orcamentoData.valor_frete)
        setDateRecalculo(props.orcamentoData.date_recalculo)

    }, [props]);

    const addProduct = (codigo, quantidade) => {

        const productInfo = getProductForId(codigo);

        setUnsavedChanges(true);

        const newProduct = {
            codigo: codigo,
            quantidade: quantidade,
            valor: productInfo.valor,
            peso: productInfo.peso,
            ipi: productInfo.ipi,
        };

        setListaProdutosAdicionados([...listaProdutosAdicionados, newProduct]);

        console.log(...listaProdutosAdicionados, newProduct)

        setOpenModalAddProduct(false);

    };

    useEffect(() => {
        recalcularValorTotal(desconto);
        recalcularPesoTotal();
    }, [listaProdutosAdicionados, desconto, valorFrete, quantidade, viewPDF])


    const recalcularPesoTotal = () => {

        const total = listaProdutosAdicionados.reduce((acc, produto) => acc + (produto.peso * produto.quantidade), 0);

        setPesoTotal(parseFloat(total));
        console.log('peso total');
        console.log(total);

    };


    const recalcularValorTotal = (novoDesconto) => {
        console.log('recalcularValorTotal');

        const total = listaProdutosAdicionados.reduce((acc, produto) => {
            const valorComIpi = parseFloat(produto.valor) + parseFloat(produto.valor * produto.ipi / 100);
            return acc + (valorComIpi * produto.quantidade);
        }, 0);

        // Calcular o desconto em reais
        const descontoEmReais = (total * novoDesconto) / 100;

        const valorFreteAtual = parseFloat(valorFrete ? valorFrete : 0);

        // Calcular o valor total considerando desconto e frete
        const valorComDesconto = total - descontoEmReais + valorFreteAtual;

        setValorTotal(parseFloat(valorComDesconto));
        console.log(valorComDesconto);
    };

    const atualizarDesconto = (novoDesconto) => {
        setDesconto(novoDesconto);
        recalcularValorTotal(novoDesconto);
    };

    const [openModalAddProduct, setOpenModalAddProduct] = useState(false)

    const [openModalEditProduct, setOpenModalEditProduct] = useState(false)

    useEffect(() => {
        getProducts();
    }, [idEmpresa])

    const [listaProdutos, setListaProdutos] = useState([]);

    const getProducts = async () => {

        try {

            const url = `${apiUrl}/produtos/getProducts`;

            const params = {
                empresa: idEmpresa
            };

            const response = await axios.get(url, { params });
            const products = response.data;


            if (products && products.length > 0) {
                setListaProdutos(products);
            }

        } catch (error) {
            console.error(error);
        }
    };

    const [buscar, setBuscar] = useState('');

    function getProductForId(idProduto) {

        return listaProdutos.find((item) => item.codigo === idProduto);
    }

    // IBGE
    async function getEstados() {
        try {
            const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const estados = response.data;
            setListaEstadosIBGE(estados);
            return estados;
        } catch (error) {
            console.error('Erro ao obter a lista de estados:', error);
            return [];
        }
    }

    const [listaEstadosIBGE, setListaEstadosIBGE] = useState([]);

    const [selectedEstadoIBGE, setSelectedEstadoIBGE] = useState(false);
    function openCloseSelectEstadoIBGE() {
        getEstados();
        setSelectedEstadoIBGE(!selectedEstadoIBGE);
    }

    function selectEstado(id, sigla) {
        setEstadoOrcamento(sigla);
        openCloseSelectEstadoIBGE();
        setSelectedCidadeIBGE(true);
        getCidades(id);
        setEstadoFiltro(sigla);
        irPraInputCidade();
    }

    const [selectedCidadeIBGE, setSelectedCidadeIBGE] = useState(false);

    const [listaCidadesIBGE, setListaCidadesIBGE] = useState([]);

    async function getCidades(estadoId) {
        try {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`);
            const cidades = response.data;
            setListaCidadesIBGE(cidades);
            return cidades;
        } catch (error) {
            console.error('Erro ao obter a lista de cidades:', error);
            return [];
        }
    }

    function selectCidade(id, nome) {
        setCityOrcamento(nome);
        setSelectedCidadeIBGE(false);
        setCidadeFiltro(nome);
    }

    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [cidadeFiltro, setCidadeFiltro] = useState('');
    const input2Ref = useRef(null);

    const irPraInputCidade = () => {
        input2Ref.current.focus();
    };

    const updateOrcamento = async () => {

        setUnsavedChanges(false);

        console.log(idOrcamento)
        try {

            const updatedData = {
                name: nameOrcamento,
                email: emailOrcamento,
                fone: foneOrcamento,
                city: cityOrcamento,
                estado: estadoOrcamento,
                desconto: desconto,
                status: statusPedido,
                lista_produtos: listaProdutosAdicionados,
                valor_total: valorTotal,
                observacoes: observacoes,
                obra: obra,
                pagamento: pagamento,
                frete: frete,
                icms: icms,
                valor_frete: valorFrete,
                date_recalculo: obterDataEHoraAtual(),
            };

            await axios.put(`${apiUrl}/orcamentos/` + idOrcamento, updatedData);

            setStatusTemporario(statusPedido);

            setDateRecalculo(obterDataEHoraAtual);

        } catch (error) {

            console.error(error);

        };

    }

    function selectStatusPedido(estatus) {
        setStatusPedido(estatus)
    }

    function formatarData(data) {
        const date = moment(data);
        return date.format('DD/MM/YYYY');
    }

    const [produtoSelecionado, setProdutoSelecionado] = useState('');
    const [quantidadeSelecionada, setQuantidadeSelecionada] = useState(0);
    const [curretIndex, setCurretIndex] = useState(0);

    const openEditProductModal = (index, codigo, quantidade) => {

        console.log(codigo)
        console.log(quantidade)
        setCurretIndex(index)
        setProdutoSelecionado(codigo);
        setQuantidadeSelecionada(quantidade);
        setOpenModalEditProduct(true);
    };


    function getProductForId(idProduto) {

        return listaProdutos.find((item) => item.codigo === idProduto);
    }

    const recalcularListaProdutos = () => {

        const isConfirmed = window.confirm('Atualizar o valor dos produtos e recalcular???');

        if (isConfirmed) {

            setDateRecalculo(obterDataEHoraAtual);

            const produtosAtualizados = listaProdutosAdicionados.map((produto) => {
                return {
                    ...produto,
                    valor: getProductForId(produto.codigo).valor,
                    peso: getProductForId(produto.codigo).peso,
                    ipi: getProductForId(produto.codigo).ipi,
                };
            });

            setListaProdutosAdicionados(produtosAtualizados);
        }

    };

    const alterarProduto = () => {
        const produtosAtualizados = listaProdutosAdicionados.map((produto, index) => {

            const currentProduct = getProductForId(produto.codigo)

            if (index === curretIndex) { // Correção aqui, compare com curretIndex
                return {
                    ...produto,
                    codigo: produtoSelecionado,
                    quantidade: quantidadeSelecionada,
                    valor: currentProduct.valor,
                    peso: currentProduct.peso,
                    ipi: currentProduct.ipi,
                };
            }
            return produto;
        });

        setListaProdutosAdicionados(produtosAtualizados);
        setOpenModalEditProduct(false);
    };

    function deleteProduto() {
        const produtosAtualizados = [...listaProdutosAdicionados];
        produtosAtualizados.splice(curretIndex, 1);
        setListaProdutosAdicionados(produtosAtualizados);
        setOpenModalEditProduct(false);
    }




    function closeOrcamento() {

        if (!unsavedChanges) {
            setOpenOrcamento(false)
        } else {
            const isConfirmed = window.confirm('Você tem alterações não salvas. Tem certeza de que deseja sair?');

            if (isConfirmed) {
                // Lógica a ser executada se o usuário confirmar
                setOpenOrcamento(false)
                console.log('Ação confirmada!');
            } else {
                // Lógica a ser executada se o usuário cancelar
                console.log('Ação cancelada!');
            }
        }
    }

    function getInformacoesUser() {

        setNameOrcamento(nameUser);
        setCityOrcamento(cityUser);
        setEstadoOrcamento(estadoUser);
        setFoneOrcamento(foneUser);
        setEmailOrcamento(emailUser);

    }

    const [openCloseHeaderOrcamento, setOpenCloseHeaderOrcamento] = useState(true);

    const MAX_ROWS = 3;

    const handleChange = (e) => {

        setUnsavedChanges(true);


        const lines = e.target.value.split('\n').length;
        if (lines <= MAX_ROWS) {
            setObservacoes(e.target.value);
        }
    };

    const exportPDF = () => {

        console.log('Iniciando exportação para PDF...');

        const pdfContainer = document.querySelector('.container-pdf');

        if (!pdfContainer) {
            console.error('Elemento .container-pdf não encontrado.');
            return;
        }

        const options = {
            margin: [5, 5, 8, 5],
            filename: 'orcamento.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        setTimeout(() => {
            try {
                html2pdf().from(pdfContainer).set(options).save();
                console.log('PDF gerado com sucesso');
            } catch (error) {
                console.error('Erro ao gerar PDF:', error);
            }
        }, 1000); // Ajuste o tempo conforme necessário
    };

    function obterDataEHoraAtual() {
        const dataAtual = new Date();

        const formatoData = new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
        });

        const dataFormatada = formatoData.format(dataAtual);

        const formatoHora = new Intl.DateTimeFormat('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit', // Adicionando a formatação para os segundos
        });

        const horaFormatada = formatoHora.format(dataAtual);

        const dataEHoraFormatadas = `${dataFormatada} - ${horaFormatada}`;

        return dataEHoraFormatadas;
    }

    const criarOportunidadeCRM = async () => {

        const isConfirmed = window.confirm('Criar Oportunidade dentro do CRM ???');

        if (isConfirmed) {

            const columnFinalizados = listaAllProcessos.find(item => item.title === 'Import'); // Use "find" em vez de "filter"
            const idColumnFinalizados = columnFinalizados.id;

            const dataAtual = new Date();
            const primeiraData = new Date(dataAtual.getTime() + 1 * 24 * 60 * 60 * 1000);
            const segundaData = new Date(dataAtual.getTime() + 2 * 24 * 60 * 60 * 1000);
            const terceiraData = new Date(dataAtual.getTime() + 15 * 24 * 60 * 60 * 1000);

            try {

                const valorFormatado = valorTotal ? parseFloat(valorTotal).toFixed(2) : '0.00';

                const cardData = {
                    document_card: documentOrcamento ? documentOrcamento : '',
                    name: nameOrcamento ? nameOrcamento : '',
                    name_obra: obra ? obra : '',
                    valor: valorFormatado,
                    email: emailOrcamento ? emailOrcamento : '',
                    fone: foneOrcamento ? foneOrcamento : '',
                    city: cityOrcamento ? cityOrcamento : '',
                    estado: estadoOrcamento ? estadoOrcamento : '',
                    previsao: '',
                    meio_contato: '',
                    create_by: nameUser,
                    id_create_by: idUser,
                    name_user: nameUser,
                    id_column: idColumnFinalizados,
                    date: new Date(),
                    nivel: 2,
                    etiqueta: '',
                    empresa: idEmpresa,
                    motivo_perca: '',
                    modification_date: new Date(),
                    produto: '',
                    status: '',
                    lista_tarefas: [
                        { id: uuidv4(), title: 'Enviar proposta ao cliente', date: format(primeiraData, 'dd/MM/yyyy'), status: false, edit: false },
                        { id: uuidv4(), title: 'Primeiro contato pós envio da proposta', date: format(segundaData, 'dd/MM/yyyy'), status: false, edit: false },
                        { id: uuidv4(), title: 'Segundo contato pós envio da proposta', date: format(terceiraData, 'dd/MM/yyyy'), status: false, edit: false }
                      ],
                    lista_historico: [{ id: uuidv4(), title: `Importado ( ${nameUser} )`, date: format(new Date(), 'dd/MM/yyyy'), edit: false }],
                };

                const response = await axios.post(`${apiUrl}/cards`, cardData);

            } catch (error) {

                console.error(`Falha ao criar Oportunidade`, error);

            }

        }

    };

    async function deletOrcamento() {

        const isConfirmed = window.confirm('Você confirma a Exclusão do orçamento ???');

        if (isConfirmed) {

            try {

                await axios.delete(`${apiUrl}/orcamentos/${idOrcamento}`);

                setOpenOrcamento(false)

            } catch (error) {
                console.error('Erro ao excluir card:', error);
            }

        }

    }

    const exportarExcel = () => {

        const data = [
            ["item do projeto", "código", "descrição produto", "descrição variante", "linha", "grupo", "subgrupo", "peso", "quantidade", "unidade", "valor"],


            ...listaProdutosAdicionados.map(item => ['VENDA', getProductForId(item.codigo).codigo_ext, getProductForId(item.codigo).name, getProductForId(item.codigo).variante, '', '', '', getProductForId(item.codigo).peso / 1000, item.quantidade, item.unidade, ( item.valor * item.quantidade ) - ( ( ( item.valor * item.quantidade ) * desconto ) / 100 ) ]),

        ];

        // Adiciona uma linha de totais
        const totalPeso = listaProdutosAdicionados.reduce((total, item) => total + parseFloat(item.peso), 0);
        const totalValor = listaProdutosAdicionados.reduce((total, item) => total + parseFloat(item.valor) * parseFloat(item.quantidade), 0);
        const totalRow = ["TOTAIS", "", "", "", "", "", "", totalPeso / 1000, "", "", totalValor - ( ( totalValor * desconto ) / 100 )];
        data.push(totalRow);

        // Criar uma nova planilha
        const ws = XLSX.utils.aoa_to_sheet(data);

        // Criar um novo livro de trabalho contendo a planilha
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Exported Data');

        // Salvar o arquivo
        XLSX.writeFile(wb, 'Modelo Pedido de Venda sem Níveis.xlsx');
    };




    return (

        <div className='modal-edit-orcamento-container'>

            <button className='btn-delete-orcamento' onClick={() => deletOrcamento()}><MdDeleteForever /></button>


            {viewPDF &&

                <div className='modal-pdf-orcamento'>

                    <button className='close-pdf-orcamento' onClick={() => setViewPDF(false)}>X</button>
                    <button className='btn-pdf-orcamento' onClick={() => exportPDF()}><MdPictureAsPdf /></button>

                    <div className='container-pdf'>

                        <div className='header-container-pdf-orcamento'>

                            {
                                imageUrl != '' && (
                                    imageUrl && <img src={imageUrl} alt="Parceiro" className='avatar-storage-orcamento' />
                                )
                            }


                            {
                                imageUrl == '' && (
                                    <img src={LogoParceiro} alt="Parceiro" className='avatar-storage-orcamento' />
                                )
                            }

                            <div className='title-pdf-orcamento'>Proposta Comercial</div>
                            <div className='document-pdf-orcamento'>

                                <label>No: {documentOrcamento}</label>
                                <label className='info-date-version-orcamento'>{obterDataEHoraAtual()}</label>
                            </div>

                        </div>

                        <div className='info-container-pdf-orcamento'>

                            <div className='left-infos-orcamento-container'>
                                <label className='title-info-orcamento'>Nome:<label className='info-value-title-orcamento'>{nameOrcamento}</label></label>
                                <label className='title-info-orcamento'>Obra:<label className='info-value-title-orcamento'>{obra}</label></label>
                                <label className='title-info-orcamento'>Endereço:<label className='info-value-title-orcamento'>{cityOrcamento} / {estadoOrcamento}</label></label>
                                <label className='title-info-orcamento'>Fone:<label className='info-value-title-orcamento'>{foneOrcamento}</label></label>
                                <label className='title-info-orcamento'>Email:<label className='info-value-title-orcamento'>{emailOrcamento}</label></label>

                            </div>

                            <div className='right-infos-orcamento-container'>
                                <label className='title-info-orcamento'>Pagamento:<label className='info-value-title-orcamento'>{pagamento}</label></label>
                                <label className='title-info-orcamento'>Frete:<label className='info-value-title-orcamento'>{frete}</label></label>
                                <label className='title-info-orcamento'>ICMS:<label className='info-value-title-orcamento'>{icms}</label></label>
                                <label className='title-info-orcamento'>Desconto:<label className='info-value-title-orcamento'>{desconto}</label></label>
                                <label className='title-info-orcamento'>Data:<label className='info-value-title-orcamento'>{formatarData(dateCreate)}</label></label>
                            </div>
                        </div>

                        <div className='obs-container-pdf-orcamento'>

                            <label className='titel-observacoes-pdf-orcamento'>Observações</label>
                            <div className='descricao-observacoes-pdf-orcamento'>{observacoes}</div>

                        </div>

                        <div className='valores-container-pdf-orcamento-frete'>
                            <label className='valor-total-pdf-orcamento-frete'>Valor do Frete Incluso no Total: {parseFloat(valorFrete ? valorFrete : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                        </div>

                        <div className='valores-container-pdf-orcamento'>
                            <label className='peso-total-pdf-orcamento'>Peso Total: {pesoTotal / 1000} kg</label>
                            <label className='valor-total-pdf-orcamento'>Valor Total: {parseFloat(valorTotal ? valorTotal : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
                        </div>


                        <table className="orcamento-table-pdf" >

                            <thead>
                                <tr>
                                    <th className='header-table-orcamento-pdf'>Código</th>
                                    <th className='header-table-orcamento-pdf'>Nome do Produto</th>
                                    <th className='header-table-orcamento-pdf'>Cor</th>
                                    <th className='header-table-orcamento-pdf'>Q.</th>
                                    <th className='header-table-orcamento-pdf'>Und</th>
                                    <th className='header-table-orcamento-pdf'>Tamanho</th>
                                    <th className='header-table-orcamento-pdf'>Valor Und</th>
                                    <th className='header-table-orcamento-pdf'>IPI</th>
                                    <th className='header-table-orcamento-pdf'>Valor Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listaProdutosAdicionados.map((item, index) => {
                                    const productInfo = getProductForId(item.codigo);
                                    return (
                                        <tr key={item.codigo} className='tabela-lista-orcamento-pdf'>
                                            <td className='item-lista-orcamento-pdf'>{item.codigo}</td>
                                            <td className='item-lista-orcamento-pdf'>{productInfo ? productInfo.name : 'Produto não encontrado'}</td>
                                            <td style={{ fontSize: '8px' }} className='item-lista-orcamento-pdf'>{productInfo ? productInfo.cor : 'Produto não encontrado'}</td>
                                            <td style={{ backgroundColor: 'rgb(138, 138, 138)', color: 'white' }} className='item-lista-orcamento-pdf'>{item.quantidade}</td>
                                            <td className='item-lista-orcamento-pdf'>{productInfo ? productInfo.unidade_medida : 'Produto não encontrado'}</td>
                                            <td className='item-lista-orcamento-pdf'>{productInfo ? productInfo.tamanho : 'Produto não encontrado'}</td>
                                            <td className='item-lista-orcamento-pdf'>{parseFloat(item.valor ? item.valor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            <td className='item-lista-orcamento-pdf'>{item.ipi}</td>
                                            <td style={{ backgroundColor: 'rgb(138, 138, 138)', color: 'white' }} className='item-lista-orcamento-pdf'>
                                                {parseFloat(productInfo ? (item.quantidade * (parseFloat(productInfo.valor) + ((parseFloat(productInfo.valor) * parseFloat(productInfo.ipi)) / 100))) : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>


                    </div>


                </div>

            }


            {openModalEditProduct &&
                <div className='select-product-container'>
                    <div className='busca-produto-container'>
                        <input className='input-novo-roduto' type='text' value={buscar} onChange={(e) => setBuscar(e.target.value)} placeholder={'Buscar por Código ou Nome...'}></input>
                    </div>
                    <div className='body-select-produto'>
                        {listaProdutos
                            .filter((item) => {
                                const lowercaseBuscar = buscar.toLowerCase();
                                return (
                                    item.name.toLowerCase().includes(lowercaseBuscar) ||
                                    (item.codigo && item.codigo.toLowerCase().includes(lowercaseBuscar))
                                );
                            })
                            .map((item) => (
                                <li
                                    style={{ backgroundColor: item.codigo == produtoSelecionado ? 'deeppink' : '' }}
                                    key={item.id}
                                    className='item-lista-produtos-select'
                                    onClick={() => setProdutoSelecionado(item.codigo)}
                                >
                                    <label className='label-item-select-product-codigo'>{item.codigo}</label>
                                    <label className='label-item-select-product'>{item.name}</label>
                                    <label className='label-item-select-product'>{item.cor}</label>

                                </li>
                            ))}

                    </div>

                    <div className='busca-produto-container'>
                        <input
                            className='input-novo-roduto'
                            type='number'
                            step='0.01'
                            value={quantidadeSelecionada}
                            onChange={(e) => setQuantidadeSelecionada(parseFloat(e.target.value))}
                            placeholder={'Quantidade'}>
                        </input>
                    </div>

                    <div className='btns-select-produtos-container'>
                        <button className='delet-product-lista' onClick={() => deleteProduto()}>Excluir</button>
                        <button className='cancel-product-lista' onClick={() => setOpenModalEditProduct(false)}>Cancelar</button>
                        <button className='add-product-lista' onClick={() => {
                            alterarProduto();
                            setOpenModalEditProduct(false);
                        }}>Alterar</button>
                    </div>

                </div>

            }

            <button className='btn-add-product' onClick={() => setOpenModalAddProduct(true)}>+</button>

            {openCloseHeaderOrcamento &&

                <div className='header-orcamento-header'>

                    <div className='dados-orcamento-container'>

                        <div className='create-orcamento-body'>
                            <label className='title'>Nome do cliente - Obrigatório</label>
                            <div className='container-name-cliente-infos-iser'>

                                <input className='input-orcamento-card' value={nameOrcamento} type='text' onChange={(e) => setNameOrcamento(e.target.value)} placeholder={'Digite o nome do cliente...'}></input>
                                <button className='icon-user-orcamento' onClick={() => getInformacoesUser()}><MdPerson /></button>

                            </div>

                            <label className='title'>Obra</label>
                            <input className='input-orcamento-card' value={obra} type='text' onChange={(e) => setObra(e.target.value)} placeholder={'Digite o nome do obra...'}></input>

                            <label className='title'>Email</label>
                            <input className='input-orcamento-card' value={emailOrcamento} type='email' onChange={(e) => setEmailOrcamento(e.target.value)} placeholder={'Digite o Email...'}></input>

                            <label className='title'>Fone</label>
                            <input className='input-orcamento-card' value={foneOrcamento} type='tel' onChange={(e) => setFoneOrcamento(e.target.value)} placeholder={'Digite o telefone...'}></input>

                            <label className='title'>Estado</label>
                            <input className='input-orcamento-card' type='text' value={estadoFiltro ?? estadoOrcamento} onChange={(e) => setEstadoFiltro(e.target.value)} onClick={openCloseSelectEstadoIBGE} placeholder={estadoOrcamento || 'Selecione o estado...'}></input>

                            {selectedEstadoIBGE ? (
                                <div className='card-select'>
                                    {
                                        listaEstadosIBGE
                                            .filter((estado) => estadoFiltro ? estado.sigla.toLowerCase().includes(estadoFiltro.toLowerCase()) : true)
                                            .sort((a, b) => a.sigla.localeCompare(b.sigla)) // Ordena em ordem alfabética por sigla
                                            .map((estado) => <li key={estado.id} className='item-list-select' onClick={() => selectEstado(estado.id, estado.sigla)}>{estado.sigla}</li>)

                                    }
                                </div>
                            ) : <></>}

                            <label className='title'>Cidade</label>
                            <input ref={input2Ref} className='input-orcamento-card' type='text' value={cidadeFiltro ?? cityOrcamento} onChange={(e) => setCidadeFiltro(e.target.value)} placeholder={cityOrcamento || 'Selecione a cidade...'}></input>

                            {selectedCidadeIBGE ? (
                                <div className='card-select'>
                                    {
                                        listaCidadesIBGE
                                            .filter((cidade) => cidadeFiltro ? cidade.nome.toLowerCase().includes(cidadeFiltro.toLowerCase()) : true)
                                            .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena em ordem alfabética por nome
                                            .map((cidade) => <li key={cidade.id} className='item-list-select' onClick={() => selectCidade(cidade.id, cidade.nome)}>{cidade.nome}</li>)
                                    }
                                </div>
                            ) : <></>}

                        </div>

                        <div className='create-orcamento-body'>

                            <label className='title'>Pagamento</label>
                            <input className='input-orcamento-card' value={pagamento} type='text' onChange={(e) => setPagamento(e.target.value)} placeholder={'Digite a forma de pagamento...'}></input>

                            <label className='title'>Frete</label>
                            <input className='input-orcamento-card' value={frete} type='text' onChange={(e) => setFrete(e.target.value)} placeholder={'Digite o tipo de Frete...'}></input>

                            <label className='title'>Valor do Frete</label>
                            <input
                                className='input-orcamento-card'
                                type='number'
                                step='0.01' // Define a precisão do valor decimal
                                value={valorFrete}
                                onChange={(e) => setValorFrete(parseFloat(e.target.value))}
                                placeholder={'Valor de Frete...'}>
                            </input>

                            <label className='title'>ICMS</label>
                            <input className='input-orcamento-card' value={icms} type='text' onChange={(e) => setICMS(e.target.value)} placeholder={'Digite o ICMS...'}></input>

                            {true ? (
                                <label className='title'>Desconto %</label>
                            ) : <></>}

                            <input
                                className='input-orcamento-card'
                                type='number'
                                step='0,01'
                                value={desconto}
                                onChange={(e) => atualizarDesconto(parseFloat(e.target.value))}
                                placeholder={'Desconto em percentual...'}>
                            </input>

                            <label className='title'>Observações</label>
                            <textarea rows={MAX_ROWS} id="observacoes" name="observacoes" spellcheck="false" className='input-orcamento-obs-textarea' value={observacoes} type='text' onChange={handleChange} placeholder={'Digite as observações...'}></textarea >

                        </div>

                    </div>

                </div>

            }

            <div className='ferramentas-container'>

                <button className='btn-ocultar-cabecalho' onClick={() => setOpenCloseHeaderOrcamento(!openCloseHeaderOrcamento)}> {openCloseHeaderOrcamento ? 'Esconder Cabeçalho' : 'Mostrar Cabeçalho'}</button>

                <button style={{ display: modulosUser == '110' ? '' : 'none' }} className='icon-pedido-orcamento' onClick={() => criarOportunidadeCRM()}><MdAddCard /></button>

                <label className='recalculo-orcamento-label'>Alteração: {dateRecalculo ? dateRecalculo : ''}</label>
                <button style={{ color: statusPedido == '' ? 'deeppink' : '' }} className='icon-pedido-orcamento' onClick={() => selectStatusPedido('')}><MdEditSquare /></button>
                <button style={{ color: statusPedido == 'Solicitado' ? 'deeppink' : '' }} className='icon-pedido-orcamento' onClick={() => selectStatusPedido('Solicitado')}><MdAssignmentAdd /></button>
                <button disabled={tipoParticipante != 'Administrador'} style={{ color: statusPedido == 'Aceito' ? 'deeppink' : '' }} className='icon-pedido-orcamento' onClick={() => selectStatusPedido('Aceito')}><MdAssignmentTurnedIn /></button>
                <button className='icon-export-excel-orcamento' onClick={() => exportarExcel()}><RiFileExcel2Fill /></button>
                <button className='icon-export-pdf-orcamento' onClick={() => setViewPDF(true)}><MdPictureAsPdf /></button>
                <label className='numero-orcamento-label'>N: {documentOrcamento}</label>
                {dateCreate && <label className='date-orcamento-label'>{formatarData(dateCreate)}</label>}

                <button className='icon-save-orcamento' onClick={() => recalcularListaProdutos()}><MdCurrencyExchange /></button>


                <label className='valor-total-produtos-orcamento'>Valor Total: {valorTotal ? valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : 0}</label>
                <label className='peso-total-produtos-orcamento'>Peso Total: {pesoTotal / 1000} kg</label>
                <button disabled={(statusTemporario == 'Solicitado' || statusTemporario == 'Aceito') && tipoParticipante != 'Administrador'} style={{ color: unsavedChanges ? 'deeppink' : '' }} className='icon-save-orcamento' onClick={() => updateOrcamento()}><MdSave /></button>
                <button className='icon-save-orcamento' onClick={() => closeOrcamento()}><MdOutlineExitToApp /></button>


            </div>

            <div className='body-edit-orcamento'>

                {listaProdutosAdicionados.length > 0 && (
                    <table className="product-table" >
                        <thead>
                            <tr>
                                <th className='header-table-orcamento'></th>
                                <th className='header-table-orcamento'>Código</th>
                                <th className='header-table-orcamento'>Nome do Produto</th>
                                <th className='header-table-orcamento'>Cor</th>
                                <th className='header-table-orcamento'>Quantidade</th>
                                <th className='header-table-orcamento'>Unidade</th>
                                <th className='header-table-orcamento'>Tamanho</th>
                                <th className='header-table-orcamento'>Peso</th>
                                <th className='header-table-orcamento'>Valor Unitário</th>
                                <th className='header-table-orcamento'>IPI</th>
                                <th className='header-table-orcamento'>Valor Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listaProdutosAdicionados.map((item, index) => {
                                const productInfo = getProductForId(item.codigo);
                                return (
                                    <tr key={item.codigo} className='tabela-lista-orcamento' onClick={() => openEditProductModal(index, item.codigo, item.quantidade)}>
                                        <td className='item-lista-orcamento'>{index}</td>
                                        <td className='item-lista-orcamento'>{item.codigo}</td>
                                        <td className='item-lista-orcamento'>{productInfo ? productInfo.name : 'Produto não encontrado'}</td>
                                        <td className='item-lista-orcamento'>{productInfo ? productInfo.cor : 'Produto não encontrado'}</td>
                                        <td className='item-lista-orcamento'>{item.quantidade}</td>
                                        <td className='item-lista-orcamento'>{productInfo ? productInfo.unidade_medida : 'Produto não encontrado'}</td>
                                        <td className='item-lista-orcamento'>{productInfo ? productInfo.tamanho : 'Produto não encontrado'}</td>
                                        <td className='item-lista-orcamento'>{productInfo ? productInfo.peso : 0} g</td>
                                        <td className='item-lista-orcamento'>{parseFloat(item.valor ? item.valor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                        <td className='item-lista-orcamento'>{item.ipi}</td>
                                        <td className='item-lista-orcamento'>
                                            {parseFloat(productInfo ? (item.quantidade * (parseFloat(productInfo.valor) + ((parseFloat(productInfo.valor) * parseFloat(productInfo.ipi)) / 100))) : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}

            </div>

            {openModalAddProduct &&
                <div className='select-product-container'>
                    <div className='busca-produto-container'>
                        <input className='input-novo-roduto' type='text' value={buscar} onChange={(e) => setBuscar(e.target.value)} placeholder={'Buscar por Código ou Nome...'}></input>
                    </div>
                    <div className='body-select-produto'>
                        {listaProdutos
                            .filter((item) => {
                                const lowercaseBuscar = buscar.toLowerCase();
                                return (
                                    item.name.toLowerCase().includes(lowercaseBuscar) ||
                                    (item.codigo && item.codigo.toLowerCase().includes(lowercaseBuscar))
                                );
                            })
                            .map((item) => (
                                <li style={{ backgroundColor: item.codigo == produto ? 'deeppink' : '' }} key={item.id} className='item-lista-produtos-select' onClick={() => setProduto(item.codigo)}>

                                    <label className='label-item-select-product-codigo'>{item.codigo}</label>
                                    <label className='label-item-select-product'>{item.name}</label>
                                    <label className='label-item-select-product'>{item.cor}</label>

                                </li>
                            ))
                        }
                    </div>

                    <div className='busca-produto-container'>
                        <input
                            className='input-novo-roduto'
                            type='number'
                            step='0.01'
                            value={quantidade}
                            onChange={(e) => setQuantidade(parseFloat(e.target.value))}
                            placeholder={'Quantidade'}>
                        </input>
                    </div>

                    <div className='btns-select-produtos-container'>
                        <button className='cancel-product-lista' onClick={() => setOpenModalAddProduct(false)}>Cancelar</button>
                        <button className='add-product-lista' onClick={() => addProduct(produto, quantidade)}>Adicionar</button>
                    </div>

                </div>

            }



        </div>


    );
}

export default EditOrcamento;