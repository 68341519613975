import React, { useEffect, useState, useContext } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { CardContext } from '../../contexts/CardContext';
import { UserContext } from '../../contexts/UserContext';

import faktory from '../../assets/logo-faktory-100x100.png';


import moment from 'moment';

import { MdAnchor, MdError, MdEditSquare, MdFileCopy, MdWindow, MdBuild, MdLocalShipping, MdRoom, MdShoppingCart, MdThumbDown, MdRestoreFromTrash, MdThumbUp, MdAddCard, MdLoyalty, MdShare, MdHome, MdAccountBox, MdAccessAlarms, MdAlternateEmail, MdGrade, MdPhone, MdAccountBalance, MdArticle } from "react-icons/md";

import { Tooltip } from 'react-tooltip'; // Mostra mensages ao colocar o mouse sobre os botoes com title

// drag and drop
function Card(props) {

  const [star, setStar] = useState();

  const { openCloseEditCardModal, listaFilteredCards, openCloseMenuFilter } = useContext(BoardContext);

  const { listaProcessos } = useContext(UserContext);

  const {
    setIdCard,
    setIdColumnCard,
    listaCards,
    setDocumentCard,
    setNameCard,
    setNameObraCard,
    setValorCard,
    setValorVendaCard,
    setCityCard,
    setEstadoCard,
    setFoneCard,
    setEmailCard,
    setPrevisaoCard,
    setMeioContatoCard,
    setDate,
    setNivel,
    setEtiquetaCard,
    setModificationDateCard,
    setListaTarefasCard,
    setListaHistoricoCard,
    setProdutoCard,
    setStatusCard,
    setIdCreateBy,
    setCreateBy,
    setNameObra,
    setNameContato,
    setMotivoVendaPerdida,
    setFileLinksCard,
    setPrevisaoEntregaCard,
    setHorasProducaoCard,
    setPrevisaoInstalacaoCard,
    setPrevisaoAssistenciaCard,
    setEtapaProducaoCard,
    setListaCompartilhar,
    setCorCard,
    setEditDateCard,
    prioridade, setPrioridade,
    setPrevisaoProducaoCard,

    recebimentoMedidasCard, setRecebimentoMedidasCard,
    prazoEntregaCard, setPrazoEntregaCard,
    numeroPedidoCard, setNumeroPedidoCard,
    quadrosCard, setQuadrosCard,
    metrosQuadradosCard, setMetrosQuadradosCard,
    quantidadeEsquadriasCard, setQuantidadeEsquadriasCard,
    setEntregaVidroCard,
    setStatusVidroCard,
    setObsCard,
    idColuna,
    setConclusaoProducaoCard
  } = useContext(CardContext);

  function getIdCard() {
    setIdCard(props.cardData.id);
    setIdColumnCard(props.cardData.id_column);
    setDocumentCard(props.cardData.document_card);
    setNameCard(props.cardData.name);
    setNameObraCard(props.cardData.name_obra);
    setValorCard(props.cardData.valor);
    setValorVendaCard(props.cardData.valor_venda);
    setCityCard(props.cardData.city);
    setEstadoCard(props.cardData.estado);
    setFoneCard(props.cardData.fone);
    setEmailCard(props.cardData.email);
    setPrevisaoCard(props.cardData.previsao);
    setMeioContatoCard(props.cardData.meio_contato);
    setDate(props.cardData.date);
    setNivel(props.cardData.nivel);
    setEtiquetaCard(props.cardData.etiqueta);
    setModificationDateCard(props.cardData.modification_date);
    setListaTarefasCard(props.cardData.lista_tarefas);
    setProdutoCard(props.cardData.produto);
    setListaHistoricoCard(props.cardData.lista_historico);
    setStatusCard(props.cardData.status);
    setIdCreateBy(props.cardData.id_create_by);
    setCreateBy(props.cardData.create_by);
    setNameObra(props.cardData.name_obra_cliente);
    setNameContato(props.cardData.name_contato);
    setMotivoVendaPerdida(props.cardData.motivo_perca);
    setFileLinksCard(props.cardData.lista_anexos);
    setPrevisaoEntregaCard(props.cardData.previsao_entrega);
    setHorasProducaoCard(props.cardData.horas_producao);
    setPrevisaoInstalacaoCard(props.cardData.previsao_instalacao);
    setPrevisaoAssistenciaCard(props.cardData.previsao_assistencia);
    setEtapaProducaoCard(props.cardData.etapa_producao);
    setListaCompartilhar(props.cardData.lista_compartilhar);
    setCorCard(props.cardData.cor);
    setEditDateCard(props.cardData.edit_date);
    setPrioridade(props.cardData.prioridade);
    setPrevisaoProducaoCard(props.cardData.previsao_producao);

    setRecebimentoMedidasCard(props.cardData.recebimento_medidas);
    setPrazoEntregaCard(props.cardData.prazo_entrega);
    setNumeroPedidoCard(props.cardData.numero_pedido);
    setQuadrosCard(props.cardData.quadros);
    setMetrosQuadradosCard(props.cardData.metros_quadrados);
    setQuantidadeEsquadriasCard(props.cardData.quantidade_esquadrias);
    setEntregaVidroCard(props.cardData.entrega_vidro);
    setStatusVidroCard(props.cardData.status_vidro);
    setObsCard(props.cardData.obs);
    setConclusaoProducaoCard(props.cardData.conclusao_producao);

    openCloseEditCardModal();
  }

  useEffect(() => {
    setStar(props.cardData.nivel);
  }, [listaCards, listaFilteredCards])

  function formatarData(data) {
    const date = moment(data);
    return date.format('DD/MM/YYYY');
  }

  const [dayCounter, setDayCounter] = useState(0);

  // Função para calcular a diferença em dias entre duas datas
  function calculateDaysDifference(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // Um dia em milissegundos
    const diffInDays = Math.round((endDate - startDate) / oneDay);
    return diffInDays;
  }


  useEffect(() => {
    const modificationDate = new Date(props.cardData.edit_date);
    const currentDate = new Date();

    const daysDifference = calculateDaysDifference(modificationDate, currentDate);

    if (props.cardData.edit_date) {
      setDayCounter(daysDifference);
    } else {
      setDayCounter(0);
    }

  }, [props.cardData.edit_date]);

  function calculateBackgroundColor(days) {
    const minColor = [54, 197, 255]; // Cor inicial (azul)
    const maxColor = [255, 20, 147]; // Cor final (vermelho)

    // Garante que days esteja no intervalo [0, 20]
    const clampedDays = Math.min(30, Math.max(0, days));

    // Calcula a cor com base na interpolação linear entre minColor e maxColor
    const color = minColor.map((min, i) => {
      const max = maxColor[i];
      return Math.round(min + (max - min) * (clampedDays / 30));
    });

    // Retorna a cor no formato "rgb"
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  }



  // Calcula a cor de fundo com base em dayCounter
  const backgroundColor = calculateBackgroundColor(dayCounter);



  function getColorPrioridade(nivel) {

    let cor = '';

    if (nivel == 'Baixa') {
      cor = 'rgb(24, 138, 252)';
    }
    if (nivel == 'Média') {
      cor = '#ffc862';
    }
    if (nivel == 'Alta') {
      cor = 'deeppink';
    }

    return cor;
  }

  function confirmarEdicao(idColuna) {
    //console.log(idColuna)
    // Procurar o item na listaProcessos com o mesmo idColuna e verifica se a propriedade edit é igual a true.
    const processoComEditTrue = listaProcessos.find(item => item.id.toString() === idColuna && item.edit === true);

    //console.log(processoComEditTrue)

    return processoComEditTrue;
  }


  return (
    // <div title="Abrir Card" className='card-container' onClick={getIdCard} style={{ boxShadow: props.cardData.etapa_producao > 0 ? 'rgba(255, 1, 192, 0.452) 0px 4px 10px -2px, rgba(255, 0, 212, 0.349) 2px 5px 6px -3px' : 'rgba(1, 162, 255, 0.5) 0px 4px 10px -2px, rgba(0, 183, 255, 0.5) 2px 5px 6px -3px', background: confirmarEdicao(props.cardData.id_column) ? '' : 'rgba(255, 255, 255, 0.2)',  /*border: props.cardData.etapa_producao > 0 ? '1px solid deeppink' : '' */ }}>

    <div
      title="Abrir Card"
      className='card-container'
      onClick={getIdCard}
      style={{
        boxShadow: props.cardData.etapa_producao > 0 ?
          'rgba(255, 1, 192, 0.452) 0px 4px 10px -2px, rgba(255, 0, 212, 0.349) 2px 5px 6px -3px' :
          'rgba(1, 162, 255, 0.5) 0px 4px 10px -2px, rgba(0, 183, 255, 0.5) 2px 5px 6px -3px',
        background: confirmarEdicao(props.cardData.id_column) ? '' : 'rgba(255, 255, 255, 0.2)',
        /*border: props.cardData.etapa_producao > 0 ? '1px solid deeppink' : '' */
        boxShadow:
          props.cardData.etapa_producao > 0 ?
            'deeppink 0px 4px 10px -2px, deeppink 2px 5px 6px -3px' :
            ''
      }}>



      <div className='card-header'>

        <label className='title'>
          {props.cardData.name.toUpperCase().substring(0, 30)}
        </label>

      </div>

      <div className='card-body'>

        {props.cardData.id == '2AjZ4OGF' ? (
          <div style={{ display: 'none' }} className='card-name'>
            {/*<MdLoyalty style={{ color: colorMotivoPerca() }} className='icon' />*/}
            <label className='etiqueta'>{props.cardData.motivo_perca}</label>
          </div>
        ) : <></>}

        {props.cardData.id != '2AjZ4OGF' ? (
          <div style={{ display: 'none' }} className='card-name'>
            {/*<MdLoyalty style={{ color: colorEtiqueta() }} className='icon' />*/}
            <label className='etiqueta' >{props.cardData.etiqueta}</label>
          </div>
        ) : <></>}

        {props.cardData.id_column == 1 ? (
          <label style={{ display: 'none' }} className='card-name'>
            <MdAccessAlarms className='icon' />{props.cardData.previsao}

          </label>
        ) : <></>}

        {props.cardData.id_column != 'LxpCulXe' ? (
          <label className='card-name'>
            <MdArticle className='icon' />{props.cardData.document_card}</label>
        ) : <></>}

        {props.cardData.id_column != 'LxpCulXe' ? (
          <label className='card-name'>
            <img className='icon' src={faktory} alt="Example" style={{ width: '15px', height: '15px', marginLeft: '2px' }} />
            {props.cardData.numero_pedido ? props.cardData.numero_pedido : 'Não Informado'}
          </label>
        ) : <></>}

        <label style={{ display: 'none' }} className='card-name'>
          <MdAccountBalance className='icon' />{props.cardData.name_obra}
        </label>

        <label className='card-name'>
          <MdHome className='icon' />{props.cardData.name_obra_cliente}
        </label>

        {props.cardData.id_column == 'LxpCulXe' ? (
          <label className='card-name'>
            <MdAlternateEmail className='icon' />{props.cardData.email}
          </label>
        ) : <></>}

        {props.cardData.id_column == 'LxpCulXe' ? (
          <label className='card-name'>
            <MdPhone className='icon' />{props.cardData.fone}
          </label>
        ) : <></>}

        <label className='card-name'>
          <MdAccountBox className='icon' />{props.cardData.name_user}
        </label>

        <label className='card-name'>
          <MdRoom className='icon' /><div> {props.cardData.city + '/' + props.cardData.estado} </div>
        </label>

        {props.cardData.id_column == 'LxpCulXe' ? (
          <label className='card-name'>
            <MdShare className='icon' />{props.cardData.meio_contato}
          </label>
        ) : <></>}

        <label style={{ display: 'none' }} className='card-name'>
          <MdLoyalty className='icon' />{props.cardData.id}
        </label>

        {props.cardData.id_column != 'LxpCulXe' ? (
          <label className='card-name'>

            <MdAccountBalance className='icon' /><label className='valor-card'>{parseFloat(props.cardData.valor ? props.cardData.valor : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
          </label>
        ) : <></>}

        {props.cardData.id_column != 'LxpCulXe' ? (
          <label className='card-name'>

            <MdShoppingCart className='icon' /><label className='valor-card'>{parseFloat(props.cardData.valor_venda ? props.cardData.valor_venda : 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</label>
          </label>
        ) : <></>}
        <label className='prioridade-label'>
          Etiqueta:
        </label>

        <label className='prioridade-container' style={{
          backgroundColor:
            props.cardData.prioridade === 'Baixa' ? 'limegreen' :
              props.cardData.prioridade === 'Média' ? 'gold' :
                props.cardData.prioridade === 'Alta' ? 'rgb(255, 96, 96)' :

                  props.cardData.prioridade === 'Recusado' ? 'rgb(255, 96, 96)' :
                    props.cardData.prioridade === 'Aguardando Informação' ? 'gold' :
                      props.cardData.prioridade === 'Contrato Assinado' ? 'limegreen' :
                        ''
        }}>
          { }{props.cardData.prioridade ? props.cardData.prioridade : 'Normal'}
        </label>


      </div>

      <div className='card-footer'>

        {
          props.cardData.status != 'Perdido' && props.cardData.status != 'Arquivado' && (
            <div className='day-counter-container' style={{ backgroundColor }}>
              <label className='label-day-counter'>{dayCounter >= 0 ? dayCounter : 0} dias</label>
            </div>
          )
        }


        {<label style={{ display: 'none' }} className='current-date-card'>{formatarData(props.cardData.date)}</label>}



        {true ? (
          <label className='star-container'>
            <MdGrade
              className='icon-star'
              /*onClick={() => setStar(1)}*/
              style={{ color: star >= 1 ? '#9961FF' : 'rgba(172, 172, 172, 0.6)' }}
            />
            <MdGrade
              className='icon-star'
              /*onClick={() => setStar(2)}*/
              style={{ color: star >= 2 ? '#777CFF' : 'rgba(172, 172, 172, 0.6)' }}
            />
            <MdGrade
              className='icon-star'
              /*onClick={() => setStar(3)}*/
              style={{ color: star >= 3 ? '#5F8EFF' : 'rgba(172, 172, 172, 0.6)' }}
            />
            <MdGrade
              className='icon-star'
              /*onClick={() => setStar(4)}*/
              style={{ color: star >= 4 ? '#39A9FF' : 'rgba(172, 172, 172, 0.6)' }}
            />
            <MdGrade
              className='icon-star'
              /*onClick={() => setStar(5)}*/
              style={{ color: star >= 5 ? '#09CEFF' : 'rgba(172, 172, 172, 0.6)' }}
            />
          </label>
        ) : <></>}

        <div className='container-icons-status'>

          {props.cardData.status == 'Vendido' ? (
            <MdThumbUp className='icon-venda-efetuada-card' />
          ) : <></>}

          {props.cardData.status == 'Perdido' ? (
            <MdThumbDown className='icon-venda-verdida-card' />
          ) : <></>}

          {props.cardData.status == 'Arquivado' ? (
            <MdRestoreFromTrash className='icon-card-arquivado' />
          ) : <></>}

          {props.cardData.status == 'Entregue' ? (
            <MdLocalShipping className='icon-card-entregue' />
          ) : <></>}

          {props.cardData.status == 'Instalacao' ? (
            <MdWindow className='icon-card-entregue' />
          ) : <></>}

          {props.cardData.status == 'Assistencia' ? (
            <MdBuild className='icon-card-entregue' />
          ) : <></>}

          {props.cardData.status == 'InstalacaoExt' ? (
            <MdWindow className='icon-card-ext' />
          ) : <></>}

          {props.cardData.status == 'AssistenciaExt' ? (
            <MdBuild className='icon-card-ext' />
          ) : <></>}

          {props.cardData.etapa_producao > 0 ? (
            <MdFileCopy className='icon-card-etapa-producao' />
          ) : <></>}

          {props.cardData.prioridade === 'Baixa' || props.cardData.prioridade === 'Média' || props.cardData.prioridade === 'Alta' ? (
            <MdError style={{ color: `${getColorPrioridade(props.cardData.prioridade)}` }} className='icon-card-prioridade' />
          ) : <></>}

          {props.cardData.prioridade === 'Alteração de Pedido' ? (
            <MdEditSquare style={{ color: `${getColorPrioridade(props.cardData.prioridade)}` }} className='icon-card-prioridade' />
          ) : <></>}

          {props.cardData.prioridade === 'Potencial Cliente' ? (
            <MdAnchor style={{ color: `${getColorPrioridade(props.cardData.prioridade)}` }} className='icon-card-ancora' />
          ) : <></>}

        </div>

      </div>
    </div>
  );
}
export default Card;