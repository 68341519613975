import React, { useContext, useState } from 'react'

// STYLE
import './style.css'

// COMPONENTS
import ChartValueColumn from '../../component/charts/ChartValueColumn';

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';

// COMPONENTS
import ChartValueEstado from '../charts/ChartValueEstado';
import ChartValueRepresentante from '../charts/ChartValueRepresentante';
import ChartValueCidade from '../charts/ChartValueCidade';
import InfosGerais from '../charts/InfosGerais';

// REACT ICONS
import { MdOutlineSearch } from "react-icons/md";

function Dashboard() {

    // CONTEXT API
    const { tipoParticipante, user, nameUser, } = useContext(UserContext);
    const { openCloseMenuFilter} = useContext(BoardContext);

    const [alterarGrafico, setAlterarGrafico] = useState(false);

    return (
        <div className='dashboard-container'>

            <div className='dashboard-header'>

                <label className='title'>Dashboard</label>
                <button className='btn-chart-cidade' onClick={() => setAlterarGrafico(!alterarGrafico)}>Cidade / Estado</button>

            </div>

            <div className='dashboard-body'>

                <div className='charts-dashboard-container'>
                    <div>
                        <InfosGerais />
                        <ChartValueColumn />
                    </div>

                    {
                        alterarGrafico ?
                            (
                                <ChartValueEstado />
                            ) : <ChartValueCidade></ChartValueCidade>
                    }

                    <ChartValueRepresentante />

                </div>

                <button className='btn-filter-float-dashboard' onClick={openCloseMenuFilter}><MdOutlineSearch /></button>

            </div>
        </div>
    );
}
export default Dashboard;