import React, { useContext } from 'react'
import './style.css'

// CONTEXT API
import { ColumnContext } from '../../contexts/ColumnContext';
import { CardContext } from '../../contexts/CardContext';

function VendaPerdida() {

    const { vendaPerdidaModal } = useContext(ColumnContext);

    const { motivoVendaPerdida, setMotivoVendaPerdida } = useContext(CardContext);

    const motivos = [
        { id: '1', name: 'Prazo de entrega' },
        { id: '2', name: 'Mudança de prioridades' },
        { id: '3', name: 'Preço elevado' },
        { id: '4', name: 'Concorrência mais atraente' },
        { id: '5', name: 'Recomendação do arquiteto' },
        { id: '6', name: 'Necessidades não atendidas' },
        { id: '7', name: 'Comprou Madeira/Alumínio' },
        { id: '8', name: 'Prazo de Final de Ano' },
        { id: '9', name: 'Sem Retorno do Cliente' },
    ]

    return (
        <div className='modal-venda-perdida-container'>

            <div className='venda-perdida-container'>

                <div className='venda-perdida-header'>
                    <div className='title-venda-perdida'>Motivo da Venda Perdida</div>
                    <div className='btn-close-container'> </div>
                </div>
                <div className='venda-perdida-body'>

                    <label className='title'>Motivo</label>
                    <input className='input-venda-perdida' type='text' value={motivoVendaPerdida} /*onChange={(e) => setMotivoVendaPerdida(e.target.value)}*/ placeholder={'Selecione o motivo...'}></input>
                    {
                        motivos.map((item) =>
                            <button key={item.id} className='btn-motivos' onClick={() => setMotivoVendaPerdida(item.name)}>{item.name}</button>
                        )
                    }

                </div>
                <div className='venda-perdida-footer'>
                    <div>
                    </div>
                    <div className='footer-rigth'>

                        <button className='btn-save' onClick={vendaPerdidaModal} disabled={motivoVendaPerdida == ''} >Confirmar</button>
                    
                    </div>

                </div>
            </div>
        </div>
    );
}


export default VendaPerdida;