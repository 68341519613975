import React, { useContext, useEffect, useState } from "react";
import { CardContext } from '../../../contexts/CardContext';
import { BoardContext } from '../../../contexts/BoardContext';
import { ColumnContext } from '../../../contexts/ColumnContext';
import { UserContext } from '../../../contexts/UserContext';
import { Chart } from "react-google-charts";
import _ from "lodash";

import Style from './style.css';

function ChartValueEstado() {

    const { listaCards } = useContext(CardContext);
    const { } = useContext(BoardContext);
    const { listaColumns } = useContext(ColumnContext);
    const { listaProcessos } = useContext(UserContext);

    // filtro de data
    const { startDate, endDate, statusCardFilter, dateCardFilter, listaFilteredCards, openCloseChatsCidadeEstado } = useContext(BoardContext);

    const [filteredCards, setFilteredCards] = useState([]);

    useEffect(() => {
        setFilteredCards(listaFilteredCards);
    }, [listaCards, endDate, startDate, statusCardFilter, dateCardFilter, listaFilteredCards]);

    // Obtenha uma lista de `id_column` com base em `listaProcessos`
    const processColumnIds = listaProcessos.map(processo => processo.id.toString());

    // Filtrar os cards com `id_column` existente em `processColumnIds`
    const filteredCardsEstado = listaFilteredCards.filter(card => processColumnIds.includes(card.id_column));

    // Agrupar os cards por estado
    const cardsByState = _.groupBy(filteredCardsEstado, "estado");

    // Calcular o valor total por estado e formatar os dados para o gráfico
    const data = Object.entries(cardsByState).map(([estado, cards]) => {
        const valorTotal = cards.reduce((total, card) => total + parseFloat(card.valor), 0);
        return [estado, valorTotal];
    });

    // Calcular a porcentagem para cada estado
    const totalValue = filteredCardsEstado.reduce((total, card) => total + card.valor, 0);
    const dataWithPercentage = data.map(([estado, valorTotal]) => {
        const porcentagem = (valorTotal / totalValue) * 100;
        return [estado, valorTotal, porcentagem];
    });

    // Ordenar os dados por valor total decrescente
    const sortedData = _.orderBy(dataWithPercentage, [1], ["desc"]);

    const chartData = [["Estado", "Valor Total", "Porcentagem"], ...sortedData];

    const options = {
        title: "VALOR TOTAL POR ESTADO",
        backgroundColor: "transparent", // Define a cor de fundo do gráfico
        chartArea: { width: "90%", height: "90%" }, // Define o raio de borda do gráfico
        is3D: false,
        legend: { position: "bottom" },
        fontSize: 11,
        pieSliceText: "value-and-percentage",
        pieSliceTextStyle: {
            color: "white",
            bold: true,
            textAlign: "center",
        },
        tooltip: { text: "value-and-percentage" },
        colors: [
            "#FF4069",
            "#FFC233",
            "#069BFF",
            "#C749EF",
            "#01BA02",
            "#F7A24D",
            "#F66DB0",
            "#8FB1CE",
            "#FF9696", // Vermelho claro
            "#FFB396", // Laranja claro
            "#FFD996", // Amarelo claro
            "#FFFF96", // Amarelo-esverdeado claro
            "#C0FF96", // Verde claro
            "#96FFAE", // Verde azulado claro
            "#96FFD4", // Ciano claro
            "#96FFFF", // Azul claro
            "#96B6FF", // Azul arroxeado claro
            "#B996FF", // Lilás claro
            "#D696FF", // Magenta claro
            "#FF96F9", // Rosa claro
            "#FF96D2", // Rosa arroxeado claro
            "#FF96AE", // Rosa alaranjado claro
            "#FF9696", // Vermelho claro (repetido)
            "#FFB396", // Laranja claro (repetido)
            "#FFD996", // Amarelo claro (repetido)
            "#FFFF96", // Amarelo-esverdeado claro (repetido)
            "#C0FF96", // Verde claro (repetido)
            "#96FFAE", // Verde azulado claro (repetido)
        ], // Define cores personalizadas
        pieHole: 0.0, // Define o tamanho do buraco no centro do gráfico (0.4 representa 40% do tamanho total)

    };

    return (

        <div className="grafico-container-c">
            <Chart
                className="grafico-cc"
                chartType="PieChart"
                data={chartData}
                options={options} />
        </div>
    );
}

export default ChartValueEstado;
