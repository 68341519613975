import React, { useState, useContext, useEffect } from 'react';
import './style.css';

import { ExcelRenderer } from 'react-excel-renderer';

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';
import { ColumnContext } from '../../contexts/ColumnContext';

// FIREBASE
import fb from '../../config/firebase';
import { getFirestore } from 'firebase/firestore';

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

// IDs ÚNICOS
import { v4 as uuidv4 } from 'uuid';

// DATE
import { format, addDays, subDays } from 'date-fns';

function ImportExcel() {




  const [numero, setNumero] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [contato, setContato] = useState('');







  const { openCloseModalImportExcel } = useContext(BoardContext);

  const { listaAllProcessos } = useContext(ColumnContext);

  const { idEmpresa, nameUser } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsersEmpresa();
  }, [])
  //--- FIREBASE
  const db = getFirestore(fb);

  // FUNÇÃO PARA BUSCAR AS INFORMAÇÕES DOS USUÁRIOS
  const [listaUsers, setListaUsers] = useState([]);

  const getUsersEmpresa = async () => {
    try {
      const url = `${apiUrl}/users/getUsers`;
      const params = {
        empresa: idEmpresa,
      };
      const response = await axios.get(url, { params });
      const usersData = response.data;
      const allUsers = [];

      usersData.forEach((userData) => {
        const todosUsers = {
          id: userData.id,
          ...userData,
        };
        allUsers.push(todosUsers);
      });

      setListaUsers(allUsers);

    } catch (error) {
      console.error(error);
    }
  };

  // PEGAR ID CORRESPONDENTE PREFWEB / SUITEFLOW
  function buscarIdReferencia(entidade) {
    const listaFiltrada = listaUsers.filter((item) => item.entidade_preference === entidade);
    const id = listaFiltrada.map((item) => item.id);

    return id.toString();
  }

  // PEGAR NOME CORRESPONDENTE PREFWEB / SUITEFLOW
  function buscarNameReferencia(entidade) {
    const listaFiltrada = listaUsers.filter((item) => item.entidade_preference === entidade);
    const name = listaFiltrada.map((item) => item.name);

    return name.toString();
  }


  // PEGAR FONE CORRESPONDENTE PREFWEB / SUITEFLOW
  function buscarFoneReferencia(entidade) {

    const listaFiltrada = listaUsers.filter((item) => item.entidade_preference === entidade);

    //console.log(listaFiltrada)
    const fone = listaFiltrada.map((item) => item.fone);

    return fone.toString();
  }

  // PEGAR NOME CORRESPONDENTE PREFWEB / SUITEFLOW
  function buscarEstadoReferencia(entidade) {
    const listaFiltrada = listaUsers.filter((item) => item.entidade_preference === entidade);
    const estado = listaFiltrada.map((item) => item.estado);

    return estado.toString();
  }

  // PEGAR NOME CORRESPONDENTE PREFWEB / SUITEFLOW
  function buscarCidadeReferencia(entidade) {
    const listaFiltrada = listaUsers.filter((item) => item.entidade_preference === entidade);
    const city = listaFiltrada.map((item) => item.city);

    return city.toString();
  }

  const [numRowsLoaded, setNumRowsLoaded] = useState(0);
  const [numCardsLoaded, setNumCardsLoaded] = useState(0);

  const handleFileUpload = (event) => {

    setLoading(true);

    const fileObj = event.target.files[0];

    // IMPORTAÇÃO PADRÃO PREFWEB
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.error(err);
        setLoading(false);
        return;
      }

      // Acessando os dados do arquivo Excel
      const rows = resp.rows;

      if (rows.length <= 1) {
        console.error('Nenhuma linha encontrada no arquivo Excel.');
        setLoading(false);
        return;
      }

      // Remove a primeira linha (cabeçalho)
      const dataRows = rows.slice(1);

      setNumRowsLoaded(dataRows.length);


      // FUNÇÃO PARA PEGAR AS INFORMAÇÕES DO EXCEL E JÁ SALVAR NO FIREBASE
      const addCardFirestore = async (row, currentNumCardsLoaded) => {

        const [
          documento_card,
          versao,
          name,
          date,
          valor,
          entidade,
        ] = row;

        // Função para converter o número de série de data para o formato 'dd/mm/aaaa'
        function convertExcelDate(serialDate) {
          // Número de série de data base do Excel (1º de janeiro de 1900)
          const excelBaseDate = new Date(1900, 0, 1);

          // Adiciona o número de dias do número de série de data ao Excel base date
          let date = addDays(excelBaseDate, serialDate);

          // Subtrai 2 dias para corrigir o deslocamento
          date = subDays(date, 2);

          // Formata a data no formato 'dd/mm/aaaa'
          const dateTexto = format(date, 'dd/MM/yyyy');

          // formatar data do tipo texto em Date()
          const partesData = dateTexto.split('/');
          const formattedDate = new Date(partesData[2], partesData[1] - 1, partesData[0]);

          return formattedDate;
        }


        const columnFinalizados = listaAllProcessos.find(item => item.title === 'Import'); // Use "find" em vez de "filter"
        const idColumnFinalizados = columnFinalizados.id;

        if (!idColumnFinalizados || idColumnFinalizados == '') {
          console.log('Coluna Import não Ativa');
          return;
        }


        if (versao != 'Versão 1' && versao != 1) {
          console.log('A versão do documento não corresponde a Versão 1');
          return;
        }

        console.log("date: " + date)


        if (entidade === '') {
          console.log('Referência da Entidade Vazia');
          return;
        }

        if (buscarIdReferencia(entidade) === "") {
          console.log('Não existem entidade!!!');
          return;
        }

        if (buscarCidadeReferencia(entidade) === "") {
          console.log('Usuário sem a Cidade no cadastro!!!');
          return;
        }

        if (buscarEstadoReferencia(entidade) === "") {
          console.log('Usuário sem o Estado no cadastro!!!');
          return;
        }

        const dataAtual = new Date();
        const primeiraData = new Date(dataAtual.getTime() + 1 * 24 * 60 * 60 * 1000);
        const segundaData = new Date(dataAtual.getTime() + 2 * 24 * 60 * 60 * 1000);
        const terceiraData = new Date(dataAtual.getTime() + 15 * 24 * 60 * 60 * 1000);

        try {

          const cardData = {
            document_card: documento_card + ' - ' + versao,
            name: name ? name : '',
            name_obra: '',
            valor: valor ? valor : 0,
            email: '',
            fone: '',
            // city: buscarCidadeReferencia(entidade),
            // estado: buscarEstadoReferencia(entidade),
            city: '',
            estado: '',
            previsao: '',
            meio_contato: '',
            create_by: buscarNameReferencia(entidade),
            id_create_by: buscarIdReferencia(entidade),
            name_user: buscarNameReferencia(entidade),
            id_column: idColumnFinalizados,
            date: new Date(),
            nivel: 2,
            etiqueta: 'Importação',
            empresa: idEmpresa,
            motivo_perca: '',
            modification_date: new Date(),
            produto: 'Esquadrias',
            status: '',
            lista_tarefas: [
              { id: uuidv4(), title: 'Revisar as informações', date: format(new Date(), 'dd/MM/yyyy'), status: false, edit: false },
              { id: uuidv4(), title: 'Enviar proposta ao cliente', date: format(primeiraData, 'dd/MM/yyyy'), status: false, edit: false },
              { id: uuidv4(), title: 'Primeiro contato pós envio da proposta', date: format(segundaData, 'dd/MM/yyyy'), status: false, edit: false },
              { id: uuidv4(), title: 'Segundo contato pós envio da proposta', date: format(terceiraData, 'dd/MM/yyyy'), status: false, edit: false }
            ],
            lista_historico: [{ id: uuidv4(), title: `Importado ( ${nameUser} )`, date: format(new Date(), 'dd/MM/yyyy'), edit: false }],
            edit_date: new Date(),
          };

          const response = await axios.post(`${apiUrl}/cards`, cardData);

          setNumCardsLoaded(currentNumCardsLoaded + 1);


          //---------------------------------------------- ENVIAR MENSAGEM AUTOMATICA PARA AS ENTIDADES POR WHATSAPP --------------------------


          // const nome = buscarNameReferencia(entidade);
          // const phone = buscarFoneReferencia(entidade); // Adicione a função buscarFoneReferencia conforme necessário

          // if (nome && phone) {

          //   const valorFormatado = valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
          //   await sendMessage(phone, nome, `${nome}\n \nCard criado com sucesso no CRM! \n \nCliente: ${name} \nNúmero do orçamento: ${documento_card} \nValor: ${valorFormatado}\n\n Verifique as informações fornecidas!\n\nBazze PVC`);

          // } else {
          //   console.error('Nome ou telefone do usuário não encontrados.');
          // }


          //---------------------------------------------- FIM ENVIAR MENSAGEM AUTOMATICA PARA AS ENTIDADES POR WHATSAPP --------------------------

        } catch (error) {
          console.error(error);
          console.error('ERRO AO IMPORTAR');
        }

      };

      // Iterar sobre as linhas de dados e criar documentos no Firestore
      for (const row of dataRows) {
        addCardFirestore(row, numCardsLoaded);
      }

      setLoading(false);

    });

  };



  const sendMessage = async (numero, contato, mensagem) => {
    try {
      // Use os dados do card recém-criado para personalizar a mensagem
      const mensagemPersonalizada = `${nameUser}, seu novo card foi criado com sucesso!`;

      // Modifique o número e a mensagem com os valores personalizados
      // setNumero(numero);
      // setMensagem(mensagemPersonalizada);
      // setContato(nameUser);

      //console.log(`${numero} + ${contato} + ${mensagem}`)

      // Envie a mensagem
      await axios.post(`${apiUrl}/mensagens/enviar`, { numero, mensagem, contato });
      console.log('Mensagem enviada com sucesso!');
    } catch (error) {
      console.error('Erro ao enviar mensagem:', error.message);
    }
  };







  const [infoImport, setInfoImport] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUploadButtonClick = () => {
    if (selectedFile) {
      const fakeEvent = {
        target: {
          files: [selectedFile]
        }
      };
      handleFileUpload(fakeEvent);
    } else {
      console.error('Nenhum arquivo selecionado.');
    }
  };

  const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/suiteflow-1c751.appspot.com/o/Planilha%20de%20importa%C3%A7%C3%A3o%20SuiteFlow.xlsx?alt=media';

  const handleDownload = () => {
    window.open(storageUrl, '_blank');
  };



  return (
    <div className='modal-import-container'>
      <div className='import-container'>
        <div className='import-header'>
          <label className='title'>Importar informações do Excel</label>
        </div>
        <div className='import-body'>
          <input className='input-import-file' type='file' onChange={handleInputChange} />

          <div className='tabela-padrao'>
            <label className='title-table'>Referência de tabela</label>
            <label className='title-table' style={{ color: 'red' }}>{infoImport}</label>
            <div className='tabela'>

              <label className='column-table'>Número do orçamento</label>
              <label className='column-table'>Versão</label>
              <label className='column-table'>Nome do Cliente</label>
              <label className='column-table'>Data</label>
              <label className='column-table'>Valor</label>
              <label className='column-table'>Nome da Entidade</label>
            </div>

          </div>

          {loading && <label className='infos-import-excel'>Carregando...</label>}

          {
            numRowsLoaded > 0 ? <label className='infos-import-excel'>Linhas no arquivo: {numRowsLoaded}</label> : <></>
          }

          {
            numCardsLoaded > 1000000000 ? <label className='title-table'>Cards carregados: {numCardsLoaded}</label> : <></>
          }

          <label className='planilha-excel-exemplo' onClick={handleDownload}>Baixar planilha de exemplo...</label>

        </div>

        <div className='import-footer'>
          <button className='btn-close-import' disabled={loading} style={{ background: loading ? "rgba(0, 0, 0, 0.05)" : '' }} onClick={openCloseModalImportExcel}>
            Fechar
          </button>
          <button className='btn-send-import' disabled={loading} style={{ background: loading ? "rgba(0, 0, 0, 0.05)" : '' }} onClick={handleUploadButtonClick}>
            Enviar
          </button>

        </div>
      </div>
    </div>
  );
}

export default ImportExcel;
