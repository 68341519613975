import React, { useContext, useState, useEffect, useRef } from 'react';
import './style.css';
import Card from '../Card';
import { BoardContext } from '../../contexts/BoardContext';
import { ColumnContext } from '../../contexts/ColumnContext';
import { CardContext } from '../../contexts/CardContext';
import { MdAddBox } from 'react-icons/md';

import { parse } from 'date-fns';
import moment from 'moment';
import 'moment/locale/pt-br';

import { Tooltip } from 'react-tooltip'; // Mostra mensages ao colocar o mouse sobre os botoes com title

function Column(props) {
  const { searchValue, openCloseCreateCardModal, status } = useContext(BoardContext);
  const { setIdColumn, setNameColumn } = useContext(ColumnContext);
  const { listaCards, isLoading, setIdColumnCard } = useContext(CardContext);

  const [quantidadeCards, setQuantidadeCards] = useState(0);
  const [valorCards, setValorCards] = useState(0);

  const [displayedCards, setDisplayedCards] = useState(10);
  const [isFetching, setIsFetching] = useState(false);
  const columnBodyRef = useRef(null);

  const { startDate, endDate, statusCardFilter, dateCardFilter, listaFilteredCards, orderBy, orderByCrescenteDecrescente } = useContext(BoardContext);

  function setColumnInfo() {
    setIdColumn(props.columnData.id);
    setIdColumnCard(props.columnData.id);
    setNameColumn(props.columnData.title);
    openCloseCreateCardModal();
  }

  useEffect(() => {
    obterQuantidadeCards();
    setDisplayedCards(10);
  }, [listaCards, listaFilteredCards, startDate, endDate, statusCardFilter, dateCardFilter, searchValue]);

  const obterQuantidadeCards = () => {

    const filteredCards = listaFilteredCards
      //.filter((item) => item.id_column === props.columnData.id)
      .filter((item) => (item.id_column === props.columnData.id.toString()) || (item.id_column === props.columnData.id))

    const quantidade = filteredCards.length;
    const somaValores = filteredCards.reduce((acumulador, item) => acumulador + parseFloat(item.valor), 0);

    setValorCards(somaValores);
    setQuantidadeCards(quantidade);
  };

  // ---------- SCROLL -------------

  useEffect(() => {
    function handleScrollMobile() {
      const { scrollTop, clientHeight, scrollHeight } = columnBodyRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setDisplayedCards((prevDisplayed) => prevDisplayed + 10);
      }
    }

    function handleScrollDesktop() {
      if (columnBodyRef.current.offsetHeight + columnBodyRef.current.scrollTop >= columnBodyRef.current.scrollHeight - 10) {
        setDisplayedCards((prevDisplayed) => prevDisplayed + 10);
      }
    }

    const columnBodyElement = columnBodyRef.current;

    if (columnBodyElement) {
      if ('ontouchstart' in window) {
        // Dispositivo móvel
        columnBodyElement.addEventListener('scroll', handleScrollMobile);
      } else {
        // Navegador de computador
        columnBodyElement.addEventListener('scroll', handleScrollDesktop);
      }
    }

    return () => {
      if (columnBodyElement) {
        if ('ontouchstart' in window) {
          // Dispositivo móvel
          columnBodyElement.removeEventListener('scroll', handleScrollMobile);
        } else {
          // Navegador de computador
          columnBodyElement.removeEventListener('scroll', handleScrollDesktop);
        }
      }
    };
  }, []);

  // --------- FIM SCROLL

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreData();
    setIsFetching(false);
  }, [isFetching]);

  function fetchMoreData() {
    setDisplayedCards((prevDisplayed) => prevDisplayed + 10);
  }


  function converterStringParaData(dataString) {
    // Converta a string para um objeto Date
    return new Date(dataString);
  }

  const cardsOrdenados = listaFilteredCards.slice();


  if (orderBy === 'data') {
    cardsOrdenados.sort((a, b) => {
      const dateA = converterStringParaData(a.date);
      const dateB = converterStringParaData(b.date);
      return orderByCrescenteDecrescente ? dateA - dateB : dateB - dateA;
    });
  }

  if (orderBy === 'dataUpdate') {
    cardsOrdenados.sort((a, b) => {
      const dateA = converterStringParaData(a.edit_date);
      const dateB = converterStringParaData(b.edit_date);
      return orderByCrescenteDecrescente ? dateA - dateB : dateB - dateA;
    });
  }


  if (orderBy === 'nome') {
    cardsOrdenados.sort((a, b) => {
      const comparison = a.name.localeCompare(b.name);
      return orderByCrescenteDecrescente ? comparison : -comparison;
    }); // Ordenar por nome
  }

  if (orderBy === 'valor') {
    cardsOrdenados.sort((a, b) => {
      return orderByCrescenteDecrescente ? a.valor - b.valor : b.valor - a.valor;
    }); // Ordenar por valor
  }

  const filteredCards = cardsOrdenados
    .filter((item) => (item.id_column === props.columnData.id) || (item.id_column === props.columnData.id.toString()))
    .slice(0, displayedCards);


  return (
    <div className='column-container'
      style={{
        //backgroundColor: props.columnData.title == 'Finalizados' ? 'rgba(0, 191, 255, 0.1)' : props.columnData.title == 'Import' ? 'rgba(255, 0, 119, 0.1)' : '#b1b1b146',
        //border: props.columnData.title === 'Finalizados' ? '2px solid rgba(0, 191, 255, 0.5)' : props.columnData.title === 'Import' ? '2px solid rgba(255, 0, 119, 0.5)' : 'none',
      }}
    >
      <div className='column-header'>
        <div className='header-title-container'>
          <label className='title'>{props.columnData.title}</label>
          {props.columnData.title != "Finalizados" && props.columnData.title != "Import" && (
            <MdAddBox className='btn-add-card' onClick={setColumnInfo} />
          )}
        </div>
        {props.columnData.id != '30' && (
          <div className='column-infos-container'>
            <label className='info'>{quantidadeCards} Cards</label>
            {props.columnData.id != 'LxpCulXe' && (
              <label className='info'>
                {valorCards.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </label>
            )}
          </div>
        )}
      </div>

      <div className='column-body' ref={columnBodyRef} >

        {/* {
          filteredCards
            .filter((item) => item.prioridade === 'Alteração de Pedido' || item.prioridade === 'Baixa' || item.prioridade === 'Média' || item.prioridade === 'Alta' || item.prioridade === 'Potencial Cliente')
            .sort((b, a) => {
              const prioridades = ['Baixa', 'Média', 'Alta', 'Alteração de Pedido', 'Potencial Cliente'];
              return prioridades.indexOf(a.prioridade) - prioridades.indexOf(b.prioridade);
            })
            .map((item) => (
              <Card key={item.id} cardData={item} />
            ))
        }


        {
          filteredCards
            .filter((item) => item.prioridade != 'Alteração de Pedido' && item.prioridade != 'Baixa' && item.prioridade != 'Média' && item.prioridade != 'Alta' && item.prioridade != 'Potencial Cliente')
            .map((item) => (
              <Card key={item.id} cardData={item} />
            ))
        } */}

{
  filteredCards
    .filter((item) => 
      item.prioridade === 'Alteração de Pedido' || 
      item.prioridade === 'Baixa' || 
      item.prioridade === 'Média' || 
      item.prioridade === 'Alta' || 
      item.prioridade === 'Potencial Cliente' ||
      item.prioridade === 'Aguardando Informação' || 
      item.prioridade === 'Recusado' || 
      item.prioridade === 'Contrato Assinado'
    )
    .sort((b, a) => {
      const prioridades = [
        'Baixa', 
        'Média', 
        'Alta', 
        'Aguardando Informação', 
        'Recusado', 
        'Contrato Assinado', 
        'Alteração de Pedido', 
        'Potencial Cliente'
      ];
      return prioridades.indexOf(a.prioridade) - prioridades.indexOf(b.prioridade);
    })
    .map((item) => (
      <Card key={item.id} cardData={item} />
    ))
}

{
  filteredCards
    .filter((item) => 
      item.prioridade !== 'Alteração de Pedido' && 
      item.prioridade !== 'Baixa' && 
      item.prioridade !== 'Média' && 
      item.prioridade !== 'Alta' && 
      item.prioridade !== 'Potencial Cliente' &&
      item.prioridade !== 'Aguardando Informação' && 
      item.prioridade !== 'Recusado' && 
      item.prioridade !== 'Contrato Assinado'
    )
    .map((item) => (
      <Card key={item.id} cardData={item} />
    ))
}



        {isLoading && <p className='loading'>Carregando...</p>}
      </div>
      <div className='column-footer'>{props.columnData.id}</div>
    </div >
  );
}

export default Column;
