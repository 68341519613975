import React, { createContext, useState, useEffect, useContext } from "react";

export const BoardContext = createContext();

export const BoardProvider = ({ children }) => {

  const [createCardModal, setCreateCardModal] = useState(false);
  function openCloseCreateCardModal() {
    setCreateCardModal(!createCardModal);
  }

  const [createUserModal, setCreateUserModal] = useState(false);
  function openCloseCreateUserModal() {
    setCreateUserModal(!createUserModal);
  }

  const [editCardModal, setEditCardModal] = useState(false);
  function openCloseEditCardModal() {
    setEditCardModal(!editCardModal);
  }

  const [modalImprtExcel, setModalImportExcel] = useState(false);
  function openCloseModalImportExcel() {
    setModalImportExcel(!modalImprtExcel);
  }

  const [menuFilter, setMenuFilter] = useState(false);
  function openCloseMenuFilter() {
    setMenuFilter(!menuFilter);
  }

  const [editUserModal, setEditUserModal] = useState(false);
  function openCloseEditUserModal() {
    setEditUserModal(!editUserModal);
  }

  const [createParticipanteModal, setCreateParticipanteModal] = useState(false);
  function openCloseCreateParticipanteModal() {
    setCreateParticipanteModal(!createParticipanteModal);
  }

  const [editParticipanteModal, setEditParticipanteModal] = useState(false);
  function openCloseEditParticipanteModal() {
    setEditParticipanteModal(!editParticipanteModal);
  }

  // ESCOLHA DE PERÍODO 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // ESCOLHA DE PERÍODO VENDA
  const [startDateVenda, setStartDateVenda] = useState(null);
  const [endDateVenda, setEndDateVenda] = useState(null);
  const [statusCardFilter, setStatusCardFilter] = useState('');

  const [dateCardFilter, setDateCardFilter] = useState('create');

  const [showConfetti, setShowConfetti] = useState(false);

  const [listaCardsVendas, setListaCardsVendas] = useState([]);
  const [listaFilteredCards, setListaFilteredCards] = useState([]);

  const [searchValue, setSearchValue] = useState('');
  const [grupoParticipantes, setGrupoParticipantes] = useState([]);

  const [definePeriodo, setDefinePeriodo] = useState('ano');

  // ORDENAR CARDS POR:
  const [orderBy, setOrderBy] = useState('data');
  const [orderByCrescenteDecrescente, setOrderByCrescenteDecrescente] = useState(true);

  // PROCESSOS
  const [editProcessModal, setEditProcessModal] = useState(false);

  const [openOrcamento, setOpenOrcamento] = useState(false);


  return (
    <BoardContext.Provider value={{
      orderBy, setOrderBy,
      orderByCrescenteDecrescente, setOrderByCrescenteDecrescente,
      editParticipanteModal,
      openCloseEditParticipanteModal,
      createParticipanteModal,
      openCloseCreateParticipanteModal,
      editUserModal,
      openCloseEditUserModal,
      menuFilter,
      openCloseMenuFilter,
      modalImprtExcel,
      openCloseModalImportExcel,
      showConfetti,
      setShowConfetti,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      searchValue,
      setSearchValue,
      createCardModal,
      openCloseCreateCardModal,
      createUserModal,
      openCloseCreateUserModal,
      editCardModal,
      openCloseEditCardModal,
      startDateVenda, setStartDateVenda,
      endDateVenda, setEndDateVenda,
      listaCardsVendas, setListaCardsVendas,
      statusCardFilter, setStatusCardFilter,
      dateCardFilter, setDateCardFilter,
      listaFilteredCards, setListaFilteredCards,
      grupoParticipantes, setGrupoParticipantes,
      definePeriodo, setDefinePeriodo,
      editProcessModal, setEditProcessModal,
      openOrcamento, setOpenOrcamento,
    }}>
      {children}
    </BoardContext.Provider>
  );

};