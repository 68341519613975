import React, { useContext, useState, useEffect } from 'react'

// STYLE
import './style.css'

// CONTEXT API
import { BoardContext } from '../../contexts/BoardContext';
import { UserContext } from '../../contexts/UserContext';

import axios from 'axios';
import apiUrl from '../../config/apiConfig';

// FIREBASE
import fb from '../../config/firebase';
import { getFirestore, doc, deleteDoc, updateDoc } from 'firebase/firestore';

function EditParticipante(props) {

    //--- FIREBASE
    const db = getFirestore(fb);

    const { openCloseEditParticipanteModal } = useContext(BoardContext);
    const { setListaParticipantes, listaParticipantes } = useContext(UserContext);

    const [nameParticipante, setNameParticipante] = useState('');
    const [identidadeParticipante, setIdentidadeParticipante] = useState('');
    const [emailParticipante, setEmailParticipante] = useState('');
    const [adressParticipante, setAdressParticipante] = useState('');
    const [cityParticipante, setCityParticipante] = useState('');
    const [estadoParticipante, setEstadoParticipante] = useState('');
    const [cepParticipante, setCepParticipante] = useState('');
    const [foneParticipante, setFoneParticipante] = useState('');
    const [idUserParticipante, setIdUserParticipante] = useState('');
    const [idParticipante, setIdParticipante] = useState('');

    const [info, setInfo] = useState('');

    useEffect(() => {
        setNameParticipante(props.dadosParticipante.name);
        setIdentidadeParticipante(props.dadosParticipante.identidade);
        setEmailParticipante(props.dadosParticipante.email);
        setAdressParticipante(props.dadosParticipante.adress);
        setCityParticipante(props.dadosParticipante.city);
        setEstadoParticipante(props.dadosParticipante.estado);
        setCepParticipante(props.dadosParticipante.cep);
        setFoneParticipante(props.dadosParticipante.fone);
        setIdUserParticipante(props.dadosParticipante.id_user);
        setIdParticipante(props.dadosParticipante.id);

    }, [])

    function validationForm() {

        if (nameParticipante != '' && emailParticipante != '' && estadoParticipante != '' && cityParticipante != '' && foneParticipante != '') {
            updateParticipante();
        } else {
            setInfo('Algum campo esta vazio!')
        }

    }

    const [listaEstadosIBGE, setListaEstadosIBGE] = useState([]);
    const [selectedEstadoIBGE, setSelectedEstadoIBGE] = useState(false);

    async function getEstados() {
        try {
            const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
            const estados = response.data;
            setListaEstadosIBGE(estados);
            return estados;
        } catch (error) {
            console.error('Erro ao obter a lista de estados:', error);
            return [];
        }
    }

    function getEstadosCidades() {
        getEstados();
        openCloseSelectEstadoIBGE();
    }

    function openCloseSelectEstadoIBGE() {
        setSelectedEstadoIBGE(!selectedEstadoIBGE);
    }

    function selectEstado(id, sigla) {
        setEstadoParticipante(sigla);
        openCloseSelectEstadoIBGE();
        setSelectedCidadeIBGE(true);
        getCidades(id);
    }

    const [selectedCidadeIBGE, setSelectedCidadeIBGE] = useState(false);
    const [listaCidadesIBGE, setListaCidadesIBGE] = useState([]);

    async function getCidades(estadoId) {
        try {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoId}/municipios`);
            const cidades = response.data;
            setListaCidadesIBGE(cidades);
            return cidades;
        } catch (error) {
            console.error('Erro ao obter a lista de cidades:', error);
            return [];
        }
    }

    function selectCidade(id, nome) {
        setCityParticipante(nome);
        setSelectedCidadeIBGE(false);
    }

    const updateParticipante = async () => {

        try {

            const data = {
                id_user: idUserParticipante,
                name: nameParticipante,
                identidade: identidadeParticipante,
                email: emailParticipante,
                adress: adressParticipante,
                city: cityParticipante,
                estado: estadoParticipante,
                cep: cepParticipante,
                fone: foneParticipante,
            };

            await axios.put(`${apiUrl}/participantes/` + idParticipante, data);

            console.log('User atualizado com sucesso!');

            const listaAtualizada = {
                id: idParticipante,
                id_user: idUserParticipante,
                name: nameParticipante,
                identidade: identidadeParticipante,
                email: emailParticipante,
                adress: adressParticipante,
                city: cityParticipante,
                estado: estadoParticipante,
                cep: cepParticipante,
                fone: foneParticipante,
            };

            atualizarListaParticipantesOffline(idParticipante, listaAtualizada);

        } catch (error) {
            console.error(error);
        }
    };

    const atualizarListaParticipantesOffline = (idParticipante, novosValores) => {

        setListaParticipantes(prevLista => {
            return prevLista.map(participante => {
                if (participante.id === idParticipante) {
                    return { ...participante, ...novosValores }; // Atualize todos os valores do item
                }
                return participante;
            });
        });
        openCloseEditParticipanteModal();
    };

    async function deletParticipante(id) {

        const confirmDelete = window.confirm("Você tem certeza que deseja excluir este documento?");

        if (confirmDelete) {

            const cardRef = doc(db, "participantes", id);
            await deleteDoc(cardRef);

            removerCard(id);
        } else {
            return;
        }
    }

    async function deleteParticipante(id) {
        const confirmDelete = window.confirm('Você tem certeza que deseja excluir este participante?');

        if (confirmDelete) {
            try {

                await axios.delete(`${apiUrl}/participantes/${id}`);

                removerCard(id);

            } catch (error) {
                console.error('Erro ao excluir card:', error);
            }
        }
    }


    const removerCard = (id) => {
        const novaLista = listaParticipantes.filter(item => item.id !== id);
        setListaParticipantes(novaLista);
        openCloseEditParticipanteModal();
    };

    return (
        <div className='modal-edit-participante-container'>

            <div className='edit-participante-container'>

                <div className='edit-participante-header'>
                    <div className='title'>Editar Participante</div>
                    <div className='btn-close-container'>
                        <button className='btn-close' onClick={openCloseEditParticipanteModal}>X</button>
                    </div>
                </div>

                <div className='edit-participante-body'>

                    <label className='title'>Nome</label>
                    <input className='input-edit-participante' type='text' value={nameParticipante} onChange={(e) => setNameParticipante(e.target.value)} placeholder={'Digite o nome...'}></input>

                    <label className='title'>CPF/CNPJ</label>
                    <input className='input-edit-participante' type='text' value={identidadeParticipante} onChange={(e) => setIdentidadeParticipante(e.target.value)} placeholder={'Digite o CPF ou CNPJ...'}></input>

                    <label className='title'>Email de contato</label>
                    <input className='input-edit-participante' type='email' value={emailParticipante} onChange={(e) => setEmailParticipante(e.target.value)} placeholder={'Digite o email de contato...'}></input>

                    <label className='title'>Endereço</label>
                    <input className='input-edit-participante' type='text' value={adressParticipante} onChange={(e) => setAdressParticipante(e.target.value)} placeholder={'Digite o endereço...'}></input>

                    <label className='title'>Estado</label>
                    <input className='input-edit-participante' type='text' value={estadoParticipante || ''} readOnly onClick={getEstadosCidades} placeholder={'Selecione o estado...'}></input>
                    {selectedEstadoIBGE ? (
                        <div className='card-select'>
                            {
                                listaEstadosIBGE.map((estado) => <li key={estado.id} className='item-list-select' onClick={() => selectEstado(estado.id, estado.sigla)}>{estado.sigla}</li>)
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>Cidade</label>
                    <input className='input-edit-participante' type='text' value={cityParticipante || ''} readOnly onClick={() => selectCidade(true)} placeholder={'Selecione a cidade...'}></input>
                    {selectedCidadeIBGE ? (
                        <div className='card-select'>
                            {
                                listaCidadesIBGE.map((cidade) => <li key={cidade.id} className='item-list-select' onClick={() => selectCidade(cidade.id, cidade.nome)}>{cidade.nome}</li>)
                            }
                        </div>
                    ) : <></>}

                    <label className='title'>CEP</label>
                    <input className='input-edit-participante' type='text' value={cepParticipante} onChange={(e) => setCepParticipante(e.target.value)} placeholder={'Digite o CEP...'}></input>

                    <label className='title'>Fone</label>
                    <input className='input-edit-participante' type='tel' value={foneParticipante} onChange={(e) => setFoneParticipante(e.target.value)} placeholder={'Digite o fone...'}></input>

                </div>

                <div className='edit-participante-footer'>
                    <div className='info-edit-participante'>{info}</div>
                    <div className='btns-edit-participante'>
                        <div>
                            <button className='btn-excluir' onClick={() => deleteParticipante(idParticipante)}>Excluir</button>
                        </div>
                        <div className='footer-rigth'>
                            <button className='btn-cancel' onClick={openCloseEditParticipanteModal}>Cancelar</button>
                            <button className='btn-save' onClick={validationForm}>Salvar</button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default EditParticipante;

